import React from 'react';
import { FormProps } from '../../../../types/FormProps';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import { axiosInstance } from '../../../../global';
import { useApiState } from '../../../../hooks/useApiState';
import useStatus from '../../../../hooks/useStatus';

const InstallmentForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const fetchPaymentType = async () => {
    const response = await axiosInstance.get('/PaymentType/dropdown');
    return response.data;
  };

  const {
    state: { isLoading: isPaymentTypeLoading, error: paymentTypeError, data: paymentTypeData },
    lazyApiCall: getPaymentTypeData
  } = useApiState({ apiCall: fetchPaymentType });

  const fetchAmountType = async () => {
    const response = await axiosInstance.get('/AmountType/dropdown');
    return response.data;
  };

  const {
    state: { isLoading: isAmountTypeLoading, error: amountTypeError, data: amountTypeData },
    lazyApiCall: getAmountTypeData
  } = useApiState({ apiCall: fetchAmountType });

  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Product');

  return (
    <>
      <CustomFormField name={'name'} label={'اسم النظام'} control={control} errors={errors} type={'text'} />
      <CustomFormField
        name={'paymentTypeId'}
        label={'طريقة الدفع'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={paymentTypeData?.data ?? []}
        disabled={isPaymentTypeLoading}
      />
      <CustomFormField
        name={'downPaymentAmountTypeId'}
        label={'نوع قيمة المقدم'}
        control={control}
        errors={errors}
        type={'toggle'}
        options={amountTypeData?.data ?? []}
        disabled={isAmountTypeLoading}
      />
      <CustomFormField
        name={'downPaymentAmount'}
        label={'قيمة المقدم'}
        control={control}
        errors={errors}
        type={'decimal'}
      />
      <CustomFormField
        name={'nbOfInstalments'}
        label={'عدد الاقساط'}
        control={control}
        errors={errors}
        type={'decimal'}
      />
      <CustomFormField
        name={'statusId'}
        label={'اختر حالة الاقساط'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={statusData?.data ?? []}
        disabled={isStatusLoading}
      />
    </>
  );
};

export default InstallmentForm;
