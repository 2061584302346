import React, { useRef } from 'react';
import { ColumnProps } from '../../../components/components/GenericDataTable/GenericDataTable';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
import * as Yup from 'yup';
import GenericModal from '../../../components/components/Modal/GenericModal';
import CustomerAddressForm from './Forms/CustomerAddressForm';
import { useToast } from '../../../context/ToastContext';

const validationSchema = Yup.object().shape({
  id: Yup.number().nullable().integer('ID must be an integer'),
  customerId: Yup.number().nullable().integer('Customer ID must be an integer'),
  name: Yup.string().required('Name is required').max(1000, 'Name cannot exceed 1000 characters'),
  locationId: Yup.number().nullable().integer('Location ID must be an integer'),
  landmark: Yup.string().required('Landmark is required').max(1000, 'Landmark cannot exceed 1000 characters'),
  statusId: Yup.number().nullable().integer('Status ID must be an integer'),
  remarks: Yup.string().nullable().max(1000, 'Remarks cannot exceed 1000 characters')
});

function CustomerGeoLocation() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const customerAddressColumns: ColumnProps[] = [
    { field: 'customerName', header: 'Customer' },
    { field: 'name', header: 'Name' },
    { field: 'address', header: 'Address' },
    { field: 'locationName', header: 'Location' },
    { field: 'areaName', header: 'Area' },
    { field: 'governorateName', header: 'Governorate' },
    { field: 'statusName', header: 'Status', isChip: true }
  ];

  const tabs = [
    {
      label: 'عناوين العملاء',
      tableHeader: 'قائمة عناوين العملاء',
      entityName: 'عناوين العملاء',
      dataKey: 'customerID',
      columns: customerAddressColumns,
      apiUrl: 'CustomersAddress/list', // Example API endpoint
      emptyStateUrl: '/no-data', // Example empty state URL
      rowOnClick: (rowData: any) =>
        openModal({
          id: rowData.id,
          customerId: rowData.customerID,
          address: rowData.address,
          name: rowData.name,
          countryId: rowData.countryID,
          governorateId: rowData.governorateID,
          areaId: rowData.areaID,
          locationId: rowData.locationID,
          landmark: rowData.landmark,
          statusId: rowData.statusID
        }),
      edit: true,
      headerButtons: [{ label: 'اضافه عنوان عميل', onClick: () => openModal() }]
    }
  ];

  const { isVisible, openModal, closeModal, control, handleSubmit, errors, setValue, loading, setLoading, watch } =
    useModal({ remarks: ' ', names: [] }, '/CustomersAddress', fetchTabData, validationSchema, toast);

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={tabs}
        headerTitle='العملاء'
        headerIcon='../images/productbox.svg' // Example icon
      />

      <GenericModal
        headerTitle={'إضافة عنوان جديد'}
        isVisible={isVisible}
        onHide={closeModal}
        handleSubmit={handleSubmit}
        toast={toast}
        loading={loading}
        isEditMode={watch('id') !== undefined}
      >
        <CustomerAddressForm control={control} errors={errors} setValue={setValue} watch={watch} />
      </GenericModal>
    </>
  );
}

export default CustomerGeoLocation;
