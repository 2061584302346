import React, { useEffect, useRef, useState } from 'react';
import './AddTransactionDetails.scss';
import '../../../style/Tables.scss';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import { Card } from 'primereact/card';
import AddTransactionProduct from '../../../components/Modals/AddTransactionProduct';
import { fill_transaction } from '../../../redux/warehouse/warehouseActions';
import { useToast } from '../../../context/ToastContext';

function AddIssuing2({ token, last_transaction, fill_transaction, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [addProductVisible, setAddProductVisible] = useState(false);
  const [transaction, setTransaction] = useState({
    items: [],
    currencyId: 1
  });

  useEffect(() => {
    fill_transaction(transaction);
    if (Object.values(transaction).filter((val, index) => val === '' || !val).length) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [transaction]);

  function handleAddProduct(newItem) {
    newItem.amount = +newItem.qty * +newItem.price;
    const _items = [...transaction.items, newItem];
    setTransaction({ ...transaction, items: _items });
  }

  const qtyEditor = (options) => {
    return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />;
  };

  const onRowEditComplete = (e) => {
    let _items = [...transaction.items];
    let { newData, index } = e;
    newData.amount = +newData.qty * +newData.price;
    _items[index] = newData;
    setTransaction({ ...transaction, items: _items });
  };

  function handleRemoveRow(e, rowData) {
    let _items = [...transaction.items];
    _items.splice(_items.indexOf(rowData), 1);
    setTransaction({ ...transaction, items: _items });
  }

  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      <div className='transaction'>
        <div className='flex flex-column'>
          <span className='Title'>المنتجات</span>
          <div className='tbl'>
            <div className='tbl__header'>
              <span className='header__title'>بيان المنتجات</span>
              <div className='tbl__btns__container mt-0'>
                <button className='tbl__addbtn tbl__addbtn--cst4' onClick={() => setAddProductVisible(true)}>
                  <img src='/images/plus.svg' />
                  إضافة منتج
                </button>
              </div>
              <AddTransactionProduct
                visible={addProductVisible}
                setVisible={setAddProductVisible}
                onAddHandler={handleAddProduct}
                warehouseId={last_transaction.warehouseId}
                transactionTypeId={last_transaction.transTypeId}
                destSourceId={last_transaction.destinationId}
                destID={
                  last_transaction.destinationWarehouseId ??
                  last_transaction.supplierId ??
                  last_transaction.customerId ??
                  last_transaction.employeeId
                }
              />
            </div>
            {loading ? (
              <div className='flex justify-content-center'>
                <ProgressSpinner />
              </div>
            ) : (
              <DataTable
                value={transaction.items}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                dataKey='productId'
                className='tbl__table'
                editMode='row'
                onRowEditComplete={onRowEditComplete}
                emptyMessage={
                  <div className='w-full flex justify-content-center'>
                    <span className='text-lg font-bold'>لم تقم بإضافة اي منتجات حتى الآن ،قم بالإضافة الآن</span>
                  </div>
                }
              >
                <Column header='اسم المنتج' field='name' />
                <Column header='الكمية المتاحة' field='availableQty' />
                <Column header='الكمية' field='qty' editor={(options) => qtyEditor(options)} />
                <Column header='سعر الوحدة' body={(rowData) => <span>{rowData.price}ج.م</span>} />
                <Column header='إجمالي' body={(rowData) => <span>{rowData.amount}ج.م</span>} />
                <Column header='خيارات' rowEditor headerStyle={{ width: '1rem' }} bodyStyle={{ textAlign: 'end' }} />
                <Column
                  headerStyle={{ width: '1rem' }}
                  body={(rowData) => (
                    <Button
                      icon='pi pi-trash'
                      severity='danger'
                      onClick={(e) => handleRemoveRow(e, rowData)}
                      text
                      rounded
                    />
                  )}
                />
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    last_transaction: state.warehouse.transaction
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_transaction: (transaction) => dispatch(fill_transaction(transaction))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIssuing2);
