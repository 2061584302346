import GenericPage from '../../../components/components/GenericPage/GenericPage';
import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductListDetailsTableTs from './ProductListDetailsTable';
import { useToast } from '../../../context/ToastContext';

const ProductListDetails = () => {
  const navigate = useNavigate();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const params = useParams();

  const toast = useToast();

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: '',
            emptyStateButtonClick: () => {
              // navigate('/product/services/add');
              // openModal();
            },
            dataKey: 'ProductsPricesListDetail',
            columns: [
              { header: 'اسم المنتج', field: 'serviceName', isChip: false },
              { header: 'السعر', field: 'cost', isChip: false },
              { header: 'العموله', field: 'commetion', isChip: false }
            ],
            rowOnClick: (rowData) => {},
            emptyStateUrl: '',
            edit: false,
            isHidden: true,
            apiUrl: `ProductsPricesListDetail/list/${params.id}`,
            entityName: 'قائمة منتجات المنتجات',
            headerButtons: [],
            tableHeader: 'قائمة اسعار منتجات'
          }
        ]}
        headerTitle={'المنتجات'}
        headerIcon={'../images/productbox.svg'}
        content={<ProductListDetailsTableTs />}
      />
      ;
    </>
  );
};

export default ProductListDetails;
