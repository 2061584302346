import { axiosInstance } from '../../global';

import {
  ADD_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  CHANGE_PRODUCT_STATE,
  CLEAR_PRODUCT_STATE,
  EDIT_PRODUCT_FAILURE,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS
} from './productTypes';
import moment from 'moment/moment';

export const clearProductState = () => {
  return {
    type: CLEAR_PRODUCT_STATE
  };
};

export const fetchProduct = (id, token) => {
  return (dispatch) => {
    dispatch(fetch_product_request());

    axiosInstance
      .get(`/Product/new/${id}`)
      .then((response) => {
        const customer = response.data.data;
        dispatch({ type: FETCH_PRODUCT_SUCCESS, payload: customer });
      })
      .catch((error) => {
        dispatch({ type: FETCH_PRODUCT_FAILURE, payload: error.message });
      });
  };
};

export const fetchSpares = (id, token) => {
  return (dispatch) => {
    dispatch(fetch_product_request());
    axiosInstance
      .get(`/Product/spareParts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((responseProductData) => {
        let tempProduct = responseProductData.data.data;
        tempProduct.code = tempProduct.code;
        tempProduct.skuCode = tempProduct.skuCode;
        tempProduct.name = tempProduct.name;
        tempProduct.productCategory = tempProduct.productCategory.id;
        tempProduct.brand = tempProduct.brand.id;
        tempProduct.manufacturer = tempProduct.manufacturer.id;
        tempProduct.nbOfStages = tempProduct.nbOfStages;
        tempProduct.status = tempProduct.status.id;
        tempProduct.countryOfOrigin = tempProduct.countryOfOrigin.id;
        tempProduct.remarks = tempProduct.remarks;
        tempProduct.details = tempProduct.details;
        tempProduct.productsImages = tempProduct.productsImages;
        tempProduct.installmentTypesIds = tempProduct.installmentTypesIds;
      })
      .catch((errorProductData) => {
        dispatch(fetch_product_failure(errorProductData.message));
      });
  };
};

const fetch_product_request = () => {
  return {
    type: FETCH_PRODUCT_REQUEST
  };
};

const clear_product_request = () => {
  return {
    type: CLEAR_PRODUCT_STATE
  };
};

const fetch_product_success = (product) => {
  return {
    type: FETCH_PRODUCT_SUCCESS,
    payload: product
  };
};
const fetch_product_failure = (error) => {
  return {
    type: FETCH_PRODUCT_FAILURE,
    payload: error
  };
};

export const addProduct = (product) => {
  return async (dispatch) => {
    product.creationDateTime = product.creationDateTime
      ? moment(product.creationDateTime).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]')
      : null;
    dispatch(add_product_request());

    try {
      const response = await axiosInstance.post(`/Product`, product);
      const addedProduct = response.data.data;
      dispatch(add_product_success(addedProduct));
      return addedProduct;
    } catch (error) {
      dispatch(add_product_failure(error.message));
      throw error; // Propagate the error to handle it in the component
    }
  };
};

export const addSpares = (product, token) => {
  return (dispatch) => {
    dispatch(add_product_request());
    axiosInstance
      .post(`/Product/spareParts`, product, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(add_product_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_product_failure(error.message));
      });
  };
};

const add_product_request = () => {
  return {
    type: ADD_PRODUCT_REQUEST
  };
};
export const add_product_success = (Product) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: Product
  };
};
const add_product_failure = (error) => {
  return {
    type: ADD_PRODUCT_FAILURE,
    payload: error
  };
};

export const editProduct = (product, token) => {
  return (dispatch) => {
    dispatch(edit_product_request());
    axiosInstance
      .put(`/Product`, product, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(edit_product_success(product));
      })
      .catch((error) => {
        dispatch(edit_product_failure(error.message));
      });
  };
};
export const editSpares = (product, token) => {
  return (dispatch) => {
    dispatch(edit_product_request());
    axiosInstance
      .put(`/Product/spareParts`, product, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(edit_product_success(product));
      })
      .catch((error) => {
        dispatch(edit_product_failure(error.message));
      });
  };
};
const edit_product_request = () => {
  return {
    type: EDIT_PRODUCT_REQUEST
  };
};
export const edit_product_success = (product) => {
  return {
    type: EDIT_PRODUCT_SUCCESS,
    payload: product
  };
};
const edit_product_failure = (error) => {
  return {
    type: EDIT_PRODUCT_FAILURE,
    payload: error
  };
};

export const fill_product = (product) => {
  return (dispatch) => {
    dispatch(change_product_state(product));
  };
};

const change_product_state = (product) => {
  return {
    type: CHANGE_PRODUCT_STATE,
    payload: product
  };
};
