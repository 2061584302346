import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FormProvider, useForm } from 'react-hook-form'; // Import FormProvider from react-hook-form
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  addCustomer,
  clearCustomerState,
  editCustomer,
  fetchCustomer,
  fill_customer
} from '../../../redux/customer/customerActions';
import './AddCustomer.scss';
import { useToast } from '../../../context/ToastContext';

const AddCustomer1 = lazy(() => import('./AddCustomer1'));
const AddCustomer2 = lazy(() => import('./AddCustomer2'));

interface AddCustomerProps {
  activeIndexnum?: number;
  addCustomer: (customer: any) => void;
  editCustomer: (customer: any) => void;
  fetchCustomer: (id: string) => void;
  fill_customer: (customer: any) => void;
  clearCustomerState: () => void;
  customer: any;
  loading: boolean;
  error: string | null;
}

// Define a validation schema for both steps
const combinedSchema = yup.object().shape({
  // Step 1 fields
  fullName: yup
    .string()
    .matches(
      /^(?=.*[ ])[a-zA-Z\u0600-\u06FF]+(([',. -][a-zA-Z\u0600-\u06FF ])?[a-zA-Z\u0600-\u06FF]*)*$/,
      'الاسم غير صحيح'
    )
    .required('نوع العميل مطلوب'),
  typeId: yup.string().required('نوع العميل مطلوب'),
  nationalNb: yup
    .string()
    .length(14, 'الرقم القومي يجب ان يكون مكون من 14 رقم')
    .matches(/^[123]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-9]\d)\d{4}\d$/, 'الرقم القومي غير صحيح')
    .required('الرقم القومي مطلوب'),
  jobTitle: yup.string().required('الوظيفة مطلوبة'),
  whatsappNb: yup
    .string()
    .length(11, 'رقم الهاتف يجب ان يكون 11 رقم')
    .matches(/^(01[0-2,5]\d{8}|0\d{2}\d{7})$/, 'رقم الهاتف غير صحيح')
    .required('رقم الواتساب مطلوب'),

  // Step 2 fields
  mobile: yup.string().when('activeIndex', {
    is: (value: number) => value === 1, // when the activeIndex is 1 (Step 2)
    then: (schema) => schema.required('رقم الموبايل مطلوب'), // make required in step 2
    otherwise: (schema) => schema.notRequired() // not required in step 1
  }),
  phoneNb: yup.string().when('activeIndex', {
    is: (value: number) => value === 1, // when the activeIndex is 1 (Step 2)
    then: (schema) => schema.required('رقم الهاتف مطلوب'), // make required in step 2
    otherwise: (schema) => schema.notRequired() // not required in step 1
  }),
  preferredContactMethodId: yup.string().when('activeIndex', {
    is: (value: number) => value === 1, // when the activeIndex is 1 (Step 2)
    then: (schema) => schema.required('طريقة التواصل المفضلة مطلوبة'), // make required in step 2
    otherwise: (schema) => schema.notRequired() // not required in step 1
  }),
  statusId: yup.string().when('activeIndex', {
    is: (value: number) => value === 1, // when the activeIndex is 1 (Step 2)
    then: (schema) => schema.required('حالة الحساب مطلوبة'), // make required in step 2
    otherwise: (schema) => schema.notRequired() // not required in step 1
  }),
  addressName: yup.string().when('activeIndex', {
    is: (value: number) => value === 1, // when the activeIndex is 1 (Step 2)
    then: (schema) => schema.required('اسم العنوان مطلوب'), // make required in step 2
    otherwise: (schema) => schema.notRequired() // not required in step 1
  }),
  address: yup.string().when('activeIndex', {
    is: (value: number) => value === 1, // when the activeIndex is 1 (Step 2)
    then: (schema) => schema.required('العنوان مطلوب'), // make required in step 2
    otherwise: (schema) => schema.notRequired() // not required in step 1
  }),
  landmark: yup.string().when('activeIndex', {
    is: (value: number) => value === 1, // when the activeIndex is 1 (Step 2)
    then: (schema) => schema.required('علامة مميزة مطلوبة'), // make required in step 2
    otherwise: (schema) => schema.notRequired() // not required in step 1
  }),
  locationId: yup.string().when('activeIndex', {
    is: (value: number) => value === 1, // when the activeIndex is 1 (Step 2)
    then: (schema) => schema.required('المنطقة مطلوبة'), // make required in step 2
    otherwise: (schema) => schema.notRequired() // not required in step 1
  }),
  addressStatusId: yup.string().when('activeIndex', {
    is: (value: number) => value === 1, // when the activeIndex is 1 (Step 2)
    then: (schema) => schema.required('حالة العنوان مطلوبة'), // make required in step 2
    otherwise: (schema) => schema.notRequired() // not required in step 1
  })
});

const AddCustomer: React.FC<AddCustomerProps> = ({
  activeIndexnum,
  addCustomer,
  editCustomer,
  fetchCustomer,
  fill_customer,
  clearCustomerState,
  customer,
  loading,
  error
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const [activeIndex, setActiveIndex] = useState(0);
  const [submitDone, setSubmitDone] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Use `useForm` for form state management
  const methods = useForm({
    resolver: yupResolver(combinedSchema),
    mode: 'all',
    defaultValues: customer,
    context:{activeIndex}
  });

  const { handleSubmit, trigger, reset } = methods;

  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      fetchCustomer(params.id);
    } else {
      clearCustomerState();
    }
    return () => {
      clearCustomerState();
    };
  }, [params.id, fetchCustomer, clearCustomerState]);

  useEffect(() => {
    if (customer && isEditing) {
      reset(customer); // Populate the form with customer data when editing
    }
  }, [customer, isEditing, reset]);

  useEffect(() => {
    setActiveIndex(activeIndexnum ?? 0);
  }, [activeIndexnum]);

  const items = [{ label: 'بيانات العميل الاساسية' }, { label: 'تفاصيل العنوان و المنطقة' }];

  useEffect(() => {
    if (submitDone && !loading) {
      if (error) {
        toast.current?.show({
          severity: 'error',
          summary: 'عملية خاطئة',
          detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} العميل: ${error}`
        });
      } else {
        toast.current?.show({
          severity: 'success',
          summary: 'عملية ناجحة',
          detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} العميل بنجاح`
        });
        navigate('/customers');
      }
      setSubmitDone(false);
    }
  }, [loading, error, submitDone, navigate, isEditing]);

  const handleOnClick = async () => {
    const isValid = await trigger(); // Trigger validation before moving to the next step
    const errors = methods.formState.errors; // Retrieve the form errors
    
    if (isValid) {
      setActiveIndex((prevIndex) => (prevIndex >= 1 ? 0 : prevIndex + 1));
    }
  };

  const handleBackClick = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleSubmitOnClick = (data: any) => {
    if (isEditing) {
      editCustomer(data);
    } else {
      addCustomer(data);
    }
    setSubmitDone(true);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitOnClick)}>
          <div className='flex flex-column add__visit__main'>
            <div className='details__header'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='details__header1__right--text'>{isEditing ? 'تعديل عميل' : 'إضافة عميل جديد'}</span>
            </div>
            <div className='flex steps card'>
              <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly />
            </div>
            <div>
              {loading ? (
                <ProgressSpinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
              ) : (
                <Suspense fallback={<ProgressSpinner />}>
                  {activeIndex === 0 && <AddCustomer1 />}
                  {activeIndex === 1 && <AddCustomer2 />}
                </Suspense>
              )}
              <div className='btn mb-5'>
                {activeIndex !== 0 && (
                  <Button className='btn__nextbtn' onClick={handleBackClick}>
                    العودة للخطوة السابقة
                  </Button>
                )}
                {activeIndex !== 1 && (
                  <Button className='btn__nextbtn' onClick={handleOnClick}>
                    الخطوة التالية
                  </Button>
                )}
                {activeIndex === 1 && (
                  <Button className='btn__nextbtn' type='submit'>
                    {isEditing ? 'حفظ التعديلات' : 'إضافة العميل'}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  customer: state.customer.customer,
  loading: state.customer.loading,
  error: state.customer.error
});

const mapDispatchToProps = {
  fetchCustomer,
  addCustomer,
  editCustomer,
  fill_customer,
  clearCustomerState
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
