import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import DetailsSheetGrid from '../../../components/DetailsSheet/DetailsSheetGrid';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import useFetch from '../../../hooks/useFetch';
import { axiosInstance } from '../../../global';
import { useApiState } from '../../../hooks/useApiState';
import * as yup from 'yup';
import { useToast } from '../../../context/ToastContext';
import useModal from '../../../hooks/useModal';
import AddEditEvaluationForm from '../Forms/AddEditEvaluationForm';
import GenericModal from '../../../components/components/Modal/GenericModal';
import { Rating } from 'primereact/rating';
import { confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment';

const EvaluationSheme = yup.object().shape({
  id: yup.number().nullable().integer('ID must be an integer'),
  typeId: yup.number().required('type is required'),
  evaluationTypeId: yup.number().required('evaluationType is required'),
  referenceId: yup.number().required('reference is required'),
  reviewerComment: yup.string().nullable(),
  remarks: yup.string().nullable()
});

const CustomerServiceDetails = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };

  const location = useLocation();
  const { visitId, activityId, complaintId, customerId, type, revesionId, completed } = location.state || {};

  const [activityID, setActivityID] = useState(null);

  // const { data: visitData, isLoading: visitIsLoading } = useFetch(`/Visit/${visitId}`);
  const { data: visitData, isLoading: visitIsLoading } =
    type === 'Visit' ? useFetch(`/Visit/${visitId}`) : { data: null, isLoading: false };
  const { data: activityData, isLoading: activityIsLoading } =
    type === 'Customer Activitiy' ? useFetch(`/CustomersActivity/${activityId}`) : { data: null, isLoading: false };

  const { data: complaintData, isLoading: complaintIsLoading } =
    type === 'Customer Complaint' ? useFetch(`/CustomersComplaint/${complaintId}`) : { data: null, isLoading: false };
  const { data: customerData, isLoading: customerIsLoading } = useFetch(`/Customer/${customerId}`);
  const { data: revesionData, isLoading: revesionIsLoading } = completed
    ? useFetch(`/Revision/${revesionId}`)
    : { data: null, isLoading: false };

  const fetchActivityonVisit = async () => {
    const response = await axiosInstance.get(`/CustomersActivity/${activityID}`);
    return response.data;
  };

  const { lazyApiCall, state } = useApiState({ apiCall: fetchActivityonVisit, isLazy: true });

  useEffect(() => {
    setActivityID(visitData?.data?.customerActivity?.id);
    if (activityID && type == 'Visit') {
      lazyApiCall();
    }
  }, [visitData]);

  function refreshPage() {
    window.location.reload();
  }

  const {
    isVisible: isevaluationVisitable,
    openModal: evaluationOpenModal,
    closeModal: evaluationCloseModal,
    control: evaluationControl,
    handleSubmit: evaluationSubmit,
    errors: evaluationErrors,
    setValue: evaluationSetValue,
    loading: evaluationLoading,
    setLoading: evaluationSetLoading,
    watch: evaluationWatch
  } = useModal(
    {
      typeId: type === 'Visit' ? 1 : type === 'Customer Activitiy' ? 3 : type === 'Customer Complaint' ? 2 : null,
      referenceId:
        type === 'Visit'
          ? visitId
          : type === 'Customer Activitiy'
          ? activityId
          : type === 'Customer Complaint'
          ? complaintId
          : null
    },
    '/Revision',
    completed
      ? refreshPage
      : () => {
          setTimeout(() => {
            navigate('/customerService/Evaluation');
          }, 1000);
        },
    EvaluationSheme,
    toast
  );

  function showConfirmDeleteEntity() {
    confirmDialog({
      message:
        type === 'Visit'
          ? 'هل تريد حذف هذه الزيارة'
          : type === 'Customer Activitiy'
          ? 'هل تريد حذف هذا الاتصال'
          : type === 'Customer Complaint'
          ? 'هل تريد حذف هذه الشكوى'
          : '',
      header:
        type === 'Visit'
          ? 'تأكيد حذف الزيارة'
          : type === 'Customer Activitiy'
          ? 'تأكيد حذف الاتصال'
          : type === 'Customer Complaint'
          ? 'تأكيد حذف الشكوى'
          : '',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept:
        type === 'Visit'
          ? () => handleDeleteEntity(visitId, 'Visit', 'الزيارة')
          : type === 'Customer Activitiy'
          ? () => handleDeleteEntity(activityId, 'CustomersActivity', 'الاتصال')
          : type === 'Customer Complaint'
          ? () => handleDeleteEntity(complaintId, 'CustomersComplaint', 'الشكوى')
          : undefined,
      draggable: false,
      resizable: false
    });
  }

  function handleDeleteEntity(id: number, url: string, type: string) {
    axiosInstance
      .delete(`/${url}/${id}`, {})
      .then(() => {
        toast.current?.show({
          severity: 'success',
          summary: `تم حذف ${type}`,
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate(-1), 3000);
      })
      .catch(() => {
        toast.current?.show({
          severity: 'error',
          summary: `حدث خطأ اثناء حذف ${type}`,
          detail: '',
          life: 3000
        });
      });
  }

  return (
    <>
      {/* ======================================================the header of page ================================================= */}

      <div className='detailsheet__header'>
        <div className='detailsheet__header__right'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='detailsheet__header__right--text'>
            {type === 'Visit'
              ? 'بيانات الزيارة'
              : type === 'Customer Activitiy'
              ? 'بيانات الاتصال'
              : type === 'Customer Complaint'
              ? 'بيانات الشكوى'
              : ''}
          </span>
        </div>

        <div className='detailsheet__header__left'>
          <Button
            className='detailsheet__header__left__btn'
            onClick={
              type === 'Visit'
                ? () => navigate(`/visit/EditVisit/${visitId}/${customerId}`)
                : type === 'Customer Activitiy'
                ? () => navigate(`/Activites/Edit/${activityId}`)
                : type === 'Customer Complaint'
                ? () => navigate(`/customers/Complaints`)
                : undefined
            }
            icon='pi pi-pencil'
            style={Style1}
            text
          >
            {type === 'Visit'
              ? 'تعديل الزيارة'
              : type === 'Customer Activitiy'
              ? 'تعديل الاتصال'
              : type === 'Customer Complaint'
              ? 'تعديل الشكوى'
              : ''}
          </Button>

          <Button
            className='detailsheet__header__left__btn'
            onClick={showConfirmDeleteEntity}
            icon='pi pi-trash'
            style={Style2}
            text
          >
            {type === 'Visit'
              ? 'حذف الزيارة'
              : type === 'Customer Activitiy'
              ? 'حذف الاتصال'
              : type === 'Customer Complaint'
              ? 'حذف الشكوى'
              : ''}
          </Button>

          <Button
            className='detailsheet__header__left btn__nextbtn'
            onClick={
              !completed
                ? () => evaluationOpenModal()
                : () =>
                    evaluationOpenModal({
                      id: revesionId,
                      typeId:
                        type === 'Visit'
                          ? 1
                          : type === 'Customer Activitiy'
                          ? 3
                          : type === 'Customer Complaint'
                          ? 2
                          : null,
                      code: revesionData?.data?.code,
                      evaluationTypeId: revesionData?.data?.evaluationType?.id,
                      referenceId: revesionData?.data?.referenceId,
                      reviewerComment: revesionData?.data?.reviewerComment,
                      userId: revesionData?.data?.user?.id,
                      remarks: revesionData?.data?.remarks
                    })
            }
          >
            {!completed ? 'تقييم' : 'تعديل التقييم'}
          </Button>
        </div>
      </div>

      {/* ======================================================the body of page ================================================= */}
      <div className='detailsheet__contentgeneral'>
        {visitIsLoading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          type === 'Visit' && (
            <div className='customerdetails__header__card'>
              <HeaderCardTS
                title={'مراد تحصيل من العميل'}
                num={15000}
                imageUrl={'/images/dollar.svg'}
                color={'#F9D8D433'}
              />
              <HeaderCardTS
                title={'تاريخ الزيارة'}
                num={visitData?.data?.visitDate}
                imageUrl={'/images/alt.svg'}
                color={'#6D54DE33'}
              />
              <HeaderCardTS
                title={'حالة الزيارة'}
                num={<Chip label={visitData?.data?.status?.name} />}
                imageUrl={'/images/briefcase.svg'}
                color={'#FFEDD0'}
              />
              <HeaderCardTS
                title={'منشئ الزيارة'}
                num={visitData?.data?.assignedRep?.name}
                imageUrl={'/images/user1.svg'}
                color={'#C050E838'}
              />
            </div>
          )
        )}

        {activityIsLoading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          type === 'Customer Activitiy' && (
            <div className='customerdetails__header__card'>
              <HeaderCardTS
                title={'الاجراء المتخذ'}
                num={activityData?.data?.decision?.name}
                imageUrl={'/images/file3.svg'}
                color={'#F9D8D433'}
              />
              <HeaderCardTS
                title={'تاريخ الاتصال'}
                num={moment(activityData?.data?.activityDateTime)
                  .locale('en-GB')
                  .format('YYYY-MM-DD')}
                imageUrl={'/images/alt.svg'}
                color={'#6D54DE33'}
              />
              <HeaderCardTS
                title={'حالة الاتصال'}
                num={<Chip label={activityData?.data?.contactStatus} />}
                imageUrl={'/images/briefcase.svg'}
                color={'#FFEDD0'}
              />
              <HeaderCardTS
                title={'منشئ الاتصال'}
                num={activityData?.data?.user?.firstName}
                imageUrl={'/images/user1.svg'}
                color={'#C050E838'}
              />
            </div>
          )
        )}

        {complaintIsLoading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          type === 'Customer Complaint' && (
            <div className='customerdetails__header__card'>
              <HeaderCardTS
                title={'الاجراء المتخذ'}
                num={complaintData?.data?.actionTaken}
                imageUrl={'/images/file3.svg'}
                color={'#F9D8D433'}
              />
              <HeaderCardTS
                title={'تاريخ الشكوى'}
                num={complaintData?.data?.complaintDate}
                imageUrl={'/images/alt.svg'}
                color={'#6D54DE33'}
              />
              <HeaderCardTS
                title={'حالة الشكوى'}
                num={<Chip label={complaintData?.data?.complaintStatus} />}
                imageUrl={'/images/briefcase.svg'}
                color={'#FFEDD0'}
              />
              <HeaderCardTS
                title={'منشئ الاتصال'}
                num={complaintData?.data?.employeeComplained?.name}
                imageUrl={'/images/user1.svg'}
                color={'#C050E838'}
              />
            </div>
          )
        )}

        <div className='detailsheet__contentgeneral__grid'>
          {customerIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            customerData?.data && (
              <DetailsSheetGrid
                headers={['الاسم كامل', 'حالة العميل', 'رقم الهاتف']}
                contents={[
                  <div className='flex flex-row gap-5'>
                    {customerData?.data?.fullName}
                    <Link to={`/customers/details/${customerId}`} className='primary__data--link text-blue-600'>
                      عرض بيانات العميل{` > `}
                    </Link>
                  </div>,
                  <Chip label={customerData?.data?.status?.name} />,
                  customerData?.data.mobile
                ]}
                title={'بيانات العميل'}
              />
            )
          )}

          {revesionIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : !completed ? (
            <DetailsSheetGrid
              headers={['حالة المراجعة']}
              contents={[
                <div className='flex flex-row gap-5'>
                  {'لم يتم المراجعة'}
                  <button
                    onClick={() => evaluationOpenModal()}
                    className='border-transparent	bg-white	primary__data--link text-blue-600'
                  >
                    قيم الزيارة الأن{` > `}{' '}
                  </button>
                </div>
              ]}
              title={'تفاصيل المراجعة'}
            />
          ) : (
            revesionData?.data && (
              <DetailsSheetGrid
                headers={[
                  'تاريخ المراجعة',
                  'كود المراجعة',
                  'منشئ المراجعة',
                  'نوع المراجعة',
                  'التقييم',
                  'ملاحظات المراجع',
                  'ملاحظات اخرى'
                ]}
                contents={[
                  revesionData?.data?.creationDateTime,
                  revesionData?.data?.code,
                  revesionData?.data?.user?.userName,
                  revesionData?.data?.type?.name,
                  revesionData?.data?.evaluationType?.name,
                  revesionData?.data?.reviewerComment,
                  revesionData?.data?.remarks
                ]}
                title={'تفاصيل المراجعة'}
              />
            )
          )}

          {activityIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            (activityData?.data || state?.data?.data) && (
              <DetailsSheetGrid
                headers={[
                  'نوع الاتصال',
                  'حالة الاتصال',
                  'فئة الاتصال',
                  'تصنيف الاتصال',
                  'سبب الاتصال',
                  'طريقة الاتصال',
                  'الإجراء المتخذ',
                  'تفاصيل الطلب',
                  'تفاصيل الرد على العميل',
                  'ملاحظات اخرى'
                ]}
                contents={[
                  state?.data?.data?.contactType || activityData?.data?.contactType,
                  <Chip label={state?.data?.data?.contactStatus || activityData?.data?.contactStatus} />,
                  state?.data?.data?.contactFamily || activityData?.data?.contactFamily,
                  state?.data?.data?.contactCategory || activityData?.data?.contactCategory,
                  state?.data?.data?.reason?.name || activityData?.data?.reason?.name,
                  state?.data?.data?.contactMethod || activityData?.data?.contactMethod,
                  state?.data?.data?.decision?.name || activityData?.data?.decision?.name,
                  state?.data?.data?.requestDetails || activityData?.data?.requestDetails,
                  state?.data?.data?.responseDetails || activityData?.data?.responseDetails,
                  state?.data?.data?.remarks || activityData?.data?.remarks
                ]}
                title={'بيانات الاتصال'}
              />
            )
          )}

          {visitIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            visitData?.data && (
              <DetailsSheetGrid
                headers={[
                  // 'الجهاز المشكو عنه',
                  'حالة الزيارة',
                  // 'كود الجهاز',
                  'تاريخ انشاء العقد',
                  // 'نوع العقد',
                  'رقم العقد',
                  'تاريخ انتهاء العقد',
                  'نوع الزيارة',
                  'اختر مستوى رضاء العميل',
                  'الفني',
                  'ملاحظات الفني',
                  'ملاحظات اخرى'
                ]}
                contents={[
                  // visitData?.data?.deviceName,
                  <Chip label={visitData?.data?.status?.name} />,
                  // visitData?.data?.deviceCode,
                  visitData?.data?.contract?.contractDate,
                  // visitData?.data?.contactType,
                  visitData?.data?.contract?.code,
                  visitData?.data?.contract?.expiryDate,
                  visitData?.data?.type?.name,
                  <Rating value={visitData?.data?.customerActivity?.satisfactionLevel} cancel={false} />,
                  visitData?.data?.assignedRep?.name,
                  visitData?.data?.repRemarks,
                  visitData?.data?.remarks
                ]}
                title={'بيانات الزيارة'}
              />
            )
          )}

          {complaintIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            complaintData?.data && (
              <DetailsSheetGrid
                headers={[
                  'نوع الشكوى',
                  'حالة الشكوى',
                  'فئة الشكوى',
                  'تصنيف الشكوى',
                  ' سبب الشكوى',
                  'طريقة الشكوى',
                  'الاجراء المتخذ',
                  'تفاصيل الطلب',
                  'تفاصيل الرد على العميل', //
                  'ملاحظات اخرى'
                ]}
                contents={[
                  complaintData?.data?.complaintType,
                  <Chip label={complaintData?.data?.complaintStatus} />,
                  complaintData?.data?.complaintCategory,
                  complaintData?.data?.complaintFamily,
                  complaintData?.data?.reason,
                  complaintData?.data?.contactMethod,
                  complaintData?.data?.actionTaken,
                  complaintData?.data?.details,
                  '',
                  complaintData?.data.remarks
                ]}
                title={'بيانات الشكوى'}
              />
            )
          )}
        </div>
      </div>

      {/* ======================================================the form of page ================================================= */}

      <GenericModal
        headerTitle={evaluationWatch('id') === undefined ? 'اضافه جهاز جديد' : 'تعديل جهاز'}
        isVisible={isevaluationVisitable}
        onHide={evaluationCloseModal}
        handleSubmit={evaluationSubmit}
        toast={toast}
        loading={evaluationLoading}
        isEditMode={evaluationWatch('id') !== undefined}
      >
        <AddEditEvaluationForm
          control={evaluationControl}
          errors={evaluationErrors}
          setValue={evaluationSetValue}
          watch={evaluationWatch}
          extraProps={{ entityName: 'جهاز' }}
        />
      </GenericModal>
    </>
  );
};

export default CustomerServiceDetails;
