import React, { useEffect, useRef, useState } from 'react';
import './AddCovenantDetails.scss';
import '../../../style/Tables.scss';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { useToast } from '../../../context/ToastContext';
import { fill_covenant } from '../../../redux/warehouse/warehouseActions';
import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';
import useFetch from '../../../hooks/useFetch';

function AddCovenant1({ token, fill_covenant, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [searchTerms, setSearchTerms] = useState({ name: '', startDate: '', endDate: '' });
  // const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [covenant, setTransaction] = useState({
    items: [],
    currencyId: 1
  });

  const { data: employeeData, isLoading: isEmployeeLoading } = useFetch('/Employee/dropdown');

  useEffect(() => {
    fill_covenant(covenant);
    if (Object.values(covenant).filter((val, index) => val === '' || !val).length) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [covenant]);

  function handleAddProduct(newItem) {
    newItem.amount = +newItem.qty * +newItem.price;
    const _items = [...covenant.items, newItem];
    setTransaction({ ...covenant, items: _items });
  }

  const qtyEditor = (options) => {
    return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />;
  };

  const onRowEditComplete = (e) => {
    let _items = [...covenant.items];
    let { newData, index } = e;
    newData.amount = +newData.qty * +newData.price;
    _items[index] = newData;
    setTransaction({ ...covenant, items: _items });
  };

  function handleRemoveRow(e, rowData) {
    let _items = [...covenant.items];
    _items.splice(_items.indexOf(rowData), 1);
    setTransaction({ ...covenant, items: _items });
  }

  function handleSearch(e) {}

  const SearchFields = () => (
    <div className='grid align-items-center mb-4'>
      <div className='flex flex-column gap-2 col-4'>
        <label htmlFor='username'>اسم المندوب</label>
        <CustomDropdown
          name='name'
          options={employeeData?.data ?? []}
          value={selectedEmployee}
          optionLabel='name'
          optionValue='id'
          disabled={isEmployeeLoading}
          onChange={(e) => {
            setSelected(e.value);
            setSearchTerms({ ...searchTerms, name: e.value });
          }}
        />
      </div>
      <div className='flex flex-column gap-2 col-fixed w-20rem'>
        <label htmlFor='username'>تاريخ من</label>
        <Calendar
          name='startDate'
          value={searchTerms.startDate}
          onChange={(e) => {
            setSelected(e.value);
            setSearchTerms({ ...searchTerms, startDate: e.value });
          }}
          showIcon
        />
      </div>
      <div className='flex flex-column gap-2 col-fixed w-20rem'>
        <label htmlFor='username'>تاريخ الى</label>
        <Calendar
          name='endDate'
          value={searchTerms.endDate}
          onChange={(e) => {
            setSelected(e.value);
            setSearchTerms({ ...searchTerms, endDate: e.value });
          }}
          showIcon
        />
      </div>
      <div className='col mt-5'>
        <button className='w-10 tbl__addbtn--cst4 ' onClick={handleSearch}>
          بحث
        </button>
      </div>
    </div>
  );

  return (
    <div className='covenant'>
      <div className='tbl surface-border	'>
        <span className='Title mb-6'>المنتجات</span>
        <SearchFields />
        {loading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <DataTable
            value={covenant.items}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            dataKey='productId'
            className='tbl__table'
            editMode='row'
            onRowEditComplete={onRowEditComplete}
            emptyMessage={
              <div className='w-full flex justify-content-center'>
                <span className='text-lg font-bold'>لم تضافة اي منتجات للموظف في هذه الفترة</span>
              </div>
            }
          >
            <Column header='اسم المنتج' field='name' />
            <Column header='الكمية المتاحة' field='availableQty' />
            <Column header='الكمية' field='qty' editor={(options) => qtyEditor(options)} />
            <Column header='سعر الوحدة' body={(rowData) => <span>{rowData.price}ج.م</span>} />
            <Column header='إجمالي' body={(rowData) => <span>{rowData.amount}ج.م</span>} />

            <Column header='خيارات' rowEditor headerStyle={{ width: '1rem' }} bodyStyle={{ textAlign: 'end' }} />
            <Column
              headerStyle={{ width: '1rem' }}
              body={(rowData) => (
                <Button
                  icon='pi pi-trash'
                  severity='danger'
                  onClick={(e) => handleRemoveRow(e, rowData)}
                  text
                  rounded
                />
              )}
            />
          </DataTable>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_covenant: (covenant) => dispatch(fill_covenant(covenant))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCovenant1);
