import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Message } from 'primereact/message';
import { connect } from 'react-redux';

import './Modals.scss';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';

import { axiosInstance } from '../../global';
import CustomDropdown from '../components/Dropdown/CustomDropDown';
import { useToast } from '../../context/ToastContext';

function EditVacationRequest({
  token,
  employeeId,
  title,
  entityName,
  visible,
  setVisible,
  statuses,
  onClosePrompt,
  onAddHandler,
  onUpdateHandler,
  initialData
}) {
  const toast = useToast();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(employeeId);
  const [selectedVacationsType, setSelectedVacationsType] = useState(null);
  const [vacationsTypes, setVacationsTypes] = useState(null);
  const [dates, setDates] = useState(null);
  const [days, setDays] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [entity, setEntity] = useState({
    statusId: null,
    employeeId,
    vacationTypeId: null,
    fromDate: Date,
    toDates: Date,
    days: Number,
    remarks: ''
  });

  const handleSave = () => {
    if (initialData) {
      onUpdateHandler(entity);
    } else {
      onAddHandler(entity);
    }

    acceptClose();
  };

  useEffect(() => {
    axiosInstance
      .get(`/Employee/all`)
      .then((response) => {
        setEmployees(response.data.data);
      })
      .catch((error) => {});

    axiosInstance
      .get(`/VacationsType/all`)
      .then((response) => {
        setVacationsTypes(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (initialData) {
      setSelectedStatus(initialData.status.id);
      setSelectedEmployee(initialData.employee.id);
      setSelectedVacationsType(initialData.vacationType.id);
      setDates([new Date(initialData.fromDate), new Date(initialData.toDates)]);
      setDays(initialData.days);
      setEntity(initialData);
    }
  }, [initialData, vacationsTypes, statuses]);

  function acceptClose() {
    setEntity({
      statusId: null,
      employeeId,
      vacationTypeId: null,
      fromDate: null,
      toDates: null,
      days: null,
      remarks: ''
    });
    setSelectedStatus(null);
    setSelectedVacationsType(null);
    setSelectedEmployee(null);
    setDates(null);
    setDays(0);
    setStartDate(null);
    setEndDate(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 4
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  function handleEmployeeChange(e) {
    setSelectedEmployee(e.value);
    setEntity({ ...entity, employeeId: e.value });
  }

  function handleVacationChange(e) {
    setSelectedVacationsType(e.value);
    setEntity({ ...entity, vacationTypeId: e.value });
  }

  const handleDateChange = (e) => {
    setDates(e.value);
    // Calculate the number of days
    if (e.value && e.value.length >= 1) {
      const startDate = new Date(e.value[0]);
      setStartDate(startDate);
      const endDate = e.value.length === 2 ? new Date(e.value[1]) : startDate;
      setEndDate(endDate);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;
      setDays(daysDifference);
      setEntity({ ...entity, days: daysDifference, fromDate: startDate, toDates: endDate });
    }
  };

  function handleDeleteItem(e) {
    axiosInstance
      .delete(`/VacationsRequest/${initialData.id}`)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم حذف الاجازة', life: 3000 });
        acceptClose();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء حذف الاجازة', life: 3000 });
      });
  }

  return (
    <>
      
      <ConfirmDialog tagKey={4} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          {!employeeId && (
            <>
              <Message severity='info' text={`يجب اختيار موظف اولا`} className='text-black-alpha-90 font-semibold' />
              <div className='w-full flex gap-5'>
                <div className='w-full flex flex-column gap-2'>
                  <label className='modals__label'>اختر الموظف</label>
                  <div className='flex gap-1'>
                    <CustomDropdown
                      value={selectedEmployee}
                      onChange={handleEmployeeChange}
                      options={employees}
                      optionLabel='name'
                      optionValue='id'
                      className='w-full'
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر نوع الاجازة</label>
              <div className='flex gap-1'>
                <CustomDropdown
                  value={selectedVacationsType}
                  onChange={handleVacationChange}
                  options={vacationsTypes}
                  optionLabel='name'
                  optionValue='id'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>مدة الاجازة</label>
              <div className='flex gap-1'>
                <Calendar
                  className={'w-full'}
                  value={dates}
                  onChange={handleDateChange}
                  selectionMode='range'
                  readOnlyInput
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>عدد الايام</label>
              <InputText name='days' value={days} className='w-full' onChange={handleInputChange} disabled={true} />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة {entityName}</label>
              <div className='flex'>
                <CustomDropdown
                  name='statusId'
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر الحالة'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea
                name='remarks'
                value={entity.remarks}
                className='w-full'
                onChange={handleInputChange}
                disabled={!selectedStatus}
              />
            </div>
          </div>

          <div className='flex justify-content-between'>
            <Button label='حذف' onClick={handleDeleteItem} severity='danger' link />
            <div className='flex'>
              <Button label='إلغاء' onClick={handleClose} size='small' outlined />
              <button
                className='modals__btn'
                onClick={handleSave}
                disabled={!selectedStatus && !selectedEmployee && !selectedVacationsType}
              >
                {initialData ? 'تحديث' : `اضافة ${entityName}`}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVacationRequest);
