import update from 'immutability-helper';

import {
  FETCH_ACTIVITY_REQUEST,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_FAILURE,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_FAILURE,
  EDIT_ACTIVITY_REQUEST,
  EDIT_ACTIVITY_SUCCESS,
  EDIT_ACTIVITY_FAILURE,
  CHANGE_ACTIVITY_STATE,
  CLEAR_ACTIVITY_STATE
} from './activityTypes';
import { now } from 'lodash';

const initialState = {
  activity: {
    customerId: '',
    customerDeviceId: '',
    activityId: '',
    reasonId: '',
    decisionId: '',
    activityDateTime: new Date(),
    reference: '',
    requestDetails: '',
    responseDetails: '',
    remarks: ''
  },
  loading: false,
  error: ''
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITY_REQUEST:
    case ADD_ACTIVITY_REQUEST:
    case EDIT_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        activity: action.payload,
        error: ''
      };
    case FETCH_ACTIVITY_FAILURE:
    case ADD_ACTIVITY_FAILURE:
    case EDIT_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_ACTIVITY_SUCCESS:
    case EDIT_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: action.payload,
        loading: false,
        error: ''
      };

    case CHANGE_ACTIVITY_STATE:
      return update(state, {
        $merge: {
          activity: {
            ...state.activity,
            ...action.payload
          }
        }
      });
    case CLEAR_ACTIVITY_STATE:
      return {
        ...state,
        activity: initialState.activity
        // employeeBackup: initialState.employeeBackup
      };
    default:
      return state;
  }
};

export default activityReducer;
