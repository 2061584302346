import React, { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '../../../components/Tabs/Tabs';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import { useToast } from '../../../context/ToastContext';

const SupplierTransColumns = [
  { header: 'رقم العملية', field: 'code', isChip: false },
  { header: 'اﻻسم', field: 'name', isChip: false },
  { header: 'نوع اﻻذن', field: 'name', isChip: false },
  { header: 'نوع المصدر', field: 'name', isChip: false },
  { header: 'المصدر', field: 'name', isChip: false },
  { header: 'نوع المستقبل', field: 'name', isChip: false },
  { header: 'المستقبل', field: 'name', isChip: false },
  { header: 'الحالة', field: 'statusName', isChip: true }
];
const SupplierRecetColumns = [
  { header: 'كود الفاتورة', field: 'code', isChip: false },
  { header: 'التاريخ', field: 'name', isChip: false },
  { header: 'طريقة الدفع', field: 'name', isChip: false },
  { header: 'نسبة الخصم', field: 'name', isChip: false },
  { header: 'الخصم', field: 'name', isChip: false },
  { header: 'الحالة', field: 'statusName', isChip: true }
];

export default function SuppliersDetailsTables({ supplierID }: { supplierID: string | undefined }) {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const navigate = useNavigate();

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const supplierTransTab = useMemo(
    () => ({
      label: 'المخازن',
      tableHeader: 'حركات المورد',
      entityName: 'الاذن',
      dataKey: 'SupplierTrans',
      columns: SupplierTransColumns,
      isColumnHidden: true,
      apiUrl: `/Supplier/list`,
      edit: false,
      emptyStateUrl: ``,
      rowOnClick: (rowData: any) => {
        navigate(`/warehouse/details/${rowData.id}`);
      },
      emptyStateButtonClick: undefined,
      headerButtons: []
    }),
    []
  );

  const supplierRecetTab = useMemo(
    () => ({
      label: 'المخازن',
      tableHeader: 'فواتير المورد',
      entityName: 'الفاتورة',
      dataKey: 'SupplierRecet',
      columns: SupplierRecetColumns,
      isColumnHidden: true,
      apiUrl: `/Supplier/list`,
      edit: false,
      emptyStateUrl: ``,
      rowOnClick: (rowData: any) => {
        navigate(`/warehouse/details/${rowData.id}`);
      },
      emptyStateButtonClick: undefined,
      headerButtons: []
    }),
    []
  );

  return (
    <>
      <div className='customerdetails__content__down'>
        <Tabs
          headers={['حركات المورد', 'فواتير المورد']}
          contents={[
            <DataTableCard ref={genericPageRef} selectedTab={supplierTransTab} />,
            <DataTableCard ref={genericPageRef} selectedTab={supplierRecetTab} />
          ]}
        />
      </div>
    </>
  );
}
