import update from 'immutability-helper';
import {
  FETCH_VISIT_REQUEST,
  FETCH_VISIT_SUCCESS,
  FETCH_VISIT_FAILURE,
  ADD_VISIT_REQUEST,
  ADD_VISIT_SUCCESS,
  ADD_VISIT_FAILURE,
  EDIT_VISIT_REQUEST,
  EDIT_VISIT_SUCCESS,
  EDIT_VISIT_FAILURE,
  CHANGE_VISIT_STATE,
  CLEAR_VISIT_STATE
} from './visitTypes';

const initialState = {
  visit: {
    code: '',
    name: '',
    customerId: '',
    contractId: '',
    typeId: '',
    customerActivityId: '',
    preferredTimePeriodId: '',
    requestedDateTime: '',
    visitDate: null,
    assignedRepId: '',
    priorityId: '',
    statusId: '',
    repRemarks: '',
    remarks: ''
  },
  loading: false,
  error: ''
};

const visitReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VISIT_REQUEST:
    case ADD_VISIT_REQUEST:
    case EDIT_VISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        visit: action.payload,
        error: ''
      };
    case FETCH_VISIT_FAILURE:
    case ADD_VISIT_FAILURE:
    case EDIT_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ADD_VISIT_SUCCESS:
    case EDIT_VISIT_SUCCESS:
      return {
        ...state,
        visit: action.payload,
        loading: false,
        error: ''
      };
    case CHANGE_VISIT_STATE:
      return update(state, {
        $merge: {
          visit: {
            ...state.visit,
            ...action.payload
          }
        }
      });
    case CLEAR_VISIT_STATE:
      return {
        ...state,
        visit: initialState.visit
        // employeeBackup: initialState.employeeBackup
      };
    default:
      return state;
  }
};

export default visitReducer;
