import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
import GenericModal from '../../../components/components/Modal/GenericModal';
import InstallmentForm from './Forms/InstallmentForm';
import * as yup from 'yup';
import { useToast } from '../../../context/ToastContext';

const installmentSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup.string().required().default(null),
  paymentTypeId: yup.number().required().default(null),
  downPaymentAmountTypeId: yup.number().required().default(null),
  downPaymentAmount: yup.number().required().default(0),
  nbOfInstalments: yup.number().required().default(0),
  monthlyInterestPercent: yup.number().required().default(0),
  yearlyInterestPercent: yup.number().required().default(0),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

function InstallmentList() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const { isVisible, openModal, closeModal, control, handleSubmit, errors, setValue, loading, setLoading, watch } =
    useModal({ remarks: ' ', names: [] }, '/InstallmentsType', fetchTabData, installmentSchema, toast);

  return (
    <>
      <GenericPage
        tabs={[
          {
            label: '',
            emptyStateButtonClick: () => {
              openModal();
            },
            dataKey: '',
            columns: [
              { header: 'اسم النظام', field: 'name', isChip: false },
              { header: 'طريقة الدفع', field: 'paymentTypeName', isChip: false },
              { header: 'نوع القسط', field: 'downPaymentAmountTypeName', isChip: false },
              { header: 'الفائدة الشهرية', field: 'monthlyInterestPercent', isChip: false },
              { header: 'الفائدة السنوية', field: 'yearlyInterestPercent', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            rowOnClick: (rowData) => {
              openModal({
                id: rowData.id,
                name: rowData.name,
                paymentTypeId: rowData.paymentTypeID,
                downPaymentAmountTypeId: rowData.downPaymentAmountTypeID,
                downPaymentAmount: rowData.downPaymentAmount,
                nbOfInstalments: rowData.nbofInstalments,
                monthlyInterestPercent: rowData.monthlyInterestPercent,
                yearlyInterestPercent: rowData.yearlyInterestPercent,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            edit: true,
            isHidden: true,
            apiUrl: 'InstallmentsType/list',
            entityName: 'نظام تقسيط',
            headerButtons: [
              {
                label: 'إضافة أنظمة تقسيط جديدة',
                onClick: () => {
                  openModal();
                }
              }
            ],
            tableHeader: 'قائمة أنظمة التقسيط'
          }
        ]}
        headerIcon={'../images/productbox.svg'}
        headerTitle={'المنتجات'}
        ref={genericPageRef}
      />

      <GenericModal
        headerTitle={''}
        isVisible={isVisible}
        onHide={closeModal}
        handleSubmit={handleSubmit}
        toast={toast}
        loading={loading}
      >
        <InstallmentForm errors={errors} control={control} setValue={setValue} watch={watch} />
      </GenericModal>
    </>
    // <div className='w-full'>
    //   <span className='main__title'>
    //     <i className='pi pi-database main__title--icon'></i>المنتجات
    //   </span>
    //
    //   <div className='mt-5 pr-4 pl-4'>
    //     <ListEntityTable
    //       columns={[
    //         { header: 'اسم النظام', field: 'name', isChip: false },
    //         { header: 'طريقة الدفع', field: 'paymentType.name', isChip: false },
    //         { header: 'نوع القسط', field: 'downPaymentAmountType.name', isChip: false },
    //         { header: 'الفائدة الشهرية', field: 'monthlyInterestPercent', isChip: false },
    //         { header: 'الفائدة السنوية', field: 'yearlyInterestPercent', isChip: false }
    //       ]}
    //       urlName='InstallmentsType'
    //       headerTitle='أنظمة التقسيط'
    //       entityName='نظام تقسيط'
    //       entityNameDefined='نظام تقسيط'
    //       addBtnText='إضافة أنظمة تقسيط جديدة'
    //       AddEntityComponent={AddInstallment}
    //     />
    //   </div>
    // </div>
  );
}

export default InstallmentList;
