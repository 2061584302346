import React from 'react';
import { connect } from 'react-redux';

import { login } from '../../../redux/user/userActions';
import Tabs from '../../../components/Tabs/Tabs';
import './General.scss';
import StatusTable from '../../../components/Tables/StatusTable';
import CurrencyTable from '../../../components/Tables/CurrencyTable';

function General() {
  return (
    <div className=' main w-full'>
      <span className='main__title'>
        <i className='pi pi-database main__title--icon'></i> البيانات الاساسية
      </span>
      <div className='mt-5 pr-4 pl-4'>
        <Tabs
          headers={[ 'العملات']}
          contents={[
            <CurrencyTable
              urlName='Currency'
              headerTitle='العملات'
              entityName='عملة'
              entityNameDefined='العملة'
              addBtnText='إضافة عملة'
            />
          ]}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
