import React, { useMemo } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { useNavigate } from 'react-router-dom';

const getContractScreenColumns = () => [
  { header: 'العميل', field: 'customerName', isChip: false },
  { header: 'الجهاز', field: 'productName', isChip: false },
  { header: 'الموظف المسؤول', field: 'employeeName', isChip: false },
  { header: 'تاريخ التعاقد', field: 'contractDate', isChip: false },
  { header: 'نوع العقد', field: 'typeName', isChip: false },
  { header: 'حالة العقد', field: 'statusName', isChip: true }
];

function ContractScreen() {
  const navigate = useNavigate();
  const columns = useMemo(getContractScreenColumns, []);
  const apiUrl = '/Contract/list';
  const addUrl = `/Contract/AddConnection`;

  const tabs = [
    {
      tableHeader: 'قائمة العقود',
      label: 'قائمة العقود',
      dataKey: 'tab1',
      entityName: 'العقد',
      columns: columns,
      apiUrl: apiUrl,
      edit: false,
      emptyStateUrl: addUrl,
      isHidden: true,
      rowOnClick: (rowData: any) => {
        navigate(`/contract/details`, { state: { contractId: rowData.contractID, customerId: rowData.customerID } });
      },
      headerButtons: [
        {
          label: 'إضافة عقد جديدة',
          onClick: () => {
            navigate(`/addContract`, {
              state: {
                contractType: 'Client',
              }
            });
          }
        }
      ],
      extraParams: {
        type: 'Client'
      }
    }
  ];

  const headerDetails = [
    {
      title: 'العقود الحالية',
      num: '40',
      imageUrl: '/images/file3.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/down2.svg',
      txtColor: 'red',
      color: '#faeceb'
    },
    {
      title: 'العقود المنتهية',
      num: '30',
      imageUrl: '/images/altsleep.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/Up.svg',
      color: '#ccc3f8'
    },
    {
      title: 'اجهزة الشركة',
      num: '20',
      imageUrl: '/images/dropbox1.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/down2.svg',
      txtColor: 'red',
      color: '#faeceb'
    },
    {
      title: 'الاجهزة الخارجية',
      num: '50',
      imageUrl: '/images/dropbox1.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/Up.svg',
      color: '#ccc3f8'
    }
  ];

  return (
    <>
      <GenericPage
        tabs={tabs}
        headerTitle='العقود'
        headerIcon='/images/file.svg'
        analyticsCards={headerDetails}
        cardsNum='4'
      />
    </>
  );
}

export default ContractScreen;
