import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Chip } from 'primereact/chip';
import { Galleria } from 'primereact/galleria';
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '../../../components/Tabs/Tabs';
import CustomerEntityTable from '../../../components/Tables/CustomerEntityTable';
import HeaderCard from '../../../components/Cards/Home header/HeaderCard';
import './ProductsDetails.scss';
import { axiosInstance } from '../../../global';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';

function ProductsDetailsContent({ product, iscutomerView, customerId, deviceId }) {
  const params = useParams();
  const navigate = useNavigate();
  const genericPageRef = useRef(null);

  const initializedBefore = useRef(false);
  const [images, setImages] = useState(null);
  const [myList, setMyList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMyData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`Product/activeCustomers/${params.id}`);
      setMyList(response.data.data);
      setLoading(false);
       // Updated to log the response instead of `myList`
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        
      } else {
        
      }
    }
  }, [params.id]);


  useEffect(() => {
    fetchMyData();
  }, [fetchMyData]);

  const PhotoService = {
    getData(product) {
      if (product && product.productsImages) {
        return product.productsImages.map((image) => ({
          itemImageSrc: `http://bestaqua.oneclickapps.org/${image.imagePath}`, // Adjust your base URL
          thumbnailImageSrc: `http://bestaqua.oneclickapps.org/${image.imagePath}`, // Adjust your base URL
          alt: `http://bestaqua.oneclickapps.org/${image.imagePath}`, // Adjust your base URL
          title: `http://bestaqua.oneclickapps.org/${image.imagePath}`, // Adjust your base URL
        }));
      }
      return [];
    },



    getImages(product) {
      return Promise.resolve(this.getData(product));
    }
  };

  useEffect(() => {
    PhotoService.getImages(product).then((data) => setImages(data));
    //TODO Function
    if (!initializedBefore.current) {
      initializedBefore.current = true;
      setTimeout(function () {
        var thumbsParentSelector = document.querySelector('.details__content__uper__right');
        var thumbsParentHeight = thumbsParentSelector.offsetHeight;
        var thumbsSelector = document.querySelector('.p-galleria-thumbnail-items-container');
        if (thumbsSelector) {
          thumbsSelector.style.height = `${thumbsParentHeight - 100}px`;
        }
      }, 100);
    }
  }, [product]);

  const itemTemplate = (item) => {
    return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%' }} />;
  };
  const thumbnailTemplate = (item) => {
    return <img src={item.thumbnailImageSrc} alt={item.alt} />;
  };
  const responsiveOptions = [
    {
      breakpoint: '991px',
      numVisible: 4
    },
    {
      breakpoint: '767px',
      numVisible: 3
    },
    {
      breakpoint: '575px',
      numVisible: 1
    }
  ];
  return (
    <div className='details'>
      {iscutomerView === false && (
        <>
          {/*<div className='details__header__card'>*/}
          {/*  <HeaderCard*/}
          {/*    title={'مبيعات المنتج'}*/}
          {/*    num={'58.200k'}*/}
          {/*    imageUrl={'/images/money.svg'}*/}
          {/*    percent={'2.5% من الشهر الماضى'}*/}
          {/*    chartImage={'/images/Up.svg'}*/}
          {/*    color={'#13DE8230'}*/}
          {/*  />*/}
          {/*  <HeaderCard*/}
          {/*    title={'عمليات البيع الناجحة'}*/}
          {/*    num={'58.200k'}*/}
          {/*    imageUrl={'/images/user_box_light.svg'}*/}
          {/*    percent={'2.5% من الشهر الماضى'}*/}
          {/*    chartImage={'/images/down2.svg'}*/}
          {/*    color={'#F9D8D4'}*/}
          {/*    txtColor={'red'}*/}
          {/*  />*/}
          {/*  <HeaderCard*/}
          {/*    title={'مشاهدات المنتج'}*/}
          {/*    num={'58.200k'}*/}
          {/*    imageUrl={'/images/officelife.svg'}*/}
          {/*    percent={'2.5% من الشهر الماضى'}*/}
          {/*    chartImage={'/images/Up.svg'}*/}
          {/*    color={'#6D54DE33'}*/}
          {/*  />*/}
          {/*  <HeaderCard*/}
          {/*    title={'التقيمات'}*/}
          {/*    num={'58.200k'}*/}
          {/*    imageUrl={'/images/check.svg'}*/}
          {/*    percent={'2.5% من الشهر الماضى'}*/}
          {/*    chartImage={'/images/Up.svg'}*/}
          {/*    color={'#FFEDD0'}*/}
          {/*  />*/}
          {/*  <HeaderCard*/}
          {/*    title={'أقساط المنتج المطلوبة'}*/}
          {/*    num={'58.200k'}*/}
          {/*    imageUrl={'/images/coins.svg'}*/}
          {/*    percent={'2.5% من الشهر الماضى'}*/}
          {/*    chartImage={'/images/Up.svg'}*/}
          {/*    color={'#C050E838'}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className='details__content details__content--wrapper'>
            <div className='details__content__uper'>
              <div className='details__content__uper__right'>
                <Galleria
                  thumbnailsPosition='left'
                  value={images}
                  responsiveOptions={responsiveOptions}
                  numVisible={4}
                  style={{ maxWidth: '640px' }}
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                />
              </div>

              <div className='details__content--wrapper'>
                <span className='details__content__uper__left__product__name'>{product?.name}</span>
                <div className='primary'>
                  <div className='primary__data'>
                    <span className='primary__data--label'> رقم المنتج\SKU: </span>
                    <span className='primary__data--text'>{product?.skuCode}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> كود المنتج: </span>
                    <span className='primary__data--text'>{product?.code}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'>حالة المنتج: </span>
                    <Chip label={product?.status.name} />
                  </div>

                  <div className='primary__data'>
                    <span className='primary__data--label'> نوع المنتج: </span>
                    <span className='primary__data--text'>
                      {product?.productCategory.productFamily.productType.name}
                    </span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> تصنيف المنتج: </span>
                    <span className='primary__data--text'>{product?.productCategory.name}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> عدد المراحل: </span>
                    <span className='primary__data--text'>{product?.nbOfStages}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> الماركة: </span>
                    <span className='primary__data--text'>{product?.brand.name}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> بلد المنشأ : </span>
                    <span className='primary__data--text'>{product?.countryOfOrigin.name}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> طرق التقسيط: </span>
                    <span className='primary__data--text'>{product?.installments}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> تاريخ الإضافة: </span>
                    <span className='primary__data--text'>{product?.creationDateTime}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='details__content__down'>
              <label className='details__content__down__label'>وصف المنتج</label>
              <p className='details__content__down__desc'>{product?.details}</p>
            </div>
          </div>

          <DataTableCard
            selectedTab={{
              rowOnClick: (rowData) => {
                navigate(`/customers/details/${rowData.customerID}`);
              },
              emptyStateButtonClick: () => {
                navigate('/customers/addCustomer');
              },
              label: 'العملاء الحاليين',
              tableHeader: 'العملاء الحاليين',
              entityName: 'عملاء',
              dataKey: 'string',
              columns: [
                { field: 'customerName', header: 'العميل' },
                { field: 'productTypeName', header: ' نوع الجهاز' },
                { field: 'productName', header: 'الجهاز' },
                { field: 'customerAddress', header: 'عنوان العميل' },
                { field: 'installationDate', header: 'تاريخ التعاقد' },
                { field: 'expiryDate', header: 'تاريخ الانتهاء' },
                { field: 'statusName', header: 'الحالة', isChip: true }
              ],
              apiUrl: `Product/activeCustomers/${params.id}`,
              edit: false,
              isHidden: true,
              extraParams: {},
              headerButtons: []
            }}/>

        </>
      )}
      {iscutomerView === true && (
        <>
          <div className='customerdetails__header__card'>
            <HeaderCard
              title={'مراد تحصيل من العميل'}
              num={'58.200k'}
              imageUrl={'/images/money.svg'}
              color={'#13DE8230'}
            />
            <HeaderCard title={'تاريخ الشراء'} num={'58.200k'} imageUrl={'/images/alt.svg'} color={'#F9D8D4'} />
            <HeaderCard title={'حالة العقد'} num={'58.200k'} imageUrl={'/images/briefcase.svg'} color={'#6D54DE33'} />
            <HeaderCard title={'منشئ العقد'} num={'58.200k'} imageUrl={'/images/user1.svg'} color={'#FFEDD0'} />
          </div>
          <div className='details__content details__content--wrapper'>
            <div className='details__content__uper'>
              <div className='details__content__uper__right'>
                <Galleria
                  thumbnailsPosition='left'
                  value={images}
                  responsiveOptions={responsiveOptions}
                  numVisible={4}
                  style={{ maxWidth: '640px' }}
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                />
              </div>

              <div className='details__content__uper__left'>
                <span className='details__content__uper__left__product__name'>{product?.name}</span>
                <div className='primary'>
                  <div className='primary__data'>
                    <span className='primary__data--label'> رقم المنتج\SKU: </span>
                    <span className='primary__data--text'>{product?.skuCode}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> كود المنتج: </span>
                    <span className='primary__data--text'>{product?.code}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'>حالة المنتج: </span>
                    <Chip label={product?.status.name} />
                  </div>

                  <div className='primary__data'>
                    <span className='primary__data--label'> نوع المنتج: </span>
                    <span className='primary__data--text'>
                      {product?.productCategory.productFamily.productType.name}
                    </span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> تصنيف المنتج: </span>
                    <span className='primary__data--text'>{product?.productCategory.name}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> عدد المراحل: </span>
                    <span className='primary__data--text'>{product?.nbOfStages}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> الماركة: </span>
                    <span className='primary__data--text'>{product?.brand.name}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> بلد المنشأ : </span>
                    <span className='primary__data--text'>{product?.countryOfOrigin.name}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> طرق التقسيط: </span>
                    <span className='primary__data--text'>{product?.installments}</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> تاريخ الإضافة: </span>
                    <span className='primary__data--text'>{product?.creationDateTime}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='details__content__down'>
              <label className='details__content__down__label'>وصف المنتج</label>
              <p className='details__content__down__desc'>{product?.details}</p>
            </div>
          </div>
          {deviceId != undefined && (
            <div className='details__table'>
              <Tabs
                headers={['سجل المتابعات', 'العقود', 'مراحل الجهاز', 'اقساط الجهاز', 'شكاوى الجهاز']}
                contents={[
                  <Tabs
                    headers={['الكل']}
                    contents={[
                      <CustomerEntityTable
                        columns={[
                          { header: 'نوع الزيارة', field: 'type.name', isChip: false },
                          { header: 'الفنى', field: 'assignedRep.name', isChip: false },
                          { header: 'الاهمية', field: 'customerActivity.satisfactionLevel', isRate: true },
                          { header: 'تاريخ الزيارة', field: 'visitDate', isChip: false },
                          { header: 'الحالة', field: 'status.name', isChip: true }
                        ]}
                        urlName={`Visit/allByDevice/${deviceId}`}
                        headerTitle='قائمة المتابعات'
                        entityNameDefined='تفاصيل الزيارة'
                        entityName='الزيارة'
                        addBtnText='إضافة زيارة للعميل'
                        isDevice={false}
                        path='/customer/followUp/details'
                        type='followUp'
                        customerId={customerId}
                      />
                    ]}
                  />, //سجل المتابعات

                  <CustomerEntityTable
                    columns={[
                      // { header: 'اسم الجهاز', field: 'customerDevice.device.name', isChip: false },
                      { header: 'الموظف المسؤل', field: 'beneficiaryEmployee.name', isChip: false },
                      { header: 'تاريخ التعاقد', field: 'contractDate', isChip: false },
                      { header: 'تاريخ الانتهاء', field: 'expiryDate', isChip: false },
                      { header: 'نوع العقد', field: 'type.name', isChip: true },
                      { header: 'حالة العقد', field: 'status.name', isChip: true }
                    ]}
                    urlName={`Contract/allByDevice/${deviceId}`}
                    headerTitle='قائمة العقود'
                    entityNameDefined='تفاصيل العقد'
                    entityName='العقد'
                    addBtnText='إضافة عقد للعميل'
                    isDevice={false}
                    path='/customer/contract/details'
                    type='contracts'
                    customerId={customerId}
                  />, //العقود

                  <CustomerEntityTable
                    columns={[
                      { header: 'اسم الجهاز', field: 'stageItem.name', isChip: false },
                      { header: 'رقم المرحلة', field: 'stageNb', isChip: false },
                      { header: 'المنتج', field: 'product.name', isChip: false },
                      { header: 'السعر', field: 'price', isChip: false },
                      { header: 'موعد التغير القادم', field: 'nextReplacementDate', isChip: false },
                      { header: 'مؤشر التغير', field: 'remainingDays', isKnob: true }
                    ]}
                    urlName={`ContractsDeviceStage/allByDevice/${deviceId}`}
                    headerTitle='متابعة المراحل'
                    addBtnText='إضافة زيارة للعميل'
                    isDevice={false}
                    // path='/product/details'
                    type='stages'
                  />, //المراحل

                  <CustomerEntityTable
                    columns={[
                      // { header: 'اسم الجهاز', field: 'product.name', isChip: false },
                      // { header: 'فئة المنتج', field: 'product.productCategory.name', isChip: false },
                      { header: 'نظام التقسيط', field: 'installmentsType.name', isChip: false },
                      { header: 'تاريخ التقسيط', field: 'collectionDate', isChip: false },
                      { header: 'قيمة القسط', field: 'installmentAmount', isChip: false },
                      { header: 'المدفوع', field: 'totalPaidAmount', isChip: false },
                      { header: 'المتبقى من السداد', field: 'remainingAmount', isChip: false },
                      { header: 'الحالة', field: 'status.name', isChip: true }
                    ]}
                    urlName={`ContractsInstallment/allByDevice/${deviceId}`}
                    headerTitle='قائمة الاقساط'
                    entityNameDefined='تفاصيل القسط'
                    entityName='القسط'
                    addBtnText='إضافة جهاز للعميل'
                    isDevice={false}
                    // path='/product/details'
                    type='installment'
                  />, //تقسيطات العميل

                  <CustomerEntityTable
                    columns={[
                      // { header: 'فئة المنتج', field: 'code', isChip: false },
                      { header: 'كود الشكوى', field: 'code', isChip: false },
                      { header: 'تصنيف الشكوى', field: 'complaintFamily', isChip: false },
                      { header: 'الشكوى', field: 'complaintType', isChip: false },
                      { header: 'تاريخ الشكوى', field: 'complaintDate', isChip: false }
                    ]}
                    urlName={`CustomersComplaint/allByDevice/${deviceId}`}
                    headerTitle='قائمة الشكاوى'
                    entityNameDefined='تفاصيل الشكوى'
                    entityName='الشكوى'
                    addBtnText='إضافة شكوى للعميل'
                    isDevice={false}
                    path='/customer/complaint/details'
                    type='complaint'
                    customerId={customerId}
                  /> //شكاوى العميل
                ]}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ProductsDetailsContent;
