import React, { useEffect, useMemo, useRef, useState } from 'react';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import { useNavigate, useParams } from 'react-router-dom';

function FollowUpScreenStatusTable({ statusID }: { statusID: string }) {
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const navigate = useNavigate();
  console.log({ statusID });

  const FollowUpTab = useMemo(
    () => ({
      label: 'المتابعة',
      entityName: 'متابعة',
      tableHeader: 'جدول المتابعة',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
        navigate(`'/customer/followUp/details'`);
      },
      apiUrl: `/Visit/list/allByStatus/${statusID}`,
      columns: [
        { header: 'العميل', field: 'customerName', isChip: false },
        { header: 'نوع الزيارة', field: 'visitTypeName', isChip: false },
        { header: 'الفنى', field: 'assignedRepName', isChip: false },
        { header: 'الاهمية', field: 'priorityName', isChip: false },
        { header: 'تاريخ الزيارة', field: 'visitDate', isDate: true },
        { header: 'الحالة', field: 'statusName', isChip: true }
      ],
      edit: true,
      dataKey: statusID,
      emptyStateButtonClick: () => {}
    }),
    [statusID]
  );

  return <DataTableCard ref={genericPageRef} button={[]} selectedTab={FollowUpTab} />;
}

export default FollowUpScreenStatusTable;
