import React from 'react';
import { FormProps } from '../../../../types/FormProps';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';
import useFetch from '../../../../hooks/useFetch';

const UserTypeDestinationSourcesForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Warehouse');

  const { data: userTypeData, isLoading: isUserTypeLoading } = useFetch('/UsersType/dropdown');
  const { data: branchData, isLoading: isBranchLoading } = useFetch('/Branch/dropdown');
  const { data: productTypeData, isLoading: isProductTypeLoading } = useFetch('/ProductsType/dropdown');
  const { data: transTypeData, isLoading: isTransTypeLoading } = useFetch('/TransType/dropdown');

  const { data: destinationSourceData, isLoading: isDestinationSourceLoading } =
    useFetch('/DestinationsSource/dropdown');

  return (
    <>
      <CustomFormField
        name={'userTypeId'}
        label={'User Type'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={userTypeData?.data ?? []}
        disabled={isEditMode ? true : false || isUserTypeLoading}
      />

      <CustomFormField
        name={'branchId'}
        label={'Branches'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={branchData?.data ?? []}
        disabled={isEditMode ? true : false || isBranchLoading}
      />

      <CustomFormField
        name={'productTypeId'}
        label={'Product Type'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={productTypeData?.data ?? []}
        disabled={isEditMode ? true : false || isProductTypeLoading}
      />

      <CustomFormField
        name={'transTypeId'}
        label={'Transaction Type'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={transTypeData?.data ?? []}
        disabled={isEditMode ? true : false || isTransTypeLoading}
      />

      <CustomFormField
        name={'listOfDestinationSourceId'}
        label={'Select Destination Sources'}
        control={control}
        errors={errors}
        optionValue='id'
        optionLabel='name'
        type={'multi-select'}
        options={destinationSourceData?.data ?? []}
      />

      <CustomFormField
        name={'statusId'}
        label={`Select Status`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={`Select Status`}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'Remarks'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default UserTypeDestinationSourcesForm;
