import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
import GenericModal from '../../../components/components/Modal/GenericModal';
import * as yup from 'yup';
import EmployeeLoanForm from './EmployeeLoanForm';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useToast } from '../../../context/ToastContext';

const loanSheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

function Loan() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const navigate = useNavigate();

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isEmployeeLoanVisitable,
    openModal: EmployeeLoanOpenModal,
    closeModal: EmployeeLoanCloseModal,
    control: EmployeeLoanControl,
    handleSubmit: EmployeeLoanSubmit,
    errors: EmployeeLoanErrors,
    setValue: EmployeeLoanSetValue,
    loading: EmployeeLoanLoading,
    setLoading: EmployeeLoanSetLoading,
    watch: EmployeeLoanWatch
  } = useModal({}, '/Loan', fetchTabData, loanSheme, toast, (data) => {
    return {
      ...data,
      firstInstallmentDate: moment(data.firstInstallmentDate).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]')
    };
  });

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'السلف',
            entityName: 'سلفة',
            tableHeader: 'جدول السلف',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              EmployeeLoanOpenModal({
                id: rowData.id,
                code: rowData.code,
                reference: rowData.reference,
                employeeId: rowData.employeeID,
                amount: rowData.amount,
                nbOfInstallments: rowData.nbOfInstallments,
                installmentValue: rowData.installmentValue,
                statusId: rowData.statusID,
                firstInstallmentDate: new Date(rowData.firstInstallmentDate),
                remarks: rowData.remarks
              });
            },
            apiUrl: 'Loan/list',
            columns: [
              { header: 'اسم الموظف', field: 'employeeName', isChip: false },
              { header: 'المرجع', field: 'reference', isChip: false },
              { header: 'المبلغ', field: 'amount', isChip: false },
              { header: 'عدد الاقساط', field: 'nbOfInstallments', isChip: false },
              { header: 'قيمة القسط', field: 'installmentValue', isChip: false },
              { header: 'معاد القسط الاول', field: 'firstInstallmentDate', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            edit: true,
            dataKey: 'EmployeeLoan',
            emptyStateButtonClick: () => {
              EmployeeLoanOpenModal();
            },
            headerButtons: [
              {
                label: 'اضافة سلفة جديده',
                onClick: () => {
                  EmployeeLoanOpenModal();
                }
              }
            ]
          }
        ]}
        headerTitle={'الموظفين'}
        headerIcon={'/images/database.svg'}
      />

      <GenericModal
        headerTitle={EmployeeLoanWatch('id') === undefined ? 'اضافه سلفة جديدة' : 'تعديل سلفة'}
        isVisible={isEmployeeLoanVisitable}
        onHide={EmployeeLoanCloseModal}
        handleSubmit={EmployeeLoanSubmit}
        toast={toast}
        loading={EmployeeLoanLoading}
        isEditMode={EmployeeLoanWatch('id') !== undefined}
      >
        <EmployeeLoanForm
          control={EmployeeLoanControl}
          errors={EmployeeLoanErrors}
          setValue={EmployeeLoanSetValue}
          watch={EmployeeLoanWatch}
          extraProps={{ entityName: 'سلفة' }}
        />
      </GenericModal>
    </>
  );
}

export default Loan;
