import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import CustomDropdown from '../components/Dropdown/CustomDropDown';
import { Calendar } from 'primereact/calendar';
import './Modals.scss';
import CustomMultiSelectDropdown from '../components/Dropdown/CustomMultiSelectDropdown';
import { useToast } from '../../context/ToastContext';

function AddEmployeeDailyAssignment({ visible, setVisible, employees, locations, onAddHandler }) {
  const toast = useToast();
  const [entity, setEntity] = useState({
    employeeId: '',
    date: '',
    locationId: [],
    remarks: ''
  });

  useEffect(() => {
    setEntity({
      employeeId: '',
      date: '',
      locationId: [],
      remarks: ''
    });
  }, [visible]);

  function acceptClose() {
    setEntity({
      employeeId: '',
      date: '',
      locationId: [],
      remarks: ''
    });
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: 'هل تريد الغاء عملية اضافة جدول اعمال للفني ؟',
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 4
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleDateChange(e) {
    setEntity({ ...entity, date: e.value });
  }

  return (
    <>
      
      <ConfirmDialog tagKey={4} />
      <Dialog
        header='إضافة مواقع للفني'
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>بحث عن الموظف ب رقم الهاتف / الرقم القومي / الاسم / ايميل </label>
              <div className='flex gap-1'>
                <CustomDropdown
                  display='chip'
                  options={employees}
                  optionValue='id'
                  optionLabel='name'
                  value={entity.employeeId}
                  name='employeeId'
                  className='w-full'
                  placeholder='اختر الفني'
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر المواقع للفني</label>
              <div className='flex gap-1'>
                <CustomMultiSelectDropdown
                  display='chip'
                  options={locations}
                  optionValue='id'
                  optionLabel='name'
                  value={entity.locationId}
                  name='locationId'
                  className='w-full'
                  placeholder='اختر المواقع للفني'
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>سيقوم الموظف بالعمل على هذه المواقع في يوم؟</label>
              <div className='flex gap-1'>
                <Calendar className='w-full' value={entity.date} onChange={handleDateChange} />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(entity)}>
              اضافة الى المجموعة
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AddEmployeeDailyAssignment;
