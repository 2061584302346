// import React, { useEffect, useRef, useState } from 'react';
// import { Button } from 'primereact/button';
// import { Dialog } from 'primereact/dialog';
// import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
// import './Modals.scss';
// import { InputNumber } from 'primereact/inputnumber';
// import { axiosInstance } from '../../global';
// import CustomDropdown from '../components/Dropdown/CustomDropDown';
// import { useParams } from 'react-router-dom';

// function AddTransactionProduct({
//   warehouseId,
//   transactionTypeId,
//   destID,
//   destSourceId,
//   visible,
//   setVisible,
//   onAddHandler
// }) {
//   const params = useParams();
//   const [productTypes, setProductTypes] = useState([]);
//   const [selectedType, setSelectedType] = useState(null);
//   const [productCategories, setProductCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [productFamilies, setProductFamilies] = useState([]);
//   const [selectedFamily, setSelectedFamily] = useState(null);
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [warehouseSections, setWarehouseSections] = useState([]);
//   const [selectedSection, setSelectedSection] = useState([]);
//   const [warehouseLocations, setWarehouseLocations] = useState([]);
//   const [selectedLocation, setSelectedLocation] = useState([]);
//   const [entity, setEntity] = useState({
//     name: '',
//     productId: null,
//     qty: 0,
//     price: 0,
//     amount: 0,
//     availableQty: 0,
//     whLocationId: null
//   });

//   useEffect(() => {
//     axiosInstance
//       .get(`/UserTypesTransDestSourcesMatrix/ProductTypesDropdown/${transactionTypeId}/${destSourceId}`)
//       .then((response) =>
//         setProductTypes(
//           response.data.data.map((item) => {
//             return { id: item.id, name: item.name };
//           })
//         )
//       )
//       .catch((error) => {});
//     axiosInstance
//       .get(`/WarehousesSection/allByWarehouseId/${warehouseId}`)
//       .then((response) =>
//         setWarehouseSections(
//           response.data.data.map((item) => {
//             return { id: item.id, name: item.name };
//           })
//         )
//       )
//       .catch((error) => {});
//   }, [warehouseId, transactionTypeId, destSourceId]);

//   useEffect(() => {
//     axiosInstance
//       .get(`/ProductsFamily/getByTypeId/${selectedType}`)
//       .then((response) =>
//         setProductFamilies(
//           response.data.data.map((item) => {
//             return { id: item.id, name: item.name };
//           })
//         )
//       )
//       .catch((error) => {});
//   }, [selectedType]);

//   useEffect(() => {
//     axiosInstance
//       .get(`/ProductsCategory/getByFamilyId/${selectedFamily}`)
//       .then((response) =>
//         setProductCategories(
//           response.data.data.map((item) => {
//             return { id: item.id, name: item.name };
//           })
//         )
//       )
//       .catch((error) => {});
//   }, [selectedFamily]);

//   useEffect(() => {
//     axiosInstance
//       .get(`/Product/allByCategory/${selectedCategory}`)
//       .then((response) =>
//         setProducts(
//           response.data.data.map((item) => {
//             return { id: item.id, name: item.name };
//           })
//         )
//       )
//       .catch((error) => {});
//   }, [selectedCategory]);

//   useEffect(() => {
//     axiosInstance
//       .get(`/WarehousesLocation/allBySectionId/${selectedSection}`)
//       .then((response) =>
//         setWarehouseLocations(
//           response.data.data.map((item) => {
//             return { id: item.id, name: item.name };
//           })
//         )
//       )
//       .catch((error) => {});
//   }, [selectedSection]);

//   function acceptClose() {
//     setEntity({
//       name: '',
//       productId: null,
//       qty: 0,
//       price: 0,
//       amount: 0,
//       availableQty: 0,
//       whLocationId: null
//     });
//     setVisible(false);
//     setSelectedType(null);
//     setSelectedFamily(null);
//     setSelectedCategory(null);
//     setSelectedProduct(null);
//     setSelectedSection(null);
//     setSelectedLocation(null);
//   }

//   function handleClose() {
//     confirmDialog({
//       header: 'هل أنت متأكد من أنك تريد الخروج من عملية إضافة منتج؟',
//       accept: acceptClose,
//       acceptLabel: 'تأكيد',
//       acceptClassName: 'modals__btn',
//       reject: () => confirmDialog().hide(),
//       rejectLabel: 'إلغاء',
//       rejectClassName: 'p-button-outlined',
//       closeOnEscape: false,
//       draggable: false,
//       resizable: false,
//       tagKey: 1
//     });
//   }

//   function handleLocationChange(e) {
//     setSelectedLocation(e.value);
//     setEntity({ ...entity, whLocationId: e.value });
//   }

//   function handleProductChange(e) {
//     const productName = products.filter((item) => item.id === e.value)[0].name;
//     setSelectedProduct(e.value);
//     setEntity({ ...entity, productId: e.value, name: productName });
//   }

//   function handleQtyChange(e) {
//     setEntity({ ...entity, qty: e.value });
//   }

//   function getPricesByProductID() {
//     axiosInstance
//       .get(`/ProductsPricesListMaster/productPrice/${selectedProduct}`)
//       .then((response) => {
//         const price = Number(response?.data?.data);
//         

//         if (!isNaN(price)) {
//           // setEntity((prev) => ({ ...prev, price })); // Update price if it's a valid number
//           setEntity({ ...entity, price: price }); // Update availbleQty if it's a valid number
//         } else {
//           console.error('Received price is not a valid number:', response.data.data);
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching product price:', error);
//       });
//   }

//   function getAvaliableQty() {
//     axiosInstance
//       .get(
//         `/ProductsPricesListMaster/productQty/${selectedProduct}/${selectedLocation}/${transactionTypeId}/${destSourceId}/${destID}/${
//           params.id ? params.id : 0
//         }`
//       )
//       .then((response) => {
//         const availbleQty = Number(response?.data?.data);
//         

//         if (!isNaN(availbleQty)) {
//           
//           setEntity({ ...entity, availableQty: availbleQty }); // Update availbleQty if it's a valid number
//         } else {
//           console.error('Received availbleQty is not a valid number:', response.data.data);
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching availbleQty :', error);
//       });
//   }

//   useEffect(() => {
//     if (selectedProduct) {
//       getPricesByProductID();
//     }
//   }, [selectedProduct]);

//   useEffect(() => {
//     if (selectedProduct && selectedLocation && transactionTypeId && destSourceId && destID) {
//       getAvaliableQty();
//     }
//   }, [selectedProduct, selectedLocation, transactionTypeId, destSourceId, destID, params.id]);

//   return (
//     <>
//       <ConfirmDialog tagKey={1} />
//       <Dialog
//         header='إضافة منتج'
//         visible={visible}
//         onHide={handleClose}
//         className='modals'
//         draggable={false}
//         resizable={false}
//         closeOnEscape={false}
//       >
//         <div className='flex flex-column gap-5'>
//           <div className='w-full flex gap-5'>
//             <div className='w-full flex flex-column gap-2'>
//               <label className='modals__label'>نوع المنتج</label>
//               <div className='flex'>
//                 <CustomDropdown
//                   value={selectedType}
//                   onChange={(e) => setSelectedType(e.value)}
//                   options={productTypes}
//                   optionValue='id'
//                   optionLabel='name'
//                   className='w-full'
//                 />
//               </div>
//             </div>
//           </div>

//           <div className='w-full flex gap-5'>
//             <div className='w-full flex flex-column gap-2'>
//               <label className='modals__label'>تصنيف المنتج</label>
//               <div className='flex'>
//                 <CustomDropdown
//                   value={selectedFamily}
//                   onChange={(e) => setSelectedFamily(e.value)}
//                   options={productFamilies}
//                   optionValue='id'
//                   optionLabel='name'
//                   className='w-full'
//                 />
//               </div>
//             </div>
//           </div>

//           <div className='w-full flex gap-5'>
//             <div className='w-full flex flex-column gap-2'>
//               <label className='modals__label'>فئة المنتج</label>
//               <div className='flex'>
//                 <CustomDropdown
//                   value={selectedCategory}
//                   onChange={(e) => setSelectedCategory(e.value)}
//                   options={productCategories}
//                   optionValue='id'
//                   optionLabel='name'
//                   className='w-full'
//                 />
//               </div>
//             </div>
//           </div>

//           <div className='w-full flex gap-5'>
//             <div className='w-full flex flex-column gap-2'>
//               <label className='modals__label'>قسم المخزن</label>
//               <div className='flex'>
//                 <CustomDropdown
//                   value={selectedSection}
//                   onChange={(e) => setSelectedSection(e.value)}
//                   options={warehouseSections}
//                   optionValue='id'
//                   optionLabel='name'
//                   className='w-full'
//                 />
//               </div>
//             </div>
//           </div>

//           <div className='w-full flex gap-5'>
//             <div className='w-full flex flex-column gap-2'>
//               <label className='modals__label'>موقع التخزين</label>
//               <div className='flex'>
//                 <CustomDropdown
//                   value={selectedLocation}
//                   onChange={handleLocationChange}
//                   options={warehouseLocations}
//                   optionValue='id'
//                   optionLabel='name'
//                   className='w-full'
//                 />
//               </div>
//             </div>
//           </div>

//           <div className='w-full flex gap-5'>
//             <div className='w-full flex flex-column gap-2'>
//               <label className='modals__label'>المنتج</label>
//               <div className='flex'>
//                 <CustomDropdown
//                   value={selectedProduct}
//                   onChange={handleProductChange}
//                   options={products}
//                   optionValue='id'
//                   optionLabel='name'
//                   className='w-full'
//                 />
//               </div>
//             </div>
//           </div>

//           <div className='w-full flex gap-5'>
//             <div className='w-full flex flex-column gap-2'>
//               <label className='modals__label'>السعر</label>
//               <div className='flex'>
//                 <InputNumber
//                   name='price'
//                   value={entity.price}
//                   disabled={true}
//                   maxFractionDigits={5}
//                   className='w-full surface-300 opacity-100'
//                 />
//               </div>
//             </div>
//           </div>

//           <div className='w-full flex gap-5'>
//             <div className='w-full flex flex-column gap-2'>
//               <label className='modals__label'>الكمية المتاحة</label>
//               <div className='flex'>
//                 <InputNumber
//                   name='availableQty'
//                   value={entity.availableQty}
//                   disabled={true}
//                   maxFractionDigits={5}
//                   className='w-full surface-300 opacity-100'
//                 />
//               </div>
//             </div>
//           </div>

//           <div className='w-full flex gap-5'>
//             <div className='w-full flex flex-column gap-2'>
//               <label className='modals__label'>الكمية</label>
//               <div className='flex'>
//                 <InputNumber name='qty' value={entity.qty} min={1} className='w-full' onChange={handleQtyChange} />
//               </div>
//             </div>
//           </div>

//           <div className='flex justify-content-end'>
//             <Button label='إلغاء' onClick={handleClose} size='small' outlined />
//             <button
//               className='modals__btn'
//               onClick={() => {
//                 onAddHandler(entity);
//                 acceptClose();

//                 // setVisible(false);
//               }}
//             >
//               اضافة المنتج
//             </button>
//           </div>
//         </div>
//       </Dialog>
//     </>
//   );
// }

// export default AddTransactionProduct;

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';
import { axiosInstance } from '../../global';
import CustomDropdown from '../components/Dropdown/CustomDropDown';
import { useParams } from 'react-router-dom';
import './Modals.scss';
import useFetch from '../../hooks/useFetch';

function AddTransactionProduct({
  warehouseId,
  transactionTypeId,
  destID,
  destSourceId,
  visible,
  setVisible,
  onAddHandler
}) {
  const params = useParams();
  const [dataOptions, setDataOptions] = useState({
    productTypes: [],
    productFamilies: [],
    productCategories: [],
    products: [],
    warehouseSections: [],
    warehouseLocations: []
  });
  // const { data: ProductTypesData, isLoading: isProductTypeLoading } = useFetch('');

  const [selected, setSelected] = useState({
    type: null,
    family: null,
    category: null,
    product: null,
    section: null,
    location: null
  });

  const [entity, setEntity] = useState({
    name: '',
    productId: null,
    qty: 0,
    price: 0,
    amount: 0,
    availableQty: 0,
    whLocationId: null
  });

  const resetEntityAndSelections = useCallback(() => {
    setEntity({
      name: '',
      productId: null,
      qty: 0,
      price: 0,
      amount: 0,
      availableQty: 0,
      whLocationId: null
    });
    setSelected({
      type: null,
      family: null,
      category: null,
      product: null,
      section: null,
      location: null
    });
    setVisible(false);
  }, [setVisible]);

  const handleApiFetch = useCallback((url, setData) => {
    axiosInstance
      .get(url)
      .then((response) => setData(response.data.data.map((item) => ({ id: item.id, name: item.name }))))
      .catch((error) => console.error('API fetch error:', error));
  }, []);

  useEffect(() => {
    handleApiFetch(
      `/UserTypesTransDestSourcesMatrix/ProductTypesDropdown/${transactionTypeId}/${destSourceId}`,
      (data) => setDataOptions((prev) => ({ ...prev, productTypes: data }))
    );
    handleApiFetch(`/WarehousesSection/allByWarehouseId/${warehouseId}`, (data) =>
      setDataOptions((prev) => ({ ...prev, warehouseSections: data }))
    );
  }, [warehouseId, transactionTypeId, destSourceId, handleApiFetch]);

  useEffect(() => {
    if (selected.type) {
      handleApiFetch(`/ProductsFamily/getByTypeId/${selected.type}`, (data) =>
        setDataOptions((prev) => ({ ...prev, productFamilies: data }))
      );
    }
  }, [selected.type, handleApiFetch]);

  useEffect(() => {
    if (selected.family) {
      handleApiFetch(`/ProductsCategory/getByFamilyId/${selected.family}`, (data) =>
        setDataOptions((prev) => ({ ...prev, productCategories: data }))
      );
    }
  }, [selected.family, handleApiFetch]);

  useEffect(() => {
    if (selected.category) {
      handleApiFetch(`/Product/allByCategory/${selected.category}`, (data) =>
        setDataOptions((prev) => ({ ...prev, products: data }))
      );
    }
  }, [selected.category, handleApiFetch]);

  useEffect(() => {
    if (selected.section) {
      handleApiFetch(`/WarehousesLocation/allBySectionId/${selected.section}`, (data) =>
        setDataOptions((prev) => ({ ...prev, warehouseLocations: data }))
      );
    }
  }, [selected.section, handleApiFetch]);

  const fetchProductPrice = useCallback(() => {
    axiosInstance
      .get(`/ProductsPricesListMaster/productPrice/${selected.product}/${warehouseId}`)
      .then((response) => setEntity((prev) => ({ ...prev, price: Number(response.data.data) || 0 })))
      .catch((error) => console.error('Error fetching product price:', error));
  }, [selected.product, warehouseId]);

  const fetchAvailableQty = useCallback(() => {
    axiosInstance
      .get(
        `/ProductsPricesListMaster/productQty/${selected.product}/${
          selected.location
        }/${transactionTypeId}/${destSourceId}/${destID}/${params.id || 0}`
      )
      .then((response) => setEntity((prev) => ({ ...prev, availableQty: Number(response.data.data) || 0 })))
      .catch((error) => console.error('Error fetching available quantity:', error));
  }, [selected.product, selected.location, transactionTypeId, destSourceId, destID, params.id]);

  useEffect(() => {
    if (selected.product) fetchProductPrice();
  }, [selected.product, fetchProductPrice]);

  useEffect(() => {
    if (selected.product && selected.location) fetchAvailableQty();
  }, [selected.product, selected.location, fetchAvailableQty]);

  const handleDropdownChange = useCallback(
    (key, value) => {
      setSelected((prev) => ({ ...prev, [key]: value }));
      if (key === 'product') {
        const productName = dataOptions.products.find((p) => p.id === value)?.name || '';
        setEntity((prev) => ({ ...prev, productId: value, name: productName }));
      } else if (key === 'location') {
        setEntity((prev) => ({ ...prev, whLocationId: value }));
      }
    },
    [dataOptions.products]
  );

  const confirmClose = useCallback(() => {
    confirmDialog({
      header: 'هل أنت متأكد من أنك تريد الخروج من عملية إضافة منتج؟',
      accept: resetEntityAndSelections,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: () => confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 1
    });
  }, [resetEntityAndSelections]);

  return (
    <>
      <ConfirmDialog tagKey={1} />
      <Dialog
        header='إضافة منتج'
        visible={visible}
        onHide={confirmClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          {[
            {
              label: 'نوع المنتج',
              value: selected.type,
              options: dataOptions.productTypes,
              onChange: (val) => handleDropdownChange('type', val)
            },
            {
              label: 'تصنيف المنتج',
              value: selected.family,
              options: dataOptions.productFamilies,
              onChange: (val) => handleDropdownChange('family', val)
            },
            {
              label: 'فئة المنتج',
              value: selected.category,
              options: dataOptions.productCategories,
              onChange: (val) => handleDropdownChange('category', val)
            },
            {
              label: 'قسم المخزن',
              value: selected.section,
              options: dataOptions.warehouseSections,
              onChange: (val) => handleDropdownChange('section', val)
            },
            {
              label: 'موقع التخزين',
              value: selected.location,
              options: dataOptions.warehouseLocations,
              onChange: (val) => handleDropdownChange('location', val)
            },
            {
              label: 'المنتج',
              value: selected.product,
              options: dataOptions.products,
              onChange: (val) => handleDropdownChange('product', val)
            }
          ].map((field, index) => (
            <div key={index} className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'>{field.label}</label>
                <CustomDropdown
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={field.options}
                  optionValue='id'
                  optionLabel='name'
                  className='w-full'
                />
              </div>
            </div>

            // <CustomFormField
            //   name={'statusId'}
            //   label={`اختر حالة ${extraProps?.entityName}`}
            //   required={true}
            //   control={control}
            //   errors={errors}
            //   type={'dropdown'}
            //   placeholder={'اختر الحاله'}
            //   disabled={isStatusLoading}
            //   options={statusData?.data ?? []}
            // />
          ))}
          {['price', 'availableQty', 'qty'].map((field, index) => (
            <div key={index} className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'>
                  {field === 'price' ? 'السعر' : field === 'availableQty' ? 'الكمية المتاحة' : 'الكمية'}
                </label>
                <InputNumber
                  name={field}
                  value={entity[field]}
                  disabled={field !== 'qty'}
                  maxFractionDigits={5}
                  className={`w-full surface-300 opacity-${field !== 'qty' ? '100' : 'auto'}`}
                  onChange={
                    field === 'qty'
                      ? (e) => setEntity((prev) => ({ ...prev, qty: e.value, amount: e.value * prev.price }))
                      : undefined
                  }
                />
              </div>
            </div>
          ))}
          {/* <Button
            label='إضافة'
            className='w-full mt-3'
            onClick={() => {
              onAddHandler(entity);
              resetEntityAndSelections();
            }}
          /> */}
          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={confirmClose} size='small' outlined />
            <Button
              label='اضافة المنتج'
              className='modals__btn'
              onClick={() => {
                onAddHandler(entity);
                resetEntityAndSelections();
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AddTransactionProduct;
