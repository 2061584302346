import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import Tabs from '../../../components/Tabs/Tabs';
import { axiosInstance } from '../../../global';
import PersonalData from '../../../components/EmployeeDetails/PersonalData';
import EmployeeSalaryTable from '../../../components/Tables/EmployeeSalaryTable';
import EmployeeVacationTable from '../../../components/Tables/EmployeeVacationTable';
import EmployeeCalendar from '../../../components/EmployeeCalendar/EmployeeCalendar';
import './Details.scss';
import AddPayroll from '../../../components/Modals/AddPayroll';
import AddVacationRequest from '../../../components/Modals/AddVacationRequest';
import PageHeader from '../../../components/components/Header/PageHeader';
import { AsyncImage } from 'loadable-image';
import useUpload from '../../../hooks/useUploadFile';
import { useToast } from '../../../context/ToastContext';

function Details() {
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState();
  const [loading, setLoading] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [addPayrollModalVisible, setAddPayrollModalVisible] = useState(false);
  const [addVacationModalVisible, setAddVacationModalVisible] = useState(false);
  const { uploadFiles } = useUpload({});

  const fetchEmployee = async () => {
    axiosInstance
      .get(`/Employee/${params.id}`)
      .then((response) => {
        setEmployee(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    fetchEmployee();
    axiosInstance
      .get(`/Status/dropdown/Employee`)
      .then((response) => {
        setStatuses(response.data.data);
      })
      .catch((error) => {});
  }, []);

  function showConfirmBlockEmployee(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حظر الموظف؟',
      header: 'تأكيد حظر الموظف',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleBlockEmployee,
      draggable: false,
      resizable: false,
      tagKey: 5
    });
  }

  function handleBlockEmployee(e) {
    axiosInstance
      .put(`/Employee/block/${params.id}`, {
        userId: params.id,
        status: 'blocked'
      })
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حظر الموظف',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/employees'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حظر الموظف',
          detail: '',
          life: 3000
        });
      });
  }

  function showConfirmDeleteEmployee(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف الموظف؟',
      header: 'تأكيد حذف الموظف',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteEmployee,
      draggable: false,
      resizable: false,
      tagKey: 5
    });
  }

  function handleDeleteEmployee(e) {
    axiosInstance
      .delete(`/Employee/${params.id}`)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حذف الموظف',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/employees'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف الموظف',
          detail: '',
          life: 3000
        });
      });
  }

  function showConfirmEndSession(e) {
    confirmDialog({
      message: 'هل تريد تأكيد انهاء الاتصال الاجباري',
      header: 'تأكيد انهاء الاتصال الاجباري',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      handleEndSession,
      draggable: false,
      resizable: false
    });
  }

  function handleEndSession(e) {
    // axios
    //   .delete(`/Employee/${params.id}`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   })
    //   .then(() => {
    //     toast.current.show({
    //       severity: 'success',
    //       summary: 'تم حذف الموظف',
    //       detail: '',
    //       life: 3000
    //     });
    //     setTimeout(() => navigate('/employees'), 3000);
    //   })
    //   .catch(() => {
    //     toast.current.show({
    //       severity: 'error',
    //       summary: 'حدث خطأ اثناء حذف الموظف',
    //       detail: '',
    //       life: 3000
    //     });
    //   });
  }

  function handleAddPayroll(payroll) {
    axiosInstance
      .post(`/EmployeesPayrollItem`, payroll)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم اضافة مفردات المرتب', life: 3000 });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء اضافة مفردات المرتب', life: 3000 });
      });
  }

  function handleAddVacation(vacation) {
    axiosInstance
      .post(`/VacationsRequest`, vacation)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم اضافة الاجازة', life: 3000 });
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء اضافة الاجازة',
          detail: error.response.data.message,
          life: 3000
        });
      });
  }

  return (
    <main className='details--wrapper'>
      <PageHeader
        icon={'/images/back-arrow.svg'}
        extraStartContent={<Chip style={{ cursor: 'default' }} label={employee?.status.name} />}
        buttons={[]}
        headerTitle={'تفاصيل الموظف'}
        iconOnClick={() => {
          navigate(-1);
        }}
      />
      <main className='details'>
        <div className='details__tabs'>
          <Tabs
            headers={['بيانات الموظف', 'مفردات المرتب', 'نشاطات الاجازات', 'جدول اعمال الموظف']}
            contents={[
              <PersonalData employee={employee} />,
              <EmployeeSalaryTable />,
              <EmployeeVacationTable />,
              <EmployeeCalendar />
            ]}
          />
          {loading ? (
            <ProgressSpinner />
          ) : (
            <div className='sidepanel'>
              <ConfirmDialog tagKey={5} />
              <AddPayroll
                visible={addPayrollModalVisible}
                setVisible={setAddPayrollModalVisible}
                statuses={statuses}
                entityName='مقرد مرتب'
                title='اضافة مفردات مرتب'
                onClosePrompt='هل تريد الغاء عملية اضافة مفردات مرتب ؟'
                employeeId={params.id}
                onAddHandler={handleAddPayroll}
              />
              <AddVacationRequest
                visible={addVacationModalVisible}
                setVisible={setAddVacationModalVisible}
                statuses={statuses}
                entityName='اجازة'
                title='اضافة اجازة للموظف'
                onClosePrompt='هل تريد الغاء عملية اضافة اجازة للموظف ؟'
                employeeId={params.id}
                onAddHandler={handleAddVacation}
              />
              <div className='sidepanel__data'>
                <div style={{ position: 'relative' }}>
                  <AsyncImage
                    src={`http://bestaqua.oneclickapps.org/${employee.user.imagePath}`}
                    style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                    loader={<div style={{ background: '#888', width: '80px', height: '80px', borderRadius: '50%' }} />}
                    onError={(e) => (e.target.src = '/images/employee2.png')}
                  />
                  {/* Edit Icon */}
                  <img
                    src='/images/edit-icon.svg' // Replace with the path to your edit icon
                    alt='Edit'
                    onClick={() => {
                      const input = document.getElementById('profileImageInput');
                      input.click();
                    }}
                    style={{
                      position: 'absolute',
                      right: '-5px',
                      bottom: '-5px',
                      width: '24px',
                      height: '24px',
                      cursor: 'pointer'
                    }}
                  />
                  <input
                    id={'profileImageInput'}
                    type='file'
                    accept='.jpg,.png'
                    style={{ display: 'none' }}
                    onChange={async (event) => {
                      const file = event.target.files;
                      
                      if (file) {
                        try {
                          await uploadFiles([...file], 'user', employee.id);
                          fetchEmployee();
                        } catch (error) {
                          
                        }
                      }
                    }}
                  />
                </div>
                <div className='flex flex-column gap-2'>
                  <span className='sidepanel__data__name'>{employee.user.userName}</span>
                  <Chip label={employee.position.name} className='sidepanel__data__role' />
                  <span className='sidepanel__data__code'>الرقم المرجعي: #{employee.code}</span>
                  <Chip label={employee.status.name} className='sidepanel__data__status' />
                </div>
              </div>
              <div className='w-full w-full--auth-container'>
                <div className='sidepanel__auth-container'>
                  <div className='flex flex-column gap-4'>
                    <span className='category'>دور الموظف</span>
                    <span className='category'>الفرع</span>
                  </div>
                  <div className='flex flex-column gap-4'>
                    <span className='auth'>{employee.position.userTypeName}</span>
                    <span className='auth'>{employee.branchName}</span>
                  </div>
                </div>
              </div>
              <div className='w-full w-full--auth-container'>
                <div className='sidepanel__actions'>
                  <Button className='p-0' text onClick={() => setAddPayrollModalVisible(true)}>
                    <span className='sidepanel__action'>
                      <img src='/images/label.svg' /> اضافة مفردات مرتب
                    </span>
                  </Button>
                  <Divider />
                  <Button className='p-0' text onClick={() => setAddVacationModalVisible(true)}>
                    <span className='sidepanel__action'>
                      <img src='/images/airplane.svg' /> إضافة إجازات
                    </span>
                  </Button>
                  <Divider />
                  <Link to={`/employees/edit/${params.id}`}>
                    <span className='sidepanel__action'>
                      <img src='/images/security.svg' alt={''} /> تعديل بيانات الموظف
                    </span>
                  </Link>
                  <Divider />
                  <Button className='p-0' text onClick={showConfirmBlockEmployee}>
                    <span className='sidepanel__action'>
                      <img src='/images/block.svg' /> حظر المستخدم
                    </span>
                  </Button>
                  <Divider />
                  <Button className='p-0' severity='danger' text onClick={showConfirmDeleteEmployee}>
                    <span className='sidepanel__action sidepanel__action--btn'>
                      <i className='pi pi-times' /> حذف المستخدم نهائياً
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </main>
  );
}

export default Details;
