import React, { useEffect } from 'react';
import { FormProps } from '../../../types/FormProps';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useStatus from '../../../hooks/useStatus';
import useFetch from '../../../hooks/useFetch';
import { axiosInstance } from '../../../global';
import { useApiState } from '../../../hooks/useApiState';

const WarehouseLocationsForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const warehouseID = watch('warehouseId');
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Warehouse');
  const { data: warehouseData, isLoading: isWarehouseLoading } = useFetch('/Warehouse/dropdown');

  const fetchWarehouseSections = async () => {
    const response = await axiosInstance.get(`/WarehousesSection/list/ByWarehouse/${warehouseID}`);
    const mapped = response.data.data.map((item: any) => {
      return { id: item.id, name: item.name };
    });
    return mapped;
  };

  const { lazyApiCall, state } = useApiState({ apiCall: fetchWarehouseSections, isLazy: true });

  useEffect(() => {
    if (warehouseID) {
      lazyApiCall();
    }
  }, [warehouseID]);

  useEffect(() => {
    if (!isWarehouseLoading) {
      setValue('warehouseId', warehouseID);
    }
  }, [isWarehouseLoading]);

  return (
    <>
      <CustomFormField
        name={'warehouseId'}
        label={isEditMode ? 'اسم المخزن' : 'اختر اسم المخزن'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={warehouseData?.data ?? []}
        disabled={extraProps?.isDisabled || isWarehouseLoading}
      />
      <CustomFormField
        name={'warehouseSectionId'}
        label={isEditMode ? 'قسم المخزن' : 'اختر قسم المخزن'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={state?.data ?? []}
        disabled={!warehouseID}
      />
      <CustomFormField
        name={'name'}
        label={'اضف اسم موقع المخزن'}
        control={control}
        errors={errors}
        type={'text'}
        placeholder='موقع المخزن'
      />
      <CustomFormField
        name={'plantCode'}
        label={'كود الPlant'}
        control={control}
        errors={errors}
        type={'text'}
        placeholder='كود الPlant'
      />
      {/* <CustomFormField
        name={'code'}
        label={'اضف كود موقع المخزن'}
        control={control}
        errors={errors}
        type={'text'}
        placeholder='كود موقع المخزن'
      /> */}
      <CustomFormField
        name={'statusId'}
        label={`اختر حالة قسم المخزن`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />
      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default WarehouseLocationsForm;
