import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { genericSchemeForNames } from '../../../components/components/Form/Scheme';
import useModal from '../../../hooks/useModal';
import GenericForm from '../../../components/Forms/GenericForm';
import { useToast } from '../../../context/ToastContext';
import GenericModal from '../../../components/components/Modal/GenericModal';
import * as yup from 'yup';
import ActivityFamilyForm from './Forms/ActivityFamilyForm';
import ActivityCategoryForm from './Forms/ActivityCategoryForm';
import ActivityForm from './Forms/ActivityForm';

const activityFamilyScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema
          .required('activity family name is required')
          .min(1, 'At least one activity family name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('activity family name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema
          .min(1, 'At least one activity family name is required')
          .required('activity family names are required');
      }
      return schema.notRequired();
    }),
  TypeId: yup.number().required('Activity Type is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});
const activityCategoryScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema
          .required('Activity Category name is required')
          .min(1, 'At least one Activity Category name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Activity Category name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema
          .min(1, 'At least one Activity Category name is required')
          .required('Activity Category names are required');
      }
      return schema.notRequired();
    }),
  FamilyId: yup.number().required('Activity Family is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});
const activityScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Activity name is required').min(1, 'At least one Activity name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Activity name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one Activity name is required').required('Activity names are required');
      }
      return schema.notRequired();
    }),
  CategoryId: yup.number().required('Activity Category is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

function VisitsGeneralList() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isVisitTypeVisitable,
    openModal: visitTypeOpenModal,
    closeModal: visitTypeCloseModal,
    control: visitTypeControl,
    handleSubmit: visitTypeSubmit,
    errors: visitTypeErrors,
    setValue: visitTypeSetValue,
    loading: visitTypeLoading,
    setLoading: visitTypeSetLoading,
    watch: visitTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/VisitsType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isPriorityVisitable,
    openModal: priorityOpenModal,
    closeModal: priorityCloseModal,
    control: priorityControl,
    handleSubmit: prioritySubmit,
    errors: priorityErrors,
    setValue: prioritySetValue,
    loading: priorityLoading,
    setLoading: prioritySetLoading,
    watch: priorityWatch
  } = useModal({ remarks: ' ', names: [] }, '/Priority', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isDescionVisitable,
    openModal: descionOpenModal,
    closeModal: descionCloseModal,
    control: descionControl,
    handleSubmit: descionSubmit,
    errors: descionErrors,
    setValue: descionSetValue,
    loading: descionLoading,
    setLoading: descionSetLoading,
    watch: descionWatch
  } = useModal({ remarks: ' ', names: [] }, '/Decision', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isReasonVisitable,
    openModal: reasonOpenModal,
    closeModal: reasonCloseModal,
    control: reasonControl,
    handleSubmit: reasonSubmit,
    errors: reasonErrors,
    setValue: reasonSetValue,
    loading: reasonLoading,
    setLoading: reasonSetLoading,
    watch: reasonWatch
  } = useModal({ remarks: ' ', names: [] }, '/Reason', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isActivityTypeVisitable,
    openModal: activityTypeOpenModal,
    closeModal: activityTypeCloseModal,
    control: activityTypeControl,
    handleSubmit: activityTypeSubmit,
    errors: activityTypeErrors,
    setValue: activityTypeSetValue,
    loading: activityTypeLoading,
    setLoading: activityTypeSetLoading,
    watch: activityTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/ActivitiesType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isActivityFamilyVisitable,
    openModal: activityFamilyOpenModal,
    closeModal: activityFamilyCloseModal,
    control: activityFamilyControl,
    handleSubmit: activityFamilySubmit,
    errors: activityFamilyErrors,
    setValue: activityFamilySetValue,
    loading: activityFamilyLoading,
    setLoading: activityFamilySetLoading,
    watch: activityFamilyWatch
  } = useModal({ remarks: ' ', names: [] }, '/ActivitiesFamily', fetchTabData, activityFamilyScheme, toast);

  const {
    isVisible: isActivityCategoryVisitable,
    openModal: activityCategoryOpenModal,
    closeModal: activityCategoryCloseModal,
    control: activityCategoryControl,
    handleSubmit: activityCategorySubmit,
    errors: activityCategoryErrors,
    setValue: activityCategorySetValue,
    loading: activityCategoryLoading,
    setLoading: activityCategorySetLoading,
    watch: activityCategoryWatch
  } = useModal({ remarks: ' ', names: [] }, '/ActivitiesCategory', fetchTabData, activityCategoryScheme, toast);

  const {
    isVisible: isActivityVisitable,
    openModal: activityOpenModal,
    closeModal: activityCloseModal,
    control: activityControl,
    handleSubmit: activitySubmit,
    errors: activityErrors,
    setValue: activitySetValue,
    loading: activityLoading,
    setLoading: activitySetLoading,
    watch: activityWatch
  } = useModal({ remarks: ' ', names: [] }, '/Activity', fetchTabData, activityScheme, toast);
  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'انواع الزيارات',
            tableHeader: 'انواع الزيارات',
            entityName: 'الزيارة',
            dataKey: 'VisitsType',
            apiUrl: '/VisitsType/list',
            columns: [
              { header: 'اسم نوع الزيارة', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة نوع زيارة جديدة',
                onClick: () => {
                  visitTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              visitTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              visitTypeOpenModal();
            }
          },
          {
            label: 'انواع مستوى الاهميه',
            tableHeader: 'انواع مستوى الاهميه',
            entityName: 'مستوى الاهمية',
            dataKey: 'Priority',
            apiUrl: '/Priority/list',
            columns: [
              { header: 'مستوى الاهمية', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة نوع مستوى اهمية جديد',
                onClick: () => {
                  priorityOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              priorityOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              priorityOpenModal();
            }
          },
          {
            label: 'انواع الاجرائات المتخذة',
            tableHeader: 'انواع الاجرائات المتخذة',
            entityName: 'الاجراء المتخذ',
            dataKey: 'Decision',
            apiUrl: '/Decision/list',
            columns: [
              { header: 'اسم الاجراء المتخذ', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة نوع اجراء متخذ جديد',
                onClick: () => {
                  descionOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              descionOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              descionOpenModal();
            }
          },
          {
            label: 'انواع اسباب الاتصال',
            tableHeader: 'انواع اسباب الاتصال',
            entityName: 'سبب الاتصال',
            dataKey: 'Reason',
            apiUrl: '/Reason/list',
            columns: [
              { header: 'اسم سبب الاتصال', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة نوع سبب اتصال جديد',
                onClick: () => {
                  reasonOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              reasonOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              reasonOpenModal();
            }
          },
          {
            label: 'انواع الاتصال',
            tableHeader: 'انواع الاتصال',
            entityName: 'الاتصال',
            dataKey: 'ActivitiesType',
            apiUrl: '/ActivitiesType/list',
            columns: [
              { header: 'اسم نوع الاتصال', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة نوع اتصال جديد',
                onClick: () => {
                  activityTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              activityTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              activityTypeOpenModal();
            }
          },
          {
            label: 'تصنيف الاتصال',
            tableHeader: 'تصنيف الاتصال',
            entityName: 'تصنيف اتصال',
            dataKey: 'ActivitiesFamily',
            apiUrl: '/ActivitiesFamily/list',
            columns: [
              { header: 'نوع الاتصال', field: 'typeName', isChip: false },
              { header: 'اسم تصنيف الاتصال', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة تصنيف اتصال جديد',
                onClick: () => {
                  activityFamilyOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              activityFamilyOpenModal({
                id: rowData.id,
                TypeId: rowData.typeID,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              activityFamilyOpenModal();
            }
          },
          {
            label: 'فئة الاتصال',
            tableHeader: 'فئة الاتصال',
            entityName: 'فئة اتصال',
            dataKey: 'ActivitiesCategory',
            apiUrl: '/ActivitiesCategory/list',
            columns: [
              { header: 'تصنيف الاتصال', field: 'familyName', isChip: false },
              { header: 'اسم فئة الاتصال', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة فئة اتصال جديد',
                onClick: () => {
                  activityCategoryOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              activityCategoryOpenModal({
                id: rowData.id,
                FamilyId: rowData.familyID,
                TypeId: rowData.typeID,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              activityCategoryOpenModal();
            }
          },
          {
            label: ' الاتصال',
            tableHeader: ' الاتصال',
            entityName: ' اتصال',
            dataKey: 'Activity',
            apiUrl: '/Activity/list',
            columns: [
              { header: 'فئة الاتصال', field: 'categoryName', isChip: false },
              { header: 'اسم الاتصال', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة اتصال جديد',
                onClick: () => {
                  activityOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              activityOpenModal({
                id: rowData.id,
                CategoryId: rowData.categoryID,
                FamilyId: rowData.familyID,
                TypeId: rowData.typeID,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              activityOpenModal();
            }
          }
        ]}
        headerTitle='بيانات الزيارات'
        headerIcon={'../images/briefcase1.svg'}
      />
      <GenericModal
        headerTitle={'إضافة انواع زيارة جديدة'}
        isVisible={isVisitTypeVisitable}
        onHide={visitTypeCloseModal}
        handleSubmit={visitTypeSubmit}
        toast={toast}
        loading={visitTypeLoading}
        isEditMode={visitTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={visitTypeControl}
          watch={visitTypeWatch}
          errors={visitTypeErrors}
          setValue={visitTypeSetValue}
          extraProps={{
            entityName: 'نوع الزيارة'
          }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة انواع مستوى الاهمية جديدة'}
        isVisible={isPriorityVisitable}
        onHide={priorityCloseModal}
        handleSubmit={prioritySubmit}
        toast={toast}
        loading={priorityLoading}
        isEditMode={priorityWatch('id') !== undefined}
      >
        <GenericForm
          control={priorityControl}
          watch={priorityWatch}
          errors={priorityErrors}
          setValue={prioritySetValue}
          extraProps={{
            entityName: 'نوع مستوى الاهمية'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة انواع اجرائات جديدة'}
        isVisible={isDescionVisitable}
        onHide={descionCloseModal}
        handleSubmit={descionSubmit}
        toast={toast}
        loading={descionLoading}
        isEditMode={descionWatch('id') !== undefined}
      >
        <GenericForm
          control={descionControl}
          watch={descionWatch}
          errors={descionErrors}
          setValue={descionSetValue}
          extraProps={{
            entityName: 'نوع الاجراء'
          }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة انواع اسباب اتصال جديدة'}
        isVisible={isReasonVisitable}
        onHide={reasonCloseModal}
        handleSubmit={reasonSubmit}
        toast={toast}
        loading={reasonLoading}
        isEditMode={reasonWatch('id') !== undefined}
      >
        <GenericForm
          control={reasonControl}
          watch={reasonWatch}
          errors={reasonErrors}
          setValue={reasonSetValue}
          extraProps={{
            entityName: 'نوع سبب الاتصال'
          }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة انواع الاتصال جديدة'}
        isVisible={isActivityTypeVisitable}
        onHide={activityTypeCloseModal}
        handleSubmit={activityTypeSubmit}
        toast={toast}
        loading={activityTypeLoading}
        isEditMode={activityTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={activityTypeControl}
          watch={activityTypeWatch}
          errors={activityTypeErrors}
          setValue={activityTypeSetValue}
          extraProps={{
            entityName: 'نوع الاتصال'
          }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة تصنيف اتصال جديد'}
        isVisible={isActivityFamilyVisitable}
        onHide={activityFamilyCloseModal}
        handleSubmit={activityFamilySubmit}
        toast={toast}
        loading={activityFamilyLoading}
        isEditMode={activityFamilyWatch('id') !== undefined}
      >
        <ActivityFamilyForm
          control={activityFamilyControl}
          watch={activityFamilyWatch}
          errors={activityFamilyErrors}
          setValue={activityFamilySetValue}
          extraProps={{
            entityName: 'تصنيف الاتصال'
          }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة تصنيف اتصال جديد'}
        isVisible={isActivityFamilyVisitable}
        onHide={activityFamilyCloseModal}
        handleSubmit={activityFamilySubmit}
        toast={toast}
        loading={activityFamilyLoading}
        isEditMode={activityFamilyWatch('id') !== undefined}
      >
        <ActivityFamilyForm
          control={activityFamilyControl}
          watch={activityFamilyWatch}
          errors={activityFamilyErrors}
          setValue={activityFamilySetValue}
          extraProps={{
            entityName: 'تصنيف الاتصال'
          }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة فئة اتصال جديد'}
        isVisible={isActivityCategoryVisitable}
        onHide={activityCategoryCloseModal}
        handleSubmit={activityCategorySubmit}
        toast={toast}
        loading={activityCategoryLoading}
        isEditMode={activityCategoryWatch('id') !== undefined}
      >
        <ActivityCategoryForm
          control={activityCategoryControl}
          watch={activityCategoryWatch}
          errors={activityCategoryErrors}
          setValue={activityCategorySetValue}
          extraProps={{
            entityName: 'فئة الاتصال'
          }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={'إضافة اتصال جديد'}
        isVisible={isActivityVisitable}
        onHide={activityCloseModal}
        handleSubmit={activitySubmit}
        toast={toast}
        loading={activityLoading}
        isEditMode={activityWatch('id') !== undefined}
      >
        <ActivityForm
          control={activityControl}
          watch={activityWatch}
          errors={activityErrors}
          setValue={activitySetValue}
          extraProps={{
            entityName: 'الاتصال'
          }}
        />
      </GenericModal>
    </>
  );
}

export default VisitsGeneralList;
