import update from 'immutability-helper';
import {
  FETCH_SERVICEVISIT_REQUEST,
  FETCH_SERVICEVISIT_SUCCESS,
  FETCH_SERVICEVISIT_FAILURE,
  ADD_SERVICEVISIT_REQUEST,
  ADD_SERVICEVISIT_SUCCESS,
  ADD_SERVICEVISIT_FAILURE,
  EDIT_SERVICEVISIT_REQUEST,
  EDIT_SERVICEVISIT_SUCCESS,
  EDIT_SERVICEVISIT_FAILURE,
  CHANGE_SERVICEVISIT_STATE,
  CHANGE_SERVICEVISIT_TEMP_STATE,
  CLEAR_SERVICEVISIT_STATE
} from './visitTypes';

const initialState = {
  visitServices: {
    visitId: '',
    visitServiceDetails: [],
    remarks: ''
  },
  visitServicesData: {
    visitId: '',
    visitServiceDetails: [],
    remarks: ''
  },
  visitServicesTemp: {
    data: []
  },
  loading: false,
  error: ''
};

const visitReducer5 = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SERVICEVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_SERVICEVISIT_SUCCESS:
      return {
        ...state,
        visitServicesData: action.payload,
        loading: false,
        error: ''
      };
    case FETCH_SERVICEVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_SERVICEVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_SERVICEVISIT_SUCCESS:
      return {
        ...state,
        visitServices: action.payload,
        loading: false,
        error: ''
      };
    case ADD_SERVICEVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case EDIT_SERVICEVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case EDIT_SERVICEVISIT_SUCCESS:
      return {
        ...state,
        visitServices: action.payload,
        loading: false,
        error: ''
      };
    case EDIT_SERVICEVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CHANGE_SERVICEVISIT_STATE:
      return update(state, {
        $merge: {
          visitServices: {
            ...state.visitServices,
            ...action.payload
          }
        }
      });
    case CHANGE_SERVICEVISIT_TEMP_STATE:
      return update(state, {
        $merge: {
          visitServicesTemp: {
            ...state.visitServicesTemp,
            ...action.payload
          }
        }
      });
    case CLEAR_SERVICEVISIT_STATE:
      return {
        ...state,
        visitServices: initialState.visitServices,
        visitServicesTemp: initialState.visitServicesTemp
      };
    default:
      return state;
  }
};

export default visitReducer5;
