import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import PageHeader from '../../../components/components/Header/PageHeader';
import './AddTransaction.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { addTransaction } from '../../../redux/warehouse/warehouseActions';
import AddReceiving2 from './AddReceiving2';
import AddReceiving1 from './AddReceiving1';
import { useToast } from '../../../context/ToastContext';

const AddReceiving = ({ activeIndexnum, addTransaction, transaction, token, loading, error }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);

  useEffect(() => {
    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, []);

  const items = [{ label: 'بيانات اذن الاستلام للعميل' }, { label: 'عناصر الطلب' }];

  function handleOnClick() {
    setActiveIndex(activeIndex + 1);
    if (activeIndex >= 1) {
      setActiveIndex(0);
    }
  }

  function handleِAddOnClick() {
    try {
      addTransaction(transaction, token);
      toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم اضافة اذن الاستلام بنجاح' });
      setTimeout(() => navigate('/warehouses/transactions/'), 1500);
    } catch (errorAddReceiving) {
      
      
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية اضافة اذن الاستلام' });
    }
  }

  return (
    <div className='screen'>
      <div className='flex flex-column gap-5'>
        <PageHeader
          icon={'/images/back-arrow.svg'}
          buttons={[]}
          headerTitle={'إضافة طلب إذن جديد'}
          iconOnClick={() => navigate(-1)}
        />
        <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} />
        </Card>
        <div>
          <div>
            {activeIndex === 0 && <AddReceiving1 setCheck={setIsFormOk} />}
            {activeIndex === 1 && <AddReceiving2 setCheck={setIsFormOk} />}
          </div>
          <div className='btn mb-5'>
            {activeIndex != 1 && (
              <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                الخطوة التالية
              </Button>
            )}
            {activeIndex === 1 &&
              (loading ? (
                <ProgressSpinner />
              ) : (
                <Button className='btn__nextbtn' onClick={handleِAddOnClick} disabled={!isFormOk}>
                  اضافة اذن الاستلام
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    transaction: state.warehouse.transaction,
    loading: state.warehouse.loading,
    error: state.warehouse.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTransaction: (transaction, token) => dispatch(addTransaction(transaction, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReceiving);
