import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Chips } from 'primereact/chips';

import CustomDropdown from '../Dropdown/CustomDropDown';
import { SelectButton } from 'primereact/selectbutton';
import { InputNumber } from 'primereact/inputnumber';
import CustomMultiSelectDropdown from '../Dropdown/CustomMultiSelectDropdown';
import { Checkbox } from 'primereact/checkbox';

interface FormFieldProps {
  name: string;
  label: string;
  control: Control<any>;
  errors: FieldErrors;
  type:
    | 'text'
    | 'textarea'
    | 'dropdown'
    | 'calendar'
    | 'multi'
    | 'decimal'
    | 'toggle'
    | 'multi-select'
    | 'checkbox'
    | string;
  options?: any[];
  optionValue?: string;
  optionLabel?: string;
  placeholder?: string;
  disabled?: boolean;
  timeOnly?: boolean;
  rows?: number;
  required?: boolean;
  calendarType?: 'single' | 'range';
  customStyle?: string;
}

const CustomFormField: React.FC<FormFieldProps> = React.memo(
  ({
    name,
    label,
    control,
    errors,
    type,
    options = [],
    optionValue = 'id',
    optionLabel = 'name',
    placeholder = '',
    disabled = false,
    timeOnly = false,
    rows = 3,
    required,
    calendarType = 'single',
    customStyle
  }) => {
    const errorMessage = errors[name]?.message as string;
    const toggleOptions = [
      { label: 'نعم', value: true },
      { label: 'لا', value: false }
    ];

    return (
      <div className={`w-full flex flex-column gap-3 ${customStyle}`}>
        {type !== 'checkbox' && (
          <label style={{ fontSize: '1em', fontWeight: '700', color: '#313131', lineHeight: 'normal' }}>
            {label} {required && <span className='text-red-500'>*</span>}
          </label>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            switch (type) {
              case 'text':
                return (
                  <>
                    <InputText
                      {...field}
                      className='w-full'
                      disabled={disabled}
                      value={field.value || ''}
                      autoComplete='off'
                    />
                    {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                  </>
                );
              case 'textarea':
                return (
                  <>
                    <InputTextarea {...field} className='w-full' rows={rows} disabled={disabled} autoComplete='off' />
                    {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                  </>
                );
              case 'dropdown':
                return (
                  <>
                    <CustomDropdown
                      {...field}
                      options={options}
                      optionValue={optionValue}
                      optionLabel={optionLabel}
                      placeholder={placeholder}
                      className='w-full'
                      disabled={disabled}
                      onChange={(e) => field.onChange(e.value)}
                    />
                    {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                  </>
                );
              case 'calendar':
                return (
                  <>
                    <Calendar
                      {...field}
                      value={field.value || null}
                      dateFormat='mm/dd/yy'
                      selectionMode={calendarType}
                      showIcon
                      className='w-full'
                      disabled={disabled}
                      timeOnly={timeOnly}
                      hourFormat='12'
                    />
                    {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                  </>
                );
              case 'multi':
                return (
                  <>
                    <Chips
                      {...field}
                      value={Array.isArray(field.value) ? field.value : []}
                      addOnBlur={true}
                      className='w-full'
                      disabled={disabled}
                    />
                    {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                  </>
                );
              case 'decimal':
                return (
                  <>
                    <InputNumber
                      {...field}
                      className='w-full'
                      disabled={disabled}
                      onChange={(e) => {
                        const value = e.value;
                        field.onChange(value);
                      }}
                    />
                    {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                  </>
                );
              case 'toggle': // Add a case for SelectButton (toggle)
                const dynamicToggleOptions =
                  options.length > 0
                    ? options.map((option) => ({ label: option[optionLabel], value: option[optionValue] }))
                    : toggleOptions;
                return (
                  <>
                    <SelectButton
                      {...field}
                      value={field.value !== undefined ? field.value : false}
                      options={dynamicToggleOptions}
                      className='w-full'
                      disabled={disabled}
                      onChange={(e) => field.onChange(e.value)}
                    />
                    {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                  </>
                );

              case 'multi-select': // New case for multi-select
                return (
                  <>
                    <CustomMultiSelectDropdown
                      {...field}
                      options={options}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      optionValue={optionValue}
                      optionLabel={optionLabel}
                      placeholder={placeholder}
                      className='w-full'
                      disabled={disabled}
                      filter={true}
                      showClear={true}
                      display={'comma'}
                    />
                    {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                  </>
                );
              case 'checkbox': // New case for checkbox
                return (
                  <>
                    <div className='flex w-full flex-row gap-1'>
                      <Checkbox
                        inputId={name}
                        {...field}
                        checked={field.value || false}
                        disabled={disabled}
                        onChange={(e) => field.onChange(e.checked)}
                      />
                      <label style={{ fontSize: '1em', fontWeight: '700', color: '#313131', lineHeight: 'normal' }}>
                        {label}
                      </label>
                      {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                    </div>
                  </>
                );
              default:
                return (
                  <>
                    <InputText {...field} className='w-full' disabled={disabled} placeholder={placeholder} />
                    {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                  </>
                );
            }
          }}
        />
      </div>
    );
  }
);

export default CustomFormField;
