import React from 'react';
import { FormProps } from '../../../../types/FormProps';
import useStatus from '../../../../hooks/useStatus';
import CustomFormField from '../../../../components/components/Form/CustomFormField';

const UserTypeForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Employee');

  return (
    <div className='flex flex-column gap-5'>
      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? `اسم ${extraProps?.entityName}` : `اضف اسم/اسماء ${extraProps?.entityName} الجديدة`}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        required={true}
        placeholder={isEditMode ? `اسم ${extraProps?.entityName}` : `اضف اسم/اسماء ${extraProps?.entityName} الجديدة`}
      />

      <CustomFormField name={'teamManager'} label={'قائد فريق'} control={control} errors={errors} type={'toggle'} />

      <CustomFormField
        name={'statusId'}
        label={`اختر حالة ${extraProps?.entityName}`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </div>
  );
};

export default UserTypeForm;
