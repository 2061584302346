import React, { lazy, startTransition, Suspense, useEffect, useState } from 'react';
import { Steps } from 'primereact/steps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  add_employee_failure,
  add_employee_success,
  addEmployee,
  clearEmployeeState,
  editEmployee,
  fetchEmployee,
  fill_employee
} from '../../../redux/employee/employeeActions';
import './AddEditEmployee.scss';
import useUpload from '../../../hooks/useUploadFile';
import { axiosInstance } from '../../../global';
import moment from 'moment';
import { useToast } from '../../../context/ToastContext';

const AddEditEmployee1 = lazy(() => import('./AddEditEmployee1'));
const AddEditEmployee2 = lazy(() => import('./AddEditEmployee2'));
const AddEditEmployee3 = lazy(() => import('./AddEditEmployee3'));

const AddEditEmployee = ({
  activeIndexnum,
  fetchEmployee,
  addEmployee,
  addEmployeeFailure,
  addEmployeeSuccess,
  editEmployee,
  employee,
  token,
  loading,
  error,
  clearEmployeeState
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);
  const [isPayrollValid, setIsPayrollValid] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { error: imageUploadError, isUploading, responseMessage, uploadFiles, uploadProgress } = useUpload({});

  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      fetchEmployee(params.id);
    } else {
      clearEmployeeState();
    }
    return () => {
      clearEmployeeState();
    };
  }, [params.id, fetchEmployee, clearEmployeeState]);

  useEffect(() => {
    setActiveIndex(activeIndexnum ?? 0);
  }, [activeIndexnum]);

  const items = [
    { label: 'تفاصيل الموظف الاساسية' },
    { label: 'تفاصيل العنوان و المنطقة' },
    { label: 'تفاصيل الراتب' }
  ];

  const handleOnClick = () => {
    startTransition(() => {
      fill_employee(employee);
      setActiveIndex((prevIndex) => (prevIndex >= 2 ? 0 : prevIndex + 1));
    });
  };

  const handleBackClick = () => {
    startTransition(() => {
      fill_employee(employee);
      setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    });
  };

  useEffect(() => {
    if (submitDone && !loading) {
      if (error) {
        toast.current.show({
          severity: 'error',
          summary: 'عملية خاطئة',
          detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} الموظف: ${error}`
        });
      } else {
        toast.current.show({
          severity: 'success',
          summary: 'عملية ناجحة',
          detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} الموظف بنجاح`
        });
        navigate('/employees');
      }
      setSubmitDone(false);
    }
  }, [loading, error, submitDone, navigate, isEditing]);

  const handleSubmitOnClick = async () => {
    const formattedPayrollList = employee.payrollList.map((item) => {
      const { id, ...rest } = item;
      return isNaN(parseInt(id)) ? rest : { id: parseInt(id), ...rest };
    });
    const employeePayload = {
      ...employee,
      hiringDate: moment(employee.hiringDate).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'), // Format date before sending
      payrollList: employee.payrollList.map((item) => ({
        id: item.id,
        payrollItemId: item.payrollItemId,
        amount: item.amount,
        effectiveDate: moment(employee.effectiveDate).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'), // Format date before sending
        endDate: item.endDate ? moment(item.endDate).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
        statusId: item.statusId
      })),
      vacations: employee.vacations.map((vac) => ({
        vacationFromDate: moment(vac.vacationFromDate).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'), // Format date before sending
        vacationToDate: moment(vac.vacationToDate).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'), // Format date before sending
        vacationTypeId: vac.vacationTypeId,
        vacationCount: vac.vacationCount
      }))
    };

    try {
      if (isEditing) {
        // Edit logic
        const { image, ...remainingEmployeePayload } = employeePayload;

        // Send employee details
        await editEmployee(remainingEmployeePayload, token);

        // If a new image is selected, upload it
        if (image) {
          await uploadFiles(image, 'user', employee.id);
        }
      } else {
        // Add logic - remove employee.id if it exists
        const { id, image, ...remainingEmployeePayload } = employeePayload;
        delete employeePayload.id;

        employeePayload.payrollList.forEach((item) => {
          delete item.id;
        });

        // Send employee details
        const response = await axiosInstance.post(`/Employee`, remainingEmployeePayload);

        // If an image is selected, upload it
        if (image) {
          await uploadFiles(image, 'user', response.data.data.id);
        }

        // Dispatch success action
        addEmployeeSuccess(response.data.data);
      }
      setSubmitDone(true);
    } catch (errorSubmit) {
      addEmployeeFailure(errorSubmit?.message ?? '');

      toast.current.show({
        severity: 'error',
        summary: 'عملية خاطئة',
        detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} الموظف: ${error}`
      });
    }
  };

  return (
    <>
      <div className='flex flex-column gap-5 add-edit__emp__main'>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} />
        </div>
        <div>
          {loading ? (
            <ProgressSpinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          ) : (
            <Suspense fallback={<ProgressSpinner />}>
              {activeIndex === 0 && <AddEditEmployee1 setCheck={setIsFormOk} employee={employee} />}
              {activeIndex === 1 && <AddEditEmployee2 setCheck={setIsFormOk} employee={employee} />}
              {activeIndex === 2 && (
                <AddEditEmployee3 setCheck={setIsFormOk} setPayrollValid={setIsPayrollValid} employee={employee} />
              )}
            </Suspense>
          )}
          <div className='btn mb-5'>
            {activeIndex !== 0 && (
              <Button className='btn__nextbtn' onClick={handleBackClick}>
                العودة للخطوة السابقة
              </Button>
            )}
            {activeIndex !== 2 && (
              <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                الخطوة التالية
              </Button>
            )}
            {activeIndex === 2 &&
              (loading ? (
                <ProgressSpinner />
              ) : (
                <Button className='btn__nextbtn' onClick={handleSubmitOnClick} disabled={!isFormOk || !isPayrollValid}>
                  {isEditing ? 'حفظ التعديلات' : 'إضافة الموظف'}
                </Button>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  employee: state.employee.employee,
  loading: state.employee.loading,
  error: state.employee.error
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmployee: (id) => dispatch(fetchEmployee(id)),
  addEmployee: (employee, token) => dispatch(addEmployee(employee, token)),
  addEmployeeSuccess: (employee) => dispatch(add_employee_success(employee)),
  addEmployeeFailure: (errorMessage) => dispatch(add_employee_failure(errorMessage)),
  editEmployee: (employee, token) => dispatch(editEmployee(employee, token)),
  clearEmployeeState: () => dispatch(clearEmployeeState())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEmployee);
