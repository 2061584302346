import React from 'react';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectProps } from 'primereact/multiselect';
import { Chip } from 'primereact/chip';

interface CustomMultiSelectProps extends MultiSelectProps {
  options: { label: string; value: string | number }[];
  value: string[] | number[] | null;
  onChange: (e: MultiSelectChangeEvent) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  filter?: boolean;
  optionLabel?: string;
  optionValue?: string;
  showClear?: boolean;
  disabled?: boolean;
  display?: 'comma' | 'chip';
  maxSelectedLabels?: number;
}

const CustomMultiSelectDropdown = React.forwardRef<any, CustomMultiSelectProps>(
  (
    {
      options,
      value,
      onChange,
      placeholder,
      style,
      className,
      filter = true,
      optionLabel = 'name',
      showClear = false,
      disabled = false,
      display = 'chip',
      ...rest
    },
    ref
  ) => {
    return (
      <MultiSelect
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder || 'Select items'}
        style={{ fontFamily: 'IBM Plex Sans Arabic', ...style }}
        className={`${className} custom-font-multiselect`}
        filter={filter}
        optionLabel={optionLabel}
        showClear={showClear}
        disabled={disabled}
        display={display}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default CustomMultiSelectDropdown;
