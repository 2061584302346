import React from 'react';
import { FormProps } from '../../../../types/FormProps';
import { axiosInstance } from '../../../../global';
import { useApiState } from '../../../../hooks/useApiState';
import useStatus from '../../../../hooks/useStatus';
import CustomFormField from '../../../../components/components/Form/CustomFormField';

const PayrollForm: React.FC<FormProps> = ({ control, errors, setValue, watch }) => {
  const fetchEmployee = async () => {
    const response = await axiosInstance.get(`/Employee/dropdown`);
    return response.data;
  };

  const fetchPayrollItem = async () => {
    const response = await axiosInstance.get(`/PayrollItem/dropdown`);
    return response.data;
  };

  const {
    state: { isLoading: isEmployeeLoading, error: employeeError, data: employeeData },
    lazyApiCall: getEmployeeData
  } = useApiState({ apiCall: fetchEmployee });

  const {
    state: { isLoading: isPayrollItemLoading, error: payrollItemError, data: payrollItemData },
    lazyApiCall: getPayrollItemData
  } = useApiState({ apiCall: fetchPayrollItem });

  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Employee');

  return (
    <div className='flex flex-column gap-5'>
      <CustomFormField
        name={'employeeId'}
        label={'اسم الموظف'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={employeeData?.data ?? []}
        disabled={isEmployeeLoading}
      />

      <CustomFormField
        name={'payrollItemId'}
        label={'نوع الاضافة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={payrollItemData?.data ?? []}
        disabled={isPayrollItemLoading}
      />

      <CustomFormField name={'amount'} label={'القيمة'} control={control} errors={errors} type={'decimal'} />

      <CustomFormField
        name={'effectiveDate'}
        label={'تاريخ بدايه التنفيذ'}
        control={control}
        errors={errors}
        type={'calendar'}
      />
      <CustomFormField name={'endDate'} label={'تاريخ الانتهاء'} control={control} errors={errors} type={'calendar'} />

      <CustomFormField
        name={'statusId'}
        label={'اختر حالة طلب المرتب'}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </div>
  );
};
export default PayrollForm;
