import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { connect } from 'react-redux';

import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import CustomDropdown from '../components/Dropdown/CustomDropDown';
import { useToast } from '../../context/ToastContext';

function AddCustomerLocation({ token, title, entityName, visible, setVisible, statuses, onClosePrompt, onAddHandler }) {
  const toast = useToast();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [entity, setEntity] = useState({
    Customer: '',
    Address: '',
    landmark: '',
    remarks: '',
    statusId: null
  });

  function acceptClose() {
    setEntity({
      Customer: '',
      Address: '',
      landmark: '',
      remarks: '',
      statusId: null
    });
    setSelectedStatus(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value || e.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  return (
    <>
      
      <ConfirmDialog tagKey={2} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          {/* <Message
            severity='info'
            text={`يجب اختيار نوع المنتج ${selectedLevel > 1 ? 'والتصنيف' : ''} اولاً حتى يكون بأمكانك اضافة ${
              selectedLevel > 1 ? 'فئة' : 'تصنيف'
            } المنتجات داخلها`}
            className='text-black-alpha-90 font-semibold'
          /> */}
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>أسم العميل</label>
              <InputText name='Customer' value={entity.Customer} className='w-full' onChange={handleInputChange} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>العنوان</label>
              <InputText name='Address' value={entity.Address} className='w-full' onChange={handleInputChange} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>علامة مميزة</label>
              <InputText name='landmark' value={entity.landmark} className='w-full' onChange={handleInputChange} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>الوصف</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة {entityName}</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر الحالة'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(entity)}>
              اضافة {entityName}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerLocation);
