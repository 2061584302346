import update from 'immutability-helper';

import {
  ADD_COVENANT_FAILURE,
  ADD_COVENANT_REQUEST,
  ADD_COVENANT_SUCCESS,
  ADD_TRANSACTION_FAILURE,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  CLEAR_TRANSACTION_STATE,
  ADD_WAREHOUSE_FAILURE,
  ADD_WAREHOUSE_REQUEST,
  ADD_WAREHOUSE_SUCCESS,
  CHANGE_COVENANT_STATE,
  CHANGE_TRANSACTION_STATE,
  CHANGE_WAREHOUSE_STATE,
  EDIT_TRANSACTION_FAILURE,
  EDIT_TRANSACTION_REQUEST,
  EDIT_TRANSACTION_SUCCESS,
  EDIT_WAREHOUSE_FAILURE,
  EDIT_WAREHOUSE_REQUEST,
  EDIT_WAREHOUSE_SUCCESS,
  FETCH_TRANSACTION_FAILURE,
  FETCH_TRANSACTION_REQUEST,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_WAREHOUSE_FAILURE,
  FETCH_WAREHOUSE_REQUEST,
  FETCH_WAREHOUSE_SUCCESS
} from './warehouseTypes';

const initialState = {
  warehouse: {},
  transaction: {},
  covenant: {},
  loading: false,
  error: ''
};

const warehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouse: action.payload,
        loading: false,
        error: ''
      };
    case FETCH_WAREHOUSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_WAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouse: action.payload,
        loading: false,
        error: ''
      };
    case ADD_WAREHOUSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case EDIT_WAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case EDIT_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouse: action.payload,
        loading: false,
        error: ''
      };
    case EDIT_WAREHOUSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CHANGE_WAREHOUSE_STATE:
      return update(state, {
        $merge: {
          warehouse: {
            ...state.warehouse,
            ...action.payload
          }
        }
      });

    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.payload,
        loading: false,
        error: ''
      };
    case FETCH_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case ADD_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case EDIT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case EDIT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case EDIT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CHANGE_TRANSACTION_STATE:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          ...action.payload
        }
      };
    case CLEAR_TRANSACTION_STATE:
      return initialState.transaction;

    case ADD_COVENANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case ADD_COVENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case ADD_COVENANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CHANGE_COVENANT_STATE:
      return update(state, {
        $merge: {
          covenant: {
            ...state.covenant,
            ...action.payload
          }
        }
      });

    default:
      return state;
  }
};

export default warehouseReducer;
