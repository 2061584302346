import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import GenericPage from '../../../components/components/GenericPage/GenericPage';

const getEmployeeColumns = () => [
  { header: 'الموظف', field: 'name', isChip: false },
  { header: 'الحالة', field: 'statusName', isChip: true },
  { header: 'الدور', field: 'positionName', isChip: false },
  { header: 'الوظيفة', field: 'departmentName', isChip: false },
  { header: 'تاريخ التعيين', field: 'hiringDate', isChip: false, isDate: true }
];

function Employees() {
  const navigate = useNavigate();
  const columns = useMemo(getEmployeeColumns, []);
  const apiUrl = '/Employee/list';
  const routeUrl = '/employees/details/';
  const addUrl = '/employees/add';

  return (
    <GenericPage
      tabs={[
        {
          label: 'الحاليين',
          tableHeader: 'الحاليين',
          entityName: 'الموظف',
          dataKey: 'tab1',
          columns: columns,
          apiUrl: apiUrl,
          edit: false,
          emptyStateUrl: addUrl,
          rowOnClick: (rowData) => {
            navigate(routeUrl + rowData.id);
          },
          extraParams: { status: 'Active' },
          headerButtons: [
            {
              label: 'إضافة موظف جديد',
              onClick: () => {
                navigate(addUrl);
              }
            }
          ]
        },
        {
          label: 'منتهي الخدمه',
          tableHeader: 'منتهي الخدمه',
          dataKey: 'tab2',
          entityName: 'الموظف',
          columns: columns,
          apiUrl: apiUrl,
          edit: false,
          rowOnClick: (rowData) => {
            navigate(routeUrl + rowData.id);
          },
          emptyStateUrl: addUrl,
          extraParams: { status: 'In-Active' },
          headerButtons: [
            {
              label: 'إضافة موظف جديد',
              onClick: () => {
                navigate(addUrl);
              }
            }
          ]
        },
        {
          label: 'مفصولين',
          tableHeader: 'مفصولين',
          dataKey: 'tab3',
          entityName: 'الموظف',
          columns: columns,
          apiUrl: apiUrl,
          edit: false,
          emptyStateUrl: addUrl,
          rowOnClick: (rowData) => {
            navigate(routeUrl + rowData.id);
          },
          extraParams: { status: 'Deleted' },
          headerButtons: [
            {
              label: 'إضافة موظف جديد',
              onClick: () => {
                navigate(addUrl);
              }
            }
          ]
        },
        {
          label: 'مؤقتين',
          tableHeader: 'مؤقتين',
          dataKey: 'tab4',
          entityName: 'الموظف',
          columns: columns,
          apiUrl: apiUrl,
          edit: false,
          emptyStateUrl: addUrl,
          rowOnClick: (rowData) => {
            navigate(routeUrl + rowData.id);
          },
          extraParams: { status: 'Awaiting' },
          headerButtons: [
            {
              label: 'إضافة موظف جديد',
              onClick: () => {
                navigate(addUrl);
              }
            }
          ]
        }
      ]}
      headerTitle='الموظفين'
      headerIcon='/images/employee.svg'
    />
  );
}

export default Employees;
