import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';

import { axiosInstance } from '../../global';
import '../../style/Tables.scss';
import { useToast } from '../../context/ToastContext';

function ServicesEditDetailsTable({ token, serviceID }) {
    const toast = useToast();
  const [SelectedGovernorates, setSelectedGovernorates] = useState(null);
  const [SelectedStatuses, setSelectedStatuses] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [OldServices, setOldServices] = useState([]);
  const [AllServices, setAllServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newlist, setNewlist] = useState([]);
  const [filterdlist, setFilterdlist] = useState([]);
  const [items, setItems] = useState({
    servicesCostsListId: parseInt(serviceID),
    details: [
      {
        id: '',
        serviceId: '',
        price: '',
        commission: '',
        remarks: ''
      }
    ]
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axiosInstance.get(`/Service/all`);
        const tempNewProductServices = response1.data.data.map((ProductServices) => ({
          id: null,
          serviceId: ProductServices.id,
          service: ProductServices.name,
          serviceStatus: ProductServices.status.name,
          price: 0,
          commission: 0
        }));
        // setNewProducts(tempNewProductPriceList);

        const response2 = await axiosInstance.get(`/ServicesCostsListDetail/list/${serviceID}`);
        const tempProductServices = response2.data.data.map((ProductService) => ({
          id: ProductService.masterID,
          serviceId: ProductService.serviceID,
          service: ProductService.serviceName,
          serviceStatus: ProductService.statusName,
          price: ProductService.cost,
          commission: ProductService.commetion,
          country: ProductService.countryName,
          governorate: ProductService.governorateName,
          currency: ProductService.currencyName,
          date: ProductService.date,
          status: ProductService.statusName
        }));
        setOldServices(tempProductServices);
        mergeAllServices(tempNewProductServices, tempProductServices);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [serviceID]);

  const mergeAllServices = (newServices, oldServices) => {
    const mergedServices = newServices.map((newService) => {
      const oldService = oldServices.find((oldService) => oldService.serviceId === newService.serviceId);
      if (oldService) {
        return {
          ...newService,
          id: oldService.id,
          price: oldService.price,
          commission: oldService.commission
        };
      }
      return newService;
    });
    setAllServices(mergedServices);
  };

  useEffect(() => {
    if (OldServices.length != 0) {
      setSelectedDate(OldServices[0].date);
      setSelectedCountry(OldServices[0].country);
      setSelectedGovernorates(OldServices[0].governorate);
      setSelectedStatuses(OldServices[0].status);
    }
    setNewlist(AllServices);
    const details = AllServices.map((Service) => {
      return {
        id: Service.id,
        serviceId: Service.ServiceId,
        price: Service.price,
        commission: Service.commission,
        remarks: ''
      };
    });
    // setItems({ ...items, id: '' });
    setItems({ ...items, details });
  }, [AllServices]);

  useEffect(() => {
    setNewlist(filterData());
    if (searchTerm === '') {
      setNewlist(AllServices);
    }
  }, [searchTerm]);

  const filterData = () => {
    setFilterdlist(newlist);
    return filterdlist.filter((item) => item.service.includes(searchTerm));
  };

  function handleSearchNameChange(e) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  function handleEditorsChange(e, options) {
    let tempServices = AllServices.map((Service) => {
      if (options.rowData.serviceId === Service.serviceId) {
        Service[e.target.name] = e.value;
      }
      return Service;
    });
    setAllServices(tempServices);
  }

  const priceEditor = (options) => {
    return <InputNumber name='price' value={options.value} onValueChange={(e) => handleEditorsChange(e, options)} />;
  };
  const priceBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('ar', { currency: 'EGP' }).format(rowData.price);
  };

  const commissionEditor = (options) => {
    return (
      <InputNumber name='commission' value={options.value} onValueChange={(e) => handleEditorsChange(e, options)} />
    );
  };
  const commissionBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('ar', { currency: 'EGP' }).format(rowData.commission) + ' %';
  };

  function handleSaveChanges(e) {
    axiosInstance
      .put(`/ServicesPricesList/details`, items, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `تم تعديل قائمة الخدمات بنجاح!`, life: 3000 });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية تعديل قائمة الخدمات `, life: 3000 });
      });
  }

  

  return (
    <div className='tbl ml-2 mb-4'>
      <div className='tbl__header'>
        <div className='flex flex-row gap-2'>
          
          <span className='header__title__price'>قائمة الخدمات </span>
          <InputMask
            mask='99/99/9999'
            placeholder={selectedDate}
            slotChar='mm/dd/yyyy'
            disabled={true}
            className='p-inputtext-sm header__pricedate'
          />
          <InputText value={selectedCountry} disabled={true} className='p-inputtext-sm header__pricegovernrate' />
          <InputText value={SelectedGovernorates} disabled={true} className='p-inputtext-sm header__pricegovernrate' />
          <InputText value={SelectedStatuses} disabled={true} className='p-inputtext-sm header__pricegovernrate' />
        </div>

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={searchTerm}
              className='header__tools__search'
              placeholder='البحث في المنتجات'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>

        <button className='tbl__addpriceList' onClick={handleSaveChanges}>
          <img src='/images/loading.svg' alt={'حفظ اتعديلات و تحديث القائمة'} />
          حفظ التعديلات و تحديث القائمة
        </button>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={AllServices}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          {/* <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} /> */}
          <Column header='ID' body={(rowData) => <span>#{rowData.serviceId}</span>} />
          <Column header='الخدمة' field='service' />
          <Column header='حالة الخدمة' field='serviceStatus' />
          <Column
            field='price'
            key='price'
            header='السعر'
            body={priceBodyTemplate}
            editor={(options) => priceEditor(options)}
          ></Column>

          <Column
            field='commission'
            key='commission'
            header='العمولة'
            body={commissionBodyTemplate}
            editor={(options) => commissionEditor(options)}
          ></Column>
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesEditDetailsTable);
