import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { ToggleButton } from 'primereact/togglebutton';
import { InputText } from 'primereact/inputtext';

import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';
import { useToast } from '../../../context/ToastContext';

export function AddEditDestinationsSources({
  title,
  entityName,
  visible,
  setVisible,
  onClosePrompt,
  onAddHandler,
  initialData,
  additionalFields = {}
}) {
    const toast = useToast();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [allowFirstReceiving, setAllowFirstReceiving] = useState(false);
  const [statuses, setStatuses] = useState(null);

  const [entity, setEntity] = useState({
    id: null,
    name: '',
    direction: '',
    allowFirstReceiving: false,
    statusId: null,
    remarks: ''
  });

  useEffect(() => {
    axiosInstance.get('/Status/dropdown/Warehouse').then((value) => setStatuses(value.data.data));
  }, []);

  useEffect(() => {
    if (initialData) {
      setEntity({
        id: initialData.id,
        name: initialData.name,
        direction: initialData.direction,
        allowFirstReceiving: initialData.allowFirstReceiving,
        statusId: initialData.status.id,
        remarks: initialData.remarks
      });
      setSelectedStatus(initialData.status.id);
      setAllowFirstReceiving(initialData.allowFirstReceiving);
    }
  }, [initialData]);

  function acceptClose() {
    setEntity({
      id: null,
      name: '',
      direction: '',
      allowFirstReceiving: false,
      statusId: null,
      remarks: ''
    });
    setSelectedStatus(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  function handleCurrencyChange(e) {
    setSelectedCurrency(e.value);
    setEntity({ ...entity, currencyId: e.value });
  }

  return (
    <>

      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Name</label>
              <div className='flex gap-1'>
                <InputText
                  name='name'
                  value={entity.name}
                  addOnBlur={true}
                  className='w-full'
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Direction</label>
              <div className='flex gap-1'>
                <InputText
                  name='direction'
                  value={entity.direction}
                  addOnBlur={true}
                  className='w-full'
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Allow First Receiving</label>
              <div className='flex'>
                <ToggleButton
                  checked={allowFirstReceiving}
                  name='allowFirstReceiving'
                  onChange={(e) => setAllowFirstReceiving(e.value)}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>
                Status<span className='astrk'>*</span>
              </label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='Select Status'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>remarks</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='Cancel' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(entity)}>
              اضافة {entityName}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
