import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function ProductsScreen() {
  const navigate = useNavigate();
  return (
    <>
      <GenericPage
        headerIcon={'../images/productbox.svg'}
        headerTitle={'المنتجات'}
        headerIconOnClick={() => {}}
        tabs={[
          {
            extraParams: {
              type: 'Filters'
            },
            label: '',
            columns: [
              { header: 'كود  المنتج', field: 'code', isChip: false },
              { header: 'كود SKU', field: 'skuCode', isChip: false },
              { header: 'اسم المنتج', field: 'name', isChip: false },
              { header: 'تصنيف المنتج', field: 'productCategoryName', isChip: false },
              { header: 'تصنيف المنتج', field: 'productFamilyName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'تاريخ الاضافة', field: 'creationDateTime', isChip: false }
            ],
            dataKey: 'product',
            apiUrl: 'Product/list',
            isHidden: true,
            rowOnClick: (rowData) => {
              navigate(`/product/details/${rowData.id}/0/device`);
            },
            tableHeader: 'جدول المنتجات',
            emptyStateUrl: '',
            headerButtons: [
              {
                label: 'إضافة منتج جديد',
                onClick: () => {
                  navigate('/products/addProduct');
                }
              }
            ],
            entityName: 'المنتج',
            emptyStateButtonClick: () => {
              navigate('/products/addProduct');
            },
            edit: false
          }
        ]}
      />
    </>
    // <div className='w-full'>
    //   <span className='main__title__custom'>
    //     <i className='main__title__custom--icon'>
    //       <img src='../images/productbox.svg' alt='' />
    //     </i>
    //     <span>المنتجات</span>
    //   </span>
    //
    //   <div className='mt-5 pr-4 pl-4'>
    //     <Tabs
    //       headers={['قائمة المنتجات']}
    //       contents={[
    //         <ProductsScreenTable
    //           columns={[
    //             // { header: 'كود  المنتج', field: 'code', isChip: false },
    //             // { header: 'كود SKU', field: 'skuCode', isChip: false },
    //             // { header: 'اسم المنتج', field: 'name', isChip: false },
    //             // { header: 'فئة المنتج', field: 'productCategory.productFamily.name', isChip: false },
    //             { header: 'الكمية المتاحة', field: 'skuCode', isChip: false },
    //             { header: 'الحالة', field: 'status.name', isChip: true },
    //             { header: 'تاريخ الاضافة', field: 'creationDateTime', isChip: false }
    //           ]}
    //           urlName='Product/all'
    //           headerTitle='المنتجات'
    //           entityNameDefined='تفاصيل المنتج'
    //           addBtnText='إضافة منتج جديد'
    //           path='/products/addProduct'
    //           type={'device'}
    //         />
    //       ]}
    //     />
    //   </div>
    // </div>
  );
}

export default ProductsScreen;
