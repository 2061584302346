import { ReactNode } from 'react';
import Sidebar from '../Sidebar/Sidebar';

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <div className='flex w-full'>
      {/*style={{ backgroundColor: '#f0f2f5' }}*/}
      <Sidebar />
      <div className='flex w-full'>{children}</div>
    </div>
  );
};

export default Layout;
