import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../context/ToastContext';
import GenericPage from '../../components/components/GenericPage/GenericPage';
const SuppliersColumns = [
  { header: 'الاسم', field: 'name', isChip: false },
  { header: 'التواصل', field: 'contactPerson', isChip: false },
  { header: 'رقم التليفون', field: 'phone', isChip: false },
  { header: 'البريد الالكتروني', field: 'email', isChip: false },
  { header: 'الموقع الالكتروني', field: 'website', isChip: false },
  { header: 'الحالة', field: 'statusName', isChip: true }
];
function Suppliers() {
  const toast = useToast();
  const navigate = useNavigate();
  const tab1 = [
    {
      label: 'المخازن',
      tableHeader: 'قائمة الموردين',
      entityName: 'المورد',
      dataKey: 'tab1',
      columns: SuppliersColumns,
      apiUrl: '/Supplier/list',
      isHidden: true,
      edit: false,
      Screentype: '',
      emptyStateUrl: `/warehouses/supplier/add`,
      rowOnClick: (rowData: any) => {
        navigate(`/warehouses/supplier/details/${rowData.id}`);
      },
      extraParams: {},
      emptyStateButtonClick: undefined,
      headerButtons: [
        {
          label: 'إضافة مورد جديد',
          onClick: () => {
            navigate(`/warehouses/supplier/add`);
          }
        }
      ]
    }
  ];

  return (
    <div className='w-full'>
      <GenericPage tabs={tab1} headerTitle='المخازن' headerIcon='/images/boxes1.svg' />
    </div>
  );
}

export default Suppliers;
