import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { Menu } from 'primereact/menu';

import { logout } from '../../redux/user/userActions';
import './Navbar.scss';
import { AsyncImage } from 'loadable-image';

function Navbar({ user, logout }) {
  const menuLeft = useRef(null);
  const navigate = useNavigate();

  const items = [
    {
      items: [
        {
          label: 'تسجيل خروج',
          icon: 'pi pi-sign-out',
          command: () => {
            logout();
          }
        },
        {
          label: 'الاعدادات',
          icon: 'pi pi-spin pi-cog',
          command: () => {
            navigate('/admin/systemParams');
          }
        }
      ]
    }
  ];

  return (
    <main className='nav'>
      <Link to='/'>
        <div className='nav__logo'>
          <img src='/images/logo.png' alt='Best Aqua Solution' />
        </div>
      </Link>

      <div className='nav__icons'>
        <div className='nav__icons__main'>
          <div className='nav__icons__main__img'>
            <AsyncImage
              src={`http://bestaqua.oneclickapps.org/${user.imagePath}`}
              style={{ width: '35px', height: '35px', borderRadius: '50%' }}
              loader={<div style={{ background: '#888', width: '35px', height: '35px', borderRadius: '50%' }} />}
              onError={(e) => (e.target.src = '/images/employee2.png')}
            />
            <div className='nav__icons__main__img__txt'>
              <h5>مرحبا,</h5>
              <h5>{user.userName}</h5>
            </div>
          </div>

          <div className='nav__icons__main__drop'>
            <Menu model={items} popup ref={menuLeft} id='popup_menu_left' />
            <Button
              icon='pi pi-angle-down'
              className='mr-2'
              onClick={(event) => menuLeft.current.toggle(event)}
              aria-controls='popup_menu_left'
              aria-haspopup
            />
          </div>
        </div>

        <div className='nav__icons__btns'>
          <button>
            <img src='/images/alarm.svg' alt='notification' />
          </button>
          <button onClick={() => logout()}>
            <i className='pi pi-sign-out' />
          </button>
        </div>
      </div>
    </main>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
