import React from 'react';
import Tabs from '../../../components/Tabs/Tabs';
import EmployeesWorkingGroupsTable from '../../../components/Tables/EmployeesWorkingGroupsTable';
import EmployeeDailyAssignmentTable from '../../../components/Tables/EmployeeDailyAssignmentTable';

function EmployeesManagement() {
  return (
    <div className='w-full'>
      <span className='main__title'>
        <i className='pi pi-database main__title--icon'></i>
        الموظفين
      </span>
      <div className='mt-5 pr-4 pl-4'>
        <Tabs
          headers={['مجموعات العمل', 'جدول اﻻعمال']}
          contents={[<EmployeesWorkingGroupsTable />, <EmployeeDailyAssignmentTable />]}
        />
      </div>
    </div>
  );
}

export default EmployeesManagement;
