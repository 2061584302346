import { FormProvider, useForm } from 'react-hook-form';
import PageHeader from '../../../components/components/Header/PageHeader';
import { Card } from 'primereact/card';
import { Steps } from 'primereact/steps';
import React, { Suspense, useEffect, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import styles from './AddEditWarehouse.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '../../../context/ToastContext';
import WarehouseInfoStep from './WarehouseInfoStep';
import WarehouseLocationStep from './WarehouseLocationStep';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../global';

const warehouseSchema = yup.object({
  id: yup.number().optional().nullable(),
  typeId: yup.number().required(),
  contactDetails: yup.string().required(),
  contactMethodId: yup.number().required(),
  name: yup.string().required(),
  statusId: yup.number().required(),
  locationId: yup.number().required(),
  address: yup.string().required(),
  remarks: yup.string().optional()
});

const AddEditWarehouse = () => {
  const toast = useToast();
  const location = useLocation();
  const { warehouseId } = location.state || {};
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  const methods = useForm({
    resolver: yupResolver(warehouseSchema),
    mode: 'all'
  });
  const { handleSubmit, trigger, reset, watch, setValue } = methods;

  const steps = [
    { title: 'بيانات المخزن الاساسية', content: <WarehouseInfoStep /> },
    { title: 'تفاصيل العنوان و المنطقة', content: <WarehouseLocationStep /> }
  ];

  const handleSubmitOnClick = async (data: any) => {
    try {
      await axiosInstance.post('Warehouse', data);
      toast.current.show({
        type: 'success',
        summary: 'عملية ناجحة',
        detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} العقد بنجاح`,
        life: 2000
      });
      navigate('/warehouses');
    } catch (error) {
      toast.current.show({
        type: 'error',
        summary: 'عملية خاطئة',
        detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} العقد: ${error}`,
        life: 3000
      });
    }
  };
  const handleBackClick = () => {
    setCurrent((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  useEffect(() => {
    if (warehouseId) {
      setIsEditing(true);
      const fetchWarehouse  = async () =>{
        const data = await  axiosInstance.get(`Warehouse/new/${warehouseId}`);
        ;
        reset(data.data.data)
      }

      // reset(data);
      fetchWarehouse();
    }
  }, [warehouseId]);

  const handleOnClick = async () => {
    if (current === 0) {
      // Fields specific to WarehouseInfoStep
      const fieldsToValidate: (keyof typeof warehouseSchema.fields)[] = [
        'typeId',
        'contactDetails',
        'contactMethodId',
        'name',
        'statusId'
      ];

      // Validate only the fields in the current step
      const isStepValid = await trigger(fieldsToValidate);

      if (isStepValid) {
        // Move to the next step if the step is valid
        setCurrent((prevIndex) => (prevIndex < steps.length - 1 ? prevIndex + 1 : prevIndex));
      } else {
        // Show an error message using your toast system
        toast.current.show({
          severity: 'error',
          summary: 'خطا',
          detail: 'يرجي ملئ البيانات.',
          life: 3000
        });
      }
    } else {
      // Move to the next step if it's not the first step
      setCurrent((prevIndex) => (prevIndex < steps.length - 1 ? prevIndex + 1 : prevIndex));
    }
  };

  const items = steps.map((item) => ({ key: item.title, label: item.title }));

  return (
    <div className={styles.screen}>
      <FormProvider {...methods}>
        <form autoComplete='off' onSubmit={handleSubmit(handleSubmitOnClick)}>
          <PageHeader
            icon={'/images/back-arrow.svg'}
            buttons={[]}
            headerTitle={'إضافة مخزن جديد'}
            iconOnClick={() => navigate(-1)}
          />
          <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
            <Steps model={items} activeIndex={current} />
          </Card>
          {<Suspense fallback={<ProgressSpinner />}>{steps[current].content}</Suspense>}
          <div className={`${styles.buttonContainer} mb-5`}>
            {current > 0 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleBackClick}>
                العودة للخطوة السابقة
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button className='btn__nextbtn' type={'button'} onClick={handleOnClick}>
                الخطوة التالية
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button className='btn__nextbtn' type='submit'>
                {isEditing ? 'حفظ التعديلات' : 'إضافة المخزن'}
              </Button>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddEditWarehouse;
