import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'antd';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const ProductChooserStep = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();
  const [searchText, setSearchText] = useState('');
  const [externalSearchTerm, setExternalSearchTerm] = useState('');

  const handleSearchClick = () => {
    setExternalSearchTerm(searchText);
  };

  const customer = watch('customer');

  const customerDeviceColumns = [
    { field: 'code', header: 'كود الجهاز' },
    { field: 'deviceName', header: 'الجهاز' },
    { field: 'deviceTypeName', header: 'نوع الجهاز' },
    { field: 'installationDate', header: 'تاريخ التركيب' },
    { field: 'statusName', header: 'الحالة' }
  ];

  const productColumns = [
    { field: 'skuCode', header: 'SKU / رقم المنتج' },
    { field: 'name', header: 'الجهاز' },
    { field: 'nbofStages', header: 'عدد المراحل' },
    { field: 'brandName', header: 'الماركة' }
  ];

  // Memoize the selectedTab object
  const selectedTab = useMemo(
    () => ({
      rowOnClick: (rowData: any) => {
        setValue('product', rowData);
        if (customer) {
          setValue('customerDeviceId', rowData.id);
        }
      },
      emptyStateButtonClick: () => {
        
      },
      label: 'Product Table',
      tableHeader: 'Product Inventory',
      entityName: 'Product',
      dataKey: 'sku',
      columns: customer ? customerDeviceColumns : productColumns,
      apiUrl: customer ? `CustomersDevice/list/ByCustomer/${customer.id}` : 'Product/list',
      emptyStateUrl: '/no-products',
      edit: false,
      extraParams: {
        type: 'Filters'
      },
      rowClickable: true
    }),
    []
  );

  return (
    <main>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        <div style={{ padding: '1rem' }}>
          <div style={{ marginBottom: '1rem' }}>
            <label style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'IBM Plex Sans Arabic' }}>
              بحث عن الجهاز
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
            </label>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <InputText style={{ width: '100%' }} onChange={(e) => setSearchText(e.target.value)} />
            <Button
              style={{
                backgroundColor: '#A2FB5D',
                width: '122px',
                border: '1px solid #333',
                borderRadius: '6px'
              }}
              onClick={handleSearchClick}
            >
              بحث
            </Button>
          </div>
          <label
            style={{
              fontSize: '20px',
              fontFamily: 'IBM Plex Sans Arabic',
              fontWeight: 'bold',
              marginTop: '1.5rem',
              marginBottom: '1.5rem',
              display: 'block'
            }}
          >
            اختر جهاز من قائمة المنتجات
          </label>

          <DataTableCard selectedTab={selectedTab} withoutHeader={true} externalSearchTerm={externalSearchTerm} />
        </div>
      </Card>
    </main>
  );
};

export default ProductChooserStep;
