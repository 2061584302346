import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

import { axiosInstance } from '../../global';
import '../../style/Tables.scss';
import CustomDropdown from '../components/Dropdown/CustomDropDown';
import { useToast } from '../../context/ToastContext';

function ServicesListDetailsTable() {
    const toast = useToast();
  const [governorates, setGovernorates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [currency, setCurrency] = useState({});
  const [loading, setLoading] = useState(true);
  const [countryCheck, setcountryCheck] = useState(true);
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newlist, setNewlist] = useState([]);
  const [filterdlist, setFilterdlist] = useState([]);
  const [items, setItems] = useState({
    governorateId: '',
    currencyId: '',
    date: null,
    remarks: '',
    statusId: '',
    details: [
      {
        serviceId: '',
        price: '',
        commission: ''
      }
    ]
  });

  useEffect(() => {
    axiosInstance
      .get(`/Service/all`)
      .then((response) => {
        const tempProductServices = response.data.data.map((ProductServices) => {
          return {
            serviceId: ProductServices.id,
            service: ProductServices.name,
            serviceStatus: ProductServices.status.name,
            price: 0,
            commission: 0
          };
        });
        setServices(tempProductServices);
        setLoading(false);
      })
      .catch((error) => {});

    axiosInstance
      .get(`/Country/all`)
      .then((response) => {
        const tempCountry = response.data.data.map((Country) => {
          return {
            id: Country.id,
            name: Country.name
          };
        });
        setCountries(tempCountry);
      })
      .catch((error) => {}); // country

    axiosInstance
      .get(`/Status/dropdown/Product`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatuses(tempStatus);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    setNewlist(services);
    const details = services.map((service) => {
      return {
        serviceId: service.serviceId,
        price: service.price,
        commission: service.commission
      };
    });
    setItems({ ...items, details });
  }, [services]);

  useEffect(() => {
    setNewlist(filterData());
    if (searchTerm === '') {
      setNewlist(services);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedCountry) {
      axiosInstance
        .get(`/Country/${selectedCountry}`)
        .then((response) => {
          setCurrency(response.data.data.currency);
        })
        .catch((error) => {});
      setItems({ ...items, currencyId: currency.id });
    }
  }, [selectedCountry]);

  const filterData = () => {
    setFilterdlist(newlist);
    return filterdlist.filter((item) => item.service.includes(searchTerm));
  };

  function handleSearchNameChange(e) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  function getGovernrates(id) {
    axiosInstance
      .get(`/Governorate/allByCountryId/${id}`)
      .then((response) => {
        const tempGovren = response.data.data.map((Govern) => {
          return {
            id: Govern.id,
            name: Govern.name
          };
        });
        setGovernorates(tempGovren);
        setcountryCheck(false);
      })
      .catch((error) => {}); // governrate
  }

  function handleEditorsChange(e, options) {
    let tempServices = services.map((service) => {
      if (options.rowData.serviceId === service.serviceId) {
        service[e.target.name] = e.value;
      }
      return service;
    });
    setServices(tempServices);
  }

  const priceEditor = (options) => {
    return <InputNumber name='price' value={options.value} onValueChange={(e) => handleEditorsChange(e, options)} />;
  };
  const priceBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('ar', { currency: 'EGP' }).format(rowData.price);
  };

  const commissionEditor = (options) => {
    return (
      <InputNumber name='commission' value={options.value} onValueChange={(e) => handleEditorsChange(e, options)} />
    );
  };
  const commissionBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('ar', { currency: 'EGP' }).format(rowData.commission) + ' %';
  };

  function handleAddInputChange(e) {
    if (e.target.name === 'selectedCountry') {
      setSelectedCountry(e.target.value);
      getGovernrates(e.target.value);
      setcountryCheck(false);
    } else if (e.target.name === 'date') {
      setItems({
        ...items,
        [e.target.name]: e.target.value || e.value
      });
    } else {
      setItems({ ...items, [e.target.name]: e.target.value || e.value });
    }
  }

  function handleSaveChanges(e) {
    axiosInstance
      .post(`/ServicesCostsList`, items, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `تم إضافة قائمة الخدمات بنجاح!`, life: 3000 });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية إضافة قائمة خدمات جديدة`, life: 3000 });
      });
  }

  return (
    <div className='tbl ml-2 mb-4'>
      <div className='tbl__header'>
        <div className='flex flex-row gap-2'>
          
          <span className='header__title__price'>قائمة خدمات </span>
          <Calendar
            name='date'
            value={items.date}
            onChange={handleAddInputChange}
            dateFormat='mm/dd/yy'
            placeholder='اختر التاريخ'
            className='header__pricedate'
          />
          <CustomDropdown
            name='selectedCountry'
            value={selectedCountry}
            onChange={handleAddInputChange}
            options={countries}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر البلد'
            className='header__pricecountry'
          />
          <CustomDropdown
            name='governorateId'
            value={items.governorateId}
            onChange={handleAddInputChange}
            options={governorates}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر المحافظة'
            className='header__pricegovernrate'
            disabled={countryCheck}
          />

          <CustomDropdown
            name='statusId'
            value={items.statusId}
            onChange={handleAddInputChange}
            options={statuses}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر الحالة'
            className='header__pricegovernrate'
          />
        </div>

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={searchTerm}
              className='header__tools__search'
              placeholder='البحث في الخدمات'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>

        <button className='tbl__addpriceList' onClick={handleSaveChanges}>
          <img src='/images/loading.svg' alt={'حفظ اتعديلات و تحديث القائمة'} />
          حفظ قائمة الخدمات الجديدة
        </button>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={services}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.serviceId}</span>} />
          <Column header='الخدمة' field='service' />
          <Column header='حالة الخدمة' field='serviceStatus' />
          <Column
            field='price'
            key='price'
            header='السعر'
            body={priceBodyTemplate}
            editor={(options) => priceEditor(options)}
          ></Column>

          <Column
            field='commission'
            key='commission'
            header='العمولة'
            body={commissionBodyTemplate}
            editor={(options) => commissionEditor(options)}
          ></Column>
        </DataTable>
      )}
    </div>
  );
}


export default ServicesListDetailsTable;
