import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import CustomDropdown from '../components/Dropdown/CustomDropDown';
import { useToast } from '../../context/ToastContext';

function AddEntity({
  title,
  entityName,
  visible,
  setVisible,
  statuses,
  onClosePrompt,
  onAddHandler,
  additionalFields = {}
}) {
  const toast = useToast();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [entity, setEntity] = useState({
    names: [],
    statusId: null,
    remarks: ''
  });
  const [additionalFieldsSelected, setAdditionalFieldsSelected] = useState(
    !additionalFields || Object.keys(additionalFields).length === 0
  );

  function acceptClose() {
    setEntity({
      names: [],
      statusId: null,
      remarks: ''
    });
    setSelectedStatus(null);
    setVisible(false);
    setAdditionalFieldsSelected(false);
  }

  function handleAdditionalFieldChange(fieldName, value) {
    const prevEntity = {
      ...additionalFields
    };
    setEntity((prevEntity) => ({
      ...prevEntity,
      [fieldName]: value
    }));

    // Check if all additional fields are selected using the updated state (prevEntity)
    const allFieldsSelected = Object.keys(additionalFields).every(
      (fieldName) =>
        prevEntity[fieldName] !== undefined &&
        prevEntity[fieldName] !== null &&
        (Array.isArray(prevEntity[fieldName]) ? prevEntity[fieldName].length > 0 : true)
    );
    setAdditionalFieldsSelected(allFieldsSelected);
  }

  const additionalFieldsControls = Object.entries(additionalFields).map(([fieldName, fieldOptions]) => (
    <div key={fieldName} className='w-full flex flex-column gap-2'>
      <label className='modals__label'>{fieldOptions.label}</label>
      <CustomDropdown
        value={entity[fieldName]}
        options={fieldOptions.values.map((option) => ({ label: option.name, value: option.id }))}
        onChange={(e) => handleAdditionalFieldChange(fieldName, e.value)}
        className='w-full'
      />
    </div>
  ));

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  function handleAddItemChipBtn(e) {
    const kbEvent = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'Enter'
    });
    document.body.dispatchEvent(kbEvent);
  }

  return (
    <>
      
      <ConfirmDialog tagKey={1} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          {additionalFieldsControls}
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اضف اسم/اسماء {entityName} الجديدة</label>
              <div className='flex gap-1'>
                <Chips
                  name='names'
                  value={entity.names}
                  addOnBlur={true}
                  className='w-full'
                  onChange={handleInputChange}
                  disabled={!additionalFieldsSelected}
                />
                <Button
                  label='اضف'
                  severity='secondary'
                  outlined
                  raised
                  onClick={handleAddItemChipBtn}
                  disabled={!additionalFieldsSelected}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة {entityName}</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                  disabled={!additionalFieldsSelected}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea
                name='remarks'
                value={entity.remarks}
                className='w-full'
                onChange={handleInputChange}
                disabled={!additionalFieldsSelected}
              />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined disabled={!additionalFieldsSelected} />
            <button className='modals__btn' onClick={() => onAddHandler(entity)} disabled={!additionalFieldsSelected}>
              اضافة {entityName}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AddEntity;
