import { Toast } from 'primereact/toast';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AddEditProductInfo from './AddEditProductInfo';
import AddEditProductStages from './AddEditProductStages';
import AddEditProductImages from './AddEditProductImages';
import { connect } from 'react-redux';
import { addProduct, clearProductState, fetchProduct } from '../../../redux/product/productActions';
import useUpload from '../../../hooks/useUploadFile';
import { useToast } from '../../../context/ToastContext';

interface AddProductProps {
  activeIndexnum?: number;
  addProduct: (product: any) => Promise<any>;
  fetchProduct: (id: string) => void;
  clearProductState: () => void;
  product: any;
  loading: boolean;
  error: string | null;
  productType: string;
}

const productSchema = yup.object().shape({
  code: yup.string().optional(),
  skuCode: yup.string().required('مطلوب كود المنتج'),
  name: yup.string().required('مطلوب اسم المنتج'),
  details: yup.string().required('مطلوب وصف المنتج'),
  productCategory: yup.string().required('مطلوب فئة المنتج'),
  manufacturer: yup.string().required('مطلوب مصنع المنتج'),
  brand: yup.string().required('مطلوب الماركة'),
  countryOfOrigin: yup.string().required('مطلوب بلد المنشأ'),
  nbOfStages: yup
    .number()
    .typeError('يجب أن يكون عدد المراحل رقمًا')
    .min(1, 'مطلوب عدد المراحل')
    .required('مطلوب عدد المراحل'),
  installmentTypesIds: yup.array().min(1, 'مطلوب طريقة تقسيط واحدة على الأقل').required('مطلوب طرق التقسيط'),
  status: yup.string().required('مطلوب حالة المنتج'),
  remarks: yup.string().optional()
  // Add any other fields that are needed for both steps
});

const spareSchema = yup.object().shape({
  code: yup.string().optional(),
  skuCode: yup.string().required('مطلوب كود المنتج'),
  name: yup.string().required('مطلوب اسم المنتج'),
  details: yup.string().required('مطلوب وصف المنتج'),
  productCategory: yup.string().required('مطلوب فئة المنتج'),
  manufacturer: yup.string().required('مطلوب مصنع المنتج'),
  brand: yup.string().required('مطلوب الماركة'),
  countryOfOrigin: yup.string().required('مطلوب بلد المنشأ'),
  status: yup.string().required('مطلوب حالة المنتج'),
  remarks: yup.string().optional()
  // Add any other fields that are needed for both steps
});

const AddEditProduct: React.FC<AddProductProps> = ({
  activeIndexnum,
  addProduct,
  fetchProduct,
  clearProductState,
  product,
  loading,
  error,
  productType
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const {
    uploadFiles,
    isUploading,
    uploadProgress,
    error: uploadError
  } = useUpload({
    withDescription: true,
    withFlags: true
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [submitDone, setSubmitDone] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const schema = productType === 'stages' || productType === 'spare' ? spareSchema : productSchema;

  // Use `useForm` for form state management
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: product
  });

  const { handleSubmit, trigger, reset } = methods;
  const productImages = methods.watch('productsImages');

  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      fetchProduct(params.id);
    }
  }, [params.id, fetchProduct]);

  useEffect(() => {
    if (product && isEditing) {
      reset(product); // Populate the form with product data when editing
    }
  }, [product, isEditing, reset]);

  useEffect(() => {
    setActiveIndex(activeIndexnum ?? 0);
  }, [activeIndexnum]);

  const items =
    productType === 'stages' || productType === 'spare'
      ? [{ label: 'تفاصيل المنتج الاساسية' }]
      : [{ label: 'تفاصيل المنتج الاساسية' }, { label: 'تفاصيل المراحل' }, { label: 'تفاصيل صور المنتج' }];

  useEffect(() => {
    if (submitDone && !loading) {
      if (error) {
        toast.current?.show({
          severity: 'error',
          summary: 'عملية خاطئة',
          detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} المنتج: ${error}`,
          life: 2000
        });
      } else {
        toast.current?.show({
          severity: 'success',
          summary: 'عملية ناجحة',
          detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} المنتج بنجاح`,
          life: 2000
        });

        setTimeout(()=> {
          navigate(
            productType === 'spare' ? '/productSpares' : productType === 'stages' ? '/products/stages' : '/products'
          );
        }, 300);

      }
      setSubmitDone(false);
    }
  }, [submitDone, loading, error, navigate, isEditing, productType]);

  const lastPageNumber = productType === 'stages' || productType === 'spare' ? 0 : 2;

  const handleOnClick = async () => {
    const isValid = await trigger(); // Trigger validation before moving to the next step
    if (isValid && activeIndex < lastPageNumber) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleBackClick = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleSubmitOnClick = async (data: any) => {
    if (activeIndex === lastPageNumber) {
      try {
        const addedProduct = await addProduct(data);
        if (productImages?.length > 0) {
          await uploadFiles(productImages, 'product', addedProduct.id);
        }
        reset();
        clearProductState();
        setSubmitDone(true);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitOnClick)}>
          <div className='flex flex-column gap-5 add__product__main'>
            <div className='details__header'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='details__header1__right--text'>{isEditing ? 'تعديل منتج' : 'إضافة منتج جديد'}</span>
            </div>
            <div className='flex steps card'>
              <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly />
            </div>
            <div>
              {loading ? (
                <ProgressSpinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
              ) : (
                <Suspense fallback={<ProgressSpinner />}>
                  {activeIndex === 0 && <AddEditProductInfo productType={productType} />}
                  {productType !== 'stages' && productType !== 'spare' && (
                    <>
                      {activeIndex === 1 && <AddEditProductStages />}
                      {activeIndex === 2 && <AddEditProductImages />}
                    </>
                  )}
                </Suspense>
              )}
              <div className='btn mb-5'>
                {activeIndex !== 0 && (
                  <Button className='btn__nextbtn' onClick={handleBackClick}>
                    العودة للخطوة السابقة
                  </Button>
                )}
                {activeIndex < lastPageNumber && (
                  <Button className='btn__nextbtn' onClick={handleOnClick}>
                    الخطوة التالية
                  </Button>
                )}
                {activeIndex === lastPageNumber && (
                  <Button className='btn__nextbtn' type='submit'>
                    {isEditing ? 'حفظ التعديلات' : 'إضافة المنتج'}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  product: state.product.product,
  loading: state.product.loading,
  error: state.product.error
});

const mapDispatchToProps = {
  fetchProduct,
  addProduct,
  clearProductState
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProduct);
