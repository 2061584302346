import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

import { axiosInstance } from '../../global';
import AddEntity from '../Modals/AddEntity';
import EditEntity from '../Modals/EditEntity';
import '../../style/Tables.scss';
import AddCustomerLocation from '../Modals/AddCustomerLocation';
import AddCustomerComplaint from '../Modals/AddCustomerComplaint';
import AddCustomerDevice from '../Modals/AddCustomerDevice';
import { useToast } from '../../context/ToastContext';

function ListEntityTable({
  token,
  columns,
  urlName,
  headerTitle,
  addBtnText,
  entityName,
  entityNameDefined,
  AddEntityComponent,
  isCustomerGeo,
  isCustomerDevice,
  isCustomerComplaint,
  type
}) {
    const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState([]);
  const [addItemVisible, setAddItemVisible] = useState(false);
  const [editItemVisible, setEditItemVisible] = useState(false);

  const [addCustomerGeoVisible, setAddCustomerGeoVisible] = useState(false);
  const [addCustomerDeviceVisible, setAddCustomerDeviceVisible] = useState(false);
  const [addCustomerComplaintVisible, setAddCustomerComplaintVisible] = useState(false);

  const [editCustomerGeoVisible, setEditCustomerGeoVisible] = useState(false);
  const [editCustomerDeviceVisible, setEditCustomerDeviceVisible] = useState(false);
  const [editCustomerComplaintVisible, setEditCustomerComplaintVisible] = useState(false);

  const [selectedEditItem, setSelectedEditItem] = useState(null);

  function fetchItems() {
    axiosInstance
      .get(`/${urlName}/all`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();

    axiosInstance
      .get(`/Status/dropdown/General`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatus(tempStatus);
      })
      .catch((error) => {});
  }, []);

  function handleSearchNameChange(e) {}

  function handleAddItem(item) {
    axiosInstance
      .post(`/${urlName}`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `تم إضافة ${entityNameDefined} بنجاح!`, life: 3000 });
        setSelectedEditItem(null);
        setAddItemVisible(false);
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية إضافة ${entityName} جديدة`, life: 3000 });
      });
  }

  function handleEditItemSelection(item) {
    setSelectedEditItem(item);
    setEditItemVisible(true);
  }

  function handleEditItem(item) {
    axiosInstance
      .put(`/${urlName}`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `'تم تعديل ${entityNameDefined} بنجاح!'`, life: 3000 });
        setSelectedEditItem(null);
        fetchItems();
        setEditItemVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية تعديل ${entityNameDefined}`, life: 3000 });
      });
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>جدول {headerTitle}</span>

        {isCustomerGeo == true && (
          <button className='tbl__addbtn' onClick={() => setAddCustomerGeoVisible(true)}>
            <img src='/images/plus.svg' alt={addBtnText} />
            {addBtnText}
          </button>
        )}
        {isCustomerDevice == true && (
          <button className='tbl__addbtn' onClick={() => setAddCustomerDeviceVisible(true)}>
            <img src='/images/plus.svg' alt={addBtnText} />
            {addBtnText}
          </button>
        )}
        {isCustomerComplaint == true && (
          <button className='tbl__addbtn' onClick={() => setAddCustomerComplaintVisible(true)}>
            <img src='/images/plus.svg' alt={addBtnText} />
            {addBtnText}
          </button>
        )}
        {isCustomerGeo == false && isCustomerDevice == false && isCustomerComplaint == false && (
          <button className='tbl__addbtn' onClick={() => setAddItemVisible(true)}>
            <img src='/images/plus.svg' alt={addBtnText} />
            {addBtnText}
          </button>
        )}

        {type === 'generalList' && (
          <button className='tbl__addbtn' onClick={() => setAddItemVisible(true)}>
            <img src='/images/plus.svg' alt={addBtnText} />
            {addBtnText}
          </button>
        )}

        

        {AddEntityComponent ? (
          <AddEntityComponent
            visible={addItemVisible || editItemVisible}
            setVisible={selectedEditItem ? setEditItemVisible : setAddItemVisible}
            title={selectedEditItem ? `تعديل ${entityName}` : addBtnText}
            entityName={entityNameDefined}
            onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية ${
              editItemVisible ? `تعديل ${entityName}` : `إضافة ${entityName}`
            } جديد؟`}
            onAddHandler={handleAddItem}
            onUpdateHandler={handleEditItem}
            initialData={selectedEditItem}
            statuses={status}
            onCloseHandler={() => setSelectedEditItem(null)}
          />
        ) : (
          <>
            <AddEntity
              visible={addItemVisible}
              setVisible={setAddItemVisible}
              title={addBtnText}
              entityName={entityNameDefined}
              statuses={status}
              onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية إضافة ${entityName} جديد؟`}
              onAddHandler={handleAddItem}
            />

            <AddCustomerLocation
              visible={addCustomerGeoVisible}
              setVisible={setAddCustomerGeoVisible}
              title={addBtnText}
              entityName={entityNameDefined}
              statuses={status}
              onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية إضافة ${entityName} جديد؟`}
              onAddHandler={handleAddItem}
            />

            <AddCustomerDevice
              visible={addCustomerDeviceVisible}
              setVisible={setAddCustomerDeviceVisible}
              title={addBtnText}
              entityName={entityNameDefined}
              statuses={status}
              onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية إضافة ${entityName} جديد؟`}
              onAddHandler={handleAddItem}
            />

            <AddCustomerComplaint
              visible={addCustomerComplaintVisible}
              setVisible={setAddCustomerComplaintVisible}
              title={addBtnText}
              entityName={entityNameDefined}
              statuses={status}
              onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية إضافة ${entityName} جديد؟`}
              onAddHandler={handleAddItem}
            />

            <EditEntity
              visible={editItemVisible}
              setVisible={setEditItemVisible}
              title={`تعديل ${entityName}`}
              entityName={entityNameDefined}
              entityBase={selectedEditItem}
              statuses={status}
              onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية تعديل ${entityName}؟`}
              onEditHandler={handleEditItem}
            />
          </>
        )}

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          {isCustomerComplaint ? (
            [
              <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />,
              <Column header='العميل' body={(rowData) => <span>{rowData.customer.fullName}</span>} />,
              <Column header='جهاز العميل' body={(rowData) => <span>{rowData.customerDevice.device?.name}</span>} />
            ]
          ) : (
            <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          )}

          {columns.map((column, index) => (
            <Column
              key={index}
              header={column.header}
              body={(rowData) => {
                if (column.isBoolean) {
                  return <span>{rowData[column.field] ? 'Yes' : 'No'}</span>;
                } else if (column.isChip) {
                  return (
                    <Chip
                      label={
                        column.field.split('.').length > 1
                          ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                          : rowData[column.field.split('.')[0]]
                      }
                    />
                  );
                } else {
                  return (
                    <span>
                      {column.field.split('.').length > 1
                        ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                        : rowData[column.field.split('.')[0]]}
                    </span>
                  );
                }
              }}
            />
          ))}
          <Column
            header='خيارات'
            body={(rowData) => (
              <span>
                <Button
                  label={`تعديل ${entityNameDefined}`}
                  icon='pi pi-pencil'
                  severity='secondary'
                  text
                  onClick={() => handleEditItemSelection(rowData)}
                />
              </span>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEntityTable);
