import React from 'react';
import { FormProps } from '../../../../types/FormProps';
import { Message } from 'primereact/message';
import useProductHierarchy from '../../../../hooks/useProductHierarchy';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';

const ProductFamilyForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Product');

  const { productTypeApiState } = useProductHierarchy();

  return (
    <>
      <Message
        severity='info'
        text={'يجب اختيار نوع المنتج اولاً حتى يكون بأمكانك اضافة تصنيف المنتجات داخلها'}
        className='text-black-alpha-90 font-semibold'
      />

      <CustomFormField
        name={'productTypeId'}
        label={'اختر نوع المنتج'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={productTypeApiState.state?.data ?? []}
      />

      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? `اسم تصنيف المنتج` : `اضف اسم/اسماء تصنيف المنتج الجديدة`}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        placeholder={isEditMode ? `اسم تصنيف المنتج` : `اضف اسم/اسماء تصنيف المنتج الجديدة`}
      />

      <CustomFormField
        name={'statusId'}
        label={`اختر حالة تصنيف المنتج`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default ProductFamilyForm;
