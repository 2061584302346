// ToastProvider.js
import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { notification } from 'antd';
import { ArgsProps, IconType, NotificationInstance } from 'antd/es/notification/interface';

export interface CustomArgsProps extends Omit<ArgsProps, 'message' | 'description'> {
  severity?: IconType;
  summary?: string;
  detail?: string;
  life?: number;
}

const ToastContext = createContext<{ current: { show: (args: CustomArgsProps) => void } } | null>(null);

export const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const toastInstance = {
    current: {
      show: ({ severity, summary, detail, life, ...rest }: CustomArgsProps) => {
        api.open({
          placement: 'topRight',
          message: summary,
          description: detail,
          icon: severity ? <span className={`anticon anticon-${severity}`} /> : undefined,
          duration: 3,
          ...rest,
        });
      },
    },
  };

  return (
    <ToastContext.Provider value={toastInstance}>
      {contextHolder}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};
