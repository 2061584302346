import React, { useState } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from '../../redux/user/userActions';
import { useLocation } from 'react-router-dom';

function ResetPassword({ resetPassword, loading, error }) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    resetPassword(token, newPassword);
    setMessage('Password reset successfully. You can now log in.');
  };

  return (
    <div className='reset-password'>
      <h2>Reset Password</h2>
      {message && <p>{message}</p>}
      {error && <p className='error'>{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type='password'
          placeholder='New Password'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <input
          type='password'
          placeholder='Confirm New Password'
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type='submit' disabled={loading}>
          Submit
        </button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  error: state.user.error
});

const mapDispatchToProps = {
  resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
