import update from 'immutability-helper';
import {
  FETCH_STAGEVISIT_REQUEST,
  FETCH_STAGEVISIT_SUCCESS,
  FETCH_STAGEVISIT_FAILURE,
  ADD_STAGEVISIT_REQUEST,
  ADD_STAGEVISIT_SUCCESS,
  ADD_STAGEVISIT_FAILURE,
  EDIT_STAGEVISIT_REQUEST,
  EDIT_STAGEVISIT_SUCCESS,
  EDIT_STAGEVISIT_FAILURE,
  CHANGE_STAGEVISIT_STATE,
  CHANGE_STAGEVISIT_TEMP_STATE,
  CLEAR_STAGEVISIT_STATE
} from './visitTypes';

const initialState = {
  visitStages: {
    visitId: '',
    contractDeviceStageIds: [],
    statusId: '',
    remarks: ''
  },
  visitStagesData: {
    visitId: '',
    contractDeviceStageIds: [],
    statusId: '',
    remarks: ''
  },
  visitStagesTemp: {
    data: []
  },
  loading: false,
  error: ''
};

const visitReducer2 = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAGEVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_STAGEVISIT_SUCCESS:
      return {
        ...state,
        visitStagesData: action.payload,
        loading: false,
        error: ''
      };
    case FETCH_STAGEVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_STAGEVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_STAGEVISIT_SUCCESS:
      return {
        ...state,
        visitStages: action.payload,
        loading: false,
        error: ''
      };
    case ADD_STAGEVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case EDIT_STAGEVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case EDIT_STAGEVISIT_SUCCESS:
      return {
        ...state,
        visitStages: action.payload,
        loading: false,
        error: ''
      };
    case EDIT_STAGEVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CHANGE_STAGEVISIT_STATE:
      return update(state, {
        $merge: {
          visitStages: {
            ...state.visitStages,
            ...action.payload
          }
        }
      });

    case CHANGE_STAGEVISIT_TEMP_STATE:
      return update(state, {
        $merge: {
          visitStagesTemp: {
            ...state.visitStagesTemp,
            ...action.payload
          }
        }
      });
    case CLEAR_STAGEVISIT_STATE:
      return {
        ...state,
        visitStages: initialState.visitStages,
        visitStagesTemp: initialState.visitStagesTemp
      };
    default:
      return state;
  }
};

export default visitReducer2;
