import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { DataTableStateEvent } from 'primereact/datatable';
import { axiosInstance } from '../../../global';
import { useApiState } from '../../../hooks/useApiState';
import GenericDataTable, { ColumnProps } from '../GenericDataTable/GenericDataTable';
import { TableHeader } from '../GenericPage/TableHeader';
import { useToast } from '../../../context/ToastContext';

interface TabProps {
  rowOnClick: (rowData: any) => void;
  emptyStateButtonClick?: (() => void) | undefined;
  label: string;
  tableHeader: string;
  entityName: string;
  dataKey: string;
  columns: ColumnProps[];
  apiUrl: string;
  emptyStateUrl: string;
  edit: boolean;
  isHidden?: boolean;
  extraParams?: Record<string, string>;
  rowClickable?: boolean;
  isColumnHidden?: boolean | false;
}

interface DataTableCardProps {
  selectedTab: TabProps;
  button?: {
    label: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }[];
  withoutHeader?: boolean;
  externalSearchTerm?: string;
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const DataTableCard = forwardRef<{ fetchTabData: () => void }, DataTableCardProps>(
  ({ selectedTab, button, withoutHeader = false, externalSearchTerm }, ref) => {
    const toast = useToast();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState<string | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(undefined);

    const debouncedSearchTerm = useDebounce(
      withoutHeader && externalSearchTerm !== undefined ? externalSearchTerm : searchTerm,
      500
    );

    const fetchData = useCallback(
      async (params = { page, size: pageSize, search: debouncedSearchTerm, sortField, sortOrder }) => {
        const sortParam = sortField && sortOrder ? `${sortField},${sortOrder}` : undefined;

        const response = await axiosInstance.get(selectedTab.apiUrl, {
          params: { ...params, ...selectedTab.extraParams, sort: sortParam }
        });
        return response.data;
      },
      [page, pageSize, debouncedSearchTerm, sortField, sortOrder, selectedTab]
    );

    const handleDownload = () => {
      axiosInstance({
        url: `${selectedTab.apiUrl}/export`, // Assuming export endpoint is under the same apiUrl
        method: 'GET',
        responseType: 'blob'
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers['content-type']
            })
          );
          const link = document.createElement('a');
          link.href = url;

          // Extract filename from Content-Disposition header
          const contentDisposition = response.headers['content-disposition'];
          let fileName = `${selectedTab.apiUrl.split('/')[0]}.xlsx`;
          if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = decodeURIComponent(matches[1].replace(/['"]/g, ''));
            }
          }

          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Error downloading the file', error);
          if (toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to download file',
              life: 3000
            });
          }
        });
    };

    const {
      lazyApiCall: fetchTabData,
      state: { isLoading, error, data }
    } = useApiState({
      isLazy: true,
      apiCall: fetchData
    });

    useEffect(() => {
      fetchTabData();
    }, [selectedTab, page, pageSize, sortOrder, sortField, debouncedSearchTerm]);

    useEffect(() => {
      setPage(0);
      setPageSize(10);
      setSearchTerm('');
      setSortField(undefined);
      setSortOrder(undefined);
    }, [selectedTab, externalSearchTerm]);

    useImperativeHandle(ref, () => ({
      fetchTabData
    }));

    useEffect(() => {
      if (toast.current && error) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch data', life: 3000 });
      }
    }, [error]);

    const onPageChange = (e: DataTableStateEvent) => {
      if (e.page !== undefined) {
        setPage(e.page);
      }
      if (e.rows !== undefined) {
        setPageSize(e.rows);
      }
    };

    const onSort = (e: DataTableStateEvent) => {
      setSortField(e.sortField);
      const order = e.sortOrder === 1 ? 'asc' : 'desc';
      setSortOrder(order);
      setPage(0);
    };

    const handleSearch = (term: string) => {
      setSearchTerm(term);
      setPage(0);
    };

    return (
      <>
        {withoutHeader ? (
          <GenericDataTable
            data={data?.data || []}
            columns={selectedTab.columns}
            page={page}
            pageSize={pageSize}
            totalRecords={data?.totalCount || 0}
            sortField={sortField}
            sortOrder={sortOrder}
            isLoading={isLoading}
            onPageChange={onPageChange}
            onSort={onSort}
            onRowClick={(rowData) => selectedTab.rowOnClick(rowData)}
            edit={selectedTab.edit}
            entityName={selectedTab.entityName}
            emptyStateButtonClick={selectedTab.emptyStateButtonClick}
            emptyStateUrl={selectedTab.emptyStateUrl}
            rowClickable={selectedTab.rowClickable}
            isColumnHidden={selectedTab.isColumnHidden}
          />
        ) : (
          <Card style={{ width: 'auto', margin: '1.5rem', borderRadius: '1.5rem' }} className={'tableCard'}>
            <TableHeader
              title={selectedTab.tableHeader}
              onSearch={handleSearch}
              searchTerm={searchTerm}
              onDownload={handleDownload}
              button={button}
            />
            <GenericDataTable
              data={data?.data || []}
              columns={selectedTab.columns}
              page={page}
              pageSize={pageSize}
              totalRecords={data?.totalCount || 0}
              sortField={sortField}
              sortOrder={sortOrder}
              isLoading={isLoading}
              onPageChange={onPageChange}
              onSort={onSort}
              onRowClick={(rowData) => selectedTab.rowOnClick(rowData)}
              edit={selectedTab.edit}
              entityName={selectedTab.entityName}
              emptyStateButtonClick={selectedTab.emptyStateButtonClick}
              emptyStateUrl={selectedTab.emptyStateUrl}
              rowClickable={selectedTab.rowClickable}
              isColumnHidden={selectedTab.isColumnHidden}
            />
          </Card>
        )}
      </>
    );
  }
);

export default DataTableCard;
