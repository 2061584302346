import React, { useEffect } from 'react';
import { FormProps } from '../../../types/FormProps';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useGeoLocation from '../../../hooks/useGeoLocation';
import useStatus from '../../../hooks/useStatus';
import { useApiState } from '../../../hooks/useApiState';
import { axiosInstance } from '../../../global';

const ServiceMasterForm: React.FC<FormProps> = ({ control, errors, setValue, watch }) => {
  const {
    countryApiState,
    selectedCountry,
    handleCountrySelect,
    governorateApiState,
    handleGovernorateSelect,
    selectedGovernorate
  } = useGeoLocation(true);

  const countryId = watch('countryId');
  const { isStatusLoading, statusData, getStatusData, statusError } = useStatus('Product');

  const fetchCurrency = async () => {
    const response = await axiosInstance.get(`/Currency/dropdown`);
    return response.data;
  };
  const {
    state: { isLoading: isCurrencyLoading, error: currencyError, data: currencyData },
    lazyApiCall: getCurrencyData
  } = useApiState({ apiCall: fetchCurrency });

  useEffect(() => {
    if (countryId) {
      handleCountrySelect({ id: countryId, name: countryId });
    }
  }, [countryId]);

  return (
    <>
      <CustomFormField
        name={'countryId'}
        label={'الدولة'}
        control={control}
        options={countryApiState?.state?.data ?? []}
        errors={errors}
        type={'dropdown'}
        required={true}
      />

      <CustomFormField
        name={'currencyId'}
        label={'العمله'}
        control={control}
        options={currencyData?.data ?? []}
        errors={errors}
        type={'dropdown'}
        disabled={isCurrencyLoading}
        required={true}
      />

      <CustomFormField
        name={'governorateId'}
        label={'المحافظة'}
        control={control}
        options={governorateApiState?.state?.data ?? []}
        errors={errors}
        type={'dropdown'}
        required={true}
      />

      <CustomFormField name={'date'} label={'تاريخ النفاذ'} control={control} errors={errors} type={'calendar'} />
      <CustomFormField
        name={'statusId'}
        label={'الحاله'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={statusData?.data ?? []}
      />
    </>
  );
};

export default ServiceMasterForm;
