import React, { useEffect } from 'react';
import { FormProps } from '../../../types/FormProps';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useStatus from '../../../hooks/useStatus';
import useFetch from '../../../hooks/useFetch';

const WarehouseSectionsForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const warehouseID = watch('warehouseId');
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Warehouse');
  const { data: warehouseData, isLoading: isWarehouseLoading } = useFetch('/Warehouse/dropdown');

  useEffect(() => {
    if (!isWarehouseLoading) {
      setValue('warehouseId', warehouseID);
    }
  }, [isWarehouseLoading]);
  return (
    <>
      <CustomFormField
        name={'warehouseId'}
        label={isEditMode ? 'اسم المخزن' : 'اختر اسم المخزن'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={warehouseData?.data ?? []}
        disabled={extraProps?.isDisabled || isWarehouseLoading}
      />
      <CustomFormField
        name={'name'}
        label={'اضف اسم قسم المخزن'}
        control={control}
        errors={errors}
        type={'text'}
        placeholder='قسم المخزن'
      />
      {/* <CustomFormField
        name={'code'}
        label={'اضف كود قسم المخزن'}
        control={control}
        errors={errors}
        type={'text'}
        placeholder='كود قسم المخزن'
      /> */}
      <CustomFormField
        name={'statusId'}
        label={`اختر حالة قسم المخزن`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />
      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default WarehouseSectionsForm;
