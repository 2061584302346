import React, { memo, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PanelMenu } from 'primereact/panelmenu';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../redux/user/userActions';
import './Sidebar.scss';

const Sidebar = memo(() => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const items = useMemo(() => {
    return (
      user?.menus?.map((menu) => ({
        label: menu.label,
        icon: <img src={`${process.env.PUBLIC_URL}/images/${menu.icon}`} alt={menu.label} />,
        items: menu.items.map((item) => ({
          label: item.label,
          template: (menuItem, options) => (
            <Link to={item.link} className={options.className}>
              {menuItem.label}
            </Link>
          )
        }))
      })) || []
    );
  }, [user?.menus]);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  /* old
  // const items = [
  //   {
  //     label: 'الاحصائيات',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/bentomenu1.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'بيانات الموظفين و المستخدمين'
  //       },
  //       {
  //         label: 'بيانات المناطق'
  //       },
  //       {
  //         label: 'بيانات المنتجات'
  //       },
  //       {
  //         label: 'بيانات العملاء'
  //       },
  //       {
  //         label: 'بيانات المخازن'
  //       },
  //       {
  //         label: 'البيانات اﻻساسية'
  //       }
  //     ]
  //   },
  //   {
  //     label: 'الموظفين',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/note.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'قائمة الموظفين',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/employees' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'اجازات الموظفين',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/employees/vacationRequest' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'مرتبات الموظفين',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/employees/payroll' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'تنظيم العمل',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/employees/management' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'البيانات الاساسية للموظفين',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/lists/users-and-employees' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     label: 'العملاء',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/double1.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'احصائيات العملاء'
  //       },
  //       {
  //         label: 'قائمة العملاء',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/customers/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'مواقع العملاء',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/customers/GeoLocation' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'اجهزة العملاء',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/customers/Devices' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'شكاوى العملاء',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/customers/Complaints' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'البيانات الاساسية للعملاء'
  //       }
  //     ]
  //   },
  //   {
  //     label: 'المنتجات',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/dropbox1.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'احصائيات المنتجات'
  //       },
  //       {
  //         label: 'قائمة المنتجات',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/products/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'قطع الغيار',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/productSpares/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'الخدمات',
  //         template: (item, options) => {
  //           return (
  //             <Link to='product/services' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'اقساط المنتجات',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/product/installments' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'قائمة الاسعار',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/product/priceList' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'البيانات الاساسية للمنتجات'
  //       }
  //     ]
  //   },
  //   {
  //     label: 'العقود',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/file.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'احصائيات العقود'
  //       },
  //       {
  //         label: 'عقود العملاء',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/Contract/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'اقساط العملاء',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/Contract/Customer/Installments' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'البيانات الاساسية للعقود'
  //       }
  //     ]
  //   },
  //   {
  //     label: 'الزيارات',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/briefcase.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'احصائيات الزيارات'
  //       },
  //       {
  //         label: 'سجل الاتصالات',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/Activites/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'سجل الزيارات',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/Visits/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'قسم المتابعة',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/Visits/followUp' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'البيانات الاساسية للزيارات',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/Visits/GeneralLists' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     label: 'التقارير والانشطة',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/user1.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'الاحصائيات'
  //       },
  //       {
  //         label: 'الاحصائيات'
  //       },
  //       {
  //         label: 'الاحصائيات'
  //       },
  //       {
  //         label: 'الاحصائيات'
  //       }
  //     ]
  //   },
  //   {
  //     label: 'المخازن',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/boxes.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'إدارة المخازن',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/warehouses/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'الحركات اليومية',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/warehouses/transactions/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'شروط الدفع',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/warehouses/payment-terms/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'الموردين',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/warehouses/suppliers/' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'تقارير و انشطة المخازن'
  //       },
  //       {
  //         label: 'البيانات الاساسية للمخازن'
  //       }
  //     ]
  //   },
  //   {
  //     label: 'الحسابات',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/coins1.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'بيانات الموظفين و المستخدمين'
  //       },
  //       {
  //         label: 'بيانات المناطق'
  //       },
  //       {
  //         label: 'بيانات المنتجات'
  //       },
  //       {
  //         label: 'بيانات العملاء'
  //       },
  //       {
  //         label: 'بيانات المخازن'
  //       },
  //       {
  //         label: 'البيانات اﻻساسية'
  //       }
  //     ]
  //   },
  //   {
  //     label: 'البيانات اﻻساسية',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/database1.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'بيانات الموظفين و المستخدمين',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/lists/users-and-employees' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'بيانات المناطق',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/lists/locations' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'بيانات العملاء',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/lists/client' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'بيانات المنتجات و الخدمات',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/lists/products' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'بيانات المخازن',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/lists/warehouses' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'البيانات اﻻساسية',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/lists/general' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     label: 'System Admin',
  //     icon: <img src={`${process.env.PUBLIC_URL}/images/database1.svg`} alt='' />,
  //     items: [
  //       {
  //         label: 'System Params',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/admin/systemParams' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       },
  //       {
  //         label: 'Warehouse',
  //         template: (item, options) => {
  //           return (
  //             <Link to='/admin/warehouse' className={options.className}>
  //               {item.label}
  //             </Link>
  //           );
  //         }
  //       }
  //     ]
  //   }
  // ];
*/

  return (
    <div className='sidebar'>
      <PanelMenu model={items} className='w-20rem' />
    </div>
  );
});

export default Sidebar;
