import React, { useEffect } from 'react';
import useGeoLocation from '../../../../hooks/useGeoLocation';
import { axiosInstance } from '../../../../global';
import { useApiState } from '../../../../hooks/useApiState';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import { FormProps } from '../../../../types/FormProps';
import useStatus from '../../../../hooks/useStatus';

const CountryForm: React.FC<FormProps> = ({ control, errors, setValue, watch }) => {
  const geoLocation = useGeoLocation(true);
  const isEditMode = watch('id') != null;
  const selectedCountry = watch('countryId');

  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('GeoLocation');

  const fetchCurrency = async () => {
    const response = await axiosInstance.get(`/Currency/dropdown`);
    return response.data;
  };

  const {
    state: { isLoading: isCurrencyLoading, error: currencyError, data: currencyData },
    lazyApiCall: getCurrencyData
  } = useApiState({ apiCall: fetchCurrency });

  useEffect(() => {
    if (selectedCountry) {
      geoLocation.handleCountrySelect({ id: selectedCountry, name: selectedCountry });
    }
  }, [selectedCountry]);

  return (
    <div className='flex flex-column gap-5'>
      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? 'اسم الدولة' : 'اضف اسم/اسماء الدوله الجديدة'}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        required={true}
        placeholder='Enter new location names'
      />

      <CustomFormField
        name={'statusId'}
        label={'اختر حالة الدوله'}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField
        name='currencyId'
        label='العملة'
        control={control}
        errors={errors}
        type='dropdown'
        required={true}
        placeholder='اختر العملة'
        disabled={isCurrencyLoading}
        options={currencyData?.data ?? []}
      />
      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </div>
  );
};

export default CountryForm;
