import { axiosInstance } from '../global';
import { useApiState } from './useApiState';

type ProductType = 'Stages' | 'Filters' | 'SpareParts';

const productTypeMap: { [key in ProductType]: number } = {
  Stages: 1,
  Filters: 2,
  SpareParts: 3
};

const useProduct = (type: ProductType) => {
  const typeId = productTypeMap[type];

  const fetchProduct = async () => {
    const response = await axiosInstance.get(`/Product/allByProductTypeDropdown/${typeId}`);
    return response.data;
  };

  const {
    state: { isLoading: isProductLoading, error: productError, data: productData },
    lazyApiCall: getProductData
  } = useApiState({ apiCall: fetchProduct });

  return {
    isProductLoading,
    productError,
    productData,
    getProductData
  };
};

export default useProduct;
