import { axiosInstance } from '../../global';

import {
  FETCH_ACTIVITY_REQUEST,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_FAILURE,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_FAILURE,
  EDIT_ACTIVITY_REQUEST,
  EDIT_ACTIVITY_SUCCESS,
  EDIT_ACTIVITY_FAILURE,
  CHANGE_ACTIVITY_STATE,
  CLEAR_ACTIVITY_STATE
} from './activityTypes';

//====================fetch data===================================
export const fetchActivity = (id) => {
  return (dispatch) => {
    dispatch(fetch_activity_request());
    axiosInstance
      .get(`/CustomersActivity/${id}`)
      .then((responseActivityData) => {
        const tempActivity = responseActivityData.data.data;
        const formattedActivity = {
          id: tempActivity.id,
          customerId: tempActivity.customer.id,
          customerDeviceId: tempActivity.customerDevice.id,
          activityId: tempActivity.activity.id,
          reasonId: tempActivity.reason.id,
          decisionId: tempActivity.decision.id,
          activityDateTime: tempActivity.activityDateTime,
          reference: tempActivity.reference,
          requestDetails: tempActivity.requestDetails,
          responseDetails: tempActivity.responseDetails,
          remarks: tempActivity.remarks
        };
        dispatch(fetch_activity_success(formattedActivity));
      })
      .catch((errorActivityData) => {
        console.log({ errorActivityData });
        dispatch(fetch_activity_failure(errorActivityData.message));
      });
  };
};
const fetch_activity_request = () => {
  return {
    type: FETCH_ACTIVITY_REQUEST
  };
};
const fetch_activity_success = (activity) => {
  return {
    type: FETCH_ACTIVITY_SUCCESS,
    payload: activity
  };
};
const fetch_activity_failure = (error) => {
  return {
    type: FETCH_ACTIVITY_FAILURE,
    payload: error
  };
};

//=====================add data======================================
export const addActivity = (activity) => {
  return (dispatch) => {
    dispatch(add_activity_request());
    axiosInstance
      .post(`/CustomersActivity`, activity)
      .then((response) => {
        dispatch(add_activity_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_activity_failure(error.message));
      });
  };
};
const add_activity_request = () => {
  return {
    type: ADD_ACTIVITY_REQUEST
  };
};
const add_activity_success = (activity) => {
  return {
    type: ADD_ACTIVITY_SUCCESS,
    payload: activity
  };
};
const add_activity_failure = (error) => {
  return {
    type: ADD_ACTIVITY_FAILURE,
    payload: error
  };
};

//=====================edit data=====================================
export const editActivity = (activity) => {
  const payload = {
    id: activity.id,
    customerId: activity.customerId,
    customerDeviceId: activity.customerDeviceId,
    activityId: activity.activityId,
    reasonId: activity.reasonId,
    decisionId: activity.decisionId,
    activityDateTime: activity.activityDateTime,
    reference: activity.reference,
    requestDetails: activity.requestDetails,
    responseDetails: activity.responseDetails,
    remarks: activity.remarks
  };
  return (dispatch) => {
    dispatch(edit_activity_request());
    axiosInstance
      .put(`/CustomersActivity`, payload)
      .then((response) => {
        dispatch(edit_activity_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_activity_failure(error.message));
      });
  };
};
const edit_activity_request = () => {
  return {
    type: EDIT_ACTIVITY_REQUEST
  };
};
const edit_activity_success = (activity) => {
  return {
    type: EDIT_ACTIVITY_SUCCESS,
    payload: activity
  };
};
const edit_activity_failure = (error) => {
  return {
    type: EDIT_ACTIVITY_FAILURE,
    payload: error
  };
};

//=================fill with data=====================================
export const fill_activity = (activity) => {
  return (dispatch) => {
    dispatch(change_activity_state(activity));
  };
};
const change_activity_state = (activity) => {
  return {
    type: CHANGE_ACTIVITY_STATE,
    payload: activity
  };
};

export const clearActivityState = () => {
  return {
    type: CLEAR_ACTIVITY_STATE
  };
};
