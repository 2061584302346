import React, { useRef, useState } from 'react';
import Tabs from '../../../components/Tabs/Tabs';
import ListEntityTable from '../../../components/Tables/ListEntityTable';
import { AddEditWarehouseTransMatrix } from './AddEditWarehouseTransMatrix';
import { AddEditDestinationsSources } from './AddEditDestinationsSources';
import { useToast } from '../../../context/ToastContext';
import GenericModal from '../../../components/components/Modal/GenericModal';
import useModal from '../../../hooks/useModal';
import * as yup from 'yup';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import UserTypeDestinationSourcesForm from './Forms/UserTypeDestinationSourcesForm';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';

const userDestinationSourceScheme = yup.object().shape({
  userTypeId: yup.number().nullable().default(null),
  branchId: yup.number().nullable().default(null),
  productTypeId: yup.number().nullable().default(null),
  transTypeId: yup.number().nullable().default(null),
  statusId: yup.number().nullable().default(null),
  remarks: yup.string().nullable()
  // listOfDestinationSourceId: yup.number().nullable().default(null)
});

export function WarehouseTransactionsMatrixList() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const [myrowData, setMyRowData] = useState<DestinationSourcesData | {}>({});
  const [expandFormVisible, setExpandFormVisible] = useState(false);

  interface DestinationSourcesData {
    branchID: number;
    branchName: string;
    userTypeID: number;
    userTypeName: string;
    productTypeID: number;
    productTypeName: string;
    transTypeID: number;
    transTypeName: string;
    destinationSources: {
      id: number;
      name: string;
    }[];
    statusID: 1;
    statusName: string;
    remarks: string;
  }

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isUserDestinationSourceVisitable,
    openModal: userDestinationSourceOpenModal,
    closeModal: userDestinationSourceCloseModal,
    control: userDestinationSourceControl,
    handleSubmit: userDestinationSourceSubmit,
    handleDelete: userDestinationSourceDelete,
    errors: userDestinationSourceErrors,
    setValue: userDestinationSourceSetValue,
    loading: userDestinationSourceLoading,
    setLoading: userDestinationSourceSetLoading,
    watch: userDestinationSourceWatch
  } = useModal({}, '/UserTypesTransDestSourcesMatrix', fetchTabData, userDestinationSourceScheme, toast);

  const handleActionButtonClick = (rowData: any) => {
    setMyRowData(rowData);
    setExpandFormVisible(true);
  };

  const tab = {
    label: 'User Type Destination Source',
    emptyStateButtonClick: () => {
      userDestinationSourceOpenModal();
    },
    dataKey: 'UserTypeDestinationSource',
    columns: [
      {
        header: 'Branch Name',
        field: 'branchName',
        isChip: false
      },
      {
        header: 'User Type',
        field: 'userTypeName',
        isChip: false
      },
      {
        header: 'Transaction Type',
        field: 'transTypeName',
        isChip: false
      },
      {
        header: 'Product Type',
        field: 'productTypeName',
        isChip: false
      },
      {
        header: 'Destination Sources',
        field: 'destinationSources',
        isButton: true,
        actionCallback: (rowData: any) => handleActionButtonClick(rowData)
      }
    ],
    edit: true,
    headerButtons: [
      // {
      //   label: 'Add Destination Source',
      //   onClick: () => {
      //     userDestinationSourceOpenModal();
      //   }
      // }
    ],
    rowOnClick: (rowData: any) => {
      const destinationSourcesIds = rowData.destinationSources.map((type: { id: number }) => type.id);
      userDestinationSourceOpenModal({
        id: rowData.id,
        userTypeId: rowData.userTypeID,
        branchId: rowData.branchID,
        productTypeId: rowData.productTypeID,
        transTypeId: rowData.transTypeID,
        statusId: rowData.statusID,
        listOfDestinationSourceId: destinationSourcesIds,
        remarks: rowData.remarks
      });
    },
    apiUrl: 'UserTypesTransDestSourcesMatrix/list',
    entityName: 'Destination Source',
    tableHeader: 'Destination Source Table',
    emptyStateUrl: ''
  };

  return (
    <div className='w-full'>
      <span className='main__title'>
        <i className='pi pi-database main__title--icon'></i>Warehouses
      </span>
      <div className='mt-5 pr-4 pl-4'>
        <Tabs
          headers={['Warehouse Movement Matrix', 'Destinations Sources', 'User Type Destination Sources']}
          contents={[
            <ListEntityTable
              columns={[
                { header: 'TransTypeName', field: 'transTypeName', isChip: true },
                { header: 'DestinationSource', field: 'destinationSource.name', isChip: true },
                { header: 'NextTransType', field: 'nextTransType.name', isChip: true },
                { header: 'NextDestinationSource', field: 'nextDestinationSource.name', isChip: true },
                { header: 'DefaultApprovalStatus', field: 'defaultApprovalStatus', isChip: true, isBoolean: true },
                { header: 'InternalTransaction', field: 'internalTransaction', isChip: true, isBoolean: true },
                { header: 'status', field: 'status.name', isChip: true },
                { header: 'ملاحظات', field: 'remarks', isChip: false }
              ]}
              urlName='WarehouseMovementMatrix'
              headerTitle='Warehouse Movement Matrix'
              entityName='Warehouse Movement Matrix'
              entityNameDefined='Warehouse Movement Matrix'
              addBtnText='Add Warehouse Movement Matrix'
              AddEntityComponent={AddEditWarehouseTransMatrix}
              type={'generalList'}
              isCustomerGeo={undefined}
              isCustomerDevice={undefined}
              isCustomerComplaint={undefined}
            />,
            <ListEntityTable
              columns={[
                { header: 'TransTypeName', field: 'name', isChip: true },
                { header: 'DestinationSource', field: 'direction', isChip: true },
                { header: 'DefaultApprovalStatus', field: 'allowFirstReceiving', isChip: true, isBoolean: true },
                { header: 'status', field: 'status.name', isChip: true },
                { header: 'ملاحظات', field: 'remarks', isChip: false }
              ]}
              urlName='DestinationsSource'
              headerTitle='Destinations Sources'
              entityName='Destinations Sources'
              entityNameDefined='Destinations Sources'
              addBtnText='Add Destinations Sources'
              AddEntityComponent={AddEditDestinationsSources}
              type={'generalList'}
              isCustomerGeo={undefined}
              isCustomerDevice={undefined}
              isCustomerComplaint={undefined}
            />,
            <DataTableCard
              ref={genericPageRef}
              button={[
                {
                  label: 'Add Destination Source',
                  onClick: () => {
                    userDestinationSourceOpenModal();
                  }
                }
              ]}
              selectedTab={tab}
            />
          ]}
        />

        <GenericModal
          headerTitle={'Add user Type Destination Source'}
          isVisible={isUserDestinationSourceVisitable}
          onHide={userDestinationSourceCloseModal}
          handleSubmit={userDestinationSourceSubmit}
          onDelete={userDestinationSourceDelete}
          toast={toast}
          loading={userDestinationSourceLoading}
          isEditMode={userDestinationSourceWatch('id') !== undefined}
        >
          <UserTypeDestinationSourcesForm
            control={userDestinationSourceControl}
            watch={userDestinationSourceWatch}
            errors={userDestinationSourceErrors}
            setValue={userDestinationSourceSetValue}
            extraProps={{}}
          />
        </GenericModal>

        <Dialog
          header={`${(myrowData as DestinationSourcesData).userTypeName}`}
          visible={expandFormVisible}
          style={{ width: '50vw', direction: 'ltr' }}
          onHide={() => {
            if (!expandFormVisible) return;
            setExpandFormVisible(false);
          }}
          className='dialog'
        >
          {myrowData && (myrowData as DestinationSourcesData).destinationSources ? (
            <div>
              {(myrowData as DestinationSourcesData).destinationSources.map((source) => (
                <Chip key={source.id} className='dialog__innerDta' label={source.name} />
              ))}
            </div>
          ) : (
            <p>NO user Type Destination Sources for this User type</p>
          )}
        </Dialog>
      </div>
    </div>
  );
}
