import React from 'react';
import { FormProps } from '../../../../types/FormProps';
import useComplaintHierarchy from '../../../../hooks/useComplaintHierarchy';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';

const ComplaintsFamilyForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;

  const { complaintTypeApiState, handleComplaintTypeSelect, complaintFamilyApiState, handleComplaintFamilySelect } =
    useComplaintHierarchy();

  const { statusData, isStatusLoading } = useStatus('Client');

  return (
    <>
      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? `اسم ${extraProps?.entityName}` : `اضف اسم/اسماء ${extraProps?.entityName} الجديدة`}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        required={true}
        placeholder={isEditMode ? `اسم ${extraProps?.entityName}` : `اضف اسم/اسماء ${extraProps?.entityName} الجديدة`}
      />

      <CustomFormField
        name={'complaintTypeId'}
        label={'فئه الشكوي'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={complaintTypeApiState.state?.data ?? []}
        disabled={complaintTypeApiState.state?.isLoading}
      />
      <CustomFormField name={'referenceTable'} label={'المرجع'} control={control} errors={errors} type={'text'} />
      <CustomFormField
        name={'statusId'}
        label={'حالة نوع الشكوي'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={statusData?.data ?? []}
        disabled={isStatusLoading}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default ComplaintsFamilyForm;
