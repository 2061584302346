import React, { useEffect } from 'react';
import { FormProps } from '../../../../types/FormProps';
import { Message } from 'primereact/message';
import useActivityHierarchy from '../../../../hooks/useActivityHierarchy';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';

const ActivityCategoryForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const activityType = watch('TypeId');
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('General');

  const { activityTypeApiState, activityFamilyApiState, handleActivityTypeSelect } = useActivityHierarchy();

  useEffect(() => {
    if (activityType) {
      handleActivityTypeSelect({ id: activityType, name: activityType });
    }
  }, [activityType]);

  return (
    <>
      <Message
        severity='info'
        text={'يجب اختيار نوع الاتصال والتصنيف اولاً حتى يكون بأمكانك اضافة فئة الاتصالات داخلها'}
        className='text-black-alpha-90 font-semibold'
      />

      <CustomFormField
        name={'TypeId'}
        label={'اختر نوع الاتصال'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={activityTypeApiState.state?.data ?? []}
      />

      <CustomFormField
        name={'FamilyId'}
        label={'اختر تصنيف الاتصال'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={activityFamilyApiState.state?.data ?? []}
      />

      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? `اسم فئة الاتصال` : `اضف اسم/اسماء فئة الاتصال الجديدة`}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        placeholder={isEditMode ? `اسم فئة الاتصال` : `اضف اسم/اسماء فئة الاتصال الجديدة`}
      />

      <CustomFormField
        name={'statusId'}
        label={`اختر حالة فئة الاتصال`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default ActivityCategoryForm;
