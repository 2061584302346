import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function ActivitesScreen() {
  const navigate = useNavigate();

  return (
    <GenericPage
      tabs={[
        {
          isHidden: true,
          label: 'بيانات التواصل',
          tableHeader: '',
          dataKey: 'التواصل',
          emptyStateButtonClick: () => {},
          apiUrl: 'CustomersActivity/list',
          edit: false,
          columns: [
            {
              header: 'اسم العميل',
              field: 'customerFullName',
              isChip: false
            },
            {
              header: 'جهاز العميل',
              field: 'customerDeviceDeviceName',
              isChip: false
            },
            {
              header: 'سبب التواصل',
              field: 'reasonName',
              isChip: false
            },
            { header: 'الاجراء المتخذ', field: 'decisionName', isChip: false },
            { header: 'مستوى الرضى', field: 'satisfactionLevel', isRate: true },
            { header: 'تاريخ الاتصال', field: 'activityDateTime', isDate: true }
          ],
          emptyStateUrl: '',
          entityName: 'بيانات التواصل',
          rowOnClick: (rowData) => {
            navigate(`/Activities/details/activities/${rowData.id}/${rowData.customerId}`);
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        }
      ]}
      headerTitle={'الزيارات'}
      headerIcon={'../images/briefcase1.svg'}
    />
    // <div className='flex w-full'>
    //   <Sidebar />
    //
    //
    //   {/*<div className='w-full'>*/}
    //   {/*  <span className='main__title__custom'>*/}
    //   {/*    <i className='main__title__custom--icon'>*/}
    //   {/*      <img src='../images/briefcase1.svg' alt='' />*/}
    //   {/*    </i>*/}
    //   {/*    <span>الزيارات</span>*/}
    //   {/*  </span>*/}
    //
    //   {/*  <div className='mt-5 pr-4 pl-4'>*/}
    //   {/*    <ActivitesScreenTable*/}
    //   {/*      columns={[*/}
    //   {/*        { header: 'سبب التواصل', field: 'reason.name', isChip: false },*/}
    //   {/*        { header: 'الاجراء المتخذ', field: 'decision.name', isChip: false },*/}
    //   {/*        { header: 'مستوى الرضى', field: 'satisfactionLevel', isRate: true },*/}
    //   {/*        { header: 'تاريخ الاتصال', field: 'activityDateTime', isChip: false }*/}
    //   {/*      ]}*/}
    //   {/*      urlName='CustomersActivity/all'*/}
    //   {/*      headerTitle='التواصل'*/}
    //   {/*      entityNameDefined='تفاصيل الاتصال'*/}
    //   {/*      addBtnText='اضافة اتصال / زيارة جديدة'*/}
    //   {/*      path='/Activites/addVisitConnection'*/}
    //   {/*      type={'activities'}*/}
    //   {/*      detailsPath='/activities/details'*/}
    //   {/*    />*/}
    //   {/*  </div>*/}
    //   {/*</div>*/}
    // </div>
  );
}

export default ActivitesScreen;
