import React from 'react';
import GenericPage from '../../components/components/GenericPage/GenericPage';
import { Toast } from 'primereact/toast';
import { useToast } from '../../context/ToastContext';
import { useNavigate } from 'react-router-dom';
import { isDate } from 'lodash';

const columns = [
  { header: 'رقم العملية', field: 'transNb', isChip: false },
  { header: 'اﻻسم', field: 'name', isChip: false },
  { header: 'نوع الاذن', field: 'transTypeName', isChip: false },
  { header: 'نوع المصدر', field: 'sourceName', isChip: false },
  { header: 'المصدر', field: 'sourceRefName', isChip: false },
  { header: 'نوع المستقبل', field: 'destinationName', isChip: false },
  { header: 'المستقبل', field: 'destinationRefName', isChip: false },
  { header: 'تاريخ الحركة', field: 'transactionDateTime', isDate: true }
];
function Transactions() {
  const toast = useToast();
  const navigate = useNavigate();
  const apiUrl = '/WarehouseTransactionsGen/list';
  const addUrl = '/warehouses/transactions/issuing/add';
  const tabs = [
    {
      label: '',
      tableHeader: 'قائمة الحركات اليومية',
      entityName: 'الاذن',
      dataKey: 'tab1',
      columns: columns,
      apiUrl: apiUrl,
      isHidden: true,
      edit: false,
      Screentype: '',
      emptyStateUrl: addUrl,
      rowOnClick: (rowData: any) => {
        navigate(`/warehouses/transactions/details/${rowData.id}`);
      },
      extraParams: {},
      emptyStateButtonClick: undefined,
      headerButtons: [
        {
          label: 'إضافة عهدة للزيارة',
          onClick: () => {
            navigate(`/warehouses/transactions/covenant/add`);
          }
        },
        {
          label: 'إضافة إذن استلام',
          onClick: () => {
            navigate(`/warehouses/transactions/receiving/add`);
          }
        },
        {
          label: 'إضافة إذن صرف',
          onClick: () => {
            navigate(addUrl);
          }
        }
      ]
    }
  ];

  return (
    <div className='w-full'>
      <GenericPage tabs={tabs} headerTitle='المخازن' headerIcon='/images/boxes1.svg' />
    </div>
  );
}

export default Transactions;
