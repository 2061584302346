import React, { useRef } from 'react';
import GenericForm from '../../../components/Forms/GenericForm';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { useToast } from '../../../context/ToastContext';
import { genericSchemeForNames } from '../../../components/components/Form/Scheme';
import GenericModal from '../../../components/components/Modal/GenericModal';
import useModal from '../../../hooks/useModal';
import PaymentTermForm from './Forms/PaymentTermForm';

function WarehousesLists() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isWarehouseTypeVisitable,
    openModal: warehouseTypeOpenModal,
    closeModal: warehouseTypeCloseModal,
    control: warehouseTypeControl,
    handleSubmit: warehouseTypeSubmit,
    errors: warehouseTypeErrors,
    setValue: warehouseTypeSetValue,
    loading: warehouseTypeLoading,
    setLoading: warehouseTypeSetLoading,
    watch: warehouseTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/WarehousesType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isDeliveryTypeVisitable,
    openModal: deliveryTypeOpenModal,
    closeModal: deliveryTypeCloseModal,
    control: deliveryTypeControl,
    handleSubmit: deliveryTypeSubmit,
    errors: deliveryTypeErrors,
    setValue: deliveryTypeSetValue,
    loading: deliveryTypeLoading,
    setLoading: deliveryTypeSetLoading,
    watch: deliveryTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/DeliveryTerm', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isPaymentTermVisitable,
    openModal: paymentTermOpenModal,
    closeModal: paymentTermCloseModal,
    control: paymentTermControl,
    handleSubmit: paymentTermSubmit,
    errors: paymentTermErrors,
    setValue: paymentTermSetValue,
    loading: paymentTermLoading,
    setLoading: paymentTermSetLoading,
    watch: paymentTermWatch
  } = useModal(
    { remarks: ' ', name: '', discountPercentage: 0.0, discountAmount: 0.0 },
    '/PaymentTerm',
    fetchTabData,
    genericSchemeForNames,
    toast
  );
  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'انواع المخازن',
            emptyStateButtonClick: () => {
              warehouseTypeOpenModal();
            },
            dataKey: 'WarehouseType',
            columns: [
              { header: 'انواع المخازن', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع مخازن جديدة',
                onClick: () => {
                  warehouseTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              warehouseTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'WarehousesType/list',
            entityName: 'نوع المخزن',
            tableHeader: 'جدول انواع المخازن',
            emptyStateUrl: ''
          },
          {
            label: 'طرق الشحن',
            emptyStateButtonClick: () => {
              deliveryTypeOpenModal();
            },
            dataKey: 'DeliveryType',
            columns: [
              { header: 'طرق الشحن', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة طريقة شحن جديدة',
                onClick: () => {
                  deliveryTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              deliveryTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'DeliveryTerm/list',
            entityName: 'طريقة الشحن',
            tableHeader: 'جدول طرق الشحن',
            emptyStateUrl: ''
          },
          {
            label: 'شروط الدفع',
            emptyStateButtonClick: () => {
              paymentTermOpenModal();
            },
            dataKey: 'PaymentTerm',
            columns: [
              { header: 'الاسم', field: 'name', isChip: false },
              { header: 'الايام', field: 'days', isChip: false },
              { header: 'قيمة الخصم', field: 'discountAmount', isChip: false },
              { header: 'نسبة الخصم', field: 'discountPercentage', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة شروط دفع جديدة',
                onClick: () => {
                  paymentTermOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              paymentTermOpenModal({
                id: rowData.id,
                name: rowData.name,
                days: rowData.days,
                discountPercentage: rowData.discountPercentage,
                discountAmount: rowData.discountAmount,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'PaymentTerm/list',
            entityName: 'شرط الدفع',
            tableHeader: 'جدول شروط الدفع',
            emptyStateUrl: ''
          }
        ]}
        headerTitle='المخازن'
        headerIcon='/images/boxes1.svg'
      />
      <GenericModal
        headerTitle={'إضافة انواع مخازن جديدة'}
        isVisible={isWarehouseTypeVisitable}
        onHide={warehouseTypeCloseModal}
        handleSubmit={warehouseTypeSubmit}
        toast={toast}
        loading={warehouseTypeLoading}
        isEditMode={warehouseTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={warehouseTypeControl}
          watch={warehouseTypeWatch}
          errors={warehouseTypeErrors}
          setValue={warehouseTypeSetValue}
          extraProps={{
            entityName: 'نوع المخزن'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة طرق شحن جديدة'}
        isVisible={isDeliveryTypeVisitable}
        onHide={deliveryTypeCloseModal}
        handleSubmit={deliveryTypeSubmit}
        toast={toast}
        loading={deliveryTypeLoading}
        isEditMode={deliveryTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={deliveryTypeControl}
          watch={deliveryTypeWatch}
          errors={deliveryTypeErrors}
          setValue={deliveryTypeSetValue}
          extraProps={{
            entityName: 'طرق الشحن'
          }}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'إضافة شروط دفع جديدة'}
        isVisible={isPaymentTermVisitable}
        onHide={paymentTermCloseModal}
        handleSubmit={paymentTermSubmit}
        toast={toast}
        loading={paymentTermLoading}
        isEditMode={paymentTermWatch('id') !== undefined}
      >
        <PaymentTermForm
          control={paymentTermControl}
          watch={paymentTermWatch}
          errors={paymentTermErrors}
          setValue={paymentTermSetValue}
          extraProps={{
            entityName: 'شروط دفع'
          }}
        />
      </GenericModal>
    </>
  );
}

export default WarehousesLists;
