import update from 'immutability-helper';
import {
  FETCH_REQITEMSVISIT_REQUEST,
  FETCH_REQITEMSVISIT_SUCCESS,
  FETCH_REQITEMSVISIT_FAILURE,
  ADD_REQITEMSVISIT_REQUEST,
  ADD_REQITEMSVISIT_SUCCESS,
  ADD_REQITEMSVISIT_FAILURE,
  EDIT_REQITEMSVISIT_REQUEST,
  EDIT_REQITEMSVISIT_SUCCESS,
  EDIT_REQITEMSVISIT_FAILURE,
  CHANGE_REQITEMSVISIT_STATE,
  CHANGE_REQITEMSVISIT_TEMP_STATE,
  CLEAR_REQITEMSVISIT_STATE
} from './visitTypes';

const initialState = {
  visitRequstedItems: {
    visitId: '',
    requestedItemDetails: [],
    remarks: ''
  },
  visitRequstedItemsData: {
    visitId: '',
    requestedItemDetails: [],
    remarks: ''
  },
  visitRequstedItemsTemp: {
    data: []
  },
  loading: false,
  error: ''
};

const visitReducer4 = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQITEMSVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_REQITEMSVISIT_SUCCESS:
      return {
        ...state,
        visitRequstedItemsData: action.payload,
        loading: false,
        error: ''
      };
    case FETCH_REQITEMSVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_REQITEMSVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_REQITEMSVISIT_SUCCESS:
      return {
        ...state,
        visitRequstedItems: action.payload,
        loading: false,
        error: ''
      };
    case ADD_REQITEMSVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case EDIT_REQITEMSVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case EDIT_REQITEMSVISIT_SUCCESS:
      return {
        ...state,
        visitRequstedItems: action.payload,
        loading: false,
        error: ''
      };
    case EDIT_REQITEMSVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CHANGE_REQITEMSVISIT_STATE:
      return update(state, {
        $merge: {
          visitRequstedItems: {
            ...state.visitRequstedItems,
            ...action.payload
          }
        }
      });
    case CHANGE_REQITEMSVISIT_TEMP_STATE:
      return update(state, {
        $merge: {
          visitRequstedItemsTemp: {
            ...state.visitRequstedItemsTemp,
            ...action.payload
          }
        }
      });

    case CLEAR_REQITEMSVISIT_STATE:
      return {
        ...state,
        visitRequstedItems: initialState.visitRequstedItems,
        visitRequstedItemsTemp: initialState.visitRequstedItemsTemp
      };
    default:
      return state;
  }
};

export default visitReducer4;
