import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import './TableHeader.scss';
import { Button } from 'primereact/button';
import styles from '../Header/Header.module.scss';

interface TableHeaderProps {
  title?: string;
  onSearch: (searchTerm: string) => void;
  searchTerm: string;
  onDownload?: () => void;
  button?: {
    label: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }[];
}

export const TableHeader: React.FC<TableHeaderProps> = ({ title, onSearch, searchTerm, onDownload, button }) => {
  const [searchCondition, setSearchCondition] = useState(searchTerm);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchCondition(searchTerm);
  }, [searchTerm]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCondition(e.target.value);
    onSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchCondition('');
    onSearch('');
  };

  const handleIconClick = () => {
    if (searchCondition) {
      handleClearSearch();
    } else {
      inputRef.current?.focus();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '10px',
        marginBottom: '15px',
        alignItems: 'center',
        borderBottom: '1px solid #EEE',
        justifyContent: 'space-between'
      }}
    >
      {title && <h2 style={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#313131' }}>{title}</h2>}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className='p-input-icon-left customSpan' style={{ direction: 'rtl', width: '232px' }}>
          <i
            className={`pi ${searchCondition ? 'pi-times' : 'pi-search'}`}
            style={{ borderRadius: '0.5rem', borderColor: '#000', cursor: 'pointer' }}
            onClick={handleIconClick}
          />
          <InputText
            placeholder={`البحث في ${title} ..`}
            style={{
              fontWeight: 400,
              fontSize: '.8rem',
              width: '100%',
              fontFamily: 'IBM Plex Sans Arabic'
            }}
            value={searchCondition}
            onChange={handleSearchChange}
            ref={inputRef}
          />
        </span>
        {button &&
          button.map((value, index) => (
            <Button
              key={index}
              label={value.label}
              onClick={value.onClick}
              icon={'pi pi-plus'}
              className={styles.button}
              style={{
                background: '#A2FB5D',
                borderColor: '#333',
                color: '#333',
                fontWeight: 'bold',
                marginRight: '.5rem',
                direction: 'rtl',
                minWidth: '180px'
              }}
            />
          ))}

        {/*<Button*/}
        {/*  icon='pi pi-download'*/}
        {/*  onClick={onDownload}*/}
        {/*  className='p-button-text p-button-plain'*/}
        {/*  tooltip='Download'*/}
        {/*/>*/}
      </div>
    </div>
  );
};
