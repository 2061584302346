import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { axiosInstance } from '../../../global';
import './CustomersDetails.scss';
import CustomersDetailsContent from './CustomersDetailsContent';
import useFetch from '../../../hooks/useFetch';
import { useToast } from '../../../context/ToastContext';

function CustomersDetails() {
  const Style1: React.CSSProperties = { color: 'black' };
  const Style2: React.CSSProperties = { color: 'red' };

  const toast = useToast();
  const params = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { data: customerData } = useFetch(`/Customer/${params.id}`);

  function showConfirmDeleteCustomer() {
    confirmDialog({
      message: 'هل تريد تأكيد حذف العميل',
      header: 'تأكيد حذف العميل',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteCustomer,
      draggable: false,
      resizable: false
    });
  }

  function handleDeleteCustomer() {
    axiosInstance
      .delete(`/Customer/${params.id}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`
        // }
      })
      .then(() => {
        toast.current?.show({
          severity: 'success',
          summary: 'تم حذف العميل',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/customers'), 3000);
      })
      .catch(() => {
        toast.current?.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف العميل',
          detail: '',
          life: 3000
        });
      });
  }

  return (
    <div className=' main w-full'>
      <div className='customerdetails__header1'>
        {/**/}

        <div className='customerdetails__header1__right'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='customerdetails__header1__right--text'>تفاصيل العميل</span>
          <Chip label={customerData?.data?.status?.name} />
        </div>

        <div className='customerdetails__header__left'>
          <Button
            className='customerdetails__header1__left__btn'
            onClick={() => navigate(`/customers/editCustomer/${params.id}`)}
            icon='pi pi-pencil'
            style={Style1}
            text
          >
            تعديل بيانات العميل
          </Button>

          <Button
            className='customerdetails__header1__left__btn'
            onClick={showConfirmDeleteCustomer}
            icon='pi pi-trash'
            style={Style2}
            text
          >
            حذف العميل
          </Button>
        </div>
      </div>
      <div className='customerdetails__contentgeneral'>
        <CustomersDetailsContent customer={customerData ? customerData.data : null} />
      </div>
    </div>
  );
}

export default CustomersDetails;
