import React, { useEffect } from 'react';
import { FormProps } from '../../../../types/FormProps';
import { Message } from 'primereact/message';
import useProductHierarchy from '../../../../hooks/useProductHierarchy';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';

const ProductCategoryForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const productType = watch('productTypeId');
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Product');

  const { productTypeApiState, productFamilyApiState, handleProductTypeSelect } = useProductHierarchy();

  useEffect(() => {
    if (productType) {
      handleProductTypeSelect({ id: productType, name: productType });
    }
  }, [productType]);

  return (
    <>
      <Message
        severity='info'
        text={'يجب اختيار نوع المنتج والتصنيف اولاً حتى يكون بأمكانك اضافة فئة المنتجات داخلها'}
        className='text-black-alpha-90 font-semibold'
      />

      <CustomFormField
        name={'productTypeId'}
        label={'اختر نوع المنتج'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={productTypeApiState.state?.data ?? []}
      />

      <CustomFormField
        name={'productFamilyId'}
        label={'اختر نوع المنتج'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={productFamilyApiState.state?.data ?? []}
      />

      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? `اسم فئة المنتج` : `اضف اسم/اسماء فئة المنتج الجديدة`}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        placeholder={isEditMode ? `اسم فئة المنتج` : `اضف اسم/اسماء فئة المنتج الجديدة`}
      />

      <CustomFormField
        name={'statusId'}
        label={`اختر حالة فئة المنتج`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default ProductCategoryForm;
