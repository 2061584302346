import { FC } from 'react';

export interface ContractDetailsHeaderProps {
  customerName: string;
  phoneNumber: string;
  skuNumber: string;
  productName: string;
  contractType: string;
  contractDate: string;
  expiryDate: string;
  installmentsType: string;
}

const ContractDetailsHeader: FC<ContractDetailsHeaderProps> = ({
  customerName,
  skuNumber,
  productName,
  phoneNumber,
  expiryDate,
  contractDate,
  contractType,
  installmentsType
}) => {
  return (
    <div style={{ backgroundColor: '#EAEAEA', borderRadius: '8px', border: '1px', padding: '12px' }}>
      <label
        style={{
          fontFamily: 'IBM Plex Sans Arabic',
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '36px',
          textAlign: 'right',
          color: '#111111'
        }}
      >
        بيانات العقد الجديد
      </label>
      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ marginBottom: '0.5rem', color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
          <span style={{ color: '#7D7D7D', fontWeight: '700' }}>العميل:</span>
          <span style={{ color: '#111111', marginRight: '8px' }}>{customerName}</span>
        </div>
        <div style={{ marginBottom: '0.5rem', color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
          <span style={{ color: '#7D7D7D', fontWeight: '700' }}>الرقم القومي:</span>
          <span style={{ color: '#111111', marginRight: '8px' }}>{phoneNumber}</span>
        </div>
        <div style={{ marginBottom: '0.5rem', color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
          <span style={{ color: '#7D7D7D', fontWeight: '700' }}>رقم المنتج (SKU):</span>
          <span style={{ color: '#111111', marginRight: '8px' }}>{skuNumber}</span>
        </div>
        <div style={{ color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
          <span style={{ color: '#7D7D7D', fontWeight: '700' }}>الجهاز المراد بيعه:</span>
          <span style={{ color: '#111111', marginRight: '8px' }}>{productName}</span>
        </div>
      </div>
      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ marginBottom: '0.5rem', color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
          <span style={{ color: '#7D7D7D', fontWeight: '700' }}>نوع العقد:</span>
          <span style={{ color: '#111111', marginRight: '8px' }}>{contractType}</span>
        </div>
        <div style={{ marginBottom: '0.5rem', color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
          <span style={{ color: '#7D7D7D', fontWeight: '700' }}>تاريخ التعاقد:</span>
          <span style={{ color: '#111111', marginRight: '8px' }}>{contractDate}</span>
        </div>
        <div style={{ marginBottom: '0.5rem', color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
          <span style={{ color: '#7D7D7D', fontWeight: '700' }}>تاريخ الانتهاء:</span>
          <span style={{ color: '#111111', marginRight: '8px' }}>{expiryDate}</span>
        </div>
        <div style={{ color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
          <span style={{ color: '#7D7D7D', fontWeight: '700' }}>نوع التقسيط:</span>
          <span style={{ color: '#111111', marginRight: '8px' }}>{installmentsType}</span>
        </div>
      </div>
    </div>
  );
};

export default ContractDetailsHeader;
