import React, { useEffect, useState } from 'react';
import './AddTransactionDetails.scss';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { fill_transaction } from '../../../redux/warehouse/warehouseActions';
import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';
import { Card } from 'primereact/card';

function AddIssuing1({ userId, fill_transaction, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const [warehouses, setWarehouses] = useState(null);
  const [destSrc, setDestSrc] = useState(null);
  const [transTypes, setTransTypes] = useState(null);
  const [destinations, setDestinations] = useState(null);
  const [selectedDestType, setSelectedDestType] = useState(null);
  const [selectedDest, setSelectedDest] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [transaction, setTransaction] = useState({
    transNb: 1,
    // transDateTime: '2024-02-19T20:21:00',
    approved: false,
    remarks: '',
    name: '',
    transTypeId: 1,
    warehouseId: null,
    destinationWarehouseId: null,
    sourceId: 1,
    destinationId: null,
    supplierId: null,
    customerId: null,
    employeeId: null,
    userId
  });

  useEffect(() => {
    axiosInstance
      .get(`/UserTypesTransDestSourcesMatrix/destinationsSourcesDropdown/1`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setDestSrc(tempList);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/Warehouse/dropdown`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setWarehouses(tempList);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/TransType/dropdown`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setTransTypes(tempList);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    switch (selectedDestType) {
      case 1:
        setDestinations(warehouses);
        break;
      case 2:
        axiosInstance
          .get(`/Supplier/dropdown`)
          .then((response) => {
            const tempList = response.data.data.map((item) => {
              return {
                id: item.id,
                name: item.name
              };
            });
            setDestinations(tempList);
          })
          .catch((error) => {});
        break;
      case 3:
        axiosInstance
          .get(`/Customer/dropdown`)
          .then((response) => {
            const tempList = response.data.data.map((item) => {
              return {
                id: item.id,
                name: item.name
              };
            });
            setDestinations(tempList);
          })
          .catch((error) => {});
        break;
      case 6:
        axiosInstance
          .get(`/Employee/dropdown`)
          .then((response) => {
            const tempList = response.data.data.map((item) => {
              return {
                id: item.id,
                name: item.name
              };
            });
            setDestinations(tempList);
          })
          .catch((error) => {});
        break;
      default:
        break;
    }
  }, [selectedDestType]);

  useEffect(() => {
    fill_transaction(transaction);
    if (
      !transaction.userId ||
      !transaction.name ||
      !transaction.warehouseId ||
      !transaction.destinationId ||
      (transaction.destinationId < 4 &&
        !transaction.customerId &&
        !transaction.supplierId &&
        !transaction.destinationWarehouseId &&
        !transaction.employeeId)
    ) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [transaction]);

  function handleAddInputChange(e) {
    setTransaction({ ...transaction, [e.target.name]: e.target.value || e.value });
  }

  function handleDestChange(e) {
    setSelectedDest(e.value);
    switch (selectedDestType) {
      case 1:
        setTransaction({ ...transaction, destinationWarehouseId: e.value });
        break;
      case 2:
        setTransaction({ ...transaction, supplierId: e.value });
        break;
      case 3:
        setTransaction({ ...transaction, customerId: e.value });
        break;
      case 6:
        setTransaction({ ...transaction, employeeId: e.value });
        break;
      default:
        break;
    }
  }

  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      <form className='transaction'>
        <div className='transaction__content'>
          <div className='transaction__content__right'>
            <span className='Title'>البيانات الاساسية للاذن</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                اسم اﻻذن<span style={Style}>*</span>
              </label>
              <InputText name='name' value={transaction.name} onChange={handleAddInputChange} />
            </div>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                نوع الاذن<span style={Style}>*</span>
              </label>
              <CustomDropdown
                value={transaction.transTypeId}
                options={transTypes}
                optionValue='id'
                optionLabel='name'
                disabled={true}
              />
            </div>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                المخزن<span style={Style}>*</span>
              </label>
              <CustomDropdown
                name='warehouseId'
                value={selectedWarehouse}
                onChange={(e) => {
                  setSelectedWarehouse(e.value);
                  handleAddInputChange(e);
                }}
                options={warehouses}
                optionValue='id'
                optionLabel='name'
                placeholder='اختر المخزن'
              />
            </div>
          </div>
          <div className='transaction__content__left'>
            <span className='Title'>بيانات المستقبل</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                نوع المستقبل<span style={Style}>*</span>
              </label>
              <CustomDropdown
                name='destinationId'
                value={selectedDestType}
                onChange={(e) => {
                  setSelectedDestType(e.value);
                  handleAddInputChange(e);
                }}
                options={destSrc}
                optionValue='id'
                optionLabel='name'
                placeholder='اختر نوع المستقبل'
              />
            </div>
            {selectedDestType < 7 && (
              <div className='flex w-full flex-column gap-4'>
                <label className='addtransaction__label'>
                  المستقبل<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  value={selectedDest}
                  onChange={handleDestChange}
                  options={destinations}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر المستقبل'
                />
              </div>
            )}
            <span className='Title'>بيانات المصدر</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                نوع المصدر<span style={Style}>*</span>
              </label>
              <CustomDropdown
                value={transaction.sourceId}
                options={destSrc}
                optionValue='id'
                optionLabel='name'
                disabled={true}
                onChange={() => {}}
              />
            </div>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                المصدر<span style={Style}>*</span>
              </label>
              <CustomDropdown
                value={selectedWarehouse}
                options={warehouses}
                optionValue='id'
                optionLabel='name'
                disabled={true}
                onChange={() => {}}
              />
            </div>
            <span className='Title'>اخرى</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>ملاحظات</label>
              <InputTextarea
                placeholder='ملاحظات عن اﻻذن بشكل عام'
                name='remarks'
                value={transaction.remarks}
                onChange={handleAddInputChange}
                rows={3}
              />
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.user.id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_transaction: (transaction) => dispatch(fill_transaction(transaction))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIssuing1);
