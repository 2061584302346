import React from 'react';
import { FormProps } from '../../../../types/FormProps';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';
import useFetch from '../../../../hooks/useFetch';

const GovernrateInstallmentsTypeForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('governorateId') != null;

  const { data: governrateData, isLoading: isGovernrateLoading } = isEditMode
    ? useFetch('/Governorate/dropdown')
    : useFetch('/Governorate/allNonRestrictedDropdown');
  const { data: installmentsTypeData, isLoading: isInstallmentTypeLoading } = useFetch('/InstallmentsType/dropdown');
  return (
    <>
      <CustomFormField
        name={'governorateId'}
        label={isEditMode ? 'اسم المحافظة' : 'اختر المحافظة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={governrateData?.data ?? []}
        disabled={isEditMode ? true : isGovernrateLoading}
      />

      <CustomFormField
        name={'listOfInstallmentTypeId'}
        label={'اختر نوع(انواع) التقسيطات'}
        control={control}
        errors={errors}
        optionValue='id'
        optionLabel='name'
        placeholder='ابحث عن طرق التقسيط'
        type={'multi-select'}
        options={installmentsTypeData?.data ?? []}
      />
    </>
  );
};

export default GovernrateInstallmentsTypeForm;
