import React, { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chip } from 'primereact/chip';
import HeaderCard from '../../../components/Cards/Home header/HeaderCard';
import './CustomersDetails.scss';
import CustomerDetailsTables from './CustomerDetailsTables';
import * as yup from 'yup';

const customerDeviceSheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  customerId: yup.number().required('Customer is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

function CustomersDetailsContent({ customer }: { customer: any | null }) {
  const params = useParams();

  return (
    <>
      <div className='customerdetails'>
        <div className='customerdetails__header__card'>
          <HeaderCard
            title={'الاقساط المطلوبة'}
            num={`${customer?.numberOfRemainingInstallment} قسط`}
            imageUrl={'/images/dollar.svg'}
            color={'#F9D8D4'}
            txtColor={'red'}
            percent={undefined}
            chartImage={undefined}
          />
          <HeaderCard
            title={'الاقساط المدفوعة'}
            num={`${customer?.sumOfPaidInstallment} ج.م`}
            imageUrl={'/images/coins.svg'}
            color={'#6D54DE33'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
          <HeaderCard
            title={'عدد الاجهزة'}
            num={`${customer?.numberOfDevices} جهاز`}
            imageUrl={'/images/drop_box.svg'}
            color={'#FFEDD0'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
          <HeaderCard
            title={'شكاوى العميل'}
            num={`${customer?.numberOfComplaints} شكوة`}
            imageUrl={'/images/Missed.svg'}
            color={'#C050E838'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
        </div>

        <div className='customerdetails__content customerdetails__content--wrapper'>
          {/*=========================================== right content ================================================================= */}
          <div className='customerdetails__content__right'>
            <div className='customerdetails__content__right__image'>
              <img src='/images/user.svg'></img>
            </div>

            <div className='customerdetails__content__right__customer__name'>{customer?.fullName}</div>

            <div className='primary__data'>
              <span className='primary__data--label'> كود العميل: </span>
              <span className='primary__data--link'>{customer?.code}</span>
            </div>

            <div className='primary__data'>
              <span className='primary__data--label'>حالة المنتج: </span>
              <Chip label={customer?.status?.name} />
            </div>

            <span className='primary__data--title mt-4'>
              بيانات العميل<img src='/images/2.svg'></img>
            </span>

            <div className='primary__data'>
              <span className='primary__data--label'> الرقم القومي: </span>
              <span className='primary__data--text'>{customer?.nationalNb}</span>
            </div>

            <div className='primary__data'>
              <span className='primary__data--label'> الوظــــــــيفــــــــة: </span>
              <span className='primary__data--text'>{customer?.jobTitle}</span>
            </div>
            <div className='primary__data'>
              <span className='primary__data--label'> العنوان الرئيسي: </span>
              <span className='primary__data--text'>{customer?.defaultAddress?.name}</span>
            </div>
            <div className='primary__data'>
              <span className='primary__data--label'> تاريخ الإضافة: </span>
              <span className='primary__data--text'>{customer?.creationDateTime}</span>
            </div>
            <div className='primary__data mt-4'>
              <span className='primary__data--title'>الاجهزة المربوطة</span>
              <Chip label={`${customer?.numberOfDevices}اجهزة`} />
            </div>
          </div>

          {/*=========================================== left content ================================================================= */}
          <div className='customerdetails__content__left'>
            <span className='primary__data--title mt-4'>
              مواقع العميل <img src='/images/2.svg'></img>
            </span>
            <div className='primary__data'>
              <span className='primary__data--label'> موقع 1 : </span>
              <span className='primary__data--text'>{customer?.defaultAddress?.name}</span>
            </div>
            <div className='primary__data'>
              <span className='primary__data--label'> الموقع : </span>
              <span className='primary__data--text'>{customer?.defaultAddress?.locationInDetails}</span>
            </div>
            <div className='primary__data'>
              <span className='primary__data--label'> العنوان : </span>
              <span className='primary__data--text'>{customer?.defaultAddress?.address}</span>
            </div>
            <span className='primary__data--title mt-4'>
              الملاحظات <img src='/images/2.svg'></img>
            </span>
            {customer?.remarks == ' ' && (
              <span className='primary__data--text'>
                هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض
                النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسومادر أو الكلمات العشوائية إلى
                النص. إن كنت تريد أن تستخدم نص لوريم إيبسادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم{' '}
              </span>
            )}

            {customer?.remarks != '' && <span className='primary__data--text'>{customer?.remarks}</span>}

            <span className='primary__data--title mt-4'>
              بيانات التواصل <img src='/images/2.svg'></img>
            </span>
            <div className='primary__data'>
              <span className='primary__data--label'>طريقة التواصل : </span>
              <span className='primary__data--text'>{customer?.preferredContactMethod?.name}</span>
            </div>
            <div className='primary__data'>
              <span className='primary__data--label'> رقم الهاتف : </span>
              <span className='primary__data--text'>{customer?.phoneNb}</span>
            </div>
            <div className='primary__data'>
              <span className='primary__data--label'> رقم الموبايل : </span>
              <span className='primary__data--text'>{customer?.mobile}</span>
            </div>
            <div className='primary__data'>
              <span className='primary__data--label'> رقم الواتساب : </span>
              <span className='primary__data--text'>{customer?.whatsappNb}</span>
            </div>
          </div>
        </div>

        {/*=========================================== tables content ================================================================= */}
        <CustomerDetailsTables customerID={params.id as string} />
      </div>
    </>
  );
}

export default CustomersDetailsContent;
