import update from 'immutability-helper';
import {
  FETCH_INSTALLMENTVISIT_REQUEST,
  FETCH_INSTALLMENTVISIT_SUCCESS,
  FETCH_INSTALLMENTVISIT_FAILURE,
  ADD_INSTALLMENTVISIT_REQUEST,
  ADD_INSTALLMENTVISIT_SUCCESS,
  ADD_INSTALLMENTVISIT_FAILURE,
  EDIT_INSTALLMENTVISIT_REQUEST,
  EDIT_INSTALLMENTVISIT_SUCCESS,
  EDIT_INSTALLMENTVISIT_FAILURE,
  CHANGE_INSTALLMENTVISIT_STATE,
  CHANGE_INSTALLMENTVISIT_TEMP_STATE,
  CLEAR_INSTALLMENTVISIT_STATE
} from './visitTypes';
import { date } from 'yup';

const initialState = {
  visitInstallments: {
    visitId: '',
    installmentDetails: [],
    remarks: ''
  },
  visitInstallmentsData: {
    visitId: '',
    installmentDetails: [],
    remarks: ''
  },
  visitInstallmentTemp: {
    date: []
  },
  loading: false,
  error: ''
};

const visitReducers1 = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTALLMENTVISIT_REQUEST:
    case ADD_INSTALLMENTVISIT_REQUEST:
    case EDIT_INSTALLMENTVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_INSTALLMENTVISIT_SUCCESS:
      return {
        ...state,
        visitInstallmentsData: action.payload,
        loading: false,
        error: ''
      };
    case FETCH_INSTALLMENTVISIT_FAILURE:
    case ADD_INSTALLMENTVISIT_FAILURE:
    case EDIT_INSTALLMENTVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ADD_INSTALLMENTVISIT_SUCCESS:
    case EDIT_INSTALLMENTVISIT_SUCCESS:
      return {
        ...state,
        visitInstallments: action.payload,
        loading: false,
        error: ''
      };
    case CHANGE_INSTALLMENTVISIT_STATE:
      return update(state, {
        $merge: {
          visitInstallments: {
            ...state.visitInstallments,
            ...action.payload
          }
        }
      });

    case CHANGE_INSTALLMENTVISIT_TEMP_STATE:
      return update(state, {
        $merge: {
          visitInstallmentTemp: {
            ...state.visitInstallmentTemp,
            ...action.payload
          }
        }
      });

    case CLEAR_INSTALLMENTVISIT_STATE:
      return {
        ...state,
        visitInstallments: initialState.visitInstallments,
        visitInstallmentTemp: initialState.visitInstallmentTemp
      };

    default:
      return state;
  }
};

export default visitReducers1;
