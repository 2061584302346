import React, { useMemo } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { useNavigate } from 'react-router-dom';

const getContractScreenColumns = () => [
  { header: 'الجهاز', field: 'productName', isChip: false },
  { header: 'تاريخ التعاقد', field: 'contractDate', isChip: false },
  { header: 'حالة العقد', field: 'statusName', isChip: true }
];

function MaintenanceContractScreen() {
  const navigate = useNavigate();
  const columns = useMemo(getContractScreenColumns, []);
  const apiUrl = '/Contract/list';
  const addUrl = `/addContract`;

  const tabs = [
    {
      tableHeader: 'قائمة عقود الصيانة',
      label: 'قائمة عقود الصيانة',
      dataKey: 'tab1',
      entityName: 'العقد',
      columns: columns,
      apiUrl: apiUrl,
      edit: false,
      emptyStateUrl: addUrl,
      isHidden: true,
      rowOnClick: (rowData: any) => {
        navigate(`/contract/details`, { state: { contractId: rowData.contractID, customerId: rowData.customerID } });
      },
      headerButtons: [
        {
          label: 'إضافة عقد صيانه جديدة',
          onClick: () => {
            navigate(addUrl);
            navigate(`/addContract`, {
              state: {
                contractType: 'Company',
              }
            });
          }
        }
      ],
      extraParams: {
        type: 'Company'
      }
    }
  ];


  return (
    <>
      <GenericPage
        tabs={tabs}
        headerTitle='العقود'
        headerIcon='/images/file.svg'
      />
    </>
  );
}

export default MaintenanceContractScreen;
