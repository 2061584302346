import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../context/ToastContext';
import GenericPage from '../../components/components/GenericPage/GenericPage';
import GenericModal from '../../components/components/Modal/GenericModal';
import useModal from '../../hooks/useModal';
import * as yup from 'yup';
import WarehouseSectionsForm from './Forms/WarehouseSectionsForm';
import WarehouseLocationsForm from './Forms/WarehouseLocationsForm';

const WarehouseColumns = [
  { header: 'كود المخزن', field: 'code', isChip: false },
  { header: 'اسم المخزن', field: 'name', isChip: false },
  { header: 'نوع المخزن', field: 'wHTypeName', isChip: false },
  { header: 'الحالة', field: 'statusName', isChip: true },
  { header: 'رقم الهاتف', field: 'contactDetails', isChip: false },
  { header: 'العنوان', field: 'address', isChip: false }
];
const WarehouseSectionColumns = [
  { header: 'اقسام المخازن', field: 'name', isChip: false },
  { header: 'اسم المخزن', field: 'wHName', isChip: false },
  { header: 'حالة قسم المخزن', field: 'statusName', isChip: true },
  { header: 'كود قسم المخزن', field: 'code', isChip: false }
];
const WarehouseLocationColumns = [
  { header: 'مواقع التخزين', field: 'name', isChip: false },
  { header: 'حالة مواقع التخزين', field: 'statusName', isChip: true },
  { header: 'قسم المخزن', field: 'wHSectionName', isChip: false },
  { header: 'كود الPlant', field: 'plantCode', isChip: false },
  { header: 'كود موقع المخزن', field: 'code', isChip: false },
  { header: 'اسم المخزن', field: 'wHName', isChip: false }
];

const sectionsSchema = yup.object().shape({
  warehouseId: yup.string().required('اسم المخزن مطلوب'),
  name: yup.string().required('اسم قسم المخزن مطلوب'),
  remarks: yup.string().notRequired(),
  statusId: yup.string().required('حالة قسم المخزن مطلوبة')
  // complaintDate: yup.date().required('تاريخ الشكوى مطلوب'),
});

const locationsSchema = yup.object().shape({
  warehouseId: yup.string().required('اسم المخزن مطلوب'),
  warehouseSectionId: yup.string().required('قسم المخزن مطلوب'),
  name: yup.string().required('اسم قسم المخزن مطلوب'),
  plantCode: yup.string().required('كود ال plant مطلوب'),
  remarks: yup.string().notRequired(),
  statusId: yup.string().required('حالة قسم المخزن مطلوبة')
});

function Warehouses() {
  const toast = useToast();
  const navigate = useNavigate();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: iswarehouseSectionsVisitable,
    openModal: warehouseSectionsOpenModal,
    closeModal: warehouseSectionsCloseModal,
    control: warehouseSectionsControl,
    handleSubmit: warehouseSectionsSubmit,
    errors: warehouseSectionsErrors,
    setValue: warehouseSectionsSetValue,
    loading: warehouseSectionsLoading,
    setLoading: warehouseSectionsSetLoading,
    watch: warehouseSectionsWatch
  } = useModal(
    {
      warehouseId: '',
      code: '',
      statusId: '',
      remarks: ''
    },
    '/WarehousesSection',
    fetchTabData,
    sectionsSchema,
    toast
  );

  const {
    isVisible: iswarehouseLocationsVisitable,
    openModal: warehouseLocationsOpenModal,
    closeModal: warehouseLocationsCloseModal,
    control: warehouseLocationsControl,
    handleSubmit: warehouseLocationsSubmit,
    errors: warehouseLocationsErrors,
    setValue: warehouseLocationsSetValue,
    loading: warehouseLocationsLoading,
    setLoading: warehouseLocationsSetLoading,
    watch: warehouseLocationsWatch
  } = useModal(
    {
      warehouseId: '',
      code: '',
      statusId: '',
      remarks: ''
    },
    '/WarehousesLocation',
    fetchTabData,
    locationsSchema,
    toast
  );

  const tab1 = {
    label: 'المخازن',
    tableHeader: 'قائمة اقسام المخازن',
    entityName: 'المخزن',
    dataKey: 'tab1',
    columns: WarehouseColumns,
    apiUrl: '/Warehouse/list',
    isHidden: false,
    edit: false,
    Screentype: '',
    emptyStateUrl: `/warehouse/add`,
    rowOnClick: (rowData: any) => {
      navigate(`/warehouse/details/${rowData.id}`);
    },
    extraParams: {},
    emptyStateButtonClick: undefined,
    headerButtons: [
      {
        label: 'إضافة مخزن جديد',
        onClick: () => {
          navigate(`/warehouse/add`);
        }
      }
    ]
  };
  const tab2 = {
    label: 'اقسام المخازن',
    tableHeader: 'قائمة اقسام المخازن',
    entityName: ' قسم المخزن',
    dataKey: 'tab2',
    columns: WarehouseSectionColumns,
    apiUrl: 'WarehousesSection/list',
    isHidden: false,
    edit: true,
    Screentype: '',
    emptyStateUrl: '',
    rowOnClick: (rowData: any) => {
      warehouseSectionsOpenModal({
        id: rowData.id,
        code: rowData.code,
        name: rowData.name,
        warehouseId: rowData.whid,
        statusId: rowData.statusID,
        remarks: rowData.remarks
      });
    },
    extraParams: {},
    emptyStateButtonClick: undefined,
    headerButtons: [
      {
        label: 'إضافة اقسام مخازن جديدة',
        onClick: () => {
          warehouseSectionsOpenModal();
        }
      }
    ]
  };
  const tab3 = {
    label: 'مواقع المخازن',
    tableHeader: 'قائمة مواقع المخازن',
    entityName: ' موقع المخزن',
    dataKey: 'tab3',
    columns: WarehouseLocationColumns,
    apiUrl: 'WarehousesLocation/list',
    isHidden: false,
    edit: true,
    Screentype: '',
    emptyStateUrl: '',
    rowOnClick: (rowData: any) => {
      warehouseLocationsOpenModal({
        id: rowData.id,
        code: rowData.code,
        name: rowData.name,
        warehouseId: rowData.whid,
        warehouseSectionId: rowData.wHSectionID,
        plantCode: rowData.plantCode,
        statusId: rowData.statusID,
        remarks: rowData.remarks
      });
    },
    extraParams: {},
    emptyStateButtonClick: undefined,
    headerButtons: [
      {
        label: 'إضافة موقع مخزن جديد',
        onClick: () => {
          warehouseLocationsOpenModal();
        }
      }
    ]
  };

  return (
    <div className='w-full'>
      <GenericPage
        ref={genericPageRef}
        tabs={[tab1, tab2, tab3]}
        headerTitle='المخازن'
        headerIcon='/images/boxes1.svg'
      />

      <GenericModal
        headerTitle={warehouseSectionsWatch('id') === undefined ? 'اضافه قسم مخزن جديد' : 'تعديل قسم مخزن'}
        isVisible={iswarehouseSectionsVisitable}
        onHide={warehouseSectionsCloseModal}
        handleSubmit={warehouseSectionsSubmit}
        toast={toast}
        loading={warehouseSectionsLoading}
        isEditMode={warehouseSectionsWatch('id') !== undefined}
      >
        <WarehouseSectionsForm
          control={warehouseSectionsControl}
          errors={warehouseSectionsErrors}
          setValue={warehouseSectionsSetValue}
          watch={warehouseSectionsWatch}
          extraProps={{ entityName: 'قسم' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={warehouseLocationsWatch('id') === undefined ? 'اضافه موقع مخزن جديد' : 'تعديل موقع مخزن'}
        isVisible={iswarehouseLocationsVisitable}
        onHide={warehouseLocationsCloseModal}
        handleSubmit={warehouseLocationsSubmit}
        toast={toast}
        loading={warehouseLocationsLoading}
        isEditMode={warehouseLocationsWatch('id') !== undefined}
      >
        <WarehouseLocationsForm
          control={warehouseLocationsControl}
          errors={warehouseLocationsErrors}
          setValue={warehouseLocationsSetValue}
          watch={warehouseLocationsWatch}
          extraProps={{ entityName: 'موقع' }}
        />
      </GenericModal>
    </div>
  );
}

export default Warehouses;
