import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../style/Tables.scss';

function EmployeesWorkingGroupDetailsTable({ items }) {
  function handleSearchNameChange(e) {}

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>قائمة الموظفين المضافين على مجموعة العمل</span>

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      <DataTable
        value={items}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        dataKey='id'
        className='tbl__table'
      >
        <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
        <Column header='الموظف' field='name' />
        <Column header='الدور' body={(rowData) => <Chip label={rowData.position} />} />
        <Column header='تاريخ الانضمام' field='hiringDate' />
        <Column
          header='خيارات'
          body={(rowData) => (
            <Link to={`/employees/details/${rowData.id}`}>
              <Button label='تفاصيل الموظف' icon='pi pi-exclamation-circle' severity='secondary' text />
            </Link>
          )}
        />
      </DataTable>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    userId: state.user.user.id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesWorkingGroupDetailsTable);
