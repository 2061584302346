import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function EvaluationScreenHistory() {
  const navigate = useNavigate();

  const headerDetails = [
    {
      title: 'المتابعات المنتظرة',
      num: '40',
      imageUrl: '/images/sandclock.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/down2.svg',
      txtColor: 'red',
      color: '#F9D8D433'
    },
    {
      title: 'الزيارات المتأخرة',
      num: '30',
      imageUrl: '/images/altsleep.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/Up.svg',
      color: '#6D54DE33'
    },
    {
      title: 'تقيمات الاداء',
      num: '20',
      imageUrl: '/images/check.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/down2.svg',
      txtColor: 'red',
      color: '#FFEDD0'
    },
    {
      title: 'الزيارات الغير مراجعة',
      num: '50',
      imageUrl: '/images/briefcase.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/Up.svg',
      color: '#C050E838'
    }
  ];

  const columnsTab1 = [
    {
      header: 'اسم العميل',
      field: 'customerName',
      isChip: false
    },

    {
      header: 'الموظف',
      field: 'employeeName',
      isChip: false
    },
    { header: 'التقييم', field: 'evaluationsType', isChip: false },
    { header: 'التاريخ', field: 'date', isDate: true },
    {
      header: 'النوع',
      field: 'type',
      isChip: true
    }
  ];

  return (
    <GenericPage
      tabs={[
        {
          isHidden: false,
          label: 'مراجعة الكل',
          tableHeader: 'سجل مراجعات الاداء',
          dataKey: 'الكل',
          emptyStateButtonClick: () => {},
          apiUrl: 'Revision/list/completedRevisions',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'التفاصيل',
          rowOnClick: (rowData) => {
            navigate(`/customerService/details`, {
              state: {
                visitId: rowData.entityId,
                activityId: rowData.entityId,
                complaintId: rowData.entityId,
                revesionId: rowData.revisionId,
                customerId: rowData.customerId,
                type: rowData.type,
                completed: true
              }
            });
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'مراجعة الزيارت',
          tableHeader: 'سجل مراجعات الاداء',
          dataKey: 'الزيارت',
          emptyStateButtonClick: () => {},
          apiUrl: 'Revision/list/completedVisitRevisions',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'التفاصيل',
          rowOnClick: (rowData) => {
            navigate(`/customerService/details`, {
              state: {
                visitId: rowData.entityId,
                revesionId: rowData.revisionId,
                customerId: rowData.customerId,
                type: rowData.type,
                completed: true
              }
            });
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'مراجعة الشكاوى',
          tableHeader: 'سجل مراجعات الاداء',
          dataKey: 'الشكاوى',
          emptyStateButtonClick: () => {},
          apiUrl: 'Revision/list/completedComplaintRevisions',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'التفاصيل',
          rowOnClick: (rowData) => {
            navigate(`/customerService/details`, {
              state: {
                complaintId: rowData.entityId,
                revesionId: rowData.revisionId,
                customerId: rowData.customerId,
                type: rowData.type,
                completed: true
              }
            });
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'مراجعة الاتصالات',
          tableHeader: 'سجل مراجعات الاداء',
          dataKey: 'الاتصالات',
          emptyStateButtonClick: () => {},
          apiUrl: 'Revision/list/completedActivityRevisions',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'التفاصيل',
          rowOnClick: (rowData) => {
            navigate(`/customerService/details`, {
              state: {
                activityId: rowData.entityId,
                revesionId: rowData.revisionId,
                customerId: rowData.customerId,
                type: rowData.type,
                completed: true
              }
            });
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        }
      ]}
      headerTitle={'خدمة العملاء'}
      headerIcon={'../images/headPhonesAlt.svg'}
      analyticsCards={headerDetails}
      cardsNum='4'
    />
  );
}

export default EvaluationScreenHistory;
