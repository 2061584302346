import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { axiosInstance } from '../../../global';
import '../Details/ProductsDetails.scss';
import ServicesEditDetailsTable from '../../../components/Tables/ServicesEditDetailsTable';
import { useToast } from '../../../context/ToastContext';

function ServicesListEditDetails({ token }) {
  const toast = useToast();
  const Style2 = { color: 'red' };

  const params = useParams();
  const navigate = useNavigate();

  function showConfirmDeleteProduct(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف القائمة',
      header: 'تأكيد حذف القائمة',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteProduct,
      draggable: false,
      resizable: false
    });
  }

  function handleDeleteProduct(e) {
    axiosInstance
      .delete(`/ProductsPricesList/${params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حذف القائمة',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/products'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف القائمة',
          detail: '',
          life: 3000
        });
      });
  }

  return (
    <div className=' main w-full'>
      <div className='details__header1'>
        <div className='details__header1__right'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='details__header1__right--text'> قائمة خدمات</span>
        </div>

        <div className='details__header__left'>
          <Button
            className='details__header1__left__btn'
            onClick={showConfirmDeleteProduct}
            icon='pi pi-trash'
            style={Style2}
            text
          >
            حذف القائمة
          </Button>
        </div>
      </div>
      <div className='mt-5 mr-2 ml-2'>
        <ServicesEditDetailsTable serviceID={params.id} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesListEditDetails);
