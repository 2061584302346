import { FC } from 'react';
import { Card } from 'primereact/card';

export interface ContractDeviceHeaderProps {
  customerName: string;
  phoneNumber: string;
  skuNumber: string;
  productName: string;
}

const ContractDeviceHeader: FC<ContractDeviceHeaderProps> = ({ customerName, skuNumber, productName, phoneNumber }) => {
  return (
      <div style={{ backgroundColor: '#EAEAEA', borderRadius: '8px', border: '1px', padding: '12px' }}>
        <label
          style={{
            fontFamily: 'IBM Plex Sans Arabic',
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '36px',
            textAlign: 'right',
            color: '#111111'
          }}
        >
          تفاصيل الجهاز
        </label>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ marginBottom: '0.5rem', color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
            <span style={{ color: '#7D7D7D', fontWeight: '700' }}>العميل:</span>
            <span style={{ color: '#111111', marginRight: '8px' }}>{customerName}</span>
          </div>
          <div style={{ marginBottom: '0.5rem', color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
            <span style={{ color: '#7D7D7D', fontWeight: '700' }}>رقم الهاتف:</span>
            <span style={{ color: '#111111', marginRight: '8px' }}>{phoneNumber}</span>
          </div>
          <div style={{ marginBottom: '0.5rem', color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
            <span style={{ color: '#7D7D7D', fontWeight: '700' }}>رقم المنتج (SKU):</span>
            <span style={{ color: '#111111', marginRight: '8px' }}>{skuNumber}</span>
          </div>
          <div style={{ color: '#7D7D7D', fontSize: '14px', fontWeight: '400' }}>
            <span style={{ color: '#7D7D7D', fontWeight: '700' }}>الجهاز المراد بيعه:</span>
            <span style={{ color: '#111111', marginRight: '8px' }}>{productName}</span>
          </div>
        </div>
      </div>
  );
};

export default ContractDeviceHeader;
