import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import GenericModal from '../../../components/components/Modal/GenericModal';
import useModal from '../../../hooks/useModal';
import * as yup from 'yup';
import PayrollForm from './Form/PayrollForm';
import { useToast } from '../../../context/ToastContext';

const addPayrollScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  employeeId: yup.number().required().default(null),
  payrollItemId: yup.number().required().default(null),
  amount: yup.number().required().default(null),
  effectiveDate: yup.date().required().default(null),
  endDate: yup.date().required().default(null),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

export default function PayrollList() {
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const toast = useToast();

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const { isVisible, openModal, closeModal, control, handleSubmit, errors, setValue, loading, setLoading, watch } =
    useModal({}, '/EmployeesPayrollItem', fetchTabData, addPayrollScheme, toast);

  return (
    <>
      <GenericPage
        tabs={[
          {
            label: 'الموظفين',
            headerButtons: [
              {
                label: 'اضافه مرتب',
                onClick: () => {
                  openModal();
                }
              }
            ],
            tableHeader: 'مرتبات الموظفين',
            isHidden: true,
            rowOnClick: (rowData) => {
              openModal({
                id: rowData.id,
                employeeId: rowData.employeeID,
                payrollItemId: rowData.payrollItemID,
                amount: rowData.amount,
                effectiveDate: new Date(rowData.effectiveDate),
                endDate: new Date(rowData.endDate),
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'EmployeesPayrollItem/list',
            emptyStateButtonClick: () => {},
            dataKey: 'EmployeesPayrollItem',
            edit: true,
            entityName: 'مرتب',
            emptyStateUrl: '',
            columns: [
              { header: 'اسم الموظف', field: 'employeeName', isChip: true },
              { header: 'تاريخ بدايه التنفيذ', field: 'effectiveDate', isChip: false },
              { header: 'نوع الاضافة', field: 'payrollItemName', isChip: false },
              { header: 'القيمة', field: 'amount', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ]
          }
        ]}
        headerTitle={'الموظفين'}
        headerIcon={'/images/employee.svg'}
        ref={genericPageRef}
      />
      <GenericModal
        isEditMode={watch('id') !== undefined}
        headerTitle={watch('id') === undefined ? 'اضافه مرتب' : 'تعديل مرتب'}
        isVisible={isVisible}
        onHide={closeModal}
        handleSubmit={handleSubmit}
        toast={toast}
        loading={loading}
      >
        <PayrollForm control={control} errors={errors} setValue={setValue} watch={watch} />
      </GenericModal>
    </>
  );
}
