import React, { useRef } from 'react';
import './Client.scss';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
import { genericSchemeForNames } from '../../../components/components/Form/Scheme';
import GenericForm from '../../../components/Forms/GenericForm';
import GenericModal from '../../../components/components/Modal/GenericModal';
import * as yup from 'yup';
import ComplaintsFamilyForm from './Forms/ComplaintsFamilyForm';
import ComplaintsCategoryForm from './Forms/ComplaintsCategoryForm';
import { useToast } from '../../../context/ToastContext';

const complaintsFamilyScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  complaintTypeId: yup.number().required('Type is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});
const complaintsCategoryScheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  complaintFamilyId: yup.number().required('Family is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

function Client() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isComplaintsTypeVisitable,
    openModal: complaintsTypeOpenModal,
    closeModal: complaintsTypeCloseModal,
    control: complaintsTypeControl,
    handleSubmit: complaintsTypeSubmit,
    errors: complaintsTypeErrors,
    setValue: complaintsTypeSetValue,
    loading: complaintsTypeLoading,
    setLoading: complaintsTypeSetLoading,
    watch: complaintsTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/ComplaintsType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isComplaintsFamilyVisitable,
    openModal: complaintsFamilyOpenModal,
    closeModal: complaintsFamilyCloseModal,
    control: complaintsFamilyControl,
    handleSubmit: complaintsFamilySubmit,
    errors: complaintsFamilyErrors,
    setValue: complaintsFamilySetValue,
    loading: complaintsFamilyLoading,
    setLoading: complaintsFamilySetLoading,
    watch: complaintsFamilyWatch
  } = useModal({ remarks: ' ', names: [] }, '/ComplaintsFamily', fetchTabData, complaintsFamilyScheme, toast);

  const {
    isVisible: isComplaintsCategoryVisitable,
    openModal: complaintsCategoryOpenModal,
    closeModal: complaintsCategoryCloseModal,
    control: complaintsCategoryControl,
    handleSubmit: complaintsCategorySubmit,
    errors: complaintsCategoryErrors,
    setValue: complaintsCategorySetValue,
    loading: complaintsCategoryLoading,
    setLoading: complaintsCategorySetLoading,
    watch: complaintsCategoryWatch
  } = useModal({ remarks: ' ', names: [] }, '/ComplaintsCategory', fetchTabData, complaintsCategoryScheme, toast);

  const {
    isVisible: isContactMethodVisitable,
    openModal: contactMethodOpenModal,
    closeModal: contactMethodCloseModal,
    control: contactMethodControl,
    handleSubmit: contactMethodSubmit,
    errors: contactMethodErrors,
    setValue: contactMethodSetValue,
    loading: contactMethodLoading,
    setLoading: contactMethodSetLoading,
    watch: contactMethodWatch
  } = useModal({ remarks: ' ', names: [] }, '/ContactMethod', fetchTabData, genericSchemeForNames, toast);

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'انواع الشكاوي',
            entityName: 'نوع الشكوي',
            tableHeader: 'جدول الشكاوي',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              complaintsTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'ComplaintsType/list',
            columns: [
              { header: 'اسم الشكوي', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            dataKey: 'ComplaintsType',
            emptyStateButtonClick: () => {
              complaintsTypeOpenModal();
            },
            headerButtons: [
              {
                label: 'اضافة نوع شكوي',
                onClick: () => {
                  complaintsTypeOpenModal();
                }
              }
            ]
          },
          {
            label: 'انواع انواع الشكاوي',
            entityName: 'نوع انواع الشكوي',
            tableHeader: 'جدول انواع الشكاوي',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              complaintsFamilyOpenModal({
                id: rowData.id,
                name: rowData.name,
                complaintTypeId: rowData.complaintTypeID,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'ComplaintsFamily/list',
            columns: [
              { header: 'اسم نوع الشكوي', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            dataKey: 'ComplaintsFamily',
            emptyStateButtonClick: () => {
              complaintsFamilyOpenModal();
            },
            headerButtons: [
              {
                label: 'اضافة نوع شكوي',
                onClick: () => {
                  complaintsFamilyOpenModal();
                }
              }
            ]
          },
          {
            label: 'انواع تصنيفات الشكاوي',
            entityName: 'نوع تصنيفات الشكوي',
            tableHeader: 'جدول تصنيفات الشكاوي',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              complaintsCategoryOpenModal({
                id: rowData.id,
                name: rowData.name,
                complaintTypeId: rowData.complaintTypeID,
                complaintFamilyId: rowData.complaintFamilyID,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'ComplaintsCategory/list',
            columns: [
              { header: 'اسم تصنيف الشكوي', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            dataKey: 'ComplaintsCategory',
            emptyStateButtonClick: () => {
              complaintsCategoryOpenModal();
            },
            headerButtons: [
              {
                label: 'اضافة تصنيف شكوي',
                onClick: () => {
                  complaintsCategoryOpenModal();
                }
              }
            ]
          },
          {
            label: 'بيانات التواصل',
            entityName: 'بيان التواصل',
            tableHeader: 'جدول بيانات التواصل',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              contactMethodOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            apiUrl: 'ContactMethod/list',
            columns: [
              { header: 'اسم بيان التواصل', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            dataKey: 'ContactMethod',
            emptyStateButtonClick: () => {
              contactMethodOpenModal();
            },
            headerButtons: [
              {
                label: 'اضافة بيان تواصل',
                onClick: () => {
                  contactMethodOpenModal();
                }
              }
            ]
          }
        ]}
        headerTitle={'بيانات العملاء'}
        headerIcon={'/images/database.svg'}
      />

      <GenericModal
        headerTitle={complaintsTypeWatch('id') === undefined ? 'اضافه فئات شكاوي جديده' : 'تعديل فئه الشكوي'}
        isVisible={isComplaintsTypeVisitable}
        onHide={complaintsTypeCloseModal}
        handleSubmit={complaintsTypeSubmit}
        toast={toast}
        loading={complaintsTypeLoading}
        isEditMode={complaintsTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={complaintsTypeControl}
          errors={complaintsTypeErrors}
          setValue={complaintsTypeSetValue}
          watch={complaintsTypeWatch}
          extraProps={{ entityName: 'فئه الشكوي' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={complaintsFamilyWatch('id') === undefined ? 'اضافه انواع شكاوي جديده' : 'تعديل نوع الشكوي'}
        isVisible={isComplaintsFamilyVisitable}
        onHide={complaintsFamilyCloseModal}
        handleSubmit={complaintsFamilySubmit}
        toast={toast}
        loading={complaintsFamilyLoading}
        isEditMode={complaintsFamilyWatch('id') !== undefined}
      >
        <ComplaintsFamilyForm
          control={complaintsFamilyControl}
          errors={complaintsFamilyErrors}
          setValue={complaintsFamilySetValue}
          watch={complaintsFamilyWatch}
          extraProps={{ entityName: 'نوع الشكوي' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={complaintsCategoryWatch('id') === undefined ? 'اضافه تصنيفات شكاوي جديده' : 'تعديل تصنيف الشكوي'}
        isVisible={isComplaintsCategoryVisitable}
        onHide={complaintsCategoryCloseModal}
        handleSubmit={complaintsCategorySubmit}
        toast={toast}
        loading={complaintsCategoryLoading}
        isEditMode={complaintsCategoryWatch('id') !== undefined}
      >
        <ComplaintsCategoryForm
          control={complaintsCategoryControl}
          errors={complaintsCategoryErrors}
          setValue={complaintsCategorySetValue}
          watch={complaintsCategoryWatch}
          extraProps={{ entityName: 'تصنيف الشكوي' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={contactMethodWatch('id') === undefined ? 'اضافه بيانات تواصل جديده' : 'تعديل بيان التواصل'}
        isVisible={isContactMethodVisitable}
        onHide={contactMethodCloseModal}
        handleSubmit={contactMethodSubmit}
        toast={toast}
        loading={contactMethodLoading}
        isEditMode={contactMethodWatch('id') !== undefined}
      >
        <GenericForm
          control={contactMethodControl}
          errors={contactMethodErrors}
          setValue={contactMethodSetValue}
          watch={contactMethodWatch}
          extraProps={{ entityName: 'بيان التواصل' }}
        />
      </GenericModal>
    </>
  );
}

export default Client;
