import update from 'immutability-helper';

import {
  FETCH_CONTRACT_REQUEST,
  FETCH_CONTRACT_SUCCESS,
  FETCH_CONTRACT_FAILURE,
  ADD_CONTRACT_REQUEST,
  ADD_CONTRACT_SUCCESS,
  ADD_CONTRACT_FAILURE,
  EDIT_CONTRACT_REQUEST,
  EDIT_CONTRACT_SUCCESS,
  EDIT_CONTRACT_FAILURE,
  CHANGE_CONTRACT_STATE,
  CLEAR_CONTRACT_STATE
} from './contractTypes';

const initialState = {
  contract: {
    id: '',
    customerDeviceId: '',
    code: '',
    typeId: '',
    customerActivityId: '',
    reference: '',
    contractDate: '',
    installmentsTypeId: '',
    sellingPrice: '',
    downPayment: '',
    nbOfInstallments: '',
    installmentAmount: '',
    freeInstallation: '',
    expiryDate: '',
    commission: '',
    beneficiaryEmployeeId: '',
    freezeDate: '',
    freezedByUserId: '',
    statusId: '',
    userId: '',
    remarks: ''
  },
  loading: false,
  error: ''
};

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTRACT_REQUEST:
    case ADD_CONTRACT_REQUEST:
    case EDIT_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contract: action.payload,
        error: ''
      };
    case FETCH_CONTRACT_FAILURE:
    case ADD_CONTRACT_FAILURE:
    case EDIT_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_CONTRACT_SUCCESS:
    case EDIT_CONTRACT_SUCCESS:
      return {
        ...state,
        contract: action.payload,
        loading: false,
        error: ''
      };

    case CHANGE_CONTRACT_STATE:
      return update(state, {
        $merge: {
          contract: {
            ...state.contract,
            ...action.payload
          }
        }
      });
      // case CHANGE_CONTRACT_STATE:
      //   return {
      //     ...state,
      //     contract: {
      //       ...state.contract,
      //       ...action.payload
      //     }
      //   };
      case CLEAR_CONTRACT_STATE:
        return {
          ...state,
          contract: initialState.contract
          // employeeBackup: initialState.employeeBackup
        };
    default:
      return state;
  }
};

export default contractReducer;
