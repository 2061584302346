import { Controller, useFormContext } from 'react-hook-form';
import ImageUploadComponent from '../../../components/UploadFile/UploadFile';

const AddEditProductImages = () => {
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext();
  const productImages = watch('productsImages');
  const Style = { color: 'red', padding: '0.7rem' };

  return (
    <div className='product'>
      <div className='product__images'>
        <div className='flex flex-column gap-5'>
          <span className='Title'>صور المنتج</span>
          <div className='w-full flex gap-5'>
            <div className='flex w-full flex-column gap-4'>
              <label className='addproduct__label'>
                صورة الصنف<span style={Style}>*</span>
              </label>

              {/* Image Upload Input */}
              <Controller
                name='productsImages'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ImageUploadComponent
                    multiple={true}
                    onFileChange={(fileList) => {
                      onChange(fileList); // Update form state with the selected files
                    }}
                    alreadySelected={productImages || []} // Pass the images from the form state
                    withFlags
                    withremarks
                  />
                )}
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditProductImages;
