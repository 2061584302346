import React, { useEffect, useState } from 'react';
import { FormProps } from '../../../../types/FormProps';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import useStatus from '../../../../hooks/useStatus';
import { SelectButton } from 'primereact/selectbutton';

const PaymentTermForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const [selectedDiscountType, setSelectedDiscountType] = useState(1);
  const [skipEffect, setSkipEffect] = useState(false); // Flag to skip effect
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Warehouse');
  const isEdit = watch('id') != null;
  const amount = watch('discountAmount');
  const percentage = watch('discountPercentage');

  const getDiscountTypeOnEdit = () => {
    ;
    if (isEdit) {
      if (amount) {
        setSelectedDiscountType(2);
        setValue('discountPercentage', 0.0);
        setValue('discountAmount', amount);
      } else {
        setSelectedDiscountType(1);
        setValue('discountAmount', 0.0);
        setValue('discountPercentage', percentage);
      }
    }
  };

  useEffect(() => {
    getDiscountTypeOnEdit();
  }, []);

  useEffect(() => {
    if (!skipEffect) {
      getDiscountTypeOnEdit();
    } else {
      setSkipEffect(false); // Reset flag after skipping
    }
  }, [amount, percentage, skipEffect]);

  return (
    <>
      <CustomFormField name={'name'} label={`اسم شرط الدفع`} control={control} errors={errors} type={'text'} />
      <CustomFormField name={'days'} label={'عدد الايام'} control={control} errors={errors} type={'decimal'} />
      <div className='w-full flex flex-column gap-2'>
        <label className='modals__label'>الخصم</label>
        <SelectButton
          value={selectedDiscountType}
          onChange={(e) => {
            setSelectedDiscountType(e.value);
          }}
          options={[
            { label: 'نسبة', value: 1 },
            { label: 'مبلغ', value: 2 }
          ]}
        />
      </div>

      <CustomFormField
        name={selectedDiscountType === 1 ? 'discountPercentage' : 'discountAmount'}
        label={'قيمة الخصم'}
        control={control}
        errors={errors}
        type={'decimal'}
      />

      <CustomFormField
        name={'statusId'}
        label={`اختر حالة شرط الدفع`}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default PaymentTermForm;
