import React, { useEffect, useRef, useState } from 'react';

import './AddCovenant.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { addCovenant } from '../../../redux/warehouse/warehouseActions';

import AddCovenant1 from './AddCovenant1';
import { useToast } from '../../../context/ToastContext';

const AddCovenant = ({ activeIndexnum, addCovenant, covenant, token, loading, error }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);

  useEffect(() => {
    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, []);

  function handleِAddOnClick() {
    try {
      addCovenant(covenant, token);
      toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم اضافة العهدة بنجاح' });
      setTimeout(() => navigate('/warehouses/transactions'), 1500);
    } catch (errorAddCovenant) {
      
      
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية اضافة العهدة' });
    }
  }

  return (
    <>
      <div className='flex flex-column gap-5 add__covenant__main'>
        <div>
          <div>
            <AddCovenant1 setCheck={setIsFormOk} />
          </div>
          <div className='btn mb-5'>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <Button className='btn__nextbtn' onClick={handleِAddOnClick} disabled={!isFormOk}>
                اضافة العهدة للموظف
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    covenant: state.warehouse.covenant,
    loading: state.warehouse.loading,
    error: state.warehouse.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCovenant: (covenant, token) => dispatch(addCovenant(covenant, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCovenant);
