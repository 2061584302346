import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function WaitingCustomrList(status: { status?: string }) {
  const navigate = useNavigate();
  const apiUrl = '/Customer/list';
  const routeUrl = '/customers/details/';
  const addUrl = '/customers/addCustomer';

  const allTabs =
    [{ id: 4, name: 'Awaiting', label: 'قيد المراجعة' }].map((status: { name: any; id: any; label: any }) => ({
      tableHeader: `قائمة العملاء - ${status.label}`,
      label: `قائمة العملاء - ${status.label}`,
      isHidden: false,
      entityName: 'عميل',
      dataKey: `tab-${status.id}`,
      columns: [
        { header: 'العميل', field: 'fullName', isChip: false },
        { header: 'عدد الأجهزة', field: 'numberOfDevices', isChip: false },
        { header: 'العنوان الرئيسي', field: 'defaultAddressAddress', isChip: false },
        { header: 'رقم الهاتف', field: 'phoneNb', isChip: false },
        { header: 'طريقة التواصل', field: 'preferredContactMethodName', isChip: false },
        { header: 'الحالة', field: 'statusName', isChip: true }
      ],
      apiUrl: apiUrl,
      edit: false,
      emptyStateUrl: addUrl,
      extraParams: {
        status: status.name
      },
      headerButtons: [
        {
          label: 'إضافة عميل جديد',
          onClick: () => {
            navigate(addUrl);
          }
        }
      ],
      rowOnClick: (rowData: { id: string }) => {
        navigate(routeUrl + rowData.id);
      }
    })) || [];

  const tabs =
    status.status === 'Awaiting'
      ? allTabs.filter((tab) => tab.dataKey === 'tab-4') // Show only the Awaiting tab
      : allTabs.filter((tab) => tab.dataKey !== 'tab-4'); // Show Active, In-Active, and Deleted only

  return <GenericPage tabs={tabs} headerTitle={'العملاء'} headerIcon={'../images/productbox.svg'} />;
}

export default WaitingCustomrList;
