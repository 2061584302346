import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function EvaluationScreen() {
  const navigate = useNavigate();

  const headerDetails = [
    {
      title: 'المتابعات المنتظرة',
      num: '40',
      imageUrl: '/images/sandclock.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/down2.svg',
      txtColor: 'red',
      color: '#F9D8D433'
    },
    {
      title: 'الزيارات المتأخرة',
      num: '30',
      imageUrl: '/images/altsleep.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/Up.svg',
      color: '#6D54DE33'
    },
    {
      title: 'تقيمات الاداء',
      num: '20',
      imageUrl: '/images/check.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/down2.svg',
      txtColor: 'red',
      color: '#FFEDD0'
    },
    {
      title: 'الزيارات الغير مراجعة',
      num: '50',
      imageUrl: '/images/briefcase.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/Up.svg',
      color: '#C050E838'
    }
  ];

  const columnsTab1 = [
    {
      header: 'اسم العميل',
      field: 'customerName',
      isChip: false
    },
    {
      header: 'النوع',
      field: 'details',
      isChip: false
    },
    {
      header: 'الموظف',
      field: 'employeeName',
      isChip: false
    },
    { header: 'الاهمية', field: 'satisfactionLevel', isRate: true },
    { header: 'التاريخ', field: 'date', isDate: true }
  ];

  return (
    <GenericPage
      tabs={[
        {
          isHidden: false,
          label: 'مراجعة الكل',
          tableHeader: 'قائمة الاداء',
          dataKey: 'tab1',
          emptyStateButtonClick: () => {},
          apiUrl: 'Revision/list/notCompletedRevisions',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'التفاصيل',
          rowOnClick: (rowData) => {
            navigate(`/customerService/details`, {
              state: {
                visitId: rowData.entityId,
                activityId: rowData.entityId,
                complaintId: rowData.entityId,
                customerId: rowData.customerId,
                type: rowData.type,
                completed: false
              }
            });
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'مراجعة الزيارت',
          tableHeader: 'قائمة الاداء',
          dataKey: 'tab2',
          emptyStateButtonClick: () => {},
          apiUrl: 'Revision/list/notCompletedVisitRevisions',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'التفاصيل',
          rowOnClick: (rowData) => {
            navigate(`/customerService/details`, {
              state: { visitId: rowData.entityId, customerId: rowData.customerId, type: rowData.type, completed: false }
            });
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'مراجعة الشكاوى',
          tableHeader: 'قائمة الاداء',
          dataKey: 'tab3',
          emptyStateButtonClick: () => {},
          apiUrl: 'Revision/list/notCompletedComplaintRevisions',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'التفاصيل',
          rowOnClick: (rowData) => {
            navigate(`/customerService/details`, {
              state: {
                complaintId: rowData.entityId,
                customerId: rowData.customerId,
                type: rowData.type,
                completed: false
              }
            });
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        },
        {
          isHidden: false,
          label: 'مراجعة الاتصالات',
          tableHeader: 'قائمة الاداء',
          dataKey: 'tab4',
          emptyStateButtonClick: () => {},
          apiUrl: 'Revision/list/notCompletedActivityRevisions',
          edit: false,
          columns: columnsTab1,
          emptyStateUrl: '',
          entityName: 'التفاصيل',
          rowOnClick: (rowData) => {
            navigate(`/customerService/details`, {
              state: {
                activityId: rowData.entityId,
                customerId: rowData.customerId,
                type: rowData.type,
                completed: false
              }
            });
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        }
      ]}
      headerTitle={'خدمة العملاء'}
      headerIcon={'../images/headPhonesAlt.svg'}
      analyticsCards={headerDetails}
      cardsNum='4'
    />
  );
}

export default EvaluationScreen;
