import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
import GenericModal from '../../../components/components/Modal/GenericModal';
import * as yup from 'yup';
import CustomerDeviceForm from './Forms/CustomerDeviceForm';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useToast } from '../../../context/ToastContext';

const complaintsFamilySheme = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

function CustomerDevices() {
  const toast = useToast();
  const navigate = useNavigate();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: iscustomerDeviceVisitable,
    openModal: customerDeviceOpenModal,
    closeModal: customerDeviceCloseModal,
    control: customerDeviceControl,
    handleSubmit: customerDeviceSubmit,
    errors: customerDeviceErrors,
    setValue: customerDeviceSetValue,
    loading: customerDeviceLoading,
    setLoading: customerDeviceSetLoading,
    watch: customerDeviceWatch
  } = useModal(
    {
      code: '',
      customerId: '',
      statusId: '',
      typeId: '',
      deviceId: '',
      customerAddressId: '',
      remarks: ''
    },
    '/CustomersDevice',
    fetchTabData,
    complaintsFamilySheme,
    toast,
    (data) => {
      return {
        ...data,
        installationDate: moment(data.installationDate).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        expiryDate: moment(data.expiryDate).locale('en').format('YYYY-MM-DDTHH:mm:ss[Z]')
      };
    }
  );

  // const {
  //   isVisible: isCustomerVisitsVisitable,
  //   openModal: customerVisitsOpenModal,
  //   closeModal: customerVisitsCloseModal,
  //   control: customerVisitsControl,
  //   handleSubmit: customerVisitsSubmit,
  //   errors: customerVisitsErrors,
  //   setValue: customerVisitsSetValue,
  //   loading: customerVisitsLoading,
  //   setLoading: customerVisitsSetLoading,
  //   watch: customerVisitsWatch
  // } = useModal({ remarks: ' ', names: [] }, '/customerVisits', fetchTabData, complaintsFamilySheme, toast);

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'الاجهزة',
            entityName: 'الجهاز',
            tableHeader: 'جدول الاجهزة',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              customerDeviceOpenModal({
                id: rowData.id,
                code: rowData.code,
                customerId: rowData.customerID,
                statusId: rowData.statusID,
                typeId: rowData.deviceTypeID,
                deviceId: rowData.deviceID,
                customerAddressId: rowData.customerAddressID,
                membraneSerialNb: rowData.membraneSerialNb,
                motorSerialNb: rowData.motorSerialNb,
                installationDate: new Date(rowData.installationDate),
                warrantyMonths: rowData.warrantyMonths,
                expiryDate: new Date(rowData.expiryDate),
                remarks: rowData.remarks
              });
            },
            apiUrl: 'CustomersDevice/list',
            columns: [
              { header: 'اسم العميل', field: 'customerName', isChip: false },
              { header: 'نوع الجهاز', field: 'deviceTypeName', isChip: false },
              { header: 'اسم الجهاز', field: 'deviceName', isChip: false },
              { header: 'الرقم الممبرين', field: 'membraneSerialNb', isChip: false },
              { header: 'رقم المتور', field: 'motorSerialNb', isChip: false },
              { header: 'تاريخ التركيب', field: 'installationDate', isChip: false },
              { header: 'تاريخ الانتهاء', field: 'expiryDate', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            edit: true,
            dataKey: 'CustomersDevice',
            emptyStateButtonClick: () => {
              customerDeviceOpenModal();
            },
            headerButtons: [
              {
                label: 'اضافة جهاز جديد',
                onClick: () => {
                  customerDeviceOpenModal();
                }
              }
            ]
          },
          {
            label: 'الزيارات',
            entityName: 'تفاصيل الزيارة',
            tableHeader: 'جدول المتابعات',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              navigate('/customer/followUp/details/' + rowData.id + '/' + rowData.customerID + '/followUp');
            },
            apiUrl: 'Visit/Customer/list',
            columns: [
              { header: 'العميل', field: 'customerName', isChip: false },
              { header: 'اسم الجهاز', field: 'deviceName', isChip: false },
              { header: 'نوع الزيارة', field: 'visitType', isChip: false },
              { header: 'الفنى', field: 'assignedRepName', isChip: false },
              { header: 'الاهمية', field: 'priorityName', isChip: false },
              { header: 'تاريخ الزيارة', field: 'visitDate', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            edit: false,
            dataKey: 'CustomerVisits',
            emptyStateButtonClick: () => {
              customerDeviceOpenModal();
            },
            headerButtons: []
          },
          {
            label: 'بيانات التقسيط',
            entityName: 'التفاصيل',
            tableHeader: 'جدول الاقساط',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              navigate('' + rowData.id);
            },
            apiUrl: 'ContractsInstallment/list',
            columns: [
              { header: 'اسم العميل', field: 'customerName', isChip: false },
              { header: 'اسم الجهاز', field: 'deviceName', isChip: false },
              { header: 'نوع التقسيط', field: 'installmentsTypeName', isChip: false },
              { header: 'تاريخ التقسيط', field: 'paymentDueDate', isChip: false },
              { header: 'قيمة القسط', field: 'installmentAmount', isChip: false },
              { header: 'المدفوع', field: 'totalPaidAmount', isChip: false },
              { header: 'المتبقى من السداد', field: 'remainingAmount', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            edit: false,
            dataKey: 'CustomerInstallment',
            emptyStateButtonClick: () => {
              customerDeviceOpenModal();
            },
            headerButtons: []
          }
        ]}
        headerTitle={'العملاء'}
        headerIcon={'/images/database.svg'}
      />

      <GenericModal
        headerTitle={customerDeviceWatch('id') === undefined ? 'اضافه جهاز جديد' : 'تعديل جهاز'}
        isVisible={iscustomerDeviceVisitable}
        onHide={customerDeviceCloseModal}
        handleSubmit={customerDeviceSubmit}
        toast={toast}
        loading={customerDeviceLoading}
        isEditMode={customerDeviceWatch('id') !== undefined}
      >
        <CustomerDeviceForm
          control={customerDeviceControl}
          errors={customerDeviceErrors}
          setValue={customerDeviceSetValue}
          watch={customerDeviceWatch}
          extraProps={{ entityName: 'جهاز' }}
        />
      </GenericModal>
    </>
  );
}

export default CustomerDevices;
