import { Message } from 'primereact/message';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import { Card } from 'primereact/card';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import useStatus from '../../../hooks/useStatus';
import useFetch from '../../../hooks/useFetch';

const WarehouseInfoStep = () => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();

  const { statusData } = useStatus('Warehouse');

  const { data: typeData } = useFetch('WarehousesType/dropdown');
  const { data: contractMethodData } = useFetch('ContactMethod/dropdown');

  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      <div style={{ display: 'flex', gap: '24px' }}>
        <div style={{ width: '100%', paddingTop: '20px' }}>
          <Message
            severity='success'
            icon={null}
            content={<p className={'text-black-alpha-90 font-semibold'}>بيانات المخزن</p>}
            style={{
              width: '100%',
              backgroundColor: 'rgba(162, 251, 93, 0.2)'
            }}
          />
          <CustomFormField
            control={control}
            name={'name'}
            label={'اسم المخزن'}
            customStyle={'pt-4'}
            errors={errors}
            type={'text'}
            required
          />
          <CustomFormField
            control={control}
            name={'typeId'}
            customStyle={'pt-4'}
            label={'نوع المخزن'}
            errors={errors}
            type={'dropdown'}
            required
            options={typeData?.data ?? []}
          />

          <CustomFormField
            control={control}
            name={'statusId'}
            customStyle={'pt-4'}
            label={'حاله المخزن'}
            errors={errors}
            type={'dropdown'}
            required
            options={statusData?.data ?? []}
          />
        </div>

        {/* Left side - Commission Details */}
        <div style={{ width: '100%', paddingTop: '20px' }}>
          <Message
            severity='success'
            icon={null}
            content={<p className={'text-black-alpha-90 font-semibold'}>بيانات التواصل</p>}
            style={{
              width: '100%',
              backgroundColor: 'rgba(162, 251, 93, 0.2)'
            }}
          />
          <CustomFormField
            control={control}
            name={'contactMethodId'}
            customStyle={'pt-4'}
            label={'طريقة التواصل'}
            errors={errors}
            type={'dropdown'}
            required
            options={contractMethodData?.data ?? []}
          />

          <CustomFormField
            control={control}
            name={'contactDetails'}
            customStyle={'pt-4'}
            label={'تفاصيل طريقة التواصل'}
            errors={errors}
            type={'text'}
            required
          />
        </div>
      </div>
    </Card>
  );
};

export default WarehouseInfoStep;
