import React, { useMemo } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
// import CustomerComplaintForm from './CustomerComplaintForm';
import * as yup from 'yup';
import { axiosInstance } from '../../../global';
import { useToast } from '../../../context/ToastContext';

const getContractInstallmentsColumns = () => [
  { header: 'العميل', field: 'customerName', isChip: false },
  { header: 'اسم الجهاز', field: 'productName', isChip: false },
  // { header: 'فئة المنتج', field: 'product?.productCategory?.name', isChip: false },
  { header: 'نظام التقسيط', field: 'installmentTypeName', isChip: false },
  { header: 'تاريخ القسط', field: 'collectionDate', isChip: false },
  { header: 'قيمة القسط المطلوب', field: 'remainingAmount', isChip: false }
];

const validationSchema = yup.object().shape({
  customerId: yup.string().required('اسم العميل مطلوب'),
  customerDeviceId: yup.string().required('جهاز العميل مطلوب'),
  complaintCategoryId: yup.string().required('تصنيف الشكوى مطلوب'),
  complaintDate: yup.date().required('تاريخ الشكوى مطلوب'),
  remarks: yup.string().required('تفاصيل الشكوى مطلوبة'),
  statusId: yup.string().required('حالة الشكوى مطلوبة')
});

function ContractInstallments() {
  const columns = useMemo(getContractInstallmentsColumns, []);
  const apiUrl = '/ContractsInstallment/list';
  const addUrl = '/CustomersComplaint';
  const toast = useToast();

  const { isVisible, openModal, closeModal, control, handleSubmit, errors, setValue, loading, setLoading, watch } =
    useModal({}, addUrl, () => {}, validationSchema, toast);

  const handleEdit = (id: string) => {
    openModal(); // Open the modal immediately
    setLoading(true);
    axiosInstance
      .get(`${addUrl}/${id}`)
      .then((response) => {
        const data = response.data.data;
        setValue('id', data.id);
        setValue('code', data.code);
        setValue('customerId', data.customer.id);
        setValue('customerDeviceId', data.customerDevice.id);
        setValue('complaintCategoryId', data.complaintCategoryObject.id);
        setValue('complaintDate', new Date(data.complaintDate));
        setValue('issuingDate', new Date(data.issuingDate));
        setValue('remarks', data.remarks);
        setValue('statusId', data.status.id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const tabs = [
    {
      tableHeader: 'قائمة الاقساط',
      label: 'قائمة الاقساط',
      entityName: 'القسط',
      dataKey: 'tab1',
      columns: columns,
      apiUrl: apiUrl,
      edit: true,
      emptyStateUrl: addUrl,
      isHidden: true,
      rowOnClick: (rowData: any) => {
        handleEdit(rowData.id);
        return {};
      },
      extraParams: {},
      emptyStateButtonClick: undefined,
      headerButtons: [
        {
          label: 'إضافة عقد جديدة',
          onClick: () => openModal()
        }
      ]
    }
  ];

  const headerDetails = [
    {
      title: 'الاقساط المطلوب تحصيلها',
      num: '40',
      imageUrl: '/images/file3.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/down2.svg',
      txtColor: 'red',
      color: '#faeceb'
    },
    {
      title: 'المبلغ الكلي للاقساط المطلوبة',
      num: '150000.00 ج.م',
      imageUrl: '/images/altsleep.svg',
      percent: '2.5% من الشهر الماضى',
      chartImage: '/images/Up.svg',
      color: '#ccc3f8'
    }
  ];
  return (
    <>
      <GenericPage
        tabs={tabs}
        headerTitle='العقود'
        headerIcon='/images/file.svg'
        analyticsCards={headerDetails}
        cardsNum='2'
      />
    </>
  );
}

export default ContractInstallments;
