import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './Modals.scss';
import { useToast } from '../../context/ToastContext';

function EditStatus({
  title,
  entityName,
  entityBase,
  visible,
  setVisible,
  onClosePrompt,
  onEditHandler,
  onDeleteHandler
}) {
  const toast = useToast();
  const [selectedStatusType, setSelectedStatusType] = useState([]);
  const [entity, setEntity] = useState({
    id: null,
    name: null,
    isActive: true,
    description: null
  });

  const options = [
    { label: 'غير متاح', value: false },
    { label: 'متاح', value: true }
  ];

  useEffect(() => {
    if (visible) {
      setEntity({
        id: entityBase.id,
        name: entityBase.name,
        isActive: entityBase.isActive,
        description: entityBase.remarks
      });
      // setSelectedStatus([entityBase.status.id]);
    }
  }, [visible, entityBase]);

  function acceptClose() {
    setEntity({
      id: null,
      name: null,
      isActive: true,
      description: null
    });
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusTypeChange(e) {
    setSelectedStatusType(e.value);
    setEntity({ ...entity, isActive: e.target.value });
    
    
  }

  function handleDelete(entity) {
    confirmDialog({
      header: `هل أنت متأكد من أنك تريد حذف ${entityName}؟`,
      accept: () => {
        onDeleteHandler(entity.id);
        acceptClose();
      },
      acceptLabel: `نعم حذف ${entityName} : ${entity.name}`,
      acceptClassName: 'p-button-danger',
      rejectLabel: 'لا',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  return (
    <>
      
      <ConfirmDialog tagKey={2} key={2} closable={false} closeOnEscape={false} draggable={false} resizable={false} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اسم {entityName}</label>
              <InputText name='name' value={entity.name} className='w-full' onChange={handleInputChange} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>
                نوع الحالة<span className='astrk'>*</span>
              </label>
              <SelectButton value={entity.isActive} onChange={handleStatusTypeChange} options={options} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputText
                name='remarks'
                value={entity.description || ''}
                className='w-full'
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='flex justify-content-between'>
            <Button
              link
              label='حذف'
              style={{ color: '#E24A34' }}
              severity='danger'
              onClick={(event) => handleDelete(entity)}
            />

            <div className='flex justify-content-end'>
              <Button label='إلغاء' onClick={handleClose} size='small' outlined />
              <button className='modals__btn' onClick={() => onEditHandler(entity)}>
                تعديل {entityName}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default EditStatus;
