import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Layout from './Layout';
import Footer from '../Footer/Footer';

function MainLayout() {
  return (
    <>
      <Navbar />
      <Layout>
        <div style={{ width: '100%' }}>
          <Outlet />
        </div>
      </Layout>
      <Footer />
    </>
  );
}

export default MainLayout;
