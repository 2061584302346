import React, { useRef } from 'react';

import './Location.scss';
import * as yup from 'yup';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import GenericModal from '../../../components/components/Modal/GenericModal';
import useModal from '../../../hooks/useModal';

import LocationForm from './Forms/LocationForm';
import AreaForm from './Forms/AreaForm';
import GovernorateForm from './Forms/GovernorateForm';
import CountryForm from './Forms/CountryForm';
import { axiosInstance } from '../../../global';
import { Toast } from 'primereact/toast';
import { useToast } from '../../../context/ToastContext';

const addLocationSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  countryId: yup.number().required('Country is required'),
  governorateId: yup.number().required('Governorate is required'),
  areaId: yup.number().required('Area is required'),
  name: yup.string().when('id', (id, schema) => {
    if (typeof id === 'number' && id !== null && id !== undefined) {
      return schema.required('Location name is required').min(1, 'At least one location name is required');
    }
    return schema.notRequired().nullable();
  }),
  names: yup
    .array()
    .of(yup.string().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string()
});

const addAreaSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  countryId: yup.number().required('Country is required'),
  governorateId: yup.number().required('Governorate is required'),
  name: yup.string().when('id', (id, schema) => {
    if (typeof id === 'number' && id !== null && id !== undefined) {
      return schema.required('Area name is required').min(1, 'At least one area name is required');
    }
    return schema.notRequired().nullable();
  }),
  names: yup
    .array()
    .of(yup.string().required('Area name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one area name is required').required('Area names are required');
      }
      return schema.notRequired();
    }),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string()
});

const addGovernorateSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  countryId: yup.number().required('Country is required'),
  name: yup.string().when('id', (id, schema) => {
    if (typeof id === 'number' && id !== null && id !== undefined) {
      return schema.required('Governorate name is required').min(1, 'At least one governorate name is required');
    }
    return schema.notRequired().nullable();
  }),
  names: yup
    .array()
    .of(yup.string().required('Governorate name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one governorate name is required').required('Governorate names are required');
      }
      return schema.notRequired();
    }),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string()
});

const addCountrySchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup.string().when('id', (id, schema) => {
    if (typeof id === 'number' && id !== null && id !== undefined) {
      return schema.required('Country name is required').min(1, 'At least one location name is required');
    }
    return schema.notRequired().nullable(); // Schema for case when id is not present
  }),
  names: yup
    .array()
    .of(yup.string().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  statusId: yup.number().required('Status is required'),
  currencyId: yup.number().required('Currency is required'),
  remarks: yup.string().nullable() // Make remarks nullable for optional input
});

function Location() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isLocationVisitable,
    openModal: locationOpenModal,
    closeModal: locationCloseModal,
    control: locationControl,
    handleSubmit: locationSubmit,
    errors: locationErrors,
    setValue: locationSetValue,
    loading: locationLoading,
    setLoading: locationSetLoading,
    watch: locationWatch
  } = useModal({ remarks: ' ', names: [] }, '/Location', fetchTabData, addLocationSchema, toast);

  const {
    isVisible: isAreaVisitable,
    openModal: areaOpenModal,
    closeModal: areaCloseModal,
    control: areaControl,
    handleSubmit: areaSubmit,
    errors: areaErrors,
    setValue: areaSetValue,
    loading: areaLoading,
    setLoading: areaSetLoading,
    watch: areaWatch
  } = useModal({ remarks: ' ', names: [] }, '/Area', fetchTabData, addAreaSchema, toast);

  const {
    isVisible: isGovernorateVisitable,
    openModal: governorateOpenModal,
    closeModal: governorateCloseModal,
    control: governorateControl,
    handleSubmit: governorateSubmit,
    errors: governorateErrors,
    setValue: governorateSetValue,
    loading: governorateLoading,
    setLoading: governorateSetLoading,
    watch: governorateWatch
  } = useModal({ remarks: ' ', names: [] }, '/Governorate', fetchTabData, addGovernorateSchema, toast);

  const {
    isVisible: isCountryVisitable,
    openModal: countryOpenModal,
    closeModal: countryCloseModal,
    control: countryControl,
    handleSubmit: countrySubmit,
    errors: countryErrors,
    setValue: countrySetValue,
    loading: countryLoading,
    setLoading: countrySetLoading,
    watch: countryWatch
  } = useModal({ remarks: ' ', names: [] }, '/Country', fetchTabData, addCountrySchema, toast);

  return (
    <>
      
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'الدول',
            entityName: 'دولة',
            tableHeader: 'جدول الدول',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              countryOpenModal({ id: rowData.id });
              countrySetLoading(true);
              axiosInstance
                .get(`Country/${rowData.id}`)
                .then((response) => {
                  const data = response.data.data;
                  countrySetValue('id', data.id);
                  countrySetValue('name', data.name);
                  countrySetValue('statusId', data.status.id);
                  countrySetValue('currencyId', data.currency.id);
                  countrySetValue('remarks', data.remarks);
                  countrySetLoading(false);
                })
                .catch(() => {
                  countrySetLoading(false);
                });
            },
            apiUrl: 'Country/list',
            columns: [
              { header: 'اسم الدوله', field: 'name', isChip: false },
              { header: 'العملة', field: 'currencyNativeLanguageName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            dataKey: 'tab1',
            emptyStateButtonClick: () => {
              countryOpenModal();
            },
            headerButtons: [
              {
                label: 'اضافة دول',
                onClick: () => {
                  countryOpenModal();
                }
              }
            ]
          },
          {
            label: 'المحافظات',
            entityName: 'محافظات',
            tableHeader: 'جدول المحافظات',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              governorateOpenModal({ id: rowData.id });
              governorateSetLoading(true);
              axiosInstance
                .get(`Country/${rowData.id}`)
                .then((response) => {
                  const data = response.data.data;
                  governorateSetValue('id', data.id);
                  governorateSetValue('name', data.name);
                  governorateSetValue('statusId', data.status.id);
                  governorateSetValue('currencyId', data.currency.id);
                  governorateSetValue('remarks', data.remarks);
                  governorateSetLoading(false);
                })
                .catch(() => {
                  governorateSetLoading(false);
                });
            },
            apiUrl: 'Governorate/list',
            columns: [
              { header: 'اسم المحافظة', field: 'name', isChip: false },
              { header: 'الدوله', field: 'countryName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            dataKey: 'tab2',
            emptyStateButtonClick: undefined,
            headerButtons: [
              {
                label: 'اضافة محافظات',
                onClick: () => {
                  governorateOpenModal();
                }
              }
            ]
          },
          {
            label: 'المدن',
            entityName: 'مدن',
            tableHeader: 'جدول المدن',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              areaOpenModal({ id: rowData.id });
              areaSetLoading(true);
              axiosInstance
                .get(`Area/${rowData.id}`)
                .then((response) => {
                  const data = response.data.data;
                  areaSetValue('id', data.id);
                  areaSetValue('name', data.name);
                  areaSetValue('countryId', data.country.id);
                  areaSetValue('governorateId', data.governorate.id);
                  areaSetValue('statusId', data.status.id);
                  areaSetValue('remarks', data.remarks);
                  areaSetLoading(false);
                })
                .catch(() => {
                  areaSetLoading(false);
                });
            },
            apiUrl: 'Area/list',
            columns: [
              { header: 'اسم المدينه', field: 'name', isChip: false },
              { header: 'المحافظه', field: 'governorateName', isChip: false },
              { header: 'الدوله', field: 'governorateCountryName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            dataKey: 'tab3',
            emptyStateButtonClick: undefined,
            headerButtons: [
              {
                label: 'اضافة مدن',
                onClick: () => {
                  areaOpenModal();
                }
              }
            ]
          },
          {
            label: 'المناطق',
            entityName: 'منطقه',
            tableHeader: 'جدول المناطق',
            emptyStateUrl: '',
            rowOnClick: (rowData) => {
              locationOpenModal({ id: rowData.id });
              locationSetLoading(true);
              axiosInstance
                .get(`Location/${rowData.id}`)
                .then((response) => {
                  const data = response.data.data;
                  locationSetValue('id', data.id);
                  locationSetValue('name', data.name);
                  locationSetValue('countryId', data.area.governorate.country.id);
                  locationSetValue('governorateId', data.area.governorate.id);
                  locationSetValue('areaId', data.area.id);
                  locationSetValue('statusId', data.status.id);
                  locationSetValue('remarks', data.remarks);
                  locationSetLoading(false);
                })
                .catch(() => {
                  locationSetLoading(false);
                });
            },
            apiUrl: 'Location/list',
            columns: [
              { header: 'اسم المنطقه', field: 'name', isChip: false },
              { header: 'المدينه', field: 'areaName', isChip: false },
              { header: 'المحافظه', field: 'areaGovernorateName', isChip: false },
              { header: 'الدوله', field: 'areaGovernorateCountryName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            dataKey: 'tab4',
            emptyStateButtonClick: undefined,
            headerButtons: [
              {
                label: 'اضافة مناطق',
                onClick: () => {
                  locationOpenModal();
                }
              }
            ]
          }
        ]}
        headerTitle={'بيانات المناطق'}
        headerIcon={'/images/database.svg'}
      />
      <GenericModal
        headerTitle={'اضافة مناطق'}
        isVisible={isLocationVisitable}
        onHide={locationCloseModal}
        handleSubmit={locationSubmit}
        toast={toast}
        loading={locationLoading}
        isEditMode={areaWatch('id') !== undefined}
      >
        <LocationForm
          control={locationControl}
          errors={locationErrors}
          setValue={locationSetValue}
          watch={locationWatch}
        />
      </GenericModal>
      <GenericModal
        headerTitle={'اضافة مدينة'}
        isVisible={isAreaVisitable}
        onHide={areaCloseModal}
        handleSubmit={areaSubmit}
        toast={toast}
        loading={areaLoading}
        isEditMode={areaWatch('id') !== undefined}
      >
        <AreaForm control={areaControl} errors={areaErrors} setValue={areaSetValue} watch={areaWatch} />
      </GenericModal>

      <GenericModal
        headerTitle={governorateWatch('id') !== undefined ? 'تعديل محافظة' : 'اضافة محافظة'}
        isVisible={isGovernorateVisitable}
        onHide={governorateCloseModal}
        handleSubmit={governorateSubmit}
        toast={toast}
        loading={governorateLoading}
        isEditMode={governorateWatch('id') !== undefined}
      >
        <GovernorateForm
          control={governorateControl}
          errors={governorateErrors}
          setValue={governorateSetValue}
          watch={governorateWatch}
        />
      </GenericModal>

      <GenericModal
        headerTitle={countryWatch('id') === undefined ? 'اضافه دوله' : 'تعديل دوله'}
        isVisible={isCountryVisitable}
        onHide={countryCloseModal}
        handleSubmit={countrySubmit}
        toast={toast}
        loading={countryLoading}
        isEditMode={countryWatch('id') !== undefined}
      >
        <CountryForm control={countryControl} errors={countryErrors} setValue={countrySetValue} watch={countryWatch} />
      </GenericModal>
    </>
  );
}

export default Location;
