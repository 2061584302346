export const FETCH_WAREHOUSE_REQUEST = 'FETCH_WAREHOUSE_REQUEST';
export const FETCH_WAREHOUSE_SUCCESS = 'FETCH_WAREHOUSE_SUCCESS';
export const FETCH_WAREHOUSE_FAILURE = 'FETCH_WAREHOUSE_FAILURE';

export const ADD_WAREHOUSE_REQUEST = 'ADD_WAREHOUSE_REQUEST';
export const ADD_WAREHOUSE_SUCCESS = 'ADD_WAREHOUSE_SUCCESS';
export const ADD_WAREHOUSE_FAILURE = 'ADD_WAREHOUSE_FAILURE';

export const EDIT_WAREHOUSE_REQUEST = 'EDIT_WAREHOUSE_REQUEST';
export const EDIT_WAREHOUSE_SUCCESS = 'EDIT_WAREHOUSE_SUCCESS';
export const EDIT_WAREHOUSE_FAILURE = 'EDIT_WAREHOUSE_FAILURE';

export const CHANGE_WAREHOUSE_STATE = 'CHANGE_WAREHOUSE_STATE';

export const FETCH_TRANSACTION_REQUEST = 'FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_FAILURE = 'FETCH_TRANSACTION_FAILURE';

export const ADD_TRANSACTION_REQUEST = 'ADD_TRANSACTION_REQUEST';
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS';
export const ADD_TRANSACTION_FAILURE = 'ADD_TRANSACTION_FAILURE';

export const EDIT_TRANSACTION_REQUEST = 'EDIT_TRANSACTION_REQUEST';
export const EDIT_TRANSACTION_SUCCESS = 'EDIT_TRANSACTION_SUCCESS';
export const EDIT_TRANSACTION_FAILURE = 'EDIT_TRANSACTION_FAILURE';

export const CHANGE_TRANSACTION_STATE = 'CHANGE_TRANSACTION_STATE';
export const CLEAR_TRANSACTION_STATE = 'CLEAR_TRANSACTION_STATE';

export const ADD_COVENANT_REQUEST = 'ADD_COVENANT_REQUEST';
export const ADD_COVENANT_SUCCESS = 'ADD_COVENANT_SUCCESS';
export const ADD_COVENANT_FAILURE = 'ADD_COVENANT_FAILURE';

export const CHANGE_COVENANT_STATE = 'CHANGE_COVENANT_STATE';
