import { useState } from 'react';
import { axiosInstance } from '../global';
import { useApiState } from './useApiState';

export type IdAndName = {
  id: number;
  name: string;
};

const useProductHierarchy = () => {
  const [selectedProductType, setSelectedProductType] = useState<IdAndName | null>(null);
  const [selectedProductFamily, setSelectedProductFamily] = useState<IdAndName | null>(null);
  const [selectedProductCategory, setSelectedProductCategory] = useState<IdAndName | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<IdAndName | null>(null);

  const productTypeApiState = useApiState<IdAndName[]>({
    apiCall: () => axiosInstance.get('/ProductsType/dropdown').then((res) => res.data.data),
    isLazy: false // Always load product types on initialization
  });

  const productFamilyApiState = useApiState<IdAndName[], IdAndName[], { productTypeId: number }>({
    apiCall: async (args) => {
      if (args && args.productTypeId) {
        const res = await axiosInstance.get(`/ProductsFamily/getByTypeIdDropdown/${args.productTypeId}`);
        return res.data.data;
      }
    },
    isLazy: true
  });

  const productCategoryApiState = useApiState<IdAndName[], IdAndName[], { productFamilyId: number }>({
    apiCall: async (args) => {
      if (args && args.productFamilyId) {
        const res = await axiosInstance.get(`/ProductsCategory/getByFamilyIdDropdown/${args.productFamilyId}`);
        return res.data.data;
      }
    },
    isLazy: true
  });

  const productApiState = useApiState<IdAndName[], IdAndName[], { productCategoryId: number }>({
    apiCall: async (args) => {
      if (args && args.productCategoryId) {
        const res = await axiosInstance.get(`/Product/allByCategoryDropdown/${args.productCategoryId}`);
        return res.data.data;
      }
    },
    isLazy: true
  });

  const handleProductTypeSelect = async (productType: IdAndName) => {
    setSelectedProductType(productType);
    setSelectedProductFamily(null);
    setSelectedProductCategory(null);
    setSelectedProduct(null);
    await productFamilyApiState.lazyApiCall({ productTypeId: productType.id });
  };

  const handleProductFamilySelect = async (productFamily: IdAndName) => {
    setSelectedProductFamily(productFamily);
    setSelectedProductCategory(null);
    setSelectedProduct(null);
    await productCategoryApiState.lazyApiCall({ productFamilyId: productFamily.id });
  };

  const handleProductCategorySelect = async (productCategory: IdAndName) => {
    setSelectedProductCategory(productCategory);
    setSelectedProduct(null);
    await productApiState.lazyApiCall({ productCategoryId: productCategory.id });
  };

  const handleProductSelect = (product: IdAndName) => {
    setSelectedProduct(product);
  };

  return {
    productTypeApiState,
    productFamilyApiState,
    productCategoryApiState,
    productApiState,
    selectedProductType,
    selectedProductFamily,
    selectedProductCategory,
    selectedProduct,
    handleProductTypeSelect,
    handleProductFamilySelect,
    handleProductCategorySelect,
    handleProductSelect,
    resetProductHierarchyState: () => {
      setSelectedProductType(null);
      setSelectedProductFamily(null);
      setSelectedProductCategory(null);
      setSelectedProduct(null);
      productFamilyApiState.resetApiCallState();
      productCategoryApiState.resetApiCallState();
      productApiState.resetApiCallState();
    }
  };
};

export default useProductHierarchy;
