import HeaderCardTS from '../../Cards/Home header/HeaderCardTS';
import React from 'react';
import { AnalyticsCardHeaderProps } from './GenericPage';


interface PageAnalyticsHeaderProps {
  analyticsCards?: AnalyticsCardHeaderProps[] | undefined;
  cardsNum?:string|undefined;
}

export const PageAnalyticsHeader = ({ analyticsCards,cardsNum }: PageAnalyticsHeaderProps) => {
  if (!analyticsCards || analyticsCards.length === 0) return null;
// 

  return (
    <div className={'details__header__card'} style={{ padding: '20px' ,gridTemplateColumns: `repeat(${cardsNum}, 1fr)`}}>
      {analyticsCards.map((cardsDet, index) => (
        <HeaderCardTS
          key={index}
          title={cardsDet.title}
          num={cardsDet.num}
          imageUrl={cardsDet.imageUrl}
          chartImage={cardsDet.chartImage}
          percent={cardsDet.percent}
          txtColor={cardsDet.txtColor}
          color={cardsDet.color}
        />
      ))}
    </div>
  );
};
