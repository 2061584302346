import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useGeoLocation from '../../../hooks/useGeoLocation';

const WarehouseLocationStep = () => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();
  const selectedCountry = watch('countryId');
  const selectedGovernorate = watch('governorateId');
  const selectedArea = watch('areaId');

  const {
    countryApiState,
    governorateApiState,
    areaApiState,
    locationApiState,
    handleCountrySelect,
    handleGovernorateSelect,
    handleAreaSelect
  } = useGeoLocation(true);

  useEffect(() => {
    if (selectedCountry) handleCountrySelect({ id: selectedCountry, name: selectedCountry });
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedGovernorate) handleGovernorateSelect({ id: selectedGovernorate, name: selectedGovernorate });
  }, [selectedGovernorate]);

  useEffect(() => {
    if (selectedArea) handleAreaSelect({ id: selectedArea, name: selectedArea });
  }, [selectedArea]);

  return (
    <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
      <Message
        severity='success'
        icon={null}
        content={<p className={'text-black-alpha-90 font-semibold'}>بيانات العنوان</p>}
        style={{
          width: '100%',
          backgroundColor: 'rgba(162, 251, 93, 0.2)'
        }}
      />

      <div style={{ display: 'flex', gap: '22px' }}>
        <CustomFormField
          control={control}
          name={'countryId'}
          label={'الدوله'}
          customStyle={'pt-4'}
          errors={errors}
          type={'dropdown'}
          required
          options={countryApiState.state?.data ?? []}
        />
        <CustomFormField
          control={control}
          name={'governorateId'}
          label={'المحافظة'}
          customStyle={'pt-4'}
          errors={errors}
          type={'dropdown'}
          required
          options={governorateApiState.state?.data ?? []}
        />
        <CustomFormField
          control={control}
          name={'areaId'}
          label={'المدينة'}
          customStyle={'pt-4'}
          errors={errors}
          type={'dropdown'}
          required
          options={areaApiState.state?.data ?? []}
        />
        <CustomFormField
          control={control}
          name={'locationId'}
          label={'المنطقة'}
          customStyle={'pt-4'}
          errors={errors}
          type={'dropdown'}
          required
          options={locationApiState.state?.data ?? []}
        />
      </div>
      <CustomFormField
        customStyle={'pt-4'}
        control={control}
        name={'address'}
        label={'العنوان'}
        errors={errors}
        type={'textarea'}
      />
      <CustomFormField
        customStyle={'pt-4'}
        control={control}
        name={'remarks'}
        label={'ملاحظات اخرى'}
        errors={errors}
        type={'textarea'}
      />
    </Card>
  );
};

export default WarehouseLocationStep;
