import React, { useMemo, useRef } from 'react';
import useModal from '../../../hooks/useModal';
import GenericModal from '../../../components/components/Modal/GenericModal';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Tabs from '../../../components/Tabs/Tabs';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import { useToast } from '../../../context/ToastContext';
import WarehouseLocationsForm from '../Forms/WarehouseLocationsForm';
import WarehouseSectionsForm from '../Forms/WarehouseSectionsForm';

const WarehouseStockColumns = [
  { header: 'نوع المنتج', field: 'productType', isChip: false },
  { header: 'فئة المنتج', field: 'productFamily', isChip: false },
  { header: 'تصنيف المنتج', field: 'productCategory', isChip: false },
  { header: 'اسم المنتج', field: 'product', isChip: false },
  { header: 'إجمالي الكمية', field: 'stockQty', isChip: false },
  { header: 'الكمية المتاحة', field: 'stockAmount', isChip: false },
  { header: 'سعر البيع', field: 'productPrice', isChip: false }
];
const WarehouseSectionColumns = [
  { header: ' المخزن', field: 'wHName', isChip: false },
  { header: 'اقسام المخزن', field: 'name', isChip: false },
  { header: ' كود قسم المخزن', field: 'code', isChip: false },
  { header: 'حالة قسم المخزن', field: 'statusName', isChip: true }
];
const WarehouseLocationColumns = [
  { header: 'مواقع تخزين', field: 'name', isChip: false },
  { header: 'حالة موقع المخزن', field: 'statusName', isChip: true },
  { header: 'قسم المخزن', field: 'wHSectionName', isChip: false },
  { header: 'الـPlant كود', field: 'plantCode', isChip: false }
];

const sectionsSchema = yup.object().shape({
  warehouseId: yup.string().required('اسم المخزن مطلوب'),
  name: yup.string().required('اسم قسم المخزن مطلوب'),
  remarks: yup.string().notRequired(),
  statusId: yup.string().required('حالة قسم المخزن مطلوبة')
});

const locationsSchema = yup.object().shape({
  warehouseId: yup.string().required('اسم المخزن مطلوب'),
  warehouseSectionId: yup.string().required('قسم المخزن مطلوب'),
  name: yup.string().required('اسم قسم المخزن مطلوب'),
  plantCode: yup.string().required('كود ال plant مطلوب'),
  remarks: yup.string().notRequired(),
  statusId: yup.string().required('حالة قسم المخزن مطلوبة')
});

export default function WarehouseDetailsTables({ warehouseID }: { warehouseID: string | undefined }) {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const navigate = useNavigate();

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: iswarehouseSectionsVisitable,
    openModal: warehouseSectionsOpenModal,
    closeModal: warehouseSectionsCloseModal,
    control: warehouseSectionsControl,
    handleSubmit: warehouseSectionsSubmit,
    errors: warehouseSectionsErrors,
    setValue: warehouseSectionsSetValue,
    loading: warehouseSectionsLoading,
    setLoading: warehouseSectionsSetLoading,
    watch: warehouseSectionsWatch
  } = useModal(
    {
      warehouseId: '',
      code: '',
      statusId: '',
      remarks: ''
    },
    '/WarehousesSection',
    fetchTabData,
    sectionsSchema,
    toast
  );

  const {
    isVisible: iswarehouseLocationsVisitable,
    openModal: warehouseLocationsOpenModal,
    closeModal: warehouseLocationsCloseModal,
    control: warehouseLocationsControl,
    handleSubmit: warehouseLocationsSubmit,
    errors: warehouseLocationsErrors,
    setValue: warehouseLocationsSetValue,
    loading: warehouseLocationsLoading,
    setLoading: warehouseLocationsSetLoading,
    watch: warehouseLocationsWatch
  } = useModal(
    {
      warehouseId: '',
      locationsSchema: '',
      code: '',
      statusId: '',
      remarks: ''
    },
    '/WarehousesLocation',
    fetchTabData,
    locationsSchema,
    toast
  );

  const warehouseStockTab = useMemo(
    () => ({
      label: 'المخازن',
      tableHeader: 'رصيد مخزون السلعة',
      entityName: 'المخزن',
      dataKey: 'warehouseStock',
      columns: WarehouseStockColumns,
      isColumnHidden: true,
      apiUrl: `/Warehouse/StockSituation/list/ByWarehouse/${warehouseID}`,
      edit: false,
      emptyStateUrl: `/warehouse/add`,
      rowOnClick: (rowData: any) => {
        navigate(`/warehouse/details/${rowData.id}`);
      },
      emptyStateButtonClick: undefined,
      headerButtons: [
        {
          label: 'إضافة مخزن جديد',
          onClick: () => {
            navigate(`/warehouse/add`);
          }
        }
      ]
    }),
    [warehouseID]
  );

  const warehouseSectionsTab = useMemo(
    () => ({
      label: '',
      entityName: 'قسم داخل المخزن',
      tableHeader: 'جدول اقسام المخزن',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
        warehouseSectionsOpenModal({
          id: rowData.id,
          code: rowData.code,
          name: rowData.name,
          warehouseId: rowData.whid,
          statusId: rowData.statusID,
          remarks: rowData.remarks
        });
      },
      apiUrl: `WarehousesSection/list/ByWarehouse/${warehouseID}`,
      columns: WarehouseSectionColumns,
      edit: true,
      dataKey: 'WarehouseSections',
      emptyStateButtonClick: () => {
        warehouseSectionsOpenModal({ warehouseId: parseInt(warehouseID as string) });
      }
    }),
    [warehouseID]
  );

  const warehouseLocationTab = useMemo(
    () => ({
      label: '',
      entityName: 'موقع المخزن',
      tableHeader: 'جدول مواقع التخزين',
      emptyStateUrl: '',
      rowOnClick: (rowData: any) => {
        warehouseLocationsOpenModal({
          id: rowData.id,
          code: rowData.code,
          name: rowData.name,
          warehouseId: rowData.whid,
          warehouseSectionId: rowData.wHSectionID,
          plantCode: rowData.plantCode,
          statusId: rowData.statusID,
          remarks: rowData.remarks
        });
      },
      apiUrl: `WarehousesLocation/list/ByWarehouse/${warehouseID}`,
      columns: WarehouseLocationColumns,
      edit: true,
      dataKey: 'WarehousesLocations',
      emptyStateButtonClick: () => {
        warehouseLocationsOpenModal({ warehouseId: parseInt(warehouseID as string) });
      }
    }),
    [warehouseID]
  );

  return (
    <>
      <div className='customerdetails__content__down'>
        <Tabs
          headers={['منتجات المخزن', 'اقسام المخزن', 'مواقع التخزين']}
          contents={[
            <DataTableCard
              ref={genericPageRef}
              button={[
                {
                  label: 'إضافة إذن صرف',
                  onClick: () => {
                    navigate(`/warehouses/transactions/issuing/add`);
                  }
                },
                {
                  label: 'إضافة إذن استلام',
                  onClick: () => {
                    navigate(`/warehouses/transactions/receiving/add`);
                  }
                }
              ]}
              selectedTab={warehouseStockTab}
            />,

            <DataTableCard
              ref={genericPageRef}
              button={[
                {
                  label: 'إضافة اقسام داخل المخزن',
                  onClick: () => {
                    warehouseSectionsOpenModal({ warehouseId: parseInt(warehouseID as string) });
                  }
                }
              ]}
              selectedTab={warehouseSectionsTab}
            />,

            <DataTableCard
              ref={genericPageRef}
              button={[
                {
                  label: 'إضافة موقع تخزين للمخزن',
                  onClick: () => {
                    warehouseLocationsOpenModal({ warehouseId: parseInt(warehouseID as string) });
                  }
                }
              ]}
              selectedTab={warehouseLocationTab}
            />
          ]}
        />
      </div>
      <GenericModal
        headerTitle={warehouseSectionsWatch('id') === undefined ? 'اضافه قسم مخزن جديد' : 'تعديل قسم مخزن'}
        isVisible={iswarehouseSectionsVisitable}
        onHide={warehouseSectionsCloseModal}
        handleSubmit={warehouseSectionsSubmit}
        toast={toast}
        loading={warehouseSectionsLoading}
        isEditMode={warehouseSectionsWatch('id') !== undefined}
      >
        <WarehouseSectionsForm
          control={warehouseSectionsControl}
          errors={warehouseSectionsErrors}
          setValue={warehouseSectionsSetValue}
          watch={warehouseSectionsWatch}
          extraProps={{ isDisabled: true }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={warehouseLocationsWatch('id') === undefined ? 'اضافه موقع مخزن جديد' : 'تعديل موقع مخزن'}
        isVisible={iswarehouseLocationsVisitable}
        onHide={warehouseLocationsCloseModal}
        handleSubmit={warehouseLocationsSubmit}
        toast={toast}
        loading={warehouseLocationsLoading}
        isEditMode={warehouseLocationsWatch('id') !== undefined}
      >
        <WarehouseLocationsForm
          control={warehouseLocationsControl}
          errors={warehouseLocationsErrors}
          setValue={warehouseLocationsSetValue}
          watch={warehouseLocationsWatch}
          extraProps={{ isDisabled: true }}
        />
      </GenericModal>
    </>
  );
}
