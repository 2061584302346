import update from 'immutability-helper';
import {
  FETCH_FAULTSVISIT_REQUEST,
  FETCH_FAULTSVISIT_SUCCESS,
  FETCH_FAULTSVISIT_FAILURE,
  ADD_FAULTSVISIT_REQUEST,
  ADD_FAULTSVISIT_SUCCESS,
  ADD_FAULTSVISIT_FAILURE,
  EDIT_FAULTSVISIT_REQUEST,
  EDIT_FAULTSVISIT_SUCCESS,
  EDIT_FAULTSVISIT_FAILURE,
  CHANGE_FAULTSVISIT_STATE,
  CHANGE_FAULTSVISIT_TEMP_STATE,
  CLEAR_FAULTSVISIT_STATE
} from './visitTypes';

const initialState = {
  visitFaults: {
    visitId: '',
    visitsFaultsDetails: [],
    remarks: ''
  },
  visitFaultsData: {
    visitId: '',
    visitsFaultsDetails: [],
    remarks: ''
  },
  visitFaultsTemp: {
    data: []
  },
  loading: false,
  error: ''
};

const visitReducer3 = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAULTSVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_FAULTSVISIT_SUCCESS:
      return {
        ...state,
        visitFaultsData: action.payload,
        loading: false,
        error: ''
      };
    case FETCH_FAULTSVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_FAULTSVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_FAULTSVISIT_SUCCESS:
      return {
        ...state,
        visitFaults: action.payload,
        loading: false,
        error: ''
      };
    case ADD_FAULTSVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case EDIT_FAULTSVISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case EDIT_FAULTSVISIT_SUCCESS:
      return {
        ...state,
        visitFaults: action.payload,
        loading: false,
        error: ''
      };
    case EDIT_FAULTSVISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CHANGE_FAULTSVISIT_STATE:
      return update(state, {
        $merge: {
          visitFaults: {
            ...state.visitFaults,
            ...action.payload
          }
        }
      });

    case CHANGE_FAULTSVISIT_TEMP_STATE:
      return update(state, {
        $merge: {
          visitFaultsTemp: {
            ...state.visitFaultsTemp,
            ...action.payload
          }
        }
      });

    case CLEAR_FAULTSVISIT_STATE:
      return {
        ...state,
        visitFaults: initialState.visitFaults,
        visitFaultsTemp: initialState.visitFaultsTemp
      };

    default:
      return state;
  }
};

export default visitReducer3;
