import ContractDetailsHeader from './Components/ContractDetailsHeader';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import styles from './AddEditContract.module.scss';
import GenericEditableDataTable, {
  EditableColumnProps
} from '../../../components/components/GenericDataTable/GenericEditableDataTable';
import useFetch from '../../../hooks/useFetch';
import CustomButton from '../../../components/components/Button/CustomButton';
import useProduct from '../../../hooks/useProduct';

interface ProductRow {
  stageId: number | null;
  name: string | null;
  availableQuantity: number | null;
  quantity: number | null;
}

interface ServiceRow {
  name: string | null;
  quantity: number | null;
}

const ContractDetails = (isEditing: { isEditing: boolean }) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();
  const product = watch('product');

  const { data: productStages, isLoading: isDeviceStageLoading } = useFetch(
    `/ProductsStage/listByProduct/${product.id}`
  );
  const { data: services, isLoading: isServiceLoading } = useFetch(`/Service/dropdown`);

  const { productData: stageType, isProductLoading } = useProduct('Stages');

  const expiryDate = watch('expiryDate');
  const contractDate = watch('contractDate');
  const installmentsTypeName = watch('installmentsTypeName');
  const contractTypeName = watch('contractTypeName');
  // const products = useProduct('st');

  const [productTableData, setProductTableData] = useState<ProductRow[]>([]);
  const [serviceTableData, setServiceTableData] = useState<ServiceRow[]>([]);
  const [deviceStages, setDeviceStages] = useState<any>([]);

  const addNewProductRow = () => {
    const newRow: ProductRow = {
      stageId: null,
      name: null,
      availableQuantity: null,
      quantity: null
    };
    setProductTableData((prevData) => [...prevData, newRow]);
  };

  const addNewServiceRow = () => {
    const newRow = {
      name: null,
      quantity: null
    };
    setServiceTableData((prevData: any) => [...prevData, newRow]);
  };

  const handleProductRowRemove = (index: number) => {
    setProductTableData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleServiceRowRemove = (index: number) => {
    setServiceTableData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleProductRowUpdate = (updatedData: ProductRow[]) => {
    setProductTableData((prevData) =>
      prevData.map((row, index) => ({
        ...row,
        ...updatedData[index] // Only override fields from updatedData
      }))
    );
    setValue('productsStock', updatedData);
  };

  const handleServiceRowUpdate = (updatedData: ServiceRow[]) => {
    setServiceTableData((prevData) =>
      prevData.map((row, index) => ({
        ...row,
        ...updatedData[index] // Only override fields from updatedData
      }))
    );
    setValue('servicesStock', updatedData);
  };

  const handleDeviceStageRowUpdate = (updatedData: any) => {
    const mergedData = updatedData.map((updatedRow: any, index: number) => {
      return { ...deviceStages[index], ...updatedRow };
    });
    setDeviceStages(mergedData);
    setValue('deviceStages', mergedData);
  };

  useEffect(() => {
    if (productStages?.data && !isEditing.isEditing) {
      if (!deviceStages || deviceStages.length === 0) {
        const initialDeviceStages = productStages.data.map((stage: any) => ({
          ...stage,
          productStageId: stage.id,
          stageItemId: stage.stageItemID,
          installedItemId: stage.installedItemId, // Preserve installedItemId from ContractChooserStep
          id: undefined
        }));

        setDeviceStages(initialDeviceStages);
        setValue('deviceStages', initialDeviceStages);
      }
    }
  }, [productStages?.data, productStages]);

  useEffect(() => {
    if (productStages?.data && isEditing.isEditing) {
      // Merge deviceStages with productStages to populate stageItemName
      const updatedDeviceStages = deviceStages.map((stage: any) => {
        // Find the corresponding product stage
        const productStage = productStages.data.find((ps: { stageNb: any }) => ps.stageNb === stage.stageNb);
        
        return {
          ...stage,
          stageItemId: productStage.stageItemID ?? stage.stageItemId,
          stageItemName: productStage ? productStage.stageItemName : stage.stageItemName,
          productStageId: productStage ? productStage.id : stage.productStageId,
          installedItemId: stage.installedItemId
        };
      });
      setDeviceStages(updatedDeviceStages);
      setValue('deviceStages', updatedDeviceStages);
    }
  }, [productStages?.data, deviceStages, isEditing]);

  useEffect(() => {
    const productsStock = watch('productsStock');
    if (productsStock) {
      setProductTableData(productsStock);
    }
  }, [watch('productsStock')]);

  useEffect(() => {
    const servicesStock = watch('servicesStock');
    if (servicesStock) {
      setServiceTableData(servicesStock);
    }
  }, [watch('servicesStock')]);

  useEffect(() => {
    const deviceStagesData = watch('deviceStages');
    if (deviceStagesData) {
      setDeviceStages(deviceStagesData);
    }
  }, [watch('deviceStages')]);

  const stageColumn: EditableColumnProps[] = [
    { field: 'stageNb', header: 'رقم المرحلة', editable: false, type: 'text' },
    {
      field: 'stageItemName',
      header: 'اسم المنتج',
      editable: false,
      type: 'text'
    },
    {
      field: 'installedItemId',
      header: 'نوع الشمعه',
      editable: true,
      type: 'dropdown',
      options: stageType?.data ?? [],
      idField: 'id',
      labelField: 'name'
    },
    { field: 'replacementMonths', header: 'شهور الاستبدال', editable: true, type: 'number' },
    { field: 'mandatoryReplacement', header: 'يجب تغييرها', editable: true, type: 'checkBox' },
    { field: 'remarks', header: 'ملاحظات', editable: true, type: 'text' },
    { field: 'productStageId', header: '', editable: false, type: 'text', hidden: true },
    { field: 'stageItemId', header: '', editable: false, type: 'text', hidden: true },
    { field: 'lastReplacementDate', header: '', editable: false, type: 'text', hidden: true }
  ];

  const productsColumn: EditableColumnProps[] = [
    {
      field: 'itemId',
      header: 'معرف المنتج',
      editable: true,
      type: 'dropdown',
      options: stageType?.data ?? [],
      idField: 'id',
      labelField: 'name'
    },
    { field: 'qty', header: 'الكمية', editable: true, type: 'number' },
    { field: 'remainingQty', header: 'الكمية المتبقية', editable: true, type: 'number' },
    { field: 'remarks', header: 'ملاحظات', editable: true, type: 'text' }
  ];

  const serviceColumns: EditableColumnProps[] = [
    {
      field: 'serviceId',
      header: 'معرف الخدمة',
      editable: true,
      type: 'dropdown',
      options: services?.data ?? [],
      idField: 'id',
      labelField: 'name'
    },
    { field: 'qty', header: 'الكمية', editable: true, type: 'number' },
    { field: 'remainingQty', header: 'الكمية المتبقية', editable: true, type: 'number' },
    { field: 'remarks', header: 'ملاحظات', editable: true, type: 'text' }
  ];
  const customer = watch('customer');
  return (
    <>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        {customer && (
          <ContractDetailsHeader
            customerName={customer.fullName}
            phoneNumber={customer.mobile}
            skuNumber={product?.skuCode}
            productName={product?.deviceName}
            contractType={contractTypeName}
            contractDate={new Date(contractDate).toLocaleDateString('en-GB')}
            expiryDate={new Date(expiryDate).toLocaleDateString('en-GB')}
            installmentsType={installmentsTypeName}
          />
        )}

        <Message
          severity='success'
          icon={null}
          content={<p className={'text-black-alpha-90 font-semibold'}>مراحل الجهاز</p>}
          style={{
            width: '100%',
            marginTop: '20px',
            marginBottom: '1rem',
            backgroundColor: 'rgba(162, 251, 93, 0.2)'
          }}
        />

        <label className={styles.detailsTitle}>{`بيان مراحل الجهاز“${product.name ?? product.deviceName}”`}</label>

        <GenericEditableDataTable
          data={deviceStages}
          columns={stageColumn}
          isLoading={isDeviceStageLoading}
          onRowUpdate={handleDeviceStageRowUpdate}
        />

        <Message
          severity='success'
          icon={null}
          content={<p className={'text-black-alpha-90 font-semibold'}>رصيد المنتجات</p>}
          style={{
            width: '100%',
            marginTop: '20px',
            marginBottom: '1rem',
            backgroundColor: 'rgba(162, 251, 93, 0.2)'
          }}
        />

        <div style={{ display: 'flex', marginBottom: '8px', justifyContent: 'space-between' }}>
          <label className={styles.detailsTitle}>{`بيان رصيد المنتجات`}</label>
          <CustomButton label={'إضف منتج'} type={'button'} onClick={addNewProductRow} />
        </div>

        <GenericEditableDataTable<ProductRow>
          data={productTableData}
          columns={productsColumn}
          isLoading={isProductLoading}
          onRowUpdate={handleProductRowUpdate}
          onRowRemove={handleProductRowRemove}
        />

        <Message
          severity='success'
          icon={null}
          content={<p className={'text-black-alpha-90 font-semibold'}>رصيد الخدمات</p>}
          style={{
            width: '100%',
            marginTop: '20px',
            marginBottom: '1rem',
            backgroundColor: 'rgba(162, 251, 93, 0.2)'
          }}
        />

        <div style={{ display: 'flex', marginBottom: '8px', justifyContent: 'space-between' }}>
          <label className={styles.detailsTitle}>{`بيان رصيد الخدمات`}</label>
          <CustomButton label={'إضف خدمة'} type={'button'} onClick={addNewServiceRow} />
        </div>

        <GenericEditableDataTable<ServiceRow>
          data={serviceTableData}
          columns={serviceColumns}
          isLoading={isServiceLoading}
          onRowUpdate={handleServiceRowUpdate}
          onRowRemove={handleServiceRowRemove}
        />
      </Card>
    </>
  );
};

export default ContractDetails;
