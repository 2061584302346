import { useState } from 'react';
import { axiosInstance } from '../global';
import { useApiState } from './useApiState';

export type IdAndName = {
  id: number;
  name: string;
};

const useActivityHierarchy = () => {
  const [selectedActivityType, setSelectedActivityType] = useState<IdAndName | null>(null);
  const [selectedActivityFamily, setSelectedActivityFamily] = useState<IdAndName | null>(null);
  const [selectedActivityCategory, setSelectedActivityCategory] = useState<IdAndName | null>(null);
  const [selectedActivity, setSelectedActivity] = useState<IdAndName | null>(null);

  const activityTypeApiState = useApiState<IdAndName[]>({
    apiCall: () => axiosInstance.get('/ActivitiesType/dropdown').then((res) => res.data.data),
    isLazy: false // Always load Activity types on initialization
  });

  const activityFamilyApiState = useApiState<IdAndName[], IdAndName[], { activityTypeId: number }>({
    apiCall: async (args) => {
      if (args && args.activityTypeId) {
        const res = await axiosInstance.get(`/ActivitiesFamily/allByTypeDropdown/${args.activityTypeId}`);
        return res.data.data;
      }
    },
    isLazy: true
  });

  const activityCategoryApiState = useApiState<IdAndName[], IdAndName[], { activityFamilyId: number }>({
    apiCall: async (args) => {
      if (args && args.activityFamilyId) {
        const res = await axiosInstance.get(`/ActivitiesCategory/allByFamilyDropdown/${args.activityFamilyId}`);
        return res.data.data;
      }
    },
    isLazy: true
  });

  const activityApiState = useApiState<IdAndName[], IdAndName[], { activityCategoryId: number }>({
    apiCall: async (args) => {
      if (args && args.activityCategoryId) {
        const res = await axiosInstance.get(`/Activity/allByCategoryDropdown/${args.activityCategoryId}`);
        return res.data.data;
      }
    },
    isLazy: true
  });

  const handleActivityTypeSelect = async (activityType: IdAndName) => {
    setSelectedActivityType(activityType);
    setSelectedActivityFamily(null);
    setSelectedActivityCategory(null);
    setSelectedActivity(null);
    await activityFamilyApiState.lazyApiCall({ activityTypeId: activityType.id });
  };

  const handleActivityFamilySelect = async (activityFamily: IdAndName) => {
    setSelectedActivityFamily(activityFamily);
    setSelectedActivityCategory(null);
    setSelectedActivity(null);
    await activityCategoryApiState.lazyApiCall({ activityFamilyId: activityFamily.id });
  };

  const handleActivityCategorySelect = async (activityCategory: IdAndName) => {
    setSelectedActivityCategory(activityCategory);
    setSelectedActivity(null);
    await activityApiState.lazyApiCall({ activityCategoryId: activityCategory.id });
  };

  const handleActivitySelect = (activity: IdAndName) => {
    setSelectedActivity(activity);
  };

  return {
    activityTypeApiState,
    activityFamilyApiState,
    activityCategoryApiState,
    activityApiState,
    selectedActivityType,
    selectedActivityFamily,
    selectedActivityCategory,
    selectedActivity,
    handleActivityTypeSelect,
    handleActivityFamilySelect,
    handleActivityCategorySelect,
    handleActivitySelect,
    resetActivityHierarchyState: () => {
      setSelectedActivityType(null);
      setSelectedActivityFamily(null);
      setSelectedActivityCategory(null);
      setSelectedActivity(null);
      activityFamilyApiState.resetApiCallState();
      activityCategoryApiState.resetApiCallState();
      activityApiState.resetApiCallState();
    }
  };
};

export default useActivityHierarchy;
