import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { genericSchemeForNames } from '../../../components/components/Form/Scheme';
import useModal from '../../../hooks/useModal';
import GenericForm from '../../../components/Forms/GenericForm';
import { useToast } from '../../../context/ToastContext';
import GenericModal from '../../../components/components/Modal/GenericModal';

function CustomerServiceList() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };

  const {
    isVisible: isEvaluationTypeVistable,
    openModal: evaluationTypeOpenModal,
    closeModal: evaluationTypeCloseModal,
    control: evaluationTypeControl,
    handleSubmit: evaluationTypeSubmit,
    errors: evaluationTypeErrors,
    setValue: evaluationTypeSetValue,
    loading: evaluationTypeLoading,
    setLoading: evaluationTypeSetLoading,
    watch: evaluationTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/EvaluationsTypesList', fetchTabData, genericSchemeForNames, toast);

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'انواع التقييم',
            tableHeader: 'انواع التقييم',
            entityName: 'التقييم',
            dataKey: 'EvaluationsType',
            apiUrl: '/EvaluationsTypesList/list',
            columns: [
              { header: 'اسم نوع التقييم', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة نوع تقييم جديد',
                onClick: () => {
                  evaluationTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              evaluationTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              evaluationTypeOpenModal();
            }
          }
        ]}
        headerTitle='بيانات الزيارات'
        headerIcon={'../images/briefcase1.svg'}
      />
      <GenericModal
        headerTitle={'إضافة انواع تقييم جديدة'}
        isVisible={isEvaluationTypeVistable}
        onHide={evaluationTypeCloseModal}
        handleSubmit={evaluationTypeSubmit}
        toast={toast}
        loading={evaluationTypeLoading}
        isEditMode={evaluationTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={evaluationTypeControl}
          watch={evaluationTypeWatch}
          errors={evaluationTypeErrors}
          setValue={evaluationTypeSetValue}
          extraProps={{
            entityName: 'نوع التقييم'
          }}
        />
      </GenericModal>
    </>
  );
}

export default CustomerServiceList;
