import { useState } from 'react';
import { axiosInstance } from '../global';
import { AxiosProgressEvent } from 'axios';
import { CustomUploadFile } from '../components/UploadFile/UploadFile';
import imageCompression from 'browser-image-compression';

interface UseUploadReturn {
  isUploading: boolean;
  uploadProgress: number;
  error: string | null;
  responseMessage: string;
  uploadFiles: (files: CustomUploadFile[], entityType: string, entityId: number) => Promise<void>;
}

const useUpload = ({
  withDescription = false,
  withFlags = false
}: {
  withDescription?: boolean;
  withFlags?: boolean;
}): UseUploadReturn => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [responseMessage, setResponseMessage] = useState<string>('');

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const uploadFiles = async (files: CustomUploadFile[], entityType: string, entityId: number): Promise<void> => {
    setIsUploading(true);
    setError(null);
    setUploadProgress(0);

    const formData = new FormData();
    for (const file of files) {
      if (!file.originFileObj) {
        formData.append('filePath', file?.imagePath);
      } else {
        const compressedFile = await imageCompression(file.originFileObj as File, options);
        formData.append('files', file.originFileObj as File);
      }

      if (!file.originFileObj && !file.imagePath) {
        // const compressedFile = await imageCompression(file as unknown as File, options);

        formData.append('files', file as unknown as File);
      }

      withDescription && formData.append('remarks', file.remarks || '');
      withFlags &&
        (() => {
          formData.append('showInGallery', JSON.stringify(!!file?.showInGalary));
          formData.append('showInStore', JSON.stringify(!!file?.showInStore));
        })();
    }
    formData.append('entityType', entityType);
    formData.append('entityId', entityId.toString());

    try {
      const response = await axiosInstance.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          }
        }
      });

      setResponseMessage(response.data);
    } catch (err: any) {
      setError(err.message || 'Failed to upload files');
    } finally {
      setIsUploading(false);
    }
  };

  return { isUploading, uploadProgress, error, responseMessage, uploadFiles };
};

export default useUpload;
