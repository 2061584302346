import { useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useFetch from '../../../hooks/useFetch';
import useProductHierarchy from '../../../hooks/useProductHierarchy';
import useStatus from '../../../hooks/useStatus';
import './AddProductDetails.scss';

const AddEditProductInfo = ({ productType }: { productType: string }) => {
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext();
  const family = watch('productFamily');

  const { data: manufacturer } = useFetch('Manufacturer/dropdown');
  const { data: country } = useFetch('Country/dropdown');
  const { data: brand } = useFetch('Brand/dropdown');
  const { data: installmentsType } = useFetch('InstallmentsType/dropdown');
  const { statusData } = useStatus('Product');
  const { handleProductTypeSelect, productFamilyApiState, handleProductFamilySelect, productCategoryApiState } =
    useProductHierarchy();

  useEffect(() => {
    const productTypeId = productType === 'stages' ? 1 : productType === 'spare' ? 3 : 2;

    handleProductTypeSelect({ id: productTypeId, name: String(productTypeId) });
  }, []);

  useEffect(() => {
    if (family) {
      handleProductFamilySelect({ id: family, name: family });
    }
  }, [family]);

  return (
    <form className='product'>
      <div className='product__content'>
        {/* page right content */}
        <div className='product__content__right'>
          <span className='Title'>عن المنتج</span>
          <CustomFormField name={'code'} label={'كود المنتج'} control={control} errors={errors} type={'text'} />
          <CustomFormField
            name={'skuCode'}
            label={'رقم المنتج / SKU'}
            control={control}
            errors={errors}
            type={'text'}
          />
          <CustomFormField
            name={'name'}
            label={'اسم المنتج بالعربية'}
            control={control}
            errors={errors}
            type={'text'}
          />
          <CustomFormField
            name={'details'}
            label={'وصف المنتج بالعربية'}
            control={control}
            errors={errors}
            type={'textarea'}
          />
          <CustomFormField
            name={'manufacturer'}
            label={'مصنع المنتج'}
            control={control}
            errors={errors}
            type={'dropdown'}
            options={manufacturer?.data ?? []}
          />

          <CustomFormField
            name={'productFamily'}
            label={'تصنيف المنتج'}
            control={control}
            errors={errors}
            type={'dropdown'}
            options={productFamilyApiState?.state?.data ?? []}
          />

          <CustomFormField
            name={'productCategory'}
            label={'فئة المنتج'}
            control={control}
            errors={errors}
            type={'dropdown'}
            options={productCategoryApiState?.state?.data ?? []}
          />

          <CustomFormField
            name={'brand'}
            label={'الماركة'}
            control={control}
            errors={errors}
            type={'dropdown'}
            options={brand?.data ?? []}
          />

          <CustomFormField
            name={'countryOfOrigin'}
            label={'بلد المنشأ'}
            control={control}
            errors={errors}
            type={'dropdown'}
            options={country?.data ?? []}
          />
        </div>

        {/* page left content */}
        <div className='product__content__left'>
          {productType === 'spare' || productType === 'stages' ? null : (
            <>
              <span className='Title'>مراحل المنتج</span>

              <div className='flex w-full flex-column gap-4'>
                <div className='stageNumNote'>
                  <li className='pi pi-info-circle'></li>
                  ستقوم ب أضافة المراحل بالتفصيل فالخطوة القادمة
                </div>

                <CustomFormField
                  name={'nbOfStages'}
                  label={'عدد مراحل الفلتر'}
                  control={control}
                  errors={errors}
                  type={'decimal'}
                />
              </div>
            </>
          )}
          {productType === 'spare' || productType === 'stages' ? null : (
            <>
              <span className='Title'>عن الدفع و طرق التقسيط </span>
              <CustomFormField
                name={'installmentTypesIds'}
                label={'طرق التقسيط'}
                control={control}
                errors={errors}
                optionValue='id'
                optionLabel='name'
                placeholder='ابحث عن طرق التقسيط'
                type={'multi-select'}
                options={installmentsType?.data ?? []}
              />
            </>
          )}

          <span className='Title'>حالة المنتج</span>
          <CustomFormField
            name={'status'}
            label={'حالة المنتج'}
            control={control}
            errors={errors}
            type={'dropdown'}
            options={statusData?.data ?? []}
          />

          <CustomFormField name={'remarks'} label={'ملاحاظات'} control={control} errors={errors} type={'textarea'} />
        </div>
      </div>
    </form>
  );
};

export default AddEditProductInfo;
