import React from 'react';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Sidebar } from 'primereact/sidebar';
import { CustomArgsProps } from '../../../context/ToastContext';

interface GenericModalProps {
  headerTitle: string;
  isVisible: boolean;
  onHide: () => void;
  handleSubmit: (data: any) => void;

  onDelete?: (data: any) => void;
  toast: { current: { show: (args: CustomArgsProps) => void } };
  children: React.ReactNode;
  loading: boolean;
  isEditMode?: boolean;
}

const GenericModal: React.FC<GenericModalProps> = ({
  headerTitle,
  isVisible,
  onHide,
  handleSubmit,
  onDelete,
  children,
  loading,
  isEditMode = false
}) => (
  <Sidebar
    position={'left'}
    visible={isVisible}
    onHide={onHide}
    className='modals'
    draggable={false}
    closeOnEscape={true}
  >
    <div
      className='p-sidebar-header'
      style={{
        padding: '0',
        position: 'absolute',
        top: '23px',
        maxWidth: 'calc(100% - 75px)'
      }}
    >
      <h2
        style={{
          fontSize: '16px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        {headerTitle}
      </h2>
    </div>

    {/* Forms Section */}
    <div className='relative'>
      <form onSubmit={handleSubmit} className='flex flex-column gap-5'>
        {children}
        <div className='flex justify-content-end'>
          {/*<Button label='حذف' onClick={onDelete} size='small' outlined type='button' severity={'danger'} />*/}
          <Button label='إلغاء' onClick={onHide} size='small' outlined type='button' className='modals__btn' />
          <Button type='submit' label={isEditMode ? 'تعديل' : 'إضافة'} size='small' className='modals__btn' />
        </div>
      </form>

      {/* Overlay for loading */}
      {loading && (
        <div
          className='absolute top-0 left-0 w-full h-full flex justify-content-center align-items-center'
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', zIndex: 1 }}
        >
          <ProgressSpinner />
        </div>
      )}
    </div>
  </Sidebar>
);

export default GenericModal;
