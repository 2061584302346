import {
  FETCH_VISIT_REQUEST,
  FETCH_VISIT_SUCCESS,
  FETCH_VISIT_FAILURE,
  ADD_VISIT_REQUEST,
  ADD_VISIT_SUCCESS,
  ADD_VISIT_FAILURE,
  EDIT_VISIT_REQUEST,
  EDIT_VISIT_SUCCESS,
  EDIT_VISIT_FAILURE,
  CHANGE_VISIT_STATE,
  CLEAR_VISIT_STATE,
  FETCH_INSTALLMENTVISIT_REQUEST,
  FETCH_INSTALLMENTVISIT_SUCCESS,
  FETCH_INSTALLMENTVISIT_FAILURE,
  ADD_INSTALLMENTVISIT_REQUEST,
  ADD_INSTALLMENTVISIT_SUCCESS,
  ADD_INSTALLMENTVISIT_FAILURE,
  EDIT_INSTALLMENTVISIT_REQUEST,
  EDIT_INSTALLMENTVISIT_SUCCESS,
  EDIT_INSTALLMENTVISIT_FAILURE,
  CHANGE_INSTALLMENTVISIT_STATE,
  CHANGE_INSTALLMENTVISIT_TEMP_STATE,
  CLEAR_INSTALLMENTVISIT_STATE,
  FETCH_STAGEVISIT_REQUEST,
  FETCH_STAGEVISIT_SUCCESS,
  FETCH_STAGEVISIT_FAILURE,
  ADD_STAGEVISIT_REQUEST,
  ADD_STAGEVISIT_SUCCESS,
  ADD_STAGEVISIT_FAILURE,
  EDIT_STAGEVISIT_REQUEST,
  EDIT_STAGEVISIT_SUCCESS,
  EDIT_STAGEVISIT_FAILURE,
  CHANGE_STAGEVISIT_STATE,
  CHANGE_STAGEVISIT_TEMP_STATE,
  FETCH_FAULTSVISIT_REQUEST,
  FETCH_FAULTSVISIT_SUCCESS,
  FETCH_FAULTSVISIT_FAILURE,
  ADD_FAULTSVISIT_REQUEST,
  ADD_FAULTSVISIT_SUCCESS,
  ADD_FAULTSVISIT_FAILURE,
  EDIT_FAULTSVISIT_REQUEST,
  EDIT_FAULTSVISIT_SUCCESS,
  EDIT_FAULTSVISIT_FAILURE,
  CHANGE_FAULTSVISIT_STATE,
  CHANGE_FAULTSVISIT_TEMP_STATE,
  FETCH_REQITEMSVISIT_REQUEST,
  FETCH_REQITEMSVISIT_SUCCESS,
  FETCH_REQITEMSVISIT_FAILURE,
  ADD_REQITEMSVISIT_REQUEST,
  ADD_REQITEMSVISIT_SUCCESS,
  ADD_REQITEMSVISIT_FAILURE,
  EDIT_REQITEMSVISIT_REQUEST,
  EDIT_REQITEMSVISIT_SUCCESS,
  EDIT_REQITEMSVISIT_FAILURE,
  CHANGE_REQITEMSVISIT_STATE,
  CHANGE_REQITEMSVISIT_TEMP_STATE,
  FETCH_SERVICEVISIT_REQUEST,
  FETCH_SERVICEVISIT_SUCCESS,
  FETCH_SERVICEVISIT_FAILURE,
  ADD_SERVICEVISIT_REQUEST,
  ADD_SERVICEVISIT_SUCCESS,
  ADD_SERVICEVISIT_FAILURE,
  EDIT_SERVICEVISIT_REQUEST,
  EDIT_SERVICEVISIT_SUCCESS,
  EDIT_SERVICEVISIT_FAILURE,
  CHANGE_SERVICEVISIT_STATE,
  CHANGE_SERVICEVISIT_TEMP_STATE,
  CHANGE_VISITCOST_STATE,
  CLEAR_STAGEVISIT_STATE,
  CLEAR_FAULTSVISIT_STATE,
  CLEAR_REQITEMSVISIT_STATE,
  CLEAR_SERVICEVISIT_STATE
} from './visitTypes';

import { axiosInstance } from '../../global';

//====================fetch data===================================
// export const fetchVisit = (id) => {
//   return (dispatch) => {
//     dispatch(fetch_visit_request());
//      axiosInstance
//       .get(`/Visit/${id}`)
//       .then((responseVisitData) => {
//         const tempVisit = responseVisitData.data.data;
//         const formattedVisit = {
//           id:tempVisit.id,
//           code : tempVisit.code,
//           name : tempVisit.name,
//           customerId : tempVisit.customer.id,
//           contractId : tempVisit.contract.id,
//           typeId : tempVisit.type.id,
//           customerActivityId : tempVisit.customerActivity.id,
//           requestedDateTime : tempVisit.requestedDateTime,
//           visitDate : tempVisit.visitDate,
//           assignedRepId : tempVisit.assignedRep.id,
//           priorityId : tempVisit.priority.id,
//           statusId : tempVisit.status.id,
//           repRemarks : tempVisit.repRemarks,
//           remarks : tempVisit.remarks,
//         }
//         dispatch(fetch_visit_success(formattedVisit));
//       })
//       .catch((errorVisitData) => {
//         dispatch(fetch_visit_failure(errorVisitData));
//       });
//   };
// };
export const fetchVisit = (id) => {
  return async (dispatch) => {
    dispatch(fetch_visit_request());
    try {
      const responseVisitData = await axiosInstance.get(`/Visit/${id}`);
      const tempVisit = responseVisitData.data.data;
      const formattedVisit = {
        id: tempVisit.id,
        code: tempVisit.code,
        name: tempVisit.name,
        customerId: tempVisit.customer.id,
        contractId: tempVisit.contract.id,
        typeId: tempVisit.type.id,
        customerActivityId: tempVisit.customerActivity.id,
        requestedDateTime: tempVisit.requestedDateTime,
        visitDate: tempVisit.visitDate,
        preferredTimePeriodId: tempVisit.preferredTimePeriod.id,
        assignedRepId: tempVisit.assignedRep.id,
        priorityId: tempVisit.priority.id,
        statusId: tempVisit.status.id,
        repRemarks: tempVisit.repRemarks,
        remarks: tempVisit.remarks
      };
      dispatch(fetch_visit_success(formattedVisit));
    } catch (errorVisitData) {
      dispatch(fetch_visit_failure(errorVisitData));
    }
  };
};

const fetch_visit_request = () => {
  return {
    type: FETCH_VISIT_REQUEST
  };
};
const fetch_visit_success = (visit) => {
  return {
    type: FETCH_VISIT_SUCCESS,
    payload: visit
  };
};
const fetch_visit_failure = (error) => {
  return {
    type: FETCH_VISIT_FAILURE,
    payload: error
  };
};

//=====================add data======================================
export const addVisit = (visit) => {
  return (dispatch) => {
    dispatch(add_visit_request());
    axiosInstance
      .post(`/Visit`, visit)
      .then((response) => {
        dispatch(add_visit_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_visit_failure(error.message));
      });
  };
};
const add_visit_request = () => {
  return {
    type: ADD_VISIT_REQUEST
  };
};
const add_visit_success = (visit) => {
  return {
    type: ADD_VISIT_SUCCESS,
    payload: visit
  };
};
const add_visit_failure = (error) => {
  return {
    type: ADD_VISIT_FAILURE,
    payload: error
  };
};

//=====================edit data=====================================
export const editVisit = (visit) => {
  const payload = {
    id: visit.id,
    code: visit.code,
    name: visit.name,
    customerId: visit.customerId,
    contractId: visit.contractId,
    typeId: visit.typeId,
    customerActivityId: visit.customerActivityId,
    requestedDateTime: visit.requestedDateTime,
    visitDate: visit.visitDate,
    preferredTimePeriodId: visit.preferredTimePeriodId,
    assignedRepId: visit.assignedRepId,
    priorityId: visit.priorityId,
    statusId: visit.statusId,
    repRemarks: visit.repRemarks,
    remarks: visit.remarks
  };
  return (dispatch) => {
    dispatch(edit_visit_request());
    axiosInstance
      .put(`/Visit`, payload)
      .then((response) => {
        dispatch(edit_visit_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_visit_failure(error.message));
      });
  };
};
const edit_visit_request = () => {
  return {
    type: EDIT_VISIT_REQUEST
  };
};
const edit_visit_success = (visit) => {
  return {
    type: EDIT_VISIT_SUCCESS,
    payload: visit
  };
};
const edit_visit_failure = (error) => {
  return {
    type: EDIT_VISIT_FAILURE,
    payload: error
  };
};

//=================fill with data=====================================
export const fill_visit = (visit) => {
  return (dispatch) => {
    dispatch(change_visit_state(visit));
  };
};
const change_visit_state = (visit) => {
  return {
    type: CHANGE_VISIT_STATE,
    payload: visit
  };
};

export const clearVisitState = () => {
  return {
    type: CLEAR_VISIT_STATE
  };
};

//******************************************************visitInstallments***********************************************************
//====================fetch data===================================
export const fetchvisitInstallment = (id) => {
  return (dispatch) => {
    dispatch(fetch_visitInstallment_request());
    axiosInstance
      .get(`/VisitsInstallment/allByVisit/${id}`)
      .then((responseVisitData) => {
        const tempVisitInstallment = responseVisitData.data.data;
        const formattedInstallment = {
          id: tempVisitInstallment.id,
          visitId: tempVisitInstallment.visit.id,
          installmentDetails: tempVisitInstallment.referencedItems.map((installment) => ({
            id: installment.contractsInstallmentId,
            collectionDate: installment.contractsInstallmentCollectionDate,
            installmentAmount: installment.contractsInstallmentInstallmentAmount,
            totalPaidAmount: installment.contractsInstallmentTotalPaidAmount,
            remainingAmount: installment.contractsInstallmentRemainingAmount,
            installmentNb: installment.contractsInstallmentInstallmentNb,
            installmentsType: {
              id: installment.installmentsTypeId,
              name: installment.installmentsTypeName
            },
            status: {
              id: installment.statusId,
              name: installment.statusName
            }
          }))
        };
        dispatch(fetch_visitInstallment_success(formattedInstallment));
      })
      .catch((errorvisitInstallmentData) => {
        dispatch(fetch_visitInstallment_failure(errorvisitInstallmentData.message));
      });
  };
};
const fetch_visitInstallment_request = () => {
  return {
    type: FETCH_INSTALLMENTVISIT_REQUEST
  };
};
const fetch_visitInstallment_success = (visitInstallment) => {
  return {
    type: FETCH_INSTALLMENTVISIT_SUCCESS,
    payload: visitInstallment
  };
};
const fetch_visitInstallment_failure = (error) => {
  return {
    type: FETCH_INSTALLMENTVISIT_FAILURE,
    payload: error
  };
};

//=====================add data======================================
export const addvisitInstallment = (visitInstallment) => {
  return (dispatch) => {
    dispatch(add_visitInstallment_request());
    axiosInstance
      .post(`/VisitsInstallment`, visitInstallment)
      .then((response) => {
        dispatch(add_visitInstallment_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_visitInstallment_failure(error.message));
      });
  };
};
const add_visitInstallment_request = () => {
  return {
    type: ADD_INSTALLMENTVISIT_REQUEST
  };
};
const add_visitInstallment_success = (visitInstallment) => {
  return {
    type: ADD_INSTALLMENTVISIT_SUCCESS,
    payload: visitInstallment
  };
};
const add_visitInstallment_failure = (error) => {
  return {
    type: ADD_INSTALLMENTVISIT_FAILURE,
    payload: error
  };
};

//=====================edit data=====================================
export const editvisitInstallment = (visitInstallment) => {
  const payload = {
    // id: visitInstallment.id,
    visitId: visitInstallment.visitId,
    installmentDetails: visitInstallment.installmentDetails,
    remarks: visitInstallment.remarks
  };
  return (dispatch) => {
    dispatch(edit_visitInstallment_request());
    axiosInstance
      .put(`/VisitsInstallment`, payload)
      .then((response) => {
        dispatch(edit_visitInstallment_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_visitInstallment_failure(error.message));
      });
  };
};
const edit_visitInstallment_request = () => {
  return {
    type: EDIT_INSTALLMENTVISIT_REQUEST
  };
};
const edit_visitInstallment_success = (visitInstallment) => {
  return {
    type: EDIT_INSTALLMENTVISIT_SUCCESS,
    payload: visitInstallment
  };
};
const edit_visitInstallment_failure = (error) => {
  return {
    type: EDIT_INSTALLMENTVISIT_FAILURE,
    payload: error
  };
};

//=================fill with data=====================================
export const fill_visitInstallment = (visitInstallment) => {
  return (dispatch) => {
    dispatch(change_visitInstallment_state(visitInstallment));
  };
};
const change_visitInstallment_state = (visitInstallment) => {
  return {
    type: CHANGE_INSTALLMENTVISIT_STATE,
    payload: visitInstallment
  };
};

export const fill_visitInstallment_temp = (visitInstallmentTemp) => {
  return (dispatch) => {
    dispatch(change_visitInstallment_state_temp(visitInstallmentTemp));
  };
};
const change_visitInstallment_state_temp = (visitInstallmentTemp) => {
  return {
    type: CHANGE_INSTALLMENTVISIT_TEMP_STATE,
    payload: visitInstallmentTemp
  };
};

export const clearvisitInstallmentState = () => {
  return {
    type: CLEAR_INSTALLMENTVISIT_STATE
  };
};

//******************************************************visitStages*****************************************************************
//====================fetch data===================================
export const fetchvisitStages = (id) => {
  return (dispatch) => {
    dispatch(fetch_visitStages_request());
    axiosInstance
      .get(`/VisitsDeviceStage/allByVisit/${id}`)
      .then((responseVisitData) => {
        const tempVisitStages = responseVisitData.data.data;
        // 
        const formattedStages = {
          visitId: tempVisitStages.visit.id,
          contractDeviceStageIds: tempVisitStages.referencedItems.map((stages) => ({
            id: stages.contractDeviceStageId,
            stageNb: stages.contractDeviceStageStageNb,
            remainingQty: stages.remainingQty,
            nextReplacementDate: stages.nextReplacementDate,
            price: stages.price,
            stageItem: {
              id: stages.contractDeviceStageItem.id,
              name: stages.contractDeviceStageItem.name
            },
            status: {
              id: stages.statusId,
              name: stages.statusName
            }
          })),
          remarks: tempVisitStages.remarks
        };
        // 

        dispatch(fetch_visitStages_success(formattedStages));
      })
      .catch((errorvisitStagesData) => {
        dispatch(fetch_visitStages_failure('pp', errorvisitStagesData.message));
      });
  };
};
const fetch_visitStages_request = () => {
  return {
    type: FETCH_STAGEVISIT_REQUEST
  };
};
const fetch_visitStages_success = (visitStages) => {
  return {
    type: FETCH_STAGEVISIT_SUCCESS,
    payload: visitStages
  };
};
const fetch_visitStages_failure = (error) => {
  return {
    type: FETCH_STAGEVISIT_FAILURE,
    payload: error
  };
};

//=====================add data=====================================
export const addvisitStages = (visitStages) => {
  return (dispatch) => {
    dispatch(add_visitStages_request());
    axiosInstance
      .post(`/VisitsDeviceStage`, visitStages)
      .then((response) => {
        dispatch(add_visitStages_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_visitStages_failure(error.message));
      });
  };
};
const add_visitStages_request = () => {
  return {
    type: ADD_STAGEVISIT_REQUEST
  };
};
const add_visitStages_success = (visitStages) => {
  return {
    type: ADD_STAGEVISIT_SUCCESS,
    payload: visitStages
  };
};
const add_visitStages_failure = (error) => {
  return {
    type: ADD_STAGEVISIT_FAILURE,
    payload: error
  };
};

//=====================edit data=====================================
export const editvisitStages = (visitStages) => {
  const payload = {
    // id: visitStages.id,
    visitId: visitStages.visitId,
    contractDeviceStageIds: visitStages.contractDeviceStageIds,
    statusId: visitStages.statusId,
    remarks: visitStages.remarks
  };
  return (dispatch) => {
    dispatch(edit_visitStages_request());
    axiosInstance
      .put(`/VisitsDeviceStage`, payload)
      .then((response) => {
        dispatch(edit_visitStages_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_visitStages_failure(error.message));
      });
  };
};
const edit_visitStages_request = () => {
  return {
    type: EDIT_STAGEVISIT_REQUEST
  };
};
const edit_visitStages_success = (visitStages) => {
  return {
    type: EDIT_STAGEVISIT_SUCCESS,
    payload: visitStages
  };
};
const edit_visitStages_failure = (error) => {
  return {
    type: EDIT_STAGEVISIT_FAILURE,
    payload: error
  };
};

//=================fill with data====================================
export const fill_visitStages = (visitStages) => {
  return (dispatch) => {
    dispatch(change_visitStages_state(visitStages));
  };
};
const change_visitStages_state = (visitStages) => {
  return {
    type: CHANGE_STAGEVISIT_STATE,
    payload: visitStages
  };
};

export const fill_visitStages_temp = (visitStagesTemp) => {
  return (dispatch) => {
    dispatch(change_visitStages_state_temp(visitStagesTemp));
  };
};
const change_visitStages_state_temp = (visitStagesTemp) => {
  return {
    type: CHANGE_STAGEVISIT_TEMP_STATE,
    payload: visitStagesTemp
  };
};

export const clearvisitStageState = () => {
  return {
    type: CLEAR_STAGEVISIT_STATE
  };
};

//******************************************************visitFaults*****************************************************************
//====================fetch data===================================
export const fetchvisitFaults = (id) => {
  return (dispatch) => {
    dispatch(fetch_visitFaults_request());
    axiosInstance
      .get(`/VisitsFaultsRequest/allByVisit/${id}`)
      .then((responseVisitData) => {
        const tempVisitComplaint = responseVisitData.data.data;
        const formattedComplaint = {
          visitId: tempVisitComplaint.visit.id,
          visitsFaultsDetails: tempVisitComplaint.referencedItems.map((faults) => ({
            id: faults.customerComplaintId,
            code: faults.customerComplaintCode,
            complaintCategory: faults.customerComplaintCategoryName,
            complaintType: faults.customerComplaintTypeName,
            creationDateTime: faults.customerComplaintComplaintDate,
            status: {
              id: faults.statusId,
              name: faults.statusName
            }
          }))
        };
        dispatch(fetch_visitFaults_success(formattedComplaint));
      })
      .catch((errorvisitFaultsData) => {
        dispatch(fetch_visitFaults_failure(errorvisitFaultsData.message));
      });
  };
};
const fetch_visitFaults_request = () => {
  return {
    type: FETCH_FAULTSVISIT_REQUEST
  };
};
const fetch_visitFaults_success = (visitFaults) => {
  return {
    type: FETCH_FAULTSVISIT_SUCCESS,
    payload: visitFaults
  };
};
const fetch_visitFaults_failure = (error) => {
  return {
    type: FETCH_FAULTSVISIT_FAILURE,
    payload: error
  };
};

//=====================add data======================================
export const addvisitFaults = (visitFaults) => {
  return (dispatch) => {
    dispatch(add_visitFaults_request());
    axiosInstance
      .post(`/VisitsFaultsRequest`, visitFaults)
      .then((response) => {
        dispatch(add_visitFaults_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_visitFaults_failure(error.message));
      });
  };
};
const add_visitFaults_request = () => {
  return {
    type: ADD_FAULTSVISIT_REQUEST
  };
};
const add_visitFaults_success = (visitFaults) => {
  return {
    type: ADD_FAULTSVISIT_SUCCESS,
    payload: visitFaults
  };
};
const add_visitFaults_failure = (error) => {
  return {
    type: ADD_FAULTSVISIT_FAILURE,
    payload: error
  };
};

//=====================edit data=====================================
export const editvisitFaults = (visitFaults) => {
  const payload = {
    // id: visitFaults.id,
    visitId: visitFaults.visitId,
    visitsFaultsDetails: visitFaults.visitsFaultsDetails,
    remarks: visitFaults.remarks
  };
  return (dispatch) => {
    dispatch(edit_visitFaults_request());
    axiosInstance
      .put(`/VisitsFaultsRequest`, payload)
      .then((response) => {
        dispatch(edit_visitFaults_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_visitFaults_failure(error.message));
      });
  };
};
const edit_visitFaults_request = () => {
  return {
    type: EDIT_FAULTSVISIT_REQUEST
  };
};
const edit_visitFaults_success = (visitFaults) => {
  return {
    type: EDIT_FAULTSVISIT_SUCCESS,
    payload: visitFaults
  };
};
const edit_visitFaults_failure = (error) => {
  return {
    type: EDIT_FAULTSVISIT_FAILURE,
    payload: error
  };
};

//=================fill with data=====================================
export const fill_visitFaults = (visitFaults) => {
  return (dispatch) => {
    dispatch(change_visitFaults_state(visitFaults));
  };
};
const change_visitFaults_state = (visitFaults) => {
  return {
    type: CHANGE_FAULTSVISIT_STATE,
    payload: visitFaults
  };
};

export const fill_visitFaults_temp = (visitFaultsTemp) => {
  return (dispatch) => {
    dispatch(change_visitFaults_state_temp(visitFaultsTemp));
  };
};
const change_visitFaults_state_temp = (visitFaultsTemp) => {
  return {
    type: CHANGE_FAULTSVISIT_TEMP_STATE,
    payload: visitFaultsTemp
  };
};

export const clearvisitFaultState = () => {
  return {
    type: CLEAR_FAULTSVISIT_STATE
  };
};
//******************************************************visitRequstedItems**********************************************************
//====================fetch data===================================
export const fetchvisitRequstedItems = (id) => {
  ``;
  return (dispatch) => {
    dispatch(fetch_visitRequstedItems_request());
    axiosInstance
      .get(`/VisitsRequestedItem/allByVisit/${id}`)
      .then((responseVisitData) => {
        const tempVisitReqItem = responseVisitData.data.data;
        const formattedReqItem = {
          visitId: tempVisitReqItem.visit.id,
          requestedItemDetails: tempVisitReqItem.referencedItems.map((ReqItem) => ({
            itemId: ReqItem.itemId,
            productCategory: ReqItem.productCategoryName,
            name: ReqItem.itemName,
            itemPrice: ReqItem.itemPrice,
            requestedQty: ReqItem.requestedQty,
            statusId: ReqItem.statusId,
            status: {
              id: ReqItem.statusId,
              name: ReqItem.statusName
            }
          }))
        };
        dispatch(fetch_visitRequstedItems_success(formattedReqItem));
      })
      .catch((errorvisitRequstedItemsData) => {
        dispatch(fetch_visitRequstedItems_failure(errorvisitRequstedItemsData.message));
      });
  };
};
const fetch_visitRequstedItems_request = () => {
  return {
    type: FETCH_REQITEMSVISIT_REQUEST
  };
};
const fetch_visitRequstedItems_success = (visitRequstedItems) => {
  return {
    type: FETCH_REQITEMSVISIT_SUCCESS,
    payload: visitRequstedItems
  };
};
const fetch_visitRequstedItems_failure = (error) => {
  return {
    type: FETCH_REQITEMSVISIT_FAILURE,
    payload: error
  };
};

//=====================add data======================================
export const addvisitRequstedItems = (visitRequstedItems) => {
  return (dispatch) => {
    dispatch(add_visitRequstedItems_request());
    axiosInstance
      .post(`/VisitsRequestedItem`, visitRequstedItems)
      .then((response) => {
        dispatch(add_visitRequstedItems_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_visitRequstedItems_failure(error.message));
      });
  };
};
const add_visitRequstedItems_request = () => {
  return {
    type: ADD_REQITEMSVISIT_REQUEST
  };
};
const add_visitRequstedItems_success = (visitRequstedItems) => {
  return {
    type: ADD_REQITEMSVISIT_SUCCESS,
    payload: visitRequstedItems
  };
};
const add_visitRequstedItems_failure = (error) => {
  return {
    type: ADD_REQITEMSVISIT_FAILURE,
    payload: error
  };
};

//=====================edit data=====================================
export const editvisitRequstedItems = (visitRequstedItems) => {
  const payload = {
    // id: visitRequstedItems.id,
    visitId: visitRequstedItems.visitId,
    requestedItemDetails: visitRequstedItems.requestedItemDetails,
    remarks: visitRequstedItems.remarks
  };
  return (dispatch) => {
    dispatch(edit_visitRequstedItems_request());
    axiosInstance
      .put(`/VisitsRequestedItem`, payload)
      .then((response) => {
        dispatch(edit_visitRequstedItems_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_visitRequstedItems_failure(error.message));
      });
  };
};
const edit_visitRequstedItems_request = () => {
  return {
    type: EDIT_REQITEMSVISIT_REQUEST
  };
};
const edit_visitRequstedItems_success = (visitRequstedItems) => {
  return {
    type: EDIT_REQITEMSVISIT_SUCCESS,
    payload: visitRequstedItems
  };
};
const edit_visitRequstedItems_failure = (error) => {
  return {
    type: EDIT_REQITEMSVISIT_FAILURE,
    payload: error
  };
};

//=================fill with data=====================================
export const fill_visitRequstedItems = (visitRequstedItems) => {
  return (dispatch) => {
    dispatch(change_visitRequstedItems_state(visitRequstedItems));
  };
};
const change_visitRequstedItems_state = (visitRequstedItems) => {
  return {
    type: CHANGE_REQITEMSVISIT_STATE,
    payload: visitRequstedItems
  };
};

export const fill_visitRequstedItems_temp = (visitRequstedItemsTemp) => {
  return (dispatch) => {
    dispatch(change_visitRequstedItems_state_temp(visitRequstedItemsTemp));
  };
};
const change_visitRequstedItems_state_temp = (visitRequstedItemsTemp) => {
  return {
    type: CHANGE_REQITEMSVISIT_TEMP_STATE,
    payload: visitRequstedItemsTemp
  };
};

export const clearvisitRequstedItemState = () => {
  return {
    type: CLEAR_REQITEMSVISIT_STATE
  };
};
//******************************************************visitServices***************************************************************
//====================fetch data===================================
export const fetchvisitServices = (id) => {
  return (dispatch) => {
    dispatch(fetch_visitServices_request());
    axiosInstance
      .get(`/VisitsService/allByVisit/${id}`)
      .then((responseVisitData) => {
        const tempVisitService = responseVisitData.data.data;
        const formattedService = {
          visitId: tempVisitService.visit.id,
          visitServiceDetails: tempVisitService.referencedItems.map((service) => ({
            id: service.serviceId,
            name: service.serviceName,
            cost: service.servicePrice,
            status: {
              id: service.statusId,
              name: service.statusName
            }
          }))
        };
        dispatch(fetch_visitServices_success(formattedService));
      })
      .catch((errorvisitServicesData) => {
        dispatch(fetch_visitServices_failure(errorvisitServicesData.message));
      });
  };
};
const fetch_visitServices_request = () => {
  return {
    type: FETCH_SERVICEVISIT_REQUEST
  };
};
const fetch_visitServices_success = (visitServices) => {
  return {
    type: FETCH_SERVICEVISIT_SUCCESS,
    payload: visitServices
  };
};
const fetch_visitServices_failure = (error) => {
  return {
    type: FETCH_SERVICEVISIT_FAILURE,
    payload: error
  };
};

//=====================add data======================================
export const addvisitServices = (visitServices) => {
  return (dispatch) => {
    dispatch(add_visitServices_request());
    axiosInstance
      .post(`/VisitsService`, visitServices)
      .then((response) => {
        dispatch(add_visitServices_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_visitServices_failure(error.message));
      });
  };
};
const add_visitServices_request = () => {
  return {
    type: ADD_SERVICEVISIT_REQUEST
  };
};
const add_visitServices_success = (visitServices) => {
  return {
    type: ADD_SERVICEVISIT_SUCCESS,
    payload: visitServices
  };
};
const add_visitServices_failure = (error) => {
  return {
    type: ADD_SERVICEVISIT_FAILURE,
    payload: error
  };
};

//=====================edit data=====================================
export const editvisitServices = (visitServices) => {
  const payload = {
    // id: visitServices.id,
    visitId: visitServices.visitId,
    visitServiceDetails: visitServices.visitServiceDetails,
    remarks: visitServices.remarks
  };
  return (dispatch) => {
    dispatch(edit_visitServices_request());
    axiosInstance
      .put(`/VisitsService`, payload)
      .then((response) => {
        dispatch(edit_visitServices_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_visitServices_failure(error.message));
      });
  };
};
const edit_visitServices_request = () => {
  return {
    type: EDIT_SERVICEVISIT_REQUEST
  };
};
const edit_visitServices_success = (visitServices) => {
  return {
    type: EDIT_SERVICEVISIT_SUCCESS,
    payload: visitServices
  };
};
const edit_visitServices_failure = (error) => {
  return {
    type: EDIT_SERVICEVISIT_FAILURE,
    payload: error
  };
};

//=================fill with data=====================================
export const fill_visitServices = (visitServices) => {
  return (dispatch) => {
    dispatch(change_visitServices_state(visitServices));
  };
};
const change_visitServices_state = (visitServices) => {
  return {
    type: CHANGE_SERVICEVISIT_STATE,
    payload: visitServices
  };
};

export const fill_visitServices_temp = (visitServicesTemp) => {
  return (dispatch) => {
    dispatch(change_visitServices_state_temp(visitServicesTemp));
  };
};
const change_visitServices_state_temp = (visitServicesTemp) => {
  return {
    type: CHANGE_SERVICEVISIT_TEMP_STATE,
    payload: visitServicesTemp
  };
};

export const clearvisitServiceState = () => {
  return {
    type: CLEAR_SERVICEVISIT_STATE
  };
};
//===============================visit cash calculate==============
export const calculateVisitCost = (newCost) => {
  return (dispatch) => {
    dispatch(changeVisitCostState(newCost));
  };
};

const changeVisitCostState = (newCost) => {
  return {
    type: CHANGE_VISITCOST_STATE,
    payload: newCost
  };
};
