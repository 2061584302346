import React from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { useNavigate } from 'react-router-dom';

function StageScreen() {
  const navigate = useNavigate();
  return (
    <GenericPage
      tabs={[
        {
          label: '',
          emptyStateButtonClick: () => {
            navigate('/products/addStages');
          },
          dataKey: '',
          extraParams: {
            type: 'Stages'
          },
          columns: [
            { header: 'كود  المنتج', field: 'code', isChip: false },
            { header: 'كود SKU', field: 'skuCode', isChip: false },
            { header: 'اسم المنتج', field: 'name', isChip: false },
            { header: 'فئة المنتج', field: 'productCategoryName', isChip: false },
            { header: 'الكمية المتاحة', field: 'stockQt', isChip: false },
            { header: 'الحالة', field: 'statusName', isChip: true },
            { header: 'تاريخ الاضافة', field: 'creationDateTime', isChip: false }
          ],
          rowOnClick: (rowData) => {
            navigate(`/product/details/${rowData.id}/0/stage`);
          },
          emptyStateUrl: '',
          edit: false,
          isHidden: true,
          apiUrl: 'Product/list',
          entityName: 'مرحله',
          headerButtons: [
            {
              label: 'إضافة مرحله جديدة',
              onClick: () => {
                navigate('/products/addStages');
              }
            }
          ],
          tableHeader: 'قائمة المراحل'
        }
      ]}
      headerTitle={'المنتجات'}
      headerIcon={'../images/productbox.svg'}
    />
  );
}

export default StageScreen;
