import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Link, useLocation } from 'react-router-dom';

import { API_BASE_URL, axiosInstance } from '../../global';
import '../../style/Tables.scss';
import AddWorkingGroups from '../Modals/AddWorkingGroups';
import AddUsersToGroups from '../Modals/AddUsersToGroups';
import EditWorkingGroups from '../Modals/EditWorkingGroups';
import moment from 'moment';
import { useToast } from '../../context/ToastContext';

function EmployeesWorkingGroupsTable({ userId }) {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [addWorkGroupVisible, setAddWorkGroupVisible] = useState(false);
  const [editWorkGroupVisible, setEditWorkGroupVisible] = useState(false);
  const [selectedGroupEdit, setSelectedGroupEdit] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [addUsersToGroupVisible, setAddUsersToGroupVisible] = useState(false);

  // 

  function fetchItems() {
    axiosInstance
      .get(`/Employee/dropdown`)
      .then((response) => {
        setEmployees(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/Team/all`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});

    axiosInstance
      .get(`/Status/dropdown/Employee`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatuses(tempStatus);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();
  }, []);

  function handleSearchNameChange(e) {}

  function handleAddWorkGroup(item) {
    axiosInstance
      .post(`/Team`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم إضافة مجموعة العمل', life: 3000 });
        fetchItems();
        setAddWorkGroupVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء إضافة مجموعة العمل', life: 3000 });
      });
  }

  function handleShowEditWorkGroup(group) {
    setSelectedGroupEdit(group);
    setEditWorkGroupVisible(true);
  }

  function handleEditWorkGroup(item) {
    axiosInstance
      .put(`/Team`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم تعديل مجموعة العمل', life: 3000 });
        fetchItems();
        setEditWorkGroupVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء تعديل مجموعة العمل', life: 3000 });
      });
  }

  function handleDeleteWorkGroup() {
    axiosInstance
      .delete(`/Team/${selectedGroupEdit.id}`)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم حذف مجموعة العمل', life: 3000 });
        fetchItems();
        setEditWorkGroupVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء حذف مجموعة العمل', life: 3000 });
      });
  }

  function handleShowAddUserToGroup(id) {
    setSelectedGroupId(id);
    setAddUsersToGroupVisible(true);
  }

  function handleAddUsersToGroup(item) {
    const employeesId = item.map((emp) => emp.id);
    
    axiosInstance
      .post(`/EmployeesTeamsMatrix/addEmployees`, { employeesId, teamId: selectedGroupId })
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم إضافة المستخدمين الى مجموعة العمل', life: 3000 });
        fetchItems();
        setAddUsersToGroupVisible(false);
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء إضافة المستخدمين الى مجموعة العمل',
          life: 3000
        });
      });
  }

  const getSelectedEmployeeList = () => items?.find((item) => item.id === selectedGroupId)?.employeesTeamsMatrices;

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>قائمة مجموعات العمل</span>
        <button className='tbl__addbtn' onClick={() => setAddWorkGroupVisible(true)}>
          <img src='/images/plus.svg' alt='إضافة مجموعات عمل' />
          إضافة مجموعات عمل
        </button>

        <AddWorkingGroups
          visible={addWorkGroupVisible}
          setVisible={setAddWorkGroupVisible}
          statuses={statuses}
          onAddHandler={handleAddWorkGroup}
          userId={userId}
        />
        {/* <EditWorkingGroups
          visible={editWorkGroupVisible}
          setVisible={setEditWorkGroupVisible}
          statuses={statuses}
          onEditHandler={handleEditWorkGroup}
          onDeleteHandler={handleDeleteWorkGroup}
          userId={userId}
          initialData={selectedGroupEdit}
        /> */}
        <AddUsersToGroups
          visible={addUsersToGroupVisible}
          setVisible={setAddUsersToGroupVisible}
          employees={employees}
          selectedEmployees={getSelectedEmployeeList()}
          groupId={selectedGroupId}
          onAddHandler={handleAddUsersToGroup}
        />

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='اسم المجموعة' field='name' />
          <Column
            header='المستخدمين'
            body={(rowData) => (
              <div className='flex w-full justify-content-between'>
                <span className=''>
                  <img src='/images/employee.png' alt='' className='' /> +{rowData.employeesTeamsMatrices.length}
                </span>
                <Button icon='pi pi-plus' onClick={() => handleShowAddUserToGroup(rowData.id)} outlined />
              </div>
            )}
          />
          <Column header='الحالة' body={(rowData) => <Chip label={rowData.status.name} />} />
          <Column
            header='تاريخ اﻻنشاء'
            body={(rowData) => <p>{moment(rowData.creationDateTime).format('DD/MM/YYYY')}</p>}
          />

          <Column
            header='خيارات'
            body={(rowData) => (
              <Link to={`/employees/management/working-groups/details/${rowData.id}`}>
                <Button label='تفاصيل المجموعة' icon='pi pi-exclamation-circle' severity='secondary' text />
              </Link>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    userId: state.user.user.id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesWorkingGroupsTable);
