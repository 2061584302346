import React, { useCallback, useEffect, useState } from 'react';
import { axiosInstance } from '../../../../global';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../../../../style/Tables.scss';
import { Knob } from 'primereact/knob';
import GenericDataTable from '../../../../components/components/GenericDataTable/GenericDataTable';
import './AddConnectionDetails.scss';
import { useDebounce } from '../../../../components/components/DataTableCard/DataTableCard';

function AddConnection1() {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRows, setExpandedRows] = useState(null);
  const [myList, setMyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageTotalCount, setPageTotalCount] = useState(0);

  const navigate = useNavigate();
  const debounceSearchTerm = useDebounce(searchTerm, 500);

  const fetchCustomers = useCallback(() => {
    axiosInstance
      .get(`/Customer/all`, { params: { page, size: pageSize, search: debounceSearchTerm, status: 'Active' } })
      .then((response) => {
        setMyList(response.data.data);
        setPageTotalCount(response.data.totalCount);
        setLoading(false);
      })
      .catch((error) => {});
  }, [page, pageSize, debounceSearchTerm]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    setPage(0);
    setPageSize(10);
  }, [searchTerm]);

  function handleSearchNameChange(e) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  const allowExpansion = (rowData) => {
    return rowData.customersDevices.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className='p-3'>
        <DataTable value={data.customersDevices}>
          <Column field='device.name' header='الجهاز'></Column>
          <Column field='membraneSerialNb' header='رقم ممبرين'></Column>
          <Column field='motorSerialNb' header='رقم الموتور'></Column>
          <Column field='type.name' header='نوع الجهاز'></Column>
          <Column field='installationDate' header='تاريخ التركيب'></Column>
          <Column field='expiryDate' header='صلاحية الضمان'></Column>
          <Column
            field='device.remainingTime'
            header='مؤشر التغيير'
            body={(rowData) => {
              return rowData.device.remainingTime > 1 ? (
                <Knob value={rowData.device.remainingTime} min={-365} max={365} size={50} valueColor='#EEBA00' />
              ) : (
                <Knob value={rowData.device.remainingTime} min={-365} max={365} size={50} valueColor='#FF0000' />
              );
            }}
          ></Column>
          <Column
            header='خيارات'
            body={(rowData) => (
              <Link to={`/Activites/Add/${data.id}/${rowData.id}`}>
                <Button label={`اضافة اتصال`} icon='pi pi-plus' text className='primary__data--link text-blue-600' />
              </Link>
            )}
          />
        </DataTable>
      </div>
    );
  };

  const columns = [
    { field: 'fullName', header: 'العميل' },
    { field: 'phoneNb', header: 'رقم الهاتف' },
    { field: 'defaultAddress.name', header: 'العنوان الرئيسي' },
    { field: 'numberOfDevices', header: 'عدد الأجهزة' },
    { field: 'numberOfStages', header: 'عدد المراحل المستحقة' },
    { field: 'numberOfComplaints', header: 'شكاوى العميل' },
    { field: 'preferredContactMethod.name', header: 'طريقة التواصل' },
    { field: 'status.name', header: 'الحالة', isChip: true }
  ];

  return (
    <div className='Connection'>
      <div>
        <div className='Note ml-2 mb-4 mr-3'>
          <li className='pi pi-info-circle'></li>
          يجب اضافة تفاصيل الاتصال اولاً حتى يكون بأمكانك اضافة الزيارة
          <br />
          قم بالبحث عن العميل او بأمكانك اضافة عميل جديد
        </div>
        <div className='tbl ml-3 mb-4 mr-3'>
          <div className='tbl__visit__header'>
            <div className='flex flex-row gap-4'>
              <span className='header__title'> قائمة البحث بالعملاء </span>
            </div>

            <div className='header__visit__tools'>
              <span className='p-input-icon-left'>
                <i className='pi pi-search' />
                <InputText
                  value={searchTerm}
                  className='header__tools__search w-38rem'
                  placeholder='ابحث بأسم العميل'
                  onChange={handleSearchNameChange}
                  type='search'
                />
              </span>
            </div>
            <button className='tbl__visit__addbtn' onClick={() => navigate('/customers/addCustomer')}>
              <img src='/images/plus.svg' alt={'إضافة عميل '} />
              إضافة عميل
            </button>
          </div>
          <div className='tbl__table'>
            {loading ? (
              <div className='flex justify-content-center'>
                <ProgressSpinner />
              </div>
            ) : (
              <GenericDataTable
                data={myList}
                columns={columns}
                page={page}
                pageSize={pageSize}
                totalRecords={pageTotalCount}
                isLoading={loading}
                onPageChange={(e) => {
                  if (e.page !== undefined) {
                    setPage(e.page);
                  }
                  if (e.rows !== undefined) {
                    setPageSize(e.rows);
                  }
                }}
                onSort={() => {}}
                onRowClick={() => {}}
                edit={false}
                entityName='العميل'
                emptyStateUrl='/add-customer'
                allowExpansion={allowExpansion}
                rowExpansionTemplate={rowExpansionTemplate}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddConnection1;
