import React, { useEffect } from 'react';
import useGeoLocation from '../../../../hooks/useGeoLocation';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import { FormProps } from '../../../../types/FormProps';
import useStatus from '../../../../hooks/useStatus';

const GovernorateForm: React.FC<FormProps> = ({ control, errors, setValue, watch }) => {
  const geoLocation = useGeoLocation(true);
  const isEditMode = watch('id') != null;
  const selectedCountry = watch('countryId');
  const selectedGovernorate = watch('governorateId');
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('GeoLocation');

  useEffect(() => {
    if (selectedCountry) {
      geoLocation.handleCountrySelect({ id: selectedCountry, name: selectedCountry });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedGovernorate) {
      geoLocation.handleGovernorateSelect({ id: selectedGovernorate, name: selectedGovernorate });
    }
  }, [selectedGovernorate]);
  
  return (
    <div className='flex flex-column gap-5'>
      <CustomFormField
        name={'countryId'}
        label={'اختر الدولة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        required={true}
        options={geoLocation.countryApiState.state.data || []}
        placeholder='اختر الدولة'
      />
      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? 'اسم المحافظة' : 'اضف اسم/اسماء المحافظة الجديدة'}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        required={true}
        placeholder='Enter new location names'
      />
      <CustomFormField
        name={'statusId'}
        label={'اختر حالة المحافظة'}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />
      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </div>
  );
};

export default GovernorateForm;
