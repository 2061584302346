import React, { useEffect, useMemo, useState } from 'react';
import { DataTable, DataTableRowEditCompleteEvent } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import CustomDropdown from '../Dropdown/CustomDropDown';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';

interface DataTableValue {
  [key: string]: any;
}

export interface EditableColumnProps {
  field: string;
  header: string;
  editable?: boolean;
  type?: 'text' | 'dropdown' | 'boolean' | 'number' | 'date' | 'checkBox';
  options?: { name: string; id: any }[];
  idField?: string;
  labelField?: string;
  width?: string;
  hidden?: boolean;
}

interface GenericEditableDataTableProps<T extends DataTableValue> {
  data: T[];
  columns: EditableColumnProps[];
  isLoading: boolean;
  onRowUpdate: (updatedData: T[]) => void;
  onRowRemove?: (index: number) => void;
}

const GenericEditableDataTable = <T extends DataTableValue>({
  data,
  columns,
  isLoading,
  onRowUpdate,
  onRowRemove
}: GenericEditableDataTableProps<T>) => {
  const [tableData, setTableData] = useState<T[]>(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const onRowEditComplete = (e: DataTableRowEditCompleteEvent) => {
    const { newData, index } = e;
    const updatedData = [...tableData] as T[];
    updatedData[index] = { ...updatedData[index], ...newData } as T;
    setTableData(updatedData);
    onRowUpdate(updatedData);
  };

  const removeRow = (index: number) => {
    // Call the parent component's onRowRemove function if provided
    if (onRowRemove) {
      onRowRemove(index); // Removes the row from parent state
    }
    // Update local tableData state
    const updatedData = tableData.filter((_, i) => i !== index);
    setTableData(updatedData);
    // If onRowUpdate is necessary here, call it to keep parent component in sync
    onRowUpdate(updatedData);
  };

  const textEditor = (options: ColumnEditorOptions) => (
    <InputText type='text' value={options.value} onChange={(e) => options.editorCallback?.(e.target.value)} />
  );

  const dropdownEditor = (
    options: ColumnEditorOptions,
    value: string | undefined,
    label: string | undefined,
    columnOptions?: {
      name: string;
      id: any;
    }[]
  ) => (
    <CustomDropdown
      value={options.value}
      optionLabel={label ?? 'name'}
      optionValue={value ?? 'id'}
      options={columnOptions ?? []}
      onChange={(e) => options.editorCallback?.(e.value)}
      placeholder='Select'
    />
  );

  const checkBoxEditor = (options: ColumnEditorOptions, columnOptions?: { name: string; id: any }[]) => (
    <Checkbox checked={options.value} onChange={(e) => options.editorCallback?.(e.checked)} />
  );

  const numberEditor = (options: ColumnEditorOptions) => (
    <InputNumber value={options.value} onValueChange={(e) => options.editorCallback?.(e.value)} />
  );

  const dateEditor = (options: ColumnEditorOptions) => (
    <Calendar value={options.value} onChange={(e) => options.editorCallback?.(e.value)} dateFormat='yy-mm-dd' />
  );

  const renderEditor = (options: ColumnEditorOptions, column: EditableColumnProps) => {
    if (column.editable) {
      switch (column.type) {
        case 'dropdown':
          return dropdownEditor(options, column.idField, column.labelField, column.options);
        case 'checkBox':
          return checkBoxEditor(options);
        case 'number':
          return numberEditor(options);
        case 'date':
          return dateEditor(options);
        case 'text':
        default:
          return textEditor(options);
      }
    } else {
      if (column.type === 'dropdown' && column.options) {
        const selectedOption = column.options.find((opt) => opt.id === options.value);
        return <span>{selectedOption ? selectedOption.name : options.value}</span>;
      }

      return <span>{options.value}</span>;
    }
  };

  const memoizedColumns = useMemo(
    () =>
      columns.filter((col) => !col.hidden).map((col) => (
        <Column
          key={col.field}
          field={col.field}
          body={(rowData) => {
            if (col.type === 'dropdown' && col.options) {
              // Use idField and labelField with fallback defaults
              const idField = col.idField || 'id';
              const labelField = col.labelField || 'name';

              // Find the option that matches the rowData's value
              const selectedOption = col.options.find((opt) => opt[idField as keyof typeof opt] === rowData[col.field]);

              // Return the label if found, else show raw value
              return selectedOption ? selectedOption[labelField as keyof typeof selectedOption] : rowData[col.field];
            }

            // For non-dropdown columns, display the value directly
            return rowData[col.field];
          }}
          header={col.header}
          editor={(options) => renderEditor(options, col)}
          style={{ width: col.width || 'auto', textAlign: 'right' }}
        />
      )),
    [columns]
  );

  return (
    <div>
      {isLoading ? (
        <ProgressSpinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
      ) : (
        <DataTable
          value={tableData}
          editMode='row'
          dataKey='id'
          lazy
          emptyMessage={'لا يوجد بيانات'}
          onRowEditComplete={onRowEditComplete}
          tableStyle={{ tableLayout: 'fixed' }}
        >
          {memoizedColumns}
          <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} />
          <Column
            body={(rowData, { rowIndex }) => (
              <Button icon='pi pi-trash' className='p-button-danger' type={'button'} onClick={() => removeRow(rowIndex)} />
            )}
            headerStyle={{ width: '5rem' }}
            bodyStyle={{ textAlign: 'center' }}
          />
        </DataTable>
      )}
    </div>
  );
};

export default GenericEditableDataTable;
