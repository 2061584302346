import React, { useState } from 'react';
import { Button, Input, Modal, Upload } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import { UploadFile } from 'antd/lib';
import { UploadChangeParam } from 'antd/es/upload';

const { Dragger } = Upload;

export interface CustomUploadFile extends UploadFile {
  showInStore?: boolean | undefined;
  showInGalary?: boolean | undefined;
  imagePath?: any;
  remarks?: string;
}

export interface ImageUploadProps {
  onFileChange: (fileList: CustomUploadFile[]) => void;
  multiple?: boolean;
  alreadySelected: CustomUploadFile[];
  withremarks?: boolean;
  withFlags?: boolean;
}

const ImageUploadComponent: React.FC<ImageUploadProps> = ({
  onFileChange,
  multiple,
  alreadySelected,
  withFlags = false,
  withremarks = false
}) => {
  const [fileList, setFileList] = useState<CustomUploadFile[]>(alreadySelected ?? []);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [previewVisible, setPreviewVisible] = useState(false);

  // Handle file changes including new uploads
  const handleChange = ({ fileList }: UploadChangeParam<UploadFile<any>>) => {
    const tempFileList = multiple ? fileList : [fileList[fileList.length - 1]];
    setFileList(tempFileList as CustomUploadFile[]);
    onFileChange?.(tempFileList);
  };

  // Handle file removal
  const handleRemove = (file: CustomUploadFile) => {
    const newList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newList);
    onFileChange?.(newList);
  };

  // Handle remarks changes, updating the file object directly
  const handleremarksChange = (file: CustomUploadFile, value: string) => {
    const updatedFileList = fileList.map((f) => {
      if (f.uid === file.uid) {
        return { ...f, remarks: value };
      }
      return f;
    });
    setFileList(updatedFileList);
    onFileChange?.(updatedFileList);
  };

  // Handle checkbox state change (for flags like Display in Gallery, Store)
  const handleCheckboxChange = (
    file: CustomUploadFile,
    field: 'showInGalary' | 'showInStore',
    value?: boolean
  ) => {
    const updatedFileList = fileList.map((f) => {
      if (f.uid === file.uid) {
        return { ...f, [field]: value };
      }
      return f;
    });
    setFileList(updatedFileList);
    onFileChange?.(updatedFileList);
  };

  // Custom rendering of each uploaded file using itemRender
  const itemRender = (
    originNode: React.ReactElement,
    file: CustomUploadFile,
    fileList: CustomUploadFile[],
    actions: { download: any; preview: any; remove: any }
  ) => {
    
    return (
      <div
        key={file.uid}
        style={{ marginTop: '20px', padding: '10px', border: '1px solid #d9d9d9', borderRadius: '5px' }}
      >
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {file.thumbUrl ? (
                <img
                  src={file.thumbUrl}
                  alt={file.name}
                  style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
                />
              ) : file.imagePath ? (
                <img
                  src={`http://bestaqua.oneclickapps.org/${file.imagePath}`}
                  alt={file.name}
                  style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
                />
              ) : null}
            </div>

            {withFlags && (
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', marginRight: 20 }}>
                <div>
                  <Checkbox
                    inputId={`showInGalary-${file.uid}`}
                    checked={file.showInGalary || false}
                    onChange={(e) => handleCheckboxChange(file, 'showInGalary', e.checked)}
                  />
                  <label htmlFor={`showInGalary-${file.uid}`} className='addproduct__label mr-1'>
                    عرض في المعرض
                  </label>
                </div>
                <div style={{ marginRight: 10 }}>
                  <Checkbox
                    inputId={`showInStore-${file.uid}`}
                    checked={file.showInStore || false}
                    onChange={(e) => handleCheckboxChange(file, 'showInStore', e.checked)}
                  />
                  <label htmlFor={`showInStore-${file.uid}`} className='addproduct__label mr-1'>
                    عرض في المتجر
                  </label>
                </div>
              </div>
            )}
          </div>
          <Button icon={<DeleteOutlined />} danger onClick={() => handleRemove(file)}>
            حذف الصورة
          </Button>
        </div>
        {withremarks && (
          <Input
            placeholder='أضف وصف يساعد العميل لمعرفة المنتج أكثر'
            value={file.remarks || ''}
            onChange={(e) => handleremarksChange(file, e.target.value)}
            style={{ marginTop: '10px', width: '100%' }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Dragger
        accept='image/*'
        listType='picture'
        fileList={_.isEmpty(alreadySelected) ? fileList : alreadySelected}
        onChange={handleChange}
        onRemove={handleRemove}
        beforeUpload={() => false} // Prevent automatic upload
        multiple={multiple}
        itemRender={itemRender} // Custom rendering for each file
      >
        <p className='ant-upload-drag-icon'>
          <UploadOutlined />
        </p>
        <p className='ant-upload-text'>انقر لإضافة الصورة أو اسحبها هنا</p>
        <p className='ant-upload-hint'>WEBP أو JPG أو PNG أو JPEG</p>
      </Dragger>

      <Modal open={previewVisible} title='Preview Image' footer={null} onCancel={() => setPreviewVisible(false)}>
        {previewImage && <img alt='Preview' style={{ width: '100%' }} src={previewImage} />}
      </Modal>
    </div>
  );
};

export default ImageUploadComponent;
