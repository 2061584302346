import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { ToggleButton } from 'primereact/togglebutton';

import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';
import { useToast } from '../../../context/ToastContext';

export function AddEditWarehouseTransMatrix({
  title,
  entityName,
  visible,
  setVisible,
  onClosePrompt,
  onAddHandler,
  initialData,
  additionalFields = {}
}) {
    const toast = useToast();
  const [statuses, setStatues] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [userTypes, setUserTypes] = useState([]);
  const [selectedUserTypes, setSelectedUserTypes] = useState(null);
  const [transType, setTransTypes] = useState([]);
  const [selectedTransType, setSelectedTransTypes] = useState(null);
  const [destinationSources, setDestinationSources] = useState([]);
  const [selectedDestinationSources, setSelectedDestinationSources] = useState(null);
  const [selectedNextTransType, setSelectedNextTransTypes] = useState(null);
  const [selectedNextDestinationSource, setSelectedNextDestinationSource] = useState(null);
  const [defaultApproval, setDefaultApproval] = useState(false);
  const [internalTransaction, setInternalTransaction] = useState(false);

  const [entity, setEntity] = useState({
    id: null,
    userTypeId: null,
    transTypeId: null,
    destinationSourceId: null,
    nextTransTypeId: null,
    nextDestinationSourceId: null,
    defaultApprovalStatus: false,
    statusId: null,
    internalTransaction: false,
    remarks: ''
  });

  const [additionalFieldsSelected, setAdditionalFieldsSelected] = useState(
    !additionalFields || Object.keys(additionalFields).length === 0
  );

  useEffect(() => {
    axiosInstance.get('/UsersType/all').then((value) => setUserTypes(value.data.data));
    axiosInstance.get('/TransType/dropdown').then((value) => setTransTypes(value.data.data));
    axiosInstance.get('/DestinationsSource/all').then((value) => setDestinationSources(value.data.data));
    axiosInstance.get('/Status/dropdown/Warehouse').then((value) => setStatues(value.data.data));
  }, []);

  useEffect(() => {
    if (initialData) {
      setEntity({
        id: initialData.id,
        userTypeId: initialData.userType.id,
        transTypeId: initialData.transType.id,
        destinationSourceId: initialData.destinationSource.id,
        nextTransTypeId: initialData.nextTransType.id,
        nextDestinationSourceId: initialData.nextDestinationSource.id,
        defaultApprovalStatus: initialData.defaultApprovalStatus,
        statusId: initialData.status.id,
        internalTransaction: initialData.internalTransaction,
        remarks: initialData.remarks
      });
      setSelectedUserTypes(initialData.userType.id);
      setSelectedTransTypes(initialData.transType.id);
      setSelectedDestinationSources(initialData.destinationSource.id);
      setSelectedNextTransTypes(initialData.nextTransType.id);
      setSelectedNextDestinationSource(initialData.nextDestinationSource.id);
      setDefaultApproval(initialData.defaultApprovalStatus);
      setSelectedStatus(initialData.status.id);
      setInternalTransaction(initialData.internalTransaction);
    }
  }, [initialData]);

  function acceptClose() {
    setEntity({
      id: null,
      userTypeId: null,
      transTypeId: null,
      destinationSourceId: null,
      nextTransTypeId: null,
      nextDestinationSourceId: null,
      defaultApprovalStatus: false,
      statusId: null,
      internalTransaction: false,
      remarks: ''
    });
    setSelectedStatus(null);
    setVisible(false);
    setAdditionalFieldsSelected(false);
  }

  function handleAdditionalFieldChange(fieldName, value) {
    const prevEntity = {
      ...additionalFields
    };
    setEntity((prevEntity) => ({
      ...prevEntity,
      [fieldName]: value
    }));

    // Check if all additional fields are selected using the updated state (prevEntity)
    const allFieldsSelected = Object.keys(additionalFields).every(
      (fieldName) =>
        prevEntity[fieldName] !== undefined &&
        prevEntity[fieldName] !== null &&
        (Array.isArray(prevEntity[fieldName]) ? prevEntity[fieldName].length > 0 : true)
    );
    setAdditionalFieldsSelected(allFieldsSelected);
  }

  const additionalFieldsControls = Object.entries(additionalFields).map(([fieldName, fieldOptions]) => (
    <div key={fieldName} className='w-full flex flex-column gap-2'>
      <label className='modals__label'>{fieldOptions.label}</label>
      <CustomDropdown
        value={entity[fieldName]}
        options={fieldOptions.values.map((option) => ({ label: option.name, value: option.id }))}
        onChange={(e) => handleAdditionalFieldChange(fieldName, e.value)}
        className='w-full'
      />
    </div>
  ));

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleAddItemChipBtn(e) {
    const kbEvent = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'Enter'
    });
    document.body.dispatchEvent(kbEvent);
  }

  return (
    <>

      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          {additionalFieldsControls}
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>User Type</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedUserTypes}
                  onChange={(event) => {
                    setSelectedUserTypes(event.value);
                    setEntity({ ...entity, userTypeId: event.value });
                  }}
                  options={userTypes}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Transaction Type</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedTransType}
                  onChange={(event) => {
                    setSelectedTransTypes(event.value);
                    setEntity({ ...entity, transTypeId: event.value });
                  }}
                  options={transType}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Destination Source</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedDestinationSources}
                  onChange={(event) => {
                    setSelectedDestinationSources(event.value);
                    setEntity({ ...entity, destinationSourceId: event.value });
                  }}
                  options={destinationSources}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Next Transaction Type</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedNextTransType}
                  onChange={(event) => {
                    setSelectedNextTransTypes(event.value);
                    setEntity({ ...entity, nextTransTypeId: event.value });
                  }}
                  options={transType}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Next Destination Source</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedNextDestinationSource}
                  onChange={(event) => {
                    setSelectedNextDestinationSource(event.value);
                    setEntity({ ...entity, nextDestinationSourceId: event.value });
                  }}
                  options={destinationSources}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Default Approval Status</label>
              <div className='flex'>
                <ToggleButton checked={defaultApproval} onChange={(e) => setDefaultApproval(e.value)} />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Internal Transaction</label>
              <div className='flex'>
                <ToggleButton checked={internalTransaction} onChange={(e) => setInternalTransaction(e.value)} />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Status</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedStatus}
                  onChange={(event) => {
                    setSelectedStatus(event.value);
                    setEntity({ ...entity, statusId: event.value });
                  }}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea
                name='remarks'
                value={entity.remarks}
                onChange={(event) => setEntity({ ...entity, remarks: event.target.value })}
                className='w-full'
              />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(entity)}>
              اضافة {entityName}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
