import { axiosInstance } from '../global';
import { useApiState } from './useApiState';

type StatusType =
  | 'General'
  | 'GeoLocation'
  | 'Client'
  | 'Employee'
  | 'Warehouse'
  | 'Product'
  | 'Contract'
  | 'Visit'
  | 'Revision'
  | 'Accounting'
  | 'Sales';

const useStatus = (type: StatusType) => {
  const fetchStatus = async () => {
    const response = await axiosInstance.get(`/Status/dropdown/${type}`);
    return response.data;
  };

  const {
    state: { isLoading: isStatusLoading, error: statusError, data: statusData },
    lazyApiCall: getStatusData
  } = useApiState({ apiCall: fetchStatus });

  return {
    isStatusLoading,
    statusError,
    statusData,
    getStatusData
  };
};

export default useStatus;
