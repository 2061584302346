import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Tabs from '../../../components/Tabs/Tabs';
import HeaderCard from '../../../components/Cards/Home header/HeaderCard';
import CustomerEntityTable from '../../../components/Tables/CustomerEntityTable';
import { axiosInstance } from '../../../global';
import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
import { useNavigate, useParams } from 'react-router-dom';
import FollowUpScreenStatusTable from './FollowUpScreenStatusTable';

interface Status {
  id: string;
  name: string;
}

function FollowUpScreen() {
  const [status, setStatus] = useState<Status[]>([]);
  useEffect(() => {
    axiosInstance
      .get(`/Visit/allStatus`)
      .then((response) => {
        setStatus(response.data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className='main w-full'>
      <span className='main__title__custom'>
        <i className='main__title__custom--icon'>
          <img src='../images/briefcase1.svg' alt='' />
        </i>
        <span>الزيارات</span>
      </span>
      <button className='tbl__addbtn'>
        <Link className='tbl__addbtn__link' to={'/Activites/addVisitConnection'}>
          <img src='/images/plus.svg' alt={''} />
          <span>{'اضافة اتصال / زيارة جديدة'}</span>
        </Link>
      </button>

      <div className='mt-5 pr-4 pl-4'>
        <div className='customerdetails__header__card mb-4'>
          <HeaderCard
            title={'المتابعات المنتظرة'}
            num={'20'}
            imageUrl={'/images/sandclock.svg'}
            color={'#13DE8230'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
          <HeaderCard
            title={'الزيارات المتأخرة'}
            num={'40'}
            imageUrl={'/images/altsleep.svg'}
            color={'#F9D8D4'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
          <HeaderCard
            title={'تقيمات العملاء'}
            num={'60'}
            imageUrl={'/images/check.svg'}
            color={'#6D54DE33'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
          <HeaderCard
            title={'الزيارات الغير محددة'}
            num={'30'}
            imageUrl={'/images/briefcase.svg'}
            color={'#FFEDD0'}
            percent={undefined}
            chartImage={undefined}
            txtColor={undefined}
          />
        </div>
        <div className='details__table'>
          <Tabs
            headers={status.map((stat) => stat.name)}
            contents={status.map((stat) => (
              <FollowUpScreenStatusTable key={stat.id} statusID={stat.id} />
            ))}
          />
        </div>
      </div>
    </div>
  );
}

export default FollowUpScreen;

// import React, { useEffect, useMemo, useRef, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Tabs from '../../../components/Tabs/Tabs';
// import HeaderCard from '../../../components/Cards/Home header/HeaderCard';
// import DataTableCard from '../../../components/components/DataTableCard/DataTableCard';
// import { axiosInstance } from '../../../global';
// import GenericPage from '../../../components/components/GenericPage/GenericPage';

// interface Status {
//   id: number;
//   name: string;
// }

// function FollowUpScreen() {
//   const genericPageRef = useRef<{ fetchTabData: () => void }>(null);
//   const navigate = useNavigate();

//   const fetchTabData = () => {
//     genericPageRef.current?.fetchTabData();
//   };

//   const [status, setStatus] = useState<Status[]>([]);

//   useEffect(() => {
//     axiosInstance
//       .get(`/Visit/allStatus`)
//       .then((response) => {
//         setStatus(response.data.data);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, []);

//   const getCustomerTab = (stat: Status) => ({
//     label: 'المتابعة',
//     entityName: 'متابعة',
//     tableHeader: 'جدول المتابعة',
//     emptyStateUrl: '',
//     rowOnClick: (rowData: any) => {
//       navigate(`/customer/followUp/details`);
//     },
//     apiUrl: `/Visit/allStatus/${stat.id}`, // Added stat.id to filter API data per tab
//     columns: [
//       { header: 'العميل', field: 'customer.fullName', isChip: false },
//       { header: 'نوع الزيارة', field: 'type.name', isChip: false },
//       { header: 'الفنى', field: 'assignedRep.name', isChip: false },
//       { header: 'الاهمية', field: 'customerActivity.satisfactionLevel', isRate: true },
//       { header: 'تاريخ الزيارة', field: 'visitDate', isChip: false },
//       { header: 'الحالة', field: 'status.name', isChip: true }
//     ],
//     edit: true,
//     dataKey: stat.id, // Set unique key for each tab
//     emptyStateButtonClick: () => {
//       // Add any specific empty state button logic if needed
//     }
//   });

//   return (
//     <div className='main w-full'>
//       <span className='main__title__custom'>
//         <i className='main__title__custom--icon'>
//           <img src='../images/briefcase1.svg' alt='' />
//         </i>
//         <span>الزيارات</span>
//       </span>
//       <button className='tbl__addbtn'>
//         <Link className='tbl__addbtn__link' to={'/Activites/addVisitConnection'}>
//           <img src='/images/plus.svg' alt={''} />
//           <span>{'اضافة اتصال / زيارة جديدة'}</span>
//         </Link>
//       </button>

//       <div className='mt-5 pr-4 pl-4'>
//         <div className='customerdetails__header__card mb-4'>
//           <HeaderCard
//             title={'المتابعات المنتظرة'}
//             num={'20'}
//             imageUrl={'/images/sandclock.svg'}
//             color={'#13DE8230'}
//             percent={undefined}
//             chartImage={undefined}
//             txtColor={undefined}
//           />
//           <HeaderCard
//             title={'الزيارات المتأخرة'}
//             num={'40'}
//             imageUrl={'/images/altsleep.svg'}
//             color={'#F9D8D4'}
//             percent={undefined}
//             chartImage={undefined}
//             txtColor={undefined}
//           />
//           <HeaderCard
//             title={'تقيمات العملاء'}
//             num={'60'}
//             imageUrl={'/images/check.svg'}
//             color={'#6D54DE33'}
//             percent={undefined}
//             chartImage={undefined}
//             txtColor={undefined}
//           />
//           <HeaderCard
//             title={'الزيارات الغير محددة'}
//             num={'30'}
//             imageUrl={'/images/briefcase.svg'}
//             color={'#FFEDD0'}
//             percent={undefined}
//             chartImage={undefined}
//             txtColor={undefined}
//           />
//         </div>

//         <div className='details__table'>
//           {/* <Tabs
//             headers={status.map((stat) => stat.name)}
//             contents={status.map((stat) => (
//               <DataTableCard
//                 ref={genericPageRef}
//                 button={[]}
//                 selectedTab={getCustomerTab(stat)} // Pass the specific CustomerTab configuration for each stat
//               />
//             ))}
//           /> */}

//           <GenericPage
//             ref={genericPageRef}
//             tabs={[
//               status.map((stat) =>(
//               {
//                 label: stat.name,
//                 emptyStateButtonClick: () => {},
//                 dataKey: stat.id,
//                 columns: [
//                   { header: 'انواع المنتجات', field: 'name', isChip: false },
//                   { header: 'الحالة', field: 'statusName', isChip: true },
//                   { header: 'ملاحظات', field: 'remarks', isChip: false }
//                 ],
//                 edit: true,
//                 headerButtons: [
//                   {
//                     label: 'إضافة انواع منتجات',
//                     onClick: () => {
//                       productsTypeOpenModal();
//                     }
//                   }
//                 ],
//                 rowOnClick: (rowData) => {

//                 },
//                 apiUrl: 'ProductsType/list',
//                 entityName: 'نوع المنتج',
//                 tableHeader: 'جدول بيانات انواع المنتجات',
//                 emptyStateUrl: ''
//               }
//             )
//             ]}
//             headerTitle={'البيانات الاساسيه'}
//             headerIcon={'../images/productbox.svg'}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default FollowUpScreen;
