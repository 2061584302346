import React from 'react';
// import ActivitesScreenTable from '../../../components/Tables/ActivitesScreenTable';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import { useNavigate } from 'react-router-dom';
const columns = [
  { header: 'العميل', field: 'customerName', isChip: false },
  { header: 'نوع الزيارة', field: 'visitTypeName', isChip: false },
  { header: 'الفنى', field: 'assignedRepName', isChip: false },
  { header: 'الاهمية', field: 'priorityName', isChip: false },
  { header: 'تاريخ الزيارة', field: 'visitDate', isDate: true },
  { header: 'الحالة', field: 'statusName', isChip: true }
];

function VisitsScreen() {
  const navigate = useNavigate();

  return (
    // <div className='w-full'>
    //   <span className='main__title__custom'>
    //     <i className='main__title__custom--icon'>
    //       <img src='../images/briefcase1.svg' alt='' />
    //     </i>
    //     <span>الزيارات</span>
    //   </span>

    //   <div className='mt-5 pr-4 pl-4'>
    //     <ActivitesScreenTable
    //       columns={[
    //         { header: 'العميل', field: 'customer.fullName', isChip: false },
    //         { header: 'نوع الزيارة', field: 'type.name', isChip: false },
    //         { header: 'الفنى', field: 'assignedRep.name', isChip: false },
    //         { header: 'الاهمية', field: 'customerActivity.satisfactionLevel', isRate: true },
    //         { header: 'تاريخ الزيارة', field: 'visitDate', isChip: false },
    //         { header: 'الحالة', field: 'status.name', isChip: true }
    //       ]}
    //       urlName='Visit/all'
    //       headerTitle='الزيارات'
    //       entityNameDefined='تفاصيل الزيارة'
    //       addBtnText='اضافة اتصال / زيارة جديدة'
    //       path='/Activites/addVisitConnection'
    //       type={'visit'}
    //       detailsPath='/visits/details'
    //     />
    //   </div>
    // </div>

    <GenericPage
      tabs={[
        {
          isHidden: true,
          label: 'بيانات الزيارة',
          tableHeader: '',
          dataKey: 'الزيارة',
          emptyStateButtonClick: () => {},
          apiUrl: 'Visit/list',
          edit: false,
          columns: columns,
          emptyStateUrl: '',
          entityName: 'بيانات الزيارة',
          rowOnClick: (rowData) => {
            navigate(`/visits/details/visit/${rowData.id}/${rowData.customerID}`);
          },
          headerButtons: [
            {
              label: 'اضافة اتصال / زيارة جديدة',
              onClick: () => {
                navigate('/Activites/addVisitConnection');
              }
            }
          ]
        }
      ]}
      headerTitle={'الزيارات'}
      headerIcon={'../images/briefcase1.svg'}
    />
  );
}

export default VisitsScreen;
