import { useFormContext } from 'react-hook-form';
import { Card } from 'primereact/card';
import ContractDeviceHeader from './Components/ContractDeviceHeader';
import { Message } from 'primereact/message';
import React, { useEffect } from 'react';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useFetch from '../../../hooks/useFetch';
import { generateInstallmentOptions } from '../../../utils/Utils';
import useStatus from '../../../hooks/useStatus';
import { useToast } from '../../../context/ToastContext';

const ContractBasicInfoStep = () => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useFormContext();

  const _ = useToast();

  const product = watch('product');
  const customer = watch('customer');
  const downPayment = watch('downPayment');
  const sellingPrice = watch('sellingPrice');
  const nbOfInstallments = watch('nbOfInstallments');
  const typeId = watch('typeId');
  const installmentsTypeId = watch('installmentsTypeId');
  const { data: contractType } = useFetch('ContractsType/dropdown');
  const { data: installmentType } = useFetch(
    `ProductsInstallmentTypesMatrix/dropdownByDevice/${product.id}`
  );
  const { data: employee } = useFetch('Employee/dropdown');
  const { statusData } = useStatus('Contract');
  const contractDate = watch('contractDate');
  useEffect(() => {
    if (contractDate) {
      const newExpiryDate = new Date(contractDate);
      newExpiryDate.setFullYear(newExpiryDate.getFullYear() + 1);
      setValue('expiryDate', newExpiryDate);
    }
  }, [contractDate]);

  useEffect(() => {
    if (sellingPrice && downPayment && nbOfInstallments) {
      const installmentAmount = (sellingPrice - downPayment) / nbOfInstallments;
      setValue('installmentAmount', installmentAmount);
    }
  }, [sellingPrice, downPayment, nbOfInstallments]);

  useEffect(() => {
    if (typeId && contractType?.data) {
      const selectedContractType = contractType.data.find((item: { id: any }) => item.id === typeId);
      setValue('contractTypeName', selectedContractType?.name || '');
    }
  }, [typeId, contractType]);

  useEffect(() => {
    if (installmentsTypeId && installmentType?.data) {
      const selectedInstallmentType = installmentType.data.find((item: { id: any }) => item.id === installmentsTypeId);
      setValue('installmentsTypeName', selectedInstallmentType?.name || '');
      ;
      setValue('downPayment', selectedInstallmentType?.downPaymentAmount);
      setValue('nbOfInstallments', selectedInstallmentType?.nbOfInstalments);
    }
  }, [installmentsTypeId, installmentType, downPayment]);


  return (
    <>
      <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
        {customer ? (
          <ContractDeviceHeader
            customerName={customer.fullName}
            phoneNumber={customer.mobile}
            skuNumber={product?.skuCode}
            productName={product?.deviceName}
          />
        ) : null}

        <div style={{ display: 'flex', gap: '24px' }}>
          {/* Right side - Basic Contract Info */}
          <div style={{ width: '100%', paddingTop: '20px' }}>
            {customer ? (
              <>
                <Message
                  severity='success'
                  icon={null}
                  content={<p className={'text-black-alpha-90 font-semibold'}>بيانات العقد الاساسية</p>}
                  style={{
                    width: '100%',
                    backgroundColor: 'rgba(162, 251, 93, 0.2)'
                  }}
                />
                <CustomFormField
                  control={control}
                  name={'contractDate'}
                  customStyle={'pt-4'}
                  label={'تاريخ التعاقد'}
                  errors={errors}
                  type={'calendar'}
                  required
                />
                <CustomFormField
                  control={control}
                  customStyle={'pt-4'}
                  name={'expiryDate'}
                  label={'تاريخ الانتهاء'}
                  errors={errors}
                  type={'calendar'}
                  required
                  disabled
                />
              </>
            ) : null}
            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold'}>بيانات الدفع</p>}
              style={{
                marginTop: customer ? '20px' : '0',
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'sellingPrice'}
              label={'قيمة العقد'}
              errors={errors}
              type={'decimal'}
              required
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'freeInstallation'}
              label={'التركيب مجاني'}
              errors={errors}
              type={'checkbox'}
            />
            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'installmentsTypeId'}
              label={'نوع التقسيط'}
              errors={errors}
              type={'dropdown'}
              required
              options={installmentType?.data ?? []}
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'downPayment'}
              label={'قيمة المقدم'}
              errors={errors}
              type={'decimal'}
              required
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'nbOfInstallments'}
              label={'عدد الاقساط'}
              errors={errors}
              type={'dropdown'}
              options={generateInstallmentOptions(24)}
              required
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'installmentAmount'}
              label={'قيمة القسط'}
              errors={errors}
              type={'decimal'}
              required
              disabled
            />
          </div>

          {/* Left side - Commission Details */}
          <div style={{ width: '100%', paddingTop: '20px' }}>
            {/* Commission Details content here */}
            {customer ? (
              <>
                <Message
                  severity='success'
                  icon={null}
                  content={<p className={'text-black-alpha-90 font-semibold'}>بيانات العمولة (اختياري)</p>}
                  style={{
                    width: '100%',
                    backgroundColor: 'rgba(162, 251, 93, 0.2)'
                  }}
                />

                <CustomFormField
                  control={control}
                  customStyle={'pt-4'}
                  name={'beneficiaryEmployeeId'}
                  label={'اسم الموظف'}
                  errors={errors}
                  type={'dropdown'}
                  options={employee?.data ?? []}
                />
              </>
            ) : null}

            <Message
              severity='success'
              icon={null}
              content={<p className={'text-black-alpha-90 font-semibold '}>اخرى</p>}
              style={{
                marginTop: '20px',
                width: '100%',
                backgroundColor: 'rgba(162, 251, 93, 0.2)'
              }}
            />

            <CustomFormField
              control={control}
              name={'statusId'}
              label={'حاله العقد'}
              errors={errors}
              type={'dropdown'}
              options={statusData?.data ?? []}
            />

            <CustomFormField
              control={control}
              customStyle={'pt-4'}
              name={'remarks'}
              label={'ملاحظات اخرى'}
              errors={errors}
              type={'textarea'}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default ContractBasicInfoStep;
