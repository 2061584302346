import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Steps } from 'primereact/steps';
import { Card } from 'primereact/card';
import './EditTransaction.scss';
import PageHeader from '../../../components/components/Header/PageHeader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { editTransaction, addTransaction, clearTransactionState } from '../../../redux/warehouse/warehouseActions';
import { useToast } from '../../../context/ToastContext';
import { FieldPath, FormProvider, useForm } from 'react-hook-form'; // Import FormProvider from react-hook-form
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { axiosInstance } from '../../../global';

const AddEditTransactionBasicInfo = lazy(() => import('./AddEditTransactionBasicInfo'));
const AddEditTransactionItems = lazy(() => import('./AddEditTransactionItems'));

const transactionInfoSchema = yup.object({
  name: yup.string().required(''),
  currencyId: yup.number().required('نوع العقد مطلوب'),
  warehouseId: yup.number().required('نوع العقد مطلوب'),
  sourceId: yup.number().required('نوع العقد مطلوب'),
  destinationId: yup.number().required('نوع العقد مطلوب')
});

interface AddEditTransProps {
  activeIndexnum?: number;
  addTransaction: (transaction: any) => void;
  editTransaction: (transaction: any) => void;
  clearTransactionState: () => void;
  transaction: any;
  loading: boolean;
  error: string | null;
}
const AddEditTransaction: React.FC<AddEditTransProps> = ({
  activeIndexnum,
  addTransaction,
  editTransaction,
  transaction,
  loading,
  error
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const [activeIndex, setActiveIndex] = useState(0);
  const [submitDone, setSubmitDone] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const methods = useForm({
    resolver: yupResolver(transactionInfoSchema),
    mode: 'all',
    // defaultValues: transaction,
    context: { activeIndex }
  });

  const { handleSubmit, trigger, reset, watch } = methods;

  async function fetchTransaction(id: any) {
    const responseGen = await axiosInstance.get(`/WarehouseTransactionsGen/${id}`);
    const responseDet = await axiosInstance.get(`/WarehouseTransactionsDet/allByGen/${id}`);
    const tempTransGen = responseGen.data.data;
    const tempTransDet = responseDet.data.data;
    let formattedDetails = [];
    let formattedTransaction = {};
    if (tempTransDet) {
      formattedDetails = tempTransDet.map((item: any) => {
        return {
          productId: item.product.id,
          qty: item.qty,
          price: item.price,
          amount: item.amount,
          availableQty: item.availableQty,
          whLocationId: item.whLocation.id
        };
      });
    }
    if (tempTransGen) {
      formattedTransaction = {
        transGenId: tempTransGen.id,
        currencyId: 1,
        name: tempTransGen.name,
        transTypeId: tempTransGen.transTypeId,
        warehouseId: tempTransGen.warehouseId,
        sourceId: tempTransGen.sourceId,
        destinationId: tempTransGen.destinationId,
        supplierId: tempTransGen.supplierId,
        employeeId: tempTransGen.employeeId,
        customerId: tempTransGen.customerId,
        destinationWarehouseId: tempTransGen.destinationWarehouseId,
        remarks: tempTransGen.remarks,
        items: formattedDetails
      };
    }
    return formattedTransaction;
  }

  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      fetchTransaction(params.id).then((fetchedTransaction) => {
        reset(fetchedTransaction); // Populate the form with fetched data
      });
    }
    return () => {
      clearTransactionState();
    };
  }, [params.id, clearTransactionState]);

  useEffect(() => {
    if (transaction && isEditing) {
    }
  }, [transaction, isEditing]);

  useEffect(() => {
    setActiveIndex(activeIndexnum ?? 0);
  }, [activeIndexnum]);

  const items = [{ label: 'بيانات الاذن للعميل' }, { label: 'عناصر الطلب' }];

  useEffect(() => {
    if (submitDone && !loading) {
      if (error) {
        toast.current?.show({
          severity: 'error',
          summary: 'عملية خاطئة',
          detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} الحركة: ${error}`
        });
      } else {
        toast.current?.show({
          severity: 'success',
          summary: 'عملية ناجحة',
          detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} الحركة بنجاح`
        });
        navigate('/warehouses/transactions/');
      }
      setSubmitDone(false);
    }
  }, [loading, error, submitDone, navigate, isEditing]);

  const handleOnClick = async () => {
    const isValid = await trigger(); // Trigger validation before moving to the next step
    const errors = methods.formState.errors; // Retrieve the form errors
    if (isValid) {
      setActiveIndex((prevIndex) => (prevIndex >= 1 ? 0 : prevIndex + 1));
    }
  };

  const handleBackClick = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleSubmitOnClick = (data: any) => {
    if (isEditing) {
      editTransaction(data);
    } else {
      addTransaction(data);
    }
    setSubmitDone(true);
  };

  return (
    <div className='screen'>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitOnClick)}>
          <PageHeader
            icon={'/images/back-arrow.svg'}
            buttons={[]}
            headerTitle={'إضافة طلب إذن جديد'}
            iconOnClick={() => navigate(-1)}
          />
          <Card style={{ margin: '1.5rem', borderRadius: '1.5rem' }}>
            <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly />
          </Card>
          {loading ? (
            <ProgressSpinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          ) : (
            <Suspense fallback={<ProgressSpinner />}>
              {activeIndex === 0 && <AddEditTransactionBasicInfo />}
              {activeIndex === 1 && <AddEditTransactionItems />}
            </Suspense>
          )}
          <div className='btn mb-5'>
            {activeIndex != 1 && (
              <Button className='btn__nextbtn' onClick={handleOnClick}>
                الخطوة التالية
              </Button>
            )}
            {activeIndex === 1 &&
              (loading ? (
                <ProgressSpinner />
              ) : (
                <>
                  <Button className='btn__nextbtn' onClick={handleBackClick}>
                    العودة للخطوة السابقة
                  </Button>
                  <Button className='btn__nextbtn' type='submit'>
                    {isEditing ? 'حفظ التعديلات' : 'إضافة الاذن'}
                  </Button>
                </>
              ))}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    transaction: state.warehouse.transaction,
    loading: state.warehouse.loading,
    error: state.warehouse.error
  };
};

const mapDispatchToProps = {
  editTransaction,
  addTransaction,
  clearTransactionState
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTransaction);
