import React, { useEffect } from 'react';
import CustomFormField from '../../../../components/components/Form/CustomFormField';
import { FormProps } from '../../../../types/FormProps';
import useFetch from '../../../../hooks/useFetch';
import useGeoLocation from '../../../../hooks/useGeoLocation';
import useStatus from '../../../../hooks/useStatus';

const CustomerAddressForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const { data: customer } = useFetch('Customer/dropdown');

  const selectedCountry = watch('countryId');
  const selectedGovernorate = watch('governorateId');
  const selectedArea = watch('areaId');
  const { statusData } = useStatus('Client');

  const {
    countryApiState,
    governorateApiState,
    areaApiState,
    locationApiState,
    handleCountrySelect,
    handleGovernorateSelect,
    handleAreaSelect
  } = useGeoLocation(true);

  useEffect(() => {
    if (selectedCountry) handleCountrySelect({ id: selectedCountry, name: selectedCountry });
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedGovernorate) handleGovernorateSelect({ id: selectedGovernorate, name: selectedGovernorate });
  }, [selectedGovernorate]);

  useEffect(() => {
    if (selectedArea) handleAreaSelect({ id: selectedArea, name: selectedArea });
  }, [selectedArea]);

  return (
    <>
      <CustomFormField
        name={'customerId'}
        label={'العميل'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={customer?.data ?? []}
      />
      <CustomFormField name={'name'} label={'اسم الموقع'} control={control} errors={errors} type={'text'} />
      <CustomFormField name={'address'} label={'العنوان'} control={control} errors={errors} type={'text'} />

      <CustomFormField
        name={'countryId'}
        label={'الدوله'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={countryApiState.state.data ?? []}
      />
      <CustomFormField
        name={'governorateId'}
        label={'المحافظة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={governorateApiState.state.data ?? []}
      />
      <CustomFormField
        name={'areaId'}
        label={'المدينه'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={areaApiState.state.data ?? []}
      />

      <CustomFormField
        name={'locationId'}
        label={'المنطقه'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={locationApiState.state.data ?? []}
      />

      <CustomFormField name={'landmark'} label={'علامه مميزه'} control={control} errors={errors} type={'text'}/>

      <CustomFormField
        name={'statusId'}
        label={'الحاله'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={statusData?.data ?? []}
      />

      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'}/>


    </>
  );
};
export default CustomerAddressForm;
