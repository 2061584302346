import styles from '../Header/Header.module.scss';
import { Button } from 'primereact/button';
import React from 'react';

interface Props {
  key?: number;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

const CustomButton: React.FC<Props> = ({ key, label, onClick, type }) => {
  return (
    <Button
      key={key ?? label}
      label={label}
      onClick={onClick}
      icon={'pi pi-plus'}
      className={styles.button}
      type={type}
      style={{
        background: '#A2FB5D',
        borderColor: '#333',
        color: '#333',
        fontWeight: 'bold',
        marginRight: '.5rem',
        direction: 'rtl',
        minWidth: '180px'
      }}
    />
  );
};

export default CustomButton;
