import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import CustomDropdown from '../components/Dropdown/CustomDropDown';
import { useToast } from '../../context/ToastContext';

function EditWorkingGroups({ initialData, userId, visible, setVisible, statuses, onEditHandler, onDeleteHandler }) {
  const toast = useToast();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [entity, setEntity] = useState({
    id: null,
    userId,
    code: 'TEAMCODE',
    name: '',
    statusId: null,
    remarks: ''
  });

  useEffect(() => {
    if (initialData) {
      setEntity({
        id: initialData.id,
        userId,
        code: 'TEAMCODE',
        name: initialData.name,
        statusId: initialData.status.id,
        remarks: initialData.remarks
      });
      setSelectedStatus(initialData.status.id);
    }
  }, [initialData]);

  function acceptClose() {
    setEntity({
      userId,
      code: 'TEAMCODE',
      name: '',
      statusId: null,
      remarks: ''
    });
    setSelectedStatus(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: 'هل تريد الغاء عملية تعديل مجموعة عمل ؟',
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 3
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  return (
    <>
      
      <ConfirmDialog tagKey={3} />
      <Dialog
        header='تعديل جموعة عمل'
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اسم المجموعة</label>
              <div className='flex gap-1'>
                <InputText name='name' value={entity.name} onChange={handleInputChange} className='w-full' />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة المجموعة</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-between'>
            <Button label='حذف' onClick={onDeleteHandler} severity='danger' link />
            <div className='flex'>
              <Button label='إلغاء' onClick={handleClose} size='small' outlined />
              <button className='modals__btn' onClick={() => onEditHandler(entity)}>
                تعديل مجموعة العمل
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default EditWorkingGroups;
