import { Button } from 'primereact/button';
import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import DetailsSheetGrid from '../../../components/DetailsSheet/DetailsSheetGrid';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HeaderCardTS from '../../../components/Cards/Home header/HeaderCardTS';
import useFetch from '../../../hooks/useFetch';

const ContractDetails = () => {
  const navigate = useNavigate();
  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };

  const location = useLocation();
  const { contractId, customerId } = location.state || {};

  const { data: contractData, isLoading: contractIsLoading } = useFetch(`/Contract/${contractId}`);
  const { data: customerData, isLoading: customerIsLoading } = useFetch(`/Customer/${customerId}`);

  return (
    <>
      <div className='detailsheet__header'>
        <div className='detailsheet__header__right'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='detailsheet__header__right--text'> بيانات العقد</span>
        </div>

        <div className='detailsheet__header__left'>
          <Button
            className='detailsheet__header__left__btn'
            onClick={() =>
              navigate(`/addContract`, {
                state: {
                  contractId: contractId,
                  contractType: contractData?.data.type.name,
                  customerId: customerId,
                  deviceId: contractData?.data.customerDevice
                    ? contractData?.data.customerDevice?.device.id
                    : contractData?.data.product.id
                }
              })
            }
            icon='pi pi-pencil'
            style={Style1}
            text
          >
            تعديل بيانات العقد
          </Button>

          <Button
            className='detailsheet__header__left__btn'
            // onClick={showConfirmDeleteCustomer}
            icon='pi pi-trash'
            style={Style2}
            text
          >
            إيقاف العقد
          </Button>
        </div>
      </div>

      <div className='detailsheet__contentgeneral'>
        {contractIsLoading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <div className='customerdetails__header__card'>
            <HeaderCardTS
              title={'تاريخ التعاقد'}
              num={contractData?.data.contractDate}
              imageUrl={'/images/alt.svg'}
              color={'#6D54DE33'}
            />
            <HeaderCardTS
              title={'تاريخ الانتهاء'}
              num={contractData?.data.expiryDate}
              imageUrl={'/images/alt.svg'}
              color={'#6D54DE33'}
            />
            <HeaderCardTS
              title={'حالة العقد'}
              num={contractData?.data.status?.name}
              imageUrl={'/images/briefcase.svg'}
              color={'#FFEDD0'}
            />
            <HeaderCardTS
              title={'منشئ العقد'}
              num={contractData?.data.user?.firstName + ' ' + contractData?.data.user?.lastName}
              imageUrl={'/images/user1.svg'}
              color={'#C050E838'}
            />
          </div>
        )}
        <div className='detailsheet__contentgeneral__grid'>
          {customerIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            customerData && ( // Add this condition
              <DetailsSheetGrid
                headers={['الاسم كامل', 'حالة العميل', 'رقم الهاتف']}
                contents={[
                  <div className='flex flex-row gap-5'>
                    {customerData?.data.fullName}
                    <Link to={`/customers/details/${customerId}`} className='primary__data--link text-blue-600'>
                      عرض بيانات العميل{` > `}
                    </Link>
                  </div>,
                  <Chip label={customerData?.data.status?.name} />,
                  customerData?.data.mobile
                ]}
                title={'بيانات العميل'}
              />
            )
          )}
          {contractIsLoading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            <DetailsSheetGrid
              headers={[
                'نوع الجهاز',
                'كود العقد',
                'تاريخ انشاء العقد',
                'نوع التقسيط',
                'سعر البيع',
                'عدد الاقساط',
                'نوع التركيب',
                'المقدم',
                'مبلغ القسط',
                'عمولة الموظف',
                'حالة العقد',
                'تفاصيل الطلب',
                'ملاحظات اخرى',
                'تاريخ التجميد'
              ]}
              contents={[
                <div className='flex flex-row gap-5'>
                  {contractData?.data.customerDevice?contractData?.data.customerDevice?.device?.name : contractData?.data.product.name}
                  <Link
                    to={`/product/details/${
                      contractData?.data.customerDevice
                        ? contractData?.data.customerDevice?.device.id
                        : contractData?.data.product.id
                    }/0/device`}
                    className='primary__data--link text-blue-600'
                  >
                    عرض تفاصيل الجهاز{` > `}
                  </Link>
                </div>,
                contractData?.data.code,
                contractData?.data.contractDate,
                contractData?.data.installmentsType?.name,
                contractData?.data.sellingPrice,
                contractData?.data.nbOfInstallments,
                contractData?.data.customerDevice?.remarks,
                contractData?.data.downPayment,
                contractData?.data.installmentAmount,
                <div className='flex flex-row gap-5'>
                  {contractData?.data.commission}
                  <Link to={``} className='primary__data--link text-blue-600'>
                    تفاصيل العمولة {` > `}
                  </Link>
                </div>,
                <Chip label={contractData?.data.status?.name} />,
                contractData?.data.remarks || 'لا توجد ملاحظات',
                ''
              ]}
              title={'بيانات العقد'}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContractDetails;
