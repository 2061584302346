import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';

import { axiosInstance } from '../../global';
import './Modals.scss';
import { useToast } from '../../context/ToastContext';

function ViewInstallmentsDetails({ visible, setVisible, id }) {
  const toast = useToast();
  const [InstallmentList, setInstallmentList] = useState();
  const options = [
    { label: 'نسبة', value: false },
    { label: 'مبلغ', value: true }
  ];

  function acceptClose() {
    setVisible(false);
  }

  useEffect(() => {
    axiosInstance
      .get(`/ProductsInstallmentTypesMatrix/${id}`)
      .then((response) => {
        setInstallmentList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>

      <ConfirmDialog tagKey={2} key={2} closable={false} closeOnEscape={false} draggable={false} resizable={false} />
      <Dialog
        header={'تفاصيل الخطة'}
        visible={visible}
        onHide={acceptClose}
        className='modals'
        draggable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اسم النظام</label>
              <InputText value={InstallmentList?.installmentType.name} className='w-full' disabled={true} />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>طريقة الدفع</label>
              <InputText value={InstallmentList?.installmentType.paymentTypeName} className='w-full' disabled={true} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>نوع قيمة المقدم</label>
              <SelectButton
                value={InstallmentList?.installmentType.downPaymentAmountTypeName}
                options={options}
                disabled={true}
              />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>قيمة المقدم</label>
              <InputText value={InstallmentList?.installmentType.id} className='w-full' disabled={true} />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>عدد الاقساط</label>
              <InputNumber
                inputId='minmax-buttons'
                value={InstallmentList?.installmentType.nbOfInstalments}
                mode='decimal'
                showButtons
                min={0}
                max={1000}
                disabled={true}
              />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>نسبة الفائدة الشهرية</label>
              <InputNumber
                inputId='minmax-buttons'
                value={InstallmentList?.installmentType.monthlyInterestPercent}
                mode='decimal'
                showButtons
                min={0}
                max={1000}
                disabled={true}
              />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>نسبة الفائدة السنوية</label>
              <InputNumber
                inputId='minmax-buttons'
                value={InstallmentList?.installmentType.yearlyInterestPercent}
                mode='decimal'
                showButtons
                min={0}
                max={1000}
                disabled={true}
              />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>حالة التقسيط</label>
              <InputText value={InstallmentList?.status.name} className='w-full' disabled={true} />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputText
                name='remarks'
                value={InstallmentList?.installmentType.remarks}
                className='w-full'
                disabled={true}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default ViewInstallmentsDetails;
