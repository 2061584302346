import React, { useRef } from 'react';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import GenericModal from '../../../components/components/Modal/GenericModal';
import useModal from '../../../hooks/useModal';
import * as yup from 'yup';
import GenericForm from '../../../components/Forms/GenericForm';
import PositionForm from './Forms/PositionForm';
import UserTypeForm from './Forms/UserTypeForm';
import { genericSchemeForNames } from '../../../components/components/Form/Scheme';
import BranchForm from './Forms/BranchForm';
import { useToast } from '../../../context/ToastContext';

const positionSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup.string().when('id', (id, schema) => {
    if (typeof id === 'number' && id !== null && id !== undefined) {
      return schema.required('Country name is required').min(1, 'At least one location name is required');
    }
    return schema.notRequired().nullable(); // Schema for case when id is not present
  }),
  names: yup
    .array()
    .of(yup.string().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  userTypeId: yup.number().required('User Type is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});
const userTypeSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup.string().when('id', (id, schema) => {
    if (typeof id === 'number' && id !== null && id !== undefined) {
      return schema.required('Country name is required').min(1, 'At least one location name is required');
    }
    return schema.notRequired().nullable(); // Schema for case when id is not present
  }),
  names: yup
    .array()
    .of(yup.string().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  teamManager: yup.boolean().required('teamManager is required'),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});
const addBranchSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  code: yup.string().required().default(null),
  locationId: yup.number().required('Country is required'),
  name: yup.string().required().default(null),
  statusId: yup.number().required('Status is required'),
  address: yup.string().required(),
  remarks: yup.string()
});

function UsersAndEmployees() {
  const toast = useToast();
  const genericPageRef = useRef<{ fetchTabData: () => void }>(null);

  const fetchTabData = () => {
    genericPageRef.current?.fetchTabData();
  };
  const {
    isVisible: isPositionVisible,
    openModal: positionOpenModal,
    closeModal: positionCloseModal,
    control: positionControl,
    handleSubmit: positionSubmit,
    errors: positionErrors,
    setValue: positionSetValue,
    loading: positionLoading,
    setLoading: positionSetLoading,
    watch: positionWatch
  } = useModal({ remarks: ' ', names: [] }, '/Position', fetchTabData, positionSchema, toast);

  const {
    isVisible: isDepartmentVisible,
    openModal: departmentOpenModal,
    closeModal: departmentCloseModal,
    control: departmentControl,
    handleSubmit: departmentSubmit,
    errors: departmentErrors,
    setValue: departmentSetValue,
    loading: departmentLoading,
    setLoading: departmentSetLoading,
    watch: departmentWatch
  } = useModal({ remarks: ' ', names: [] }, '/Department', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isVacationsTypeVisible,
    openModal: vacationsTypeOpenModal,
    closeModal: vacationsTypeCloseModal,
    control: vacationsTypeControl,
    handleSubmit: vacationsTypeSubmit,
    errors: vacationsTypeErrors,
    setValue: vacationsTypeSetValue,
    loading: vacationsTypeLoading,
    setLoading: vacationsTypeSetLoading,
    watch: vacationsTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/VacationsType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isAttendanceTypeVisible,
    openModal: attendanceTypeOpenModal,
    closeModal: attendanceTypeCloseModal,
    control: attendanceTypeControl,
    handleSubmit: attendanceTypeSubmit,
    errors: attendanceTypeErrors,
    setValue: attendanceTypeSetValue,
    loading: attendanceTypeLoading,
    setLoading: attendanceTypeSetLoading,
    watch: attendanceTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/AttendanceType', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isUsersTypeVisible,
    openModal: usersTypeOpenModal,
    closeModal: usersTypeCloseModal,
    control: usersTypeControl,
    handleSubmit: usersTypeSubmit,
    errors: usersTypeErrors,
    setValue: usersTypeSetValue,
    loading: usersTypeLoading,
    setLoading: usersTypeSetLoading,
    watch: usersTypeWatch
  } = useModal({ remarks: ' ', names: [] }, '/UsersType', fetchTabData, userTypeSchema, toast);

  const {
    isVisible: isPayrollItemVisible,
    openModal: payrollItemOpenModal,
    closeModal: payrollItemCloseModal,
    control: payrollItemControl,
    handleSubmit: payrollItemSubmit,
    errors: payrollItemErrors,
    setValue: payrollItemSetValue,
    loading: payrollItemLoading,
    setLoading: payrollItemSetLoading,
    watch: payrollItemWatch
  } = useModal({ remarks: ' ', names: [] }, '/PayrollItem', fetchTabData, genericSchemeForNames, toast);

  const {
    isVisible: isBranchVisitable,
    openModal: branchOpenModal,
    closeModal: branchCloseModal,
    control: branchControl,
    handleSubmit: branchSubmit,
    errors: branchErrors,
    setValue: branchSetValue,
    loading: branchLoading,
    setLoading: branchSetLoading,
    watch: branchWatch
  } = useModal({ remarks: ' ', names: [] }, '/Branch', fetchTabData, addBranchSchema, toast);

  return (
    <>
      <GenericPage
        ref={genericPageRef}
        tabs={[
          {
            label: 'الفروع',
            tableHeader: 'الفروع',
            entityName: 'الفرع',
            dataKey: 'Branch',
            apiUrl: '/Branch/list',
            columns: [
              { header: 'الكود', field: 'code', isChip: false },
              { header: 'اسم الفرع', field: 'name', isChip: false },
              { header: 'اسم المنطقه', field: 'locationName', isChip: false },
              { header: 'اسم المدينه', field: 'areaName', isChip: false },
              { header: 'اسم المحافظه', field: 'governorateName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة فروع جديدة',
                onClick: () => {
                  branchOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              branchOpenModal({
                id: rowData.id,
                code: rowData.code,
                countryId: rowData.countryID,
                governorateId: rowData.governorateID,
                areaId: rowData.areaID,
                locationId: rowData.locationID,
                name: rowData.name,
                statusId: rowData.statusID,
                address: rowData.address,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              branchOpenModal();
            }
          },
          {
            label: 'الادوار',
            tableHeader: 'الادوار',
            entityName: 'دور',
            dataKey: 'UsersType',
            apiUrl: '/UsersType/list',
            columns: [
              { header: 'الادوار', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة ادوار جديدة',
                onClick: () => {
                  usersTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              usersTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                teamManager: rowData.teamManager,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              usersTypeOpenModal();
            }
          },
          {
            label: 'الدرجات الوظيفية',
            tableHeader: 'الدرجات الوظيفية',
            entityName: 'درجة وظيفية',
            dataKey: 'Position',
            apiUrl: '/Position/list',
            columns: [
              { header: 'الدرجات الوظيفية', field: 'name', isChip: false },
              { header: 'الدول', field: 'userTypeName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة درجة وظيفية جديدة',
                onClick: () => {
                  positionOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              positionOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                userTypeId: rowData.userTypeID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              positionOpenModal();
            }
          },
          {
            label: 'الاقسام',
            tableHeader: 'الاقسام',
            entityName: 'قسم',
            dataKey: 'Department',
            apiUrl: '/Department/list',
            columns: [
              { header: 'الاقسام', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة قسم جديد',
                onClick: () => {
                  departmentOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              departmentOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              departmentOpenModal();
            }
          },
          {
            label: 'انواع الاجازات',
            tableHeader: 'انواع الاجازات',
            entityName: 'نوع اجازة',
            dataKey: 'VacationsType',
            apiUrl: '/VacationsType/list',
            columns: [
              { header: 'انواع الاجازات', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة نوع اجازة جديد',
                onClick: () => {
                  vacationsTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              vacationsTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              vacationsTypeOpenModal();
            }
          },
          {
            label: 'انواع أنظمة الحضور',
            tableHeader: 'انواع أنظمة الحضور',
            entityName: 'نظام حضور',
            dataKey: 'AttendanceType',
            apiUrl: '/AttendanceType/list',
            columns: [
              { header: 'انواع أنظمة الحضور', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة أنظمة حضور جديدة',
                onClick: () => {
                  attendanceTypeOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              attendanceTypeOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              attendanceTypeOpenModal();
            }
          },
          {
            label: 'انواع مفردات المرتبات',
            tableHeader: 'انواع مفردات المرتبات',
            entityName: 'مفردات مرتب',
            dataKey: 'PayrollItem',
            apiUrl: '/PayrollItem/list',
            columns: [
              { header: 'انواع مفردات المرتبات', field: 'name', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true },
              { header: 'ملاحظات', field: 'remarks', isChip: false }
            ],
            edit: true,
            headerButtons: [
              {
                label: 'إضافة انواع مفردات مرتب',
                onClick: () => {
                  payrollItemOpenModal();
                }
              }
            ],
            rowOnClick: (rowData) => {
              payrollItemOpenModal({
                id: rowData.id,
                name: rowData.name,
                statusId: rowData.statusID,
                remarks: rowData.remarks
              });
            },
            emptyStateUrl: '',
            emptyStateButtonClick: () => {
              payrollItemOpenModal();
            }
          }
        ]}
        headerTitle='الموظفين'
        headerIcon='/images/database.svg'
      />
      <GenericModal
        headerTitle={departmentWatch('id') === undefined ? 'اضافه قسم جديد' : 'تعديل قسم جديد'}
        isVisible={isDepartmentVisible}
        onHide={departmentCloseModal}
        handleSubmit={departmentSubmit}
        toast={toast}
        loading={departmentLoading}
        isEditMode={departmentWatch('id') !== undefined}
      >
        <GenericForm
          control={departmentControl}
          errors={departmentErrors}
          setValue={departmentSetValue}
          watch={departmentWatch}
          extraProps={{ entityName: 'قسم' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={positionWatch('id') === undefined ? 'اضافه درجة وظيفية جديده' : 'تعديل  درجة وظيفية'}
        isVisible={isPositionVisible}
        onHide={positionCloseModal}
        handleSubmit={positionSubmit}
        toast={toast}
        loading={positionLoading}
        isEditMode={positionWatch('id') !== undefined}
      >
        <PositionForm
          control={positionControl}
          errors={positionErrors}
          setValue={positionSetValue}
          watch={positionWatch}
          extraProps={{ entityName: 'درجة وظيفية' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={vacationsTypeWatch('id') === undefined ? 'اضافه نوع اجازة جديده' : 'تعديل نوع اجازة'}
        isVisible={isVacationsTypeVisible}
        onHide={vacationsTypeCloseModal}
        handleSubmit={vacationsTypeSubmit}
        toast={toast}
        loading={vacationsTypeLoading}
        isEditMode={vacationsTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={vacationsTypeControl}
          errors={vacationsTypeErrors}
          setValue={vacationsTypeSetValue}
          watch={vacationsTypeWatch}
          extraProps={{ entityName: 'نوع اجازة' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={attendanceTypeWatch('id') === undefined ? 'اضافه نظام حضور جديده' : 'تعديل نظام الحضور'}
        isVisible={isAttendanceTypeVisible}
        onHide={attendanceTypeCloseModal}
        handleSubmit={attendanceTypeSubmit}
        toast={toast}
        loading={attendanceTypeLoading}
        isEditMode={attendanceTypeWatch('id') !== undefined}
      >
        <GenericForm
          control={attendanceTypeControl}
          errors={attendanceTypeErrors}
          setValue={attendanceTypeSetValue}
          watch={attendanceTypeWatch}
          extraProps={{ entityName: 'نظام حضور' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={usersTypeWatch('id') === undefined ? 'اضافه دور جديد' : 'تعديل الدور'}
        isVisible={isUsersTypeVisible}
        onHide={usersTypeCloseModal}
        handleSubmit={usersTypeSubmit}
        toast={toast}
        loading={usersTypeLoading}
        isEditMode={usersTypeWatch('id') !== undefined}
      >
        <UserTypeForm
          control={usersTypeControl}
          errors={usersTypeErrors}
          setValue={usersTypeSetValue}
          watch={usersTypeWatch}
          extraProps={{ entityName: 'نظام دور' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={payrollItemWatch('id') === undefined ? 'اضافه مفردات مرتب جديده' : 'تعديل مفردات المرتب'}
        isVisible={isPayrollItemVisible}
        onHide={payrollItemCloseModal}
        handleSubmit={payrollItemSubmit}
        toast={toast}
        loading={payrollItemLoading}
        isEditMode={payrollItemWatch('id') !== undefined}
      >
        <GenericForm
          control={payrollItemControl}
          errors={payrollItemErrors}
          setValue={payrollItemSetValue}
          watch={payrollItemWatch}
          extraProps={{ entityName: 'مفردات مرتب' }}
        />
      </GenericModal>

      <GenericModal
        headerTitle={branchWatch('id') === undefined ? 'اضافه دوله' : 'تعديل دوله'}
        isVisible={isBranchVisitable}
        onHide={branchCloseModal}
        handleSubmit={branchSubmit}
        toast={toast}
        loading={branchLoading}
        isEditMode={branchWatch('id') !== undefined}
      >
        <BranchForm control={branchControl} errors={branchErrors} setValue={branchSetValue} watch={branchWatch} />
      </GenericModal>
    </>
  );
}

export default UsersAndEmployees;
