import * as yup from 'yup';

export const genericSchemeForNames = yup.object().shape({
  id: yup.number().nullable().default(null),
  name: yup
    .string()
    .trim()
    .when('id', (id, schema) => {
      if (typeof id === 'number' && id !== null && id !== undefined) {
        return schema.required('Country name is required').min(1, 'At least one location name is required');
      }
      return schema.notRequired().nullable(); // Schema for case when id is not present
    }),
  names: yup
    .array()
    .of(yup.string().trim().required('Location name cannot be empty'))
    .when('id', (id, schema) => {
      if (id === null || id === undefined) {
        return schema.min(1, 'At least one location name is required').required('Location names are required');
      }
      return schema.notRequired();
    }),
  statusId: yup.number().required('Status is required'),
  remarks: yup.string().nullable()
});

