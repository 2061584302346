import { useState } from 'react';

import { axiosInstance } from '../global';

import { useApiState } from './useApiState';

export type IdAndName = {
  id: number;
  name: string;
};

const useGeoLocation = (isCountryRequired: boolean = false) => {
  const [selectedCountry, setSelectedCountry] = useState<IdAndName | null>(null);
  const [selectedGovernorate, setSelectedGovernorate] = useState<IdAndName | null>(null);
  const [selectedArea, setSelectedArea] = useState<IdAndName | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<IdAndName | null>(null);

  const countryApiState = useApiState<IdAndName[]>({
    apiCall: () => axiosInstance.get('/Country/dropdown').then((res) => res.data.data),
    isLazy: !isCountryRequired // Load countries lazily only if they are not required
  });

  const governorateApiState = useApiState<IdAndName[], IdAndName[], { countryId?: number }>({
    apiCall: async (args) => {
      if (isCountryRequired && args && args.countryId) {
        const res = await axiosInstance.get(`/Governorate/allByCountryIdDropdown/${args.countryId}`);
        return res.data.data;
      } else {
        const res = await axiosInstance.get('/Governorate/dropdown');
        return res.data.data;
      }
    },
    isLazy: isCountryRequired
  });

  const areaApiState = useApiState<IdAndName[], IdAndName[], { governorateId: number }>({
    apiCall: async (args) => {
      if (args && args.governorateId) {
        const res = await axiosInstance.get(`/Area/allByGovernorateIdDropdown/${args.governorateId}`);
        return res.data.data;
      }
      // return Promise.reject(new Error('Invalid governorate ID'));
    },
    isLazy: true
  });

  const locationApiState = useApiState<IdAndName[], IdAndName[], { areaId: number }>({
    apiCall: async (args) => {
      if (args && args.areaId) {
        const res = await axiosInstance.get(`/Location/allByAreaIdDropdown/${args.areaId}`);
        return res.data.data;
      }
      // return Promise.reject(new Error('Invalid area ID'));
    },
    isLazy: true
  });

  const handleCountrySelect = async (country: IdAndName) => {
    setSelectedCountry(country);
    setSelectedGovernorate(null);
    setSelectedArea(null);
    setSelectedLocation(null);
    await governorateApiState.lazyApiCall({ countryId: country.id });
  };

  const handleGovernorateSelect = async (governorate: IdAndName) => {
    setSelectedGovernorate(governorate);
    setSelectedArea(null);
    setSelectedLocation(null);
    await areaApiState.lazyApiCall({ governorateId: governorate.id });
  };

  const handleAreaSelect = async (area: IdAndName) => {
    setSelectedArea(area);
    setSelectedLocation(null);
    await locationApiState.lazyApiCall({ areaId: area.id });
  };

  const handleLocationSelect = (location: IdAndName) => {
    setSelectedLocation(location);
  };

  return {
    countryApiState,
    governorateApiState,
    areaApiState,
    locationApiState,
    selectedCountry,
    selectedGovernorate,
    selectedArea,
    selectedLocation,
    handleCountrySelect,
    handleGovernorateSelect,
    handleAreaSelect,
    handleLocationSelect,
    resetGeoLocationState: () => {
      setSelectedCountry(null);
      setSelectedGovernorate(null);
      setSelectedArea(null);
      setSelectedLocation(null);
      governorateApiState.resetApiCallState();
      areaApiState.resetApiCallState();
      locationApiState.resetApiCallState();
    }
  };
};

export default useGeoLocation;
