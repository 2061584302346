import { Accordion, AccordionTab } from 'primereact/accordion';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useFetch from '../../../hooks/useFetch';
import { useEffect } from 'react';

const AddEditProductStages = () => {
  const Style = { color: 'red', padding: '0.7rem' };
  const {
    control,
    getFieldState,
    getValues,
    watch,
    formState: { errors }
  } = useFormContext();
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => ({ label: month, value: month }));

  const { data: productsStagesType } = useFetch('ProductsStagesType/dropdown');
  const { data: stages } = useFetch('Product/stages/dropdown');
  const nbOfStages = watch('nbOfStages');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productsStages',
  });

  useEffect(() => {
    const currentStagesCount = fields.length;

    // If nbOfStages is greater than current stages, append new empty stages
    if (nbOfStages > currentStagesCount) {
      for (let i = currentStagesCount; i < nbOfStages; i++) {
        append({
          stageNb: i + 1,
          stageTypeId: '',
          stageItemId: '',
          mandatoryReplacement: false,
          replacementMonths: '',
          remarks: ''
        });
      }
    }

    // If nbOfStages is less than current stages, remove the excess stages
    if (nbOfStages < currentStagesCount) {
      for (let i = currentStagesCount; i > nbOfStages; i--) {
        remove(i - 1);
      }
    }
  }, [nbOfStages, append, remove, fields.length]);

  return (
    <>
      <div className='product'>
        <div className='product__stages'>
          <span className='Title'>تفاصيل المراحل</span>
          <div className='w-full flex gap-5'>
            <div className='flex w-full flex-column gap-4'>
              {fields.map((field, index) => (
                <div className='Stage__content w-full flex gap-5'>
                  <Accordion style={{ width: '100%' }} activeIndex={0}>
                    <AccordionTab header={`مرحلة ${index + 1}`}>
                      <div className='w-full flex gap-5'>
                        <div className='flex w-full flex-column gap-4'>
                          <CustomFormField
                            name={`productsStages[${index}].stageNb`}
                            label={'رقم المرحلة'}
                            control={control}
                            errors={errors}
                            type={'decimal'}
                            disabled={true}
                          />
                          <CustomFormField
                            name={`productsStages[${index}].stageTypeId`}
                            label={'نوع المرحلة'}
                            control={control}
                            errors={errors}
                            type={'dropdown'}
                            options={productsStagesType?.data ?? []}
                          />

                          <CustomFormField
                            name={`productsStages[${index}].stageItemId`}
                            label={'المرحلة'}
                            control={control}
                            errors={errors}
                            type={'dropdown'}
                            options={stages?.data ?? []}
                          />
                          <CustomFormField
                            name={`productsStages[${index}].mandatoryReplacement`}
                            label={'يجب التغيير'}
                            control={control}
                            errors={errors}
                            type={'checkbox'}
                          />

                          <CustomFormField
                            name={`productsStages[${index}].replacmentMonths`}
                            label={'عدد شهور تغير المرحلة'}
                            control={control}
                            errors={errors}
                            optionLabel={'label'}
                            optionValue={'value'}
                            type={'dropdown'}
                            options={months}
                          />

                          <CustomFormField
                            name={`productsStages[${index}].remarks`}
                            label={'ملاحظات'}
                            control={control}
                            errors={errors}
                            type={'textarea'}
                          />
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditProductStages;
