import { useState } from 'react';
import { axiosInstance } from '../global';
import { useApiState } from './useApiState';

export type IdAndName = {
  id: number;
  name: string;
};

const useComplaintHierarchy = () => {
  const [selectedComplaintType, setSelectedComplaintType] = useState<IdAndName | null>(null);
  const [selectedComplaintFamily, setSelectedComplaintFamily] = useState<IdAndName | null>(null);
  const [selectedComplaintCategory, setSelectedComplaintCategory] = useState<IdAndName | null>(null);

  const complaintTypeApiState = useApiState<IdAndName[]>({
    apiCall: () => axiosInstance.get('/ComplaintsType/dropdown').then((res) => res.data.data),
    isLazy: false
  });

  const complaintFamilyApiState = useApiState<IdAndName[], IdAndName[], { complaintTypeId: number }>({
    apiCall: async (args) => {
      if (args && args.complaintTypeId) {
        const res = await axiosInstance.get(`/ComplaintsFamily/allByTypeIdDropdown/${args.complaintTypeId}`);
        return res.data.data;
      }
    },
    isLazy: true
  });

  const complaintCategoryApiState = useApiState<IdAndName[], IdAndName[], { complaintFamilyId: number }>({
    apiCall: async (args) => {
      if (args && args.complaintFamilyId) {
        const res = await axiosInstance.get(`/ComplaintsCategory/allByFamilyIdDropdown/${args.complaintFamilyId}`);
        return res.data.data;
      }
    },
    isLazy: true
  });


  const handleComplaintTypeSelect = async (complaintType: IdAndName) => {
    setSelectedComplaintType(complaintType);
    setSelectedComplaintFamily(null);
    setSelectedComplaintCategory(null);
    await complaintFamilyApiState.lazyApiCall({ complaintTypeId: complaintType.id });
  };

  const handleComplaintFamilySelect = async (complaintFamily: IdAndName) => {
    setSelectedComplaintFamily(complaintFamily);
    setSelectedComplaintCategory(null);
    await complaintCategoryApiState.lazyApiCall({ complaintFamilyId: complaintFamily.id });
  };

  const handleComplaintCategorySelect = async (complaintCategory: IdAndName) => {
    setSelectedComplaintCategory(complaintCategory);
  };


  return {
    complaintTypeApiState,
    complaintFamilyApiState,
    complaintCategoryApiState,
    selectedComplaintType,
    selectedComplaintFamily,
    selectedComplaintCategory,
    handleComplaintTypeSelect,
    handleComplaintFamilySelect,
    handleComplaintCategorySelect,
    resetComplaintHierarchyState: () => {
      setSelectedComplaintType(null);
      setSelectedComplaintFamily(null);
      setSelectedComplaintCategory(null);
      complaintFamilyApiState.resetApiCallState();
      complaintCategoryApiState.resetApiCallState();
    }
  };
};

export default useComplaintHierarchy;
