import React, { useEffect } from 'react';
import { FormProps } from '../../../types/FormProps';
import CustomFormField from '../../../components/components/Form/CustomFormField';
import useStatus from '../../../hooks/useStatus';
import useFetch from '../../../hooks/useFetch';

const EmployeeLoanForm: React.FC<FormProps> = ({ control, errors, setValue, watch, extraProps }) => {
  const isEditMode = watch('id') != null;
  const employeeID = watch('employeeId');
  const amount = watch('amount');
  const nbOfInstallments = watch('nbOfInstallments');
  const { statusData, statusError, isStatusLoading, getStatusData } = useStatus('Employee');

  const { data: employeeData, isLoading: isEmployeeLoading } = useFetch('/Employee/dropdown');

  useEffect(() => {
    if (!isEmployeeLoading) {
      setValue('employeeId', employeeID);
    }
  }, [isEmployeeLoading]);

  useEffect(() => {
    if (amount && nbOfInstallments) {
      setValue('installmentValue', amount / nbOfInstallments);
    }
  }, [amount, nbOfInstallments]);

  return (
    <>
      <CustomFormField
        name={'employeeId'}
        label={isEditMode ? 'اسم الموظف' : 'اختر الموظف'}
        control={control}
        errors={errors}
        type={'dropdown'}
        options={employeeData?.data ?? []}
        disabled={extraProps?.isDisabled || isEmployeeLoading}
      />
      <CustomFormField
        name={'refernce'}
        label={'المرجع'}
        control={control}
        errors={errors}
        type={'text'}
        placeholder='المرجع'
      />
      <CustomFormField
        name={'code'}
        label={'الكود'}
        control={control}
        errors={errors}
        type={'text'}
        placeholder='الكود'
      />
      <CustomFormField
        name={'amount'}
        label={'قيمة السلفة'}
        control={control}
        errors={errors}
        type={'decimal'}
        placeholder='قيمة السلفة'
      />
      <CustomFormField
        name={'nbOfInstallments'}
        label={'عدد الاقساط'}
        control={control}
        errors={errors}
        type={'decimal'}
        placeholder='عدد الاقساط'
      />
      <CustomFormField
        name={'installmentValue'}
        label={'قيمة القسط'}
        control={control}
        errors={errors}
        type={'decimal'}
        placeholder='قيمة القسط'
        disabled={true}
      />
      <CustomFormField
        name={'firstInstallmentDate'}
        label={'تاريخ اول قسط'}
        control={control}
        errors={errors}
        type={'calendar'}
      />
      <CustomFormField
        name={'statusId'}
        label={`اختر الحالة `}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />
      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </>
  );
};

export default EmployeeLoanForm;
