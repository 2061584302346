import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import CustomDropdown from '../components/Dropdown/CustomDropDown';
import { useToast } from '../../context/ToastContext';

function EditEntity({
  title,
  entityName,
  entityBase,
  visible,
  setVisible,
  statuses,
  onClosePrompt,
  onEditHandler,
  onDeleteHandler,
  additionalFields = {}
}) {
  const toast = useToast();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [entity, setEntity] = useState({
    id: null,
    name: null,
    statusId: null,
    remarks: null,
    ...Object.fromEntries(Object.keys(additionalFields).map((fieldName) => [fieldName, null]))
  });

  useEffect(() => {
    
    if (visible && entityBase) {
      setEntity({
        id: entityBase.id,
        name: entityBase.name,
        statusId: entityBase.status.id || null,
        remarks: entityBase.remarks,
        complaintType: entityBase.complaintType,
        ...Object.fromEntries(
          Object.keys(additionalFields).map((fieldName) => [
            fieldName,
            entityBase[fieldName.endsWith('Id') ? fieldName.slice(0, -2) : fieldName].id
          ])
        )
      });
      setSelectedStatus(entityBase.status.id);
    }
  }, [visible, entityBase]);

  function acceptClose() {
    setEntity({
      id: null,
      name: null,
      statusId: null,
      remarks: null,
      ...additionalFields
    });
    setSelectedStatus(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleDelete(entity) {
    confirmDialog({
      header: `هل أنت متأكد من أنك تريد حذف ${entityName}؟`,
      accept: () => {
        onDeleteHandler(entity.id);
        acceptClose();
      },
      acceptLabel: `نعم حذف ${entityName} : ${entity.name}`,
      acceptClassName: 'p-button-danger',
      rejectLabel: 'لا',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  function handleAdditionalFieldChange(fieldName, value) {
    setEntity((prevEntity) => ({
      ...prevEntity,
      [fieldName]: value
    }));
  }

  const additionalFieldsControls = Object.entries(additionalFields).map(([fieldName, fieldOptions]) => (
    <div key={fieldName} className='w-full flex flex-column gap-2'>
      <label className='modals__label'>{fieldOptions.label}</label>
      <CustomDropdown
        value={entity[fieldName]}
        options={fieldOptions.values.map((option) => ({ label: option.name, value: option.id }))}
        onChange={(e) => handleAdditionalFieldChange(fieldName, e.value)}
        className='w-full'
      />
    </div>
  ));

  return (
    <>
      

      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          {additionalFieldsControls}

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اسم {entityName}</label>
              <InputText name='name' value={entity.name} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة {entityName}</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر الحالة'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea
                name='remarks'
                value={entity.remarks || ''}
                className='w-full'
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='flex justify-content-between'>
            <Button
              link
              label='حذف'
              style={{ color: '#E24A34' }}
              severity='danger'
              onClick={(event) => handleDelete(entity)}
            />
            <div className='flex justify-content-end'>
              <Button label='إلغاء' onClick={handleClose} size='small' outlined />
              <button className='modals__btn' onClick={() => onEditHandler(entity)}>
                تعديل {entityName}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default EditEntity;
